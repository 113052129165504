
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BaseService } from '../base/base.service';
import { User } from './user';
import { HungrigClient, ResponseFactory, ErrorFactory } from '@ro-ngx/core';
import { Observable } from 'rxjs';

@Injectable()
export class UserService extends BaseService {

    constructor(public hungrigClient: HungrigClient) {
        super(hungrigClient);
    }

    public me(): Observable<User> {
        return this.hungrigClient.get(this.url('rocms/auth/me')).pipe(
            map(ResponseFactory.toData),
            catchError(ErrorFactory.throwObservable));
    }
}
