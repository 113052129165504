export class OrderRowHeaderColumnCarrierCustomerCommentComponent {
    public static readonly controller = OrderRowHeaderColumnCarrierCustomerCommentComponent;
    public static readonly controllerAs = 'vm';
    public static readonly template = require('./order-row-header-column-carrier-customer-comment.component.html');
    public static readonly bindings = {
        order: '<'
    };

    public order: any;

    public hasCustomerMessage() {
        if (typeof this.order.hasCustomerMessage !== 'undefined') {
            return this.order.hasCustomerMessage;
        }

        return !!this.order.order.ordercustomer.message;
    }
}
