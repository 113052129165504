import * as angular from "angular";
import { AbstractRepository } from "./abstract/abstract-repository";
import { AlertRepository } from "./alert/alert-repository";
import { AppController } from "./app.controller";
import { PrinterComponent } from "./help/printer.component";
import { HelpComponent } from "./help/help.component";
import { AngularMomentConfig } from "./configs/angular-moment.config";
import { ENV } from "./enviroment";
import { assign, set, get } from 'lodash';
import { restangularConfig } from "./configs/restangular.config";
import "common/trustedFilter/trustedFilter.filter";
import "common/zipcodeFilter/zipcodeFilter.filter";
import "app/admin/admin";
import "app/auth/auth";
import "app/auth/auth-bridge";
import "app/login/login";
import "app/eathere/eathere";
import "app/financeanalyzer/financeanalyzer";
import "app/app.factory";
import "app/delivery/delivery";
import "app/delivery/company/deliverycompany";
import "app/delivery/assign/deliveryassign";
import "app/delivery/driver/deliverydriver";
import "app/delivery/company/deliverymessage.factory";
import "app/delivery/company/deliverycompany.factory";
import "app/delivery/company/summary/summary.component";
import "app/delivery/priceplan/deliverypriceplan";
import "app/delivery/priceplan/deliverypriceplan.edit.directive";
import "app/delivery/priceplan/deliverypriceplan.factory";
import "app/delivery/priceplanspan/deliverypriceplanspan.directive";
import "app/delivery/priceplanspan/deliverypriceplanspan.factory";
import "app/delivery/template/deliverytemplate";
import "app/delivery/template/deliverytemplate.factory";
import "app/delivery/template/deliverytemplate.edit.directive";
import "app/delivery/templatearea/deliverytemplatearea.directive";
import "app/delivery/templatearea/deliverytemplatearea.factory";
import "app/delivery/templatearea/deliveryzipspan.factory";
import "app/delivery/restaurant/deliveryrestaurant";
import "app/delivery/restaurant/deliveryrestaurant.factory";
import "app/delivery/timeout/schedule/timeoutschedule";
import "app/delivery/global/deliveryglobal";
import "app/delivery/global/deliveryconverter.factory";
import "app/delivery/financesettings/deliveryfinancesettings";
import "app/delivery/summaries/deliverysummaries";
import "app/delivery/polygon/polygon";
import "app/driverfinance/driverfinance";
import "app/alert/alert";
import "app/alert/alerter.factory";
import "app/alert/alerts/alerts.component";
import "app/alert/group/alertgroup";
import "app/alert/group/alertgroup.factory";
import "app/alert/profile/alertprofile";
import "app/alert/profile/removeprofilemodal/removeprofile.modal";
import "app/alert/profile/newprofilemodal/newprofile.modal";
import "app/alert/profile/alertprofile.factory";
import "app/alert/alertmember.factory";
import "app/alertviewer/alertviewer.component";
import "app/deliveryfinance/deliveryfinance";
import "app/othersettings/othersettings";
import "app/othersettings/admincontact/admincontact";
import "app/user/user";
import "app/user/user.factory";
import "app/appthemes/appthemes";
import "app/appthemes/apptheme/apptheme";
import "app/appthemes/appconfig/appconfig";
import "app/appthemes/apptheme/apptheme.factory";
import "app/appthemes/appconfig/appconfig.factory";
import "app/tech/tech.component";
import "app/pm/pm";
import "common/ErrorCatcher/errorcatcher.factory";
import "common/modalService/modalService";
import "common/roFormsubmit/roResizable";
import "common/roFormsubmit/roFormsubmit";
import "common/roFormsubmit/roForminput";
import "common/roFormsubmit/roFormfocusme";
import "common/roFormsubmit/roFormfocusnext";
import "common/ZipcodeService/ZipcodeService";
import "common/ZipcodeService/zipcodes.factory";
import "common/ngEnter/ngEnter.directive";
import "common/rofilters/a-rofilters";
import { downgradeInjectable, downgradeComponent } from "@angular/upgrade/static";
import { SeoService } from "./global/seo/seo.service";
import { HungrigClient, NotificationStorage, DialogService } from "@ro-ngx/core";
import { AuthorizedService, AuthenticationTokenStorage } from '@ro-ngx/authentication';

import { IStateProvider } from "angular-ui-router";
import { BookingPmRepository } from "./pm/booking-pm-repository";
import { DeliveryRestaurantRepository } from "./delivery/restaurant/deliveryrestaurant.factory";
import { DeliverySummaryRepository } from './delivery/company/summary/summary-repository';
import { SummaryComponent } from './delivery/company/summary/summary.component';
import { SummaryViewComponent } from './delivery/company/summary/summary-view.component';
import { SummaryDatepickerComponent } from './delivery/company/summary/summary-datepicker.component';
import { AdwordsComponent, AdwordsRepository } from 'app/restaurant/adwords';
import { durationFilter } from './shared/filters/duration.filter';
import { TranslateService } from '@ngx-translate/core';
import { translateFilter } from './global/translate/translate.filter';

/**
 * --- Angular 2 components ---
 * A B C D E F G H I J K L M N O P Q R S T U V W X Y Z
 */

import { AuthAdminGuard } from '@ro-ngx/authentication';
import {
    DriverFormComponent,
    DeliveryOrderScheduleService,
    DeliveryDriverService,
    DeliveryOrderService,
    DeliveryDirectionsComponent
} from '@ro-ngx/delivery';
import { RoCurrencyPipe, roCurrencyFilter } from './global/pipes/rocurrency.pipe';
import { CurrencyInputComponent } from '@ro-ngx/core';
import { SendMessageComponent } from 'app/components/delivery/actions/send-message.component';
import { DeliveryDistancePriceService } from './http/delivery/distance/delivery-distance-price.service';
import { DeliveryDistancePriceStorage } from './http/delivery/distance/delivery-distance-price.storage';
import { DistancePipe } from './global/pipes';
import { kmFilter } from './shared/filters/km.filter';
import { UserService, UserActivityListComponent } from '@ro-ngx/users';
import { IProvider } from 'restangular';
import { PermissionName } from '@ro-ngx/users';
import { DeliveryOrderActionsComponent } from '@ro-ngx/delivery';
import { FinanceService } from './http/finance/finance.service';
import {first} from "rxjs/operators";

/**
 * --- Angular 2 components --- 
 */

const pmTemplate                      = require('./pm/pm.tpl.html');
const eathereTemplate                 = require('./eathere/eathere.tpl.html');
const financeanalyzerTemplate         = require('./financeanalyzer/financeanalyzer.tpl.html');
const loginTemplate                   = require('./login/login.tpl.html');
const authTemplate                    = require('./auth/auth.tpl.html');
const adminTemplate                   = require('./admin/admin.tpl.html');
const deliveryTemplate                = require('./delivery/delivery.tpl.html');
const deliverycompanyTemplate         = require('./delivery/company/deliverycompany.tpl.html');
const deliverypriceplanTemplate       = require('./delivery/priceplan/deliverypriceplan.tpl.html');
const deliverytemplateTemplate        = require('./delivery/template/deliverytemplate.tpl.html');
const deliveryrestaurantTemplate      = require('./delivery/restaurant/deliveryrestaurant.tpl.html');
const deliveryassignTemplate          = require('./delivery/assign/deliveryassign.tpl.html');
const deliverydriverTemplate          = require('./delivery/driver/deliverydriver.tpl.html');
const deliveryglobalTemplate          = require('./delivery/global/deliveryglobal.tpl.html');
const deliveryinterceptorTemplate     = require('./delivery/interceptor/deliveryinterceptor.tpl.html');
const polygonTemplate                 = require('./delivery/polygon/polygon.tpl.html');
const timeoutscheduleTemplate         = require('./delivery/timeout/schedule/timeoutschedule.tpl.html');
const deliveryfinancesettingsTemplate = require('./delivery/financesettings/deliveryfinancesettings.tpl.html');
const deliverysummariesTemplate       = require('./delivery/summaries/deliverysummaries.tpl.html');
const alertTemplate                   = require('./alert/alert.tpl.html');
const alertgroupTemplate              = require('./alert/group/alertgroup.tpl.html');
const alertprofileTemplate            = require('./alert/profile/alertprofile.tpl.html');
const deliveryalertsTemplate          = require('./alert/deliveryalerts/deliveryalerts.tpl.html');
const restaurantalertsTemplate        = require('./alert/restaurantalerts/restaurantalerts.tpl.html');
const driverfinanceTemplate           = require('./driverfinance/driverfinance.tpl.html');
const deliveryfinanceTemplate         = require('./deliveryfinance/deliveryfinance.tpl.html');
const appthemesTemplate               = require('./appthemes/appthemes.tpl.html');
const appthemeTemplate                = require('./appthemes/apptheme/apptheme.tpl.html');
const appconfigTemplate               = require('./appthemes/appconfig/appconfig.tpl.html');
const othersettingsTemplate           = require('./othersettings/othersettings.tpl.html');
const admincontactTemplate            = require('./othersettings/admincontact/admincontact.tpl.html');
const admindenymessageTemplate        = require('./othersettings/admindenymessage/admindenymessage.tpl.html');

const FastClick = require('fastclick');

angular.module('cmsApp', [
    'trustedFilter',
    'zipcodeFilter',
    'cmsApp.admin',
    'cmsApp.auth',
    'cmsApp.auth-bridge',
    'cmsApp.login',
    'cmsApp.eathere',
    'cmsApp.financeanalyzer',
    'cmsApp.app.factory',
    'cmsApp.delivery',
    'cmsApp.delivery.company',
    'cmsApp.delivery.assign',
    'cmsApp.delivery.driver',
    'cmsApp.delivery.message.factory',
    'cmsApp.delivery.priceplan',
    'cmsApp.delivery.priceplan.edit',
    'cmsApp.delivery.priceplan.factory',
    'cmsApp.delivery.priceplan.span',
    'cmsApp.delivery.priceplan.span.factory',
    'cmsApp.delivery.template',
    'cmsApp.delivery.template.factory',
    'cmsApp.delivery.template.edit',
    'cmsApp.delivery.template.area',
    'cmsApp.delivery.template.area.factory',
    'cmsApp.delivery.zipspan.factory',
    'cmsApp.delivery.restaurant',
    'cmsApp.delivery.timeout.schedule',
    'cmsApp.delivery.global',
    'cmsApp.delivery.converter.factory',
    'cmsApp.delivery.financesettings',
    'cmsApp.delivery.summaries',
    'cmsApp.delivery.polygon',
    'cmsApp.driverfinance',
    'cmsApp.alert',
    'cmsApp.alerter.factory',
    'cmsApp.alert.alerts.component',
    'cmsApp.alert.group',
    'cmsApp.alert.group.factory',
    'cmsApp.alert.profile',
    'cmsApp.alert.profile.factory',
    'cmsApp.alert.member.factory',
    'cmsApp.alert.blacklist.component',
    'cmsApp.alertviewer.component',
    'cmsApp.deliveryfinance',
    'cmsApp.othersettings',
    'cmsApp.othersettings.admincontact',
    'cmsApp.user',
    'cmsApp.user.factory',
    'cmsApp.appthemes',
    'cmsApp.appthemes.apptheme',
    'cmsApp.appthemes.appconfig',
    'cmsApp.apptheme.factory',
    'cmsApp.appconfig.factory',
    'cmsApp.tech.component',
    'cmsApp.pm',
    'common.errorcatcher',
    'common.modalService',
    'common.roResizable',
    'common.roFormsubmit',
    'common.roForminput',
    'common.roFormfocusme',
    'common.roFormfocusnext',
    'common.ZipcodeService',
    'common.zipcode.factory',
    'common.ngEnter',
    'matchmedia-ng',
    'ngStorage',
    'duScroll',
    'timer',
    'ngAnimate',
    'ngSanitize',
    'cgBusy',
    'angularMoment',
    'restangular',
    'ui.mask',
    'ui.router',
    'ui.sortable',
    'ngMap',
    'common.rofilters',
    'ui.select'
])

    /**
     * Constants
     * Configs
     * Factories
     * Services
     * Filters
     * Components
     * Directives
     * Controllers
     *
     * Put them in this order. Order by Name ASC
     * 101 <-- search term
     */
    .constant('ENV', ENV)
    .constant('CLIENT_VERSION', 1)
    .constant('angularMomentConfig', AngularMomentConfig)
    .config(restangularConfig)
    .factory('notificationStorage', downgradeInjectable(NotificationStorage))
    .factory('deliveryOrderScheduleService', downgradeInjectable(DeliveryOrderScheduleService))
    .factory('deliveryOrderService', downgradeInjectable(DeliveryOrderService))
    .factory('dialogService', downgradeInjectable(DialogService))
    .factory('deliveryDriverService', downgradeInjectable(DeliveryDriverService))
    .factory('AbstractRepository', function() {
        // Ugly fix
        return AbstractRepository;
    })
    .filter('duration', durationFilter)
    .filter('translate', translateFilter)
    .filter('rocurrency', roCurrencyFilter)
    .filter('km', kmFilter)
    .service('AdwordsRepository', AdwordsRepository)
    .service('DeliverySummaryRepository', DeliverySummaryRepository)
    .service('AlertRepository', AlertRepository)
    .service('BookingPmRepository', BookingPmRepository)
    .service('DeliveryDistancePriceService', downgradeInjectable(DeliveryDistancePriceService))
    .service('DeliveryDistancePriceStorage', downgradeInjectable(DeliveryDistancePriceStorage))
    .service('hungrigClient', downgradeInjectable(HungrigClient))
    .service('seoService', downgradeInjectable(SeoService))
    .service('userService', downgradeInjectable(UserService))
    .service('translateService', downgradeInjectable(TranslateService))
    .service('authorizedService', downgradeInjectable(AuthorizedService))
    .service('authenticationTokenStorage', downgradeInjectable(AuthenticationTokenStorage))
    .service('authAdminGuard', downgradeInjectable(AuthAdminGuard))
    .service('roCurrencyPipe', downgradeInjectable(RoCurrencyPipe))
    .service('distancePipe', downgradeInjectable(DistancePipe))
    .service('financeService', downgradeInjectable(FinanceService))
    .service('DeliveryRestaurantRepository', DeliveryRestaurantRepository)
    .component('adwordsComponent', AdwordsComponent as any)
    .component('summaryComponent', SummaryComponent as any)
    .component('summaryViewComponent', SummaryViewComponent as any)
    .component('summaryDatepickerComponent', SummaryDatepickerComponent as any)
    .component('helpComponent', HelpComponent as any)
    .component('printerComponent', PrinterComponent as any)
    .directive('sendMessageComponent', downgradeComponent({ component: SendMessageComponent }) as angular.IDirectiveFactory)
    .directive('currencyInput', downgradeComponent({ component: CurrencyInputComponent }) as angular.IDirectiveFactory)
    .directive('driverForm', downgradeComponent({ component: DriverFormComponent }) as angular.IDirectiveFactory)
    .directive('deliveryOrderActions', downgradeComponent({ component: DeliveryOrderActionsComponent }) as angular.IDirectiveFactory)
    .directive('userActivityList', downgradeComponent({ component: UserActivityListComponent }) as angular.IDirectiveFactory)
    .directive('deliveryDirections', downgradeComponent({ component: DeliveryDirectionsComponent }) as angular.IDirectiveFactory)
    .controller('AppCtrl', AppController as any)

.config(function myAppConfig($stateProvider: IStateProvider, $urlRouterProvider, $locationProvider) {

    $locationProvider.html5Mode({ enabled: true, }).hashPrefix('!');

    // $urlRouterProvider.otherwise('/login');

    $stateProvider.state('help', {
        url: '/help',
        views: {
            "main": {
                component: 'helpComponent'
            }
        }
    });

    $stateProvider.state('helpprinter', {
        url: '/help/printer',
        views: {
            "main": {
                component: 'printerComponent'
            }
        }
    });

    $stateProvider.state( 'pm', {
        url: '/pm/{pmid:[0-9A-Za-z-]+}',
        views: {
            "main": {
                controller: 'PmCtrl',
                controllerAs: 'vm',
                template: pmTemplate
            }
        },
        resolve: {
            pmid: function($stateParams) {
                return $stateParams.pmid;
            },
        },
        data:{
            pageTitle: 'PM',
            pageDescription: '',
            pageKeywords: ''
        }
    });

    $stateProvider.state( 'eathere', {
        url: '/eathere',
        views: {
            "main": {
                controller: 'EathereCtrl',
                controllerAs: 'vm',
                template: eathereTemplate
            }
        },
        data:{
            pageTitle: 'Eathere',
            pageDescription: '',
            pageKeywords: ''
        }
    });

    $stateProvider.state( 'financeanalyzer', {
        url: '/financeanalyzer',
        views: {
            "main": {
                controller: 'FinanceAnalyzerCtrl',
                controllerAs: 'vm',
                template: financeanalyzerTemplate
            }
        },
        resolve: {
        },
        data:{
            pageTitle: 'Finance Analyzer',
            pageDescription: '',
            pageKeywords: ''
        }
    });

    $stateProvider.state( 'deliverycompanysummary', {
        url: '/delivery/company/summary',
        views: {
            "main": {
                component: 'summaryComponent'
            }
        },
        data: {
            title: 'route.delivery_company_summary_title',
            adminGuard: true
        }
    });

    $stateProvider.state( 'adwords', {
        url: '/restaurant/:clientKey/meta/adwords',
        views: {
            "main": {
                component: 'adwordsComponent'
            }
        },
        data:{
            pageTitle: 'Adwords',
            pageDescription: '',
            pageKeywords: ''
        }
    });

    $stateProvider.state( 'auth', {
        url: '/auth/{grant:[0-9A-Za-z-]+}/{token:[0-9A-Za-z-]+}/{targetState:.+}',
        views: {
            "main": {
                controller: 'AuthCtrl',
                controllerAs: 'vm',
                template: authTemplate
            }
        },
        resolve: {
            grant: function($stateParams) {
                return $stateParams.grant;
            },
            token: function($stateParams) {
                return $stateParams.token;
            },
            targetState: function($stateParams) {
                return $stateParams.targetState;
            }
        },
        data: {
            pageTitle: 'Admin Auth',
            pageDescription: '',
            pageKeywords: ''
        }
    });

    $stateProvider.state( 'driver/login', {
        url: '/driver/login?accessToken',
        views: {
            "main": {
                controller: 'LoginCtrl',
                controllerAs: 'vm',
                template: loginTemplate
            }
        },
        resolve: {
        },
        data: {
            pageTitle: 'RestaurangOnline Admin',
            pageDescription: '',
            pageKeywords: ''
        }
    });

    $stateProvider.state( 'authlogout', {
        url: '/auth/logout',
        views: {
            "main": {
                controller: 'AuthCtrl',
                controllerAs: 'vm',
                template: authTemplate
            }
        },
        resolve: {
            grant: function($stateParams) {
                return false;
            },
            token: function($stateParams) {
                return false;
            },
            targetState: function($stateParams) {
                return false;
            }
        },
        data: {
            pageTitle: 'Admin Auth',
            pageDescription: '',
            pageKeywords: ''
        }
    });

    $stateProvider.state( 'authadmin', {
        url: '/admin/auth/{aToken:[0-9A-Za-z-]+}/{rToken:[0-9A-Za-z-]+}/{target:[0-9A-Za-z-]+}',
        views: {
            "main": {
                controller: 'AdminCtrl',
                controllerAs: 'vm',
                template: adminTemplate
            }
        },
        resolve: {
            tokens: function($stateParams) {
                return {
                    aToken: $stateParams.aToken,
                    rToken: $stateParams.rToken
                };
            },
            target: function($stateParams) {
                return $stateParams.target;
            }
        },
        data:{
            pageTitle: 'Admin Start',
            pageDescription: '',
            pageKeywords: ''
        }
    });

    $stateProvider.state( 'delivery', {
        url: '/delivery',
        views: {
            "main": {
                controller: 'DeliveryCtrl',
                controllerAs: 'vm',
                template: deliveryTemplate
            }
        },
        resolve: {
        },
        data: {
            title: 'route.delivery_manager_title',
            adminGuard: true,
            permissionGroups: [
                [PermissionName.DELIVERY_COMPANIES_READ_ALL]
            ]
        }
    });

    $stateProvider.state( 'delivery.company', {
        url: '/company/{deliveryCompanyID:[0-9]+}',
        views: {
            "right": {
                controller: 'DeliveryCompanyCtrl',
                controllerAs: 'cvm',
                template: deliverycompanyTemplate
            }
        },
        resolve: {
            deliveryCompanyID: function($stateParams) {
                return $stateParams.deliveryCompanyID;
            }
        },
        data:{
            title: 'route.delivery_manager_title',
            adminGuard: true
        }
    });

    $stateProvider.state( 'delivery.priceplans', {
        url: '/company/{deliveryCompanyID:[0-9]+}/priceplans',
        views: {
            "right": {
                controller: 'DeliveryPriceplanCtrl',
                controllerAs: 'cvm',
                template: deliverypriceplanTemplate
            }
        },
        resolve: {
            deliveryCompanyID: function($stateParams) {
                return $stateParams.deliveryCompanyID;
            }
        },
        data: {
            title: 'route.delivery_manager_title',
            adminGuard: true
        }
    });

    $stateProvider.state( 'delivery.templates', {
        url: '/company/{deliveryCompanyID:[0-9]+}/templates',
        views: {
            "right": {
                controller: 'DeliveryTemplateCtrl',
                controllerAs: 'cvm',
                template: deliverytemplateTemplate
            }
        },
        resolve: {
            deliveryCompanyID: function($stateParams) {
                return $stateParams.deliveryCompanyID;
            }
        },
        data:{
            title: 'route.delivery_manager_title',
            adminGuard: true
        }
    });

    $stateProvider.state( 'delivery.restaurants', {
        url: '/company/{deliveryCompanyID:[0-9]+}/restaurants',
        views: {
            "right": {
                controller: 'DeliveryRestaurantCtrl',
                controllerAs: 'cvm',
                template: deliveryrestaurantTemplate
            }
        },
        resolve: {
            deliveryCompanyID: function($stateParams) {
                return $stateParams.deliveryCompanyID;
            }
        },
        data:{
            title: 'route.delivery_manager_title',
            adminGuard: true
        }
    });

    $stateProvider.state( 'delivery.assign', {
        url: '/company/{deliveryCompanyID:[0-9]+}/assigns',
        views: {
            "right": {
                controller: 'DeliveryAssignCtrl',
                controllerAs: 'cvm',
                template: deliveryassignTemplate
            }
        },
        resolve: {
            deliveryCompanyID: function($stateParams) {
                return $stateParams.deliveryCompanyID;
            }
        },
        data:{
            title: 'route.delivery_manager_title',
            adminGuard: true
        }
    });

    $stateProvider.state( 'delivery.driver', {
        url: '/company/{deliveryCompanyID:[0-9]+}/drivers',
        views: {
            "right": {
                controller: 'DeliveryDriverCtrl',
                controllerAs: 'cvm',
                template: deliverydriverTemplate
            }
        },
        resolve: {
            deliveryCompanyID: function($stateParams) {
                return $stateParams.deliveryCompanyID;
            }
        },
        data:{
            title: 'route.delivery_manager_title',
            adminGuard: true
        }
    });

    $stateProvider.state( 'delivery.global', {
        url: '/global',
        views: {
            "right": {
                controller: 'DeliveryGlobalCtrl',
                controllerAs: 'gvm',
                template: deliveryglobalTemplate
            }
        },
        resolve: {
        },
        data:{
            title: 'route.delivery_manager_title',
            adminGuard: true
        }
    });

    $stateProvider.state( 'delivery.interceptor', {
        url: '/interceptor',
        views: {
            "right": {
                controller: 'DeliveryInterceptorCtrl',
                controllerAs: 'ivm',
                template: deliveryinterceptorTemplate
            }
        },
        resolve: {
        },
        data:{
            title: 'route.delivery_manager_title',
            adminGuard: true
        }
    });

    $stateProvider.state( 'delivery.polygon', {
        url: '/polygon',
        views: {
            "right": {
                controller: 'DeliveryPolygonCtrl',
                controllerAs: 'pvm',
                template: polygonTemplate
            }
        },
        resolve: {
        },
        data:{
            title: 'route.delivery_manager_title',
            adminGuard: true
        }
    });

    $stateProvider.state( 'delivery.timeoutschedule', {
        url: '/timeoutschedule',
        views: {
            "right": {
                controller: 'DeliveryTimeoutScheduleCtrl',
                controllerAs: 'cvm',
                template: timeoutscheduleTemplate
            }
        },
        resolve: {
        },
        data:{
            title: 'route.delivery_timeout_schedule_title',
            adminGuard: true
        }
    });

    $stateProvider.state( 'delivery.financesettings', {
        url: '/financesettings/{deliveryCompanyID:[0-9]+}',
        views: {
            "right": {
                controller: 'DeliveryFinanceSettingsCtrl',
                controllerAs: 'cvm',
                template: deliveryfinancesettingsTemplate
            }
        },
        resolve: {
            deliveryCompanyID: function($stateParams) {
                return $stateParams.deliveryCompanyID;
            }
        },
        data:{
            title: 'route.delivery_manager_title',
            adminGuard: true
        }
    });

    $stateProvider.state( 'delivery.summaries', {
        url: '/summaries/{deliveryCompanyID:[0-9]+}',
        views: {
            "right": {
                controller: 'DeliverySummariesCtrl',
                controllerAs: 'cvm',
                template: deliverysummariesTemplate
            }
        },
        resolve: {
            deliveryCompanyID: function($stateParams) {
                return $stateParams.deliveryCompanyID;
            }
        },
        data:{
            title: 'route.delivery_manager_title',
            adminGuard: true
        }
    });

    $stateProvider.state( 'alert', {
        url: '/alert',
        views: {
            "main": {
                controller: 'AlertCtrl',
                controllerAs: 'vm',
                template: alertTemplate
            }
        },
        resolve: {
        },
        data:{
            title: 'route.alert_manager_title',
            adminGuard: true
        }
    });

    $stateProvider.state( 'alert.group', {
        url: '/group/{alertGroupID:[0-9]+}',
        views: {
            "right": {
                controller: 'AlertGroupCtrl',
                controllerAs: 'gvm',
                template: alertgroupTemplate
            }
        },
        resolve: {
            alertGroupID: function($stateParams) {
                return $stateParams.alertGroupID;
            }
        },
        data:{
            title: 'route.alert_manager_title',
            adminGuard: true
        }
    });

    $stateProvider.state( 'alert.profile', {
        url: '/group/{alertGroupID:[0-9]+}/profile/{alertProfileID:[0-9]+}',
        views: {
            "right": {
                controller: 'AlertProfileCtrl',
                controllerAs: 'pvm',
                template: alertprofileTemplate
            }
        },
        resolve: {
            alertGroupID: function($stateParams) {
                return $stateParams.alertGroupID;
            },
            alertProfileID: function($stateParams) {
                return $stateParams.alertProfileID;
            }
        },
        data:{
            title: 'route.alert_manager_title',
            adminGuard: true
        }
    });

    $stateProvider.state( 'alert.deliveryalerts', {
        url: '/deliveryalerts',
        views: {
            "right": {
                controller: 'DeliveryAlertsCtrl',
                controllerAs: 'vm',
                template: deliveryalertsTemplate
            }
        },
        resolve: {
        },
        data:{
            title: 'route.alert_manager_title',
            adminGuard: true
        }
    });

    $stateProvider.state( 'alert.restaurantalerts', {
        url: '/restaurantalerts',
        views: {
            "right": {
                controller: 'RestaurantAlertsCtrl',
                controllerAs: 'vm',
                template: restaurantalertsTemplate
            }
        },
        resolve: {
        },
        data:{
            title: 'route.alert_manager_title',
            adminGuard: true
        }
    });

    $stateProvider.state('alert.alerts', {
        url: '/alerts',
        views: {
            right: {
                component: 'roAlertAlertsComponent'
            }
        },
        resolve: {},
        data: {
            title: 'route.alert_manager_title',
            adminGuard: true
        }
    });

    $stateProvider.state( 'alert.blacklist', {
        url: '/blacklist',
        views: {
            "right": {
                component: 'roAlertBlacklist'
            }
        },
        resolve: {
        },
        data:{
            title: 'route.alert_blacklist_title',
            adminGuard: true
        }
    });

    $stateProvider.state( 'alertviewer', {
        url: '/alertviewer',
        views: {
            'main': {
                component: 'roAlertviewer'
            }
        },
        resolve: {
        },
        data: {
            title: 'route.alertviewer_title',
            adminGuard: true
        }
    });

    $stateProvider.state( 'deliveryfinance', {
        url: '/deliveryfinance',
        views: {
            "main": {
                controller: 'DeliveryFinanceCtrl',
                controllerAs: 'vm',
                template: deliveryfinanceTemplate
            }
        },
        resolve: {
        },
        data:{
            title: 'route.delivery_finance_title',
            adminGuard: true
        }
    });

    $stateProvider.state( 'driverfinance', {
        url: '/driverfinance',
        views: {
            "main": {
                controller: 'DriverFinanceCtrl',
                controllerAs: 'vm',
                template: driverfinanceTemplate
            }
        },
        resolve: {
        },
        data:{
            pageTitle: 'Driver Finance',
            pageDescription: '',
            pageKeywords: ''
        }
    });

    $stateProvider.state( 'deliveryfinance.show', {
        url: '/{orderID:[0-9]+}',
        views: {
            "main": {
                controller: 'DeliveryFinanceCtrl',
                controllerAs: 'vm',
                template: deliveryfinanceTemplate
            }
        },
        resolve: {
            orderID: function($stateParams) {
                return $stateParams.orderID;
            }
        },
        data:{
            pageTitle: 'Delivery Finance',
            pageDescription: '',
            pageKeywords: '',
            adminGuard: true
        }
    });

    $stateProvider.state( 'appthemes', {
        url: '/apptheme',
        views: {
            "main": {
                controller: 'AppthemesCtrl',
                controllerAs: 'vm',
                template: appthemesTemplate
            }
        },
        resolve: {
        },
        data:{
            pageTitle: 'Apptheme Manager',
            pageDescription: '',
            pageKeywords: '',
            adminGuard: true
        }
    });

    $stateProvider.state( 'appthemes.apptheme', {
        url: '/{appThemeID:[0-9]+}',
        views: {
            "right": {
                controller: 'AppthemeCtrl',
                controllerAs: 'atvm',
                template: appthemeTemplate
            }
        },
        resolve: {
            appThemeID: function($stateParams) {
                return $stateParams.appThemeID;
            }
        },
        data:{
            pageTitle: 'Apptheme Manager',
            pageDescription: '',
            pageKeywords: '',
            adminGuard: true
        }
    });

    $stateProvider.state( 'appthemes.restaurants', {
        url: '/{appThemeID:[0-9]+}/restaurants',
        views: {
            "right": {
                controller: 'AppconfigCtrl',
                controllerAs: 'acvm',
                template: appconfigTemplate
            }
        },
        resolve: {
            appThemeID: function($stateParams) {
                return $stateParams.appThemeID;
            }
        },
        data:{
            pageTitle: 'Apptheme Manager',
            pageDescription: '',
            pageKeywords: '',
            adminGuard: true
        }
    });

    $stateProvider.state( 'othersettings', {
        url: '/othersettings',
        views: {
            "main": {
                controller: 'OthersettingsCtrl',
                controllerAs: 'vm',
                template: othersettingsTemplate
            }
        },
        resolve: {
        },
        data:{
            pageTitle: 'Other Settings',
            pageDescription: '',
            pageKeywords: ''
        }
    });

    $stateProvider.state( 'othersettings.admincontact', {
        url: '/othersettings/admincontact',
        views: {
            "right": {
                controller: 'AdmincontactCtrl',
                controllerAs: 'acvm',
                template: admincontactTemplate
            }
        },
        resolve: {
        },
        data: {
            pageTitle: 'Admin Contact',
            pageDescription: '',
            pageKeywords: ''
        }
    });

    $stateProvider.state( 'othersettings.admindenymessage', {
        url: '/othersettings/admindenymessage',
        views: {
            "right": {
                controller: 'AdminDenymessageCtrl',
                controllerAs: 'advm',
                template: admindenymessageTemplate
            }
        },
        resolve: {
        },
        data: {
            pageTitle: 'Admin Denymessage',
            pageDescription: '',
            pageKeywords: ''
        }
    });

    $stateProvider.state('tech', {
        url: '/tech',
        views: {
            'main': {
                component: 'roTechComponent'
            }
        },
        resolve: {
        },
        data: {
            pageTitle: '',
            pageDescription: '',
            pageKeywords: ''
        }
    });

    $stateProvider.state('migration-empty-page', {
        url: '*path',
        template: ''
    });
})

.run(function(Restangular: IProvider, $http, userService: UserService, authenticationTokenStorage: AuthenticationTokenStorage) {
    const unauthorized = 401;
    let isRefreshing = false;
    const failedResponses = [];

    Restangular.setErrorInterceptor((response, deferred, responseHandler) => {
        const doRefresh = response.status === unauthorized
            && authenticationTokenStorage.hasAuthorizationToken();

        if (doRefresh && ! isRefreshing) {
            isRefreshing = true;
            userService.findMyself()
                .subscribe(
                    () => {
                        isRefreshing = false;
                        failedResponses.forEach((responder) => {
                            const token = authenticationTokenStorage.getAuthorizationToken();
                            const header = authenticationTokenStorage.toHeader(token);
                            set(responder.response.config, `headers.${header.key}`, header.value);
                            $http(responder.response.config).then(responder.responseHandler, responder.deferred.reject);
                        });
                    },
                    () => {
                        isRefreshing = false;
                        failedResponses.length = 0;
                    }
                );
        }

        if (doRefresh && isRefreshing) {
            failedResponses.push({ response, deferred, responseHandler });
        }

        return response.status !== unauthorized;
    });

    Restangular.addFullRequestInterceptor((element, operation, route, url, headers, params, httpConfig) => {
        headers = assign({
            'Accept-Language': 'sv-SE',
        }, headers);

        if (authenticationTokenStorage.hasAuthorizationToken()) {
            const token = authenticationTokenStorage.getAuthorizationToken();
            const header = authenticationTokenStorage.toHeader(token);
            headers[header.key] = header.value;
        }

        return { headers, params, element, httpConfig };
    });
})

.run( function run (editableOptions, editableThemes) {
    // TODO: Maybe should be injected as constant instead to be able to mock it
    FastClick.attach(document.body);
    editableThemes.bs3.inputClass = 'input-sm';
    editableThemes.bs3.buttonsClass = 'btn-sm';
    editableOptions.theme = 'bs3';
})

.run(($state, $rootScope, authAdminGuard: AuthAdminGuard) => {
    $rootScope.$on('$stateChangeStart', async (event: any, toState: any) => {
        if (!toState || ! toState.hasOwnProperty('data') || ! toState.data.hasOwnProperty('adminGuard')) {
            return;
        }

        if (toState.data.adminGuard) {
            const canActivate = await authAdminGuard.canNg1Activate(toState.url, get(toState, 'data.permissionGroups', [])).pipe(first()).toPromise();
            if (! canActivate) {
                event.preventDefault();
            }
        }
    });
})

.run(($rootScope, seoService: SeoService, translateService: TranslateService) => {
        $rootScope.$on('$stateChangeSuccess', (event: any, toState: any) => {
            if (!toState || !toState.hasOwnProperty('data')) {
                return;
            }

            if (toState.data.hasOwnProperty('title')) {
                seoService.setTitle(translateService.instant(toState.data.title));
            } else if (toState.data.hasOwnProperty('pageTitle')) {
                seoService.setTitle(toState.data.pageTitle);
            }
        });
});
