import { NgModule } from '@angular/core';
import { ChunkPipe } from './chunk.pipe';
import { DistancePipe } from './distance.pipe';
import { DurationPipe } from './duration.pipe';
import { RoCurrencyPipe } from './rocurrency.pipe';

@NgModule({
    declarations: [
        ChunkPipe,
        DistancePipe,
        DurationPipe,
        RoCurrencyPipe
    ],
    exports: [
        ChunkPipe,
        DistancePipe,
        DurationPipe,
        RoCurrencyPipe
    ]
})
export class PipeModule {
}
