export class OrderRowHeaderColumnDistanceKilometersComponent {
    public kilo = 1000;

    public static readonly controller = OrderRowHeaderColumnDistanceKilometersComponent;
    public static readonly controllerAs = 'vm';
    public static readonly template = require('./order-row-header-column-distance-kilometers.component.html');
    public static readonly bindings = {
        order: '<'
    };

    public order: any;

    public getKilometersWithUnit() {
        const distanceInMeters = this.getDistanceInMeters();
        return distanceInMeters ? (distanceInMeters / this.kilo).toFixed(1).toString() + ' km' : null;
    }

    public getDistanceInMeters() {
        if (typeof this.order.distanceInMeters !== 'undefined') {
            return this.order.distanceInMeters;
        }

        if (!this.order.distance && !this.order.gmDistanceBike) {
            return 0;
        }
        else if (this.order.status < 2) {
            return Math.min(this.order.distance, this.order.gmDistanceBike);
        }
        else if (this.order.deliverydriver.transportMode !== 1 || !this.order.gmDistanceBike) {
            return this.order.distance;
        }
        else if (this.order.deliverydriver.transportMode === 1) {
            return this.order.gmDistanceBike;
        }

        return 0;
    }
}
