import * as angular from 'angular';

import 'common/modalService/modalService';
import 'app/delivery/company/deliverycompanystatus.factory';
import 'app/delivery/interceptor/deliveryinterceptor.factory';
import 'app/delivery/company/deliverydistrict.factory';
import './deliverycompanyclose.modal';

var deliveryCompanyCloseTemplate = require('./deliverycompanyclose.modal.tpl.html');

import { clone, range } from 'lodash';

angular.module('cmsApp.delivery.company', [
    'ui.router',
    'ui.bootstrap',
    'common.modalService',
    'ui.select',
    'ngSanitize',
    'cmsApp.delivery.company.status.factory',
    'cmsApp.delivery.company.modal.close',
    'cmsApp.delivery.interceptor.factory',
    'cmsApp.delivery.district.factory',
])
    .config(DeliveryCompanyCtrlConfig)
    .controller('DeliveryCompanyCtrl', DeliveryCompanyCtrl);

function DeliveryCompanyCtrlConfig($stateProvider) {

}

function DeliveryCompanyCtrl(
    $scope,
    $state,
    $sce,
    $modal,
    $q,
    DeliveryCompanyRepository,
    DeliveryCompanyStatusRepository,
    DeliveryMessageRepository,
    AlertRepository,
    AlertProfileRepository,
    DeliveryInterceptorRepository,
    DeliveryDistrictRepository,
    deliveryCompanyID,
    modalService,
    translateService,
    ENV,
) {
    /* jshint validthis: true */
    var cvm = this;

    var ustatus = {};

    cvm.company = {};
    cvm.originalCompany = {};

    cvm.availableProfiles = [];

    cvm.emailAlerts = [];
    cvm.smsAlerts = [];

    cvm.curMessage = '';
    cvm.messageSent = false;
    cvm.messages = [];

    cvm.weektimes = [];

    cvm.companyStatus = {};
    cvm.originalCompanyStatus = {};

    cvm.interceptors = [];
    cvm.districts = [];
    cvm._selectedDistrict = undefined;

    cvm.companyPriceTypeOptions = {
        0: translateService.instant('delivery.company.fixed_price'),
        1: translateService.instant('delivery.company.price_per_kilometer'),
    };

    cvm.driverTransportModeOptions = {
        0: translateService.instant('delivery.driver.mode_car'),
        1: translateService.instant('delivery.driver.mode_bike'),
    };

    if (ENV.APP_COUNTRY === 'SE') {
        cvm.companyPriceTypeOptions[2] = 'CabOnline';
    }

    // tslint:disable-next-line no-magic-numbers
    cvm.closedMetersDefaultOptions = range(0, 15500, 500);

    ////////////////////

    cvm.reload = function() {
        DeliveryCompanyRepository.get('/' + deliveryCompanyID + "?with=emailAlerter.alerts.alertprofile,smsAlerter.alerts.alertprofile", '').then(function(data) {
            cvm.company = data;
            cvm.originalCompany = angular.copy(data);

            cvm.company.confirmReq          = cvm.company.confirmReq == 1;
            cvm.company.terminalAdmin       = cvm.company.terminalAdmin == 1;
            cvm.company.showPrice           = cvm.company.showPrice == 1;
            cvm.company.assign              = cvm.company.assign == 1;
            cvm.company.showPriorityTag     = cvm.company.showPriorityTag == 1;
            cvm.company.reqDriver           = cvm.company.reqDriver == 1;
            cvm.company.showAcceptTimeAlert = cvm.company.showAcceptTimeAlert == 1;
            cvm.company.reqCabOnlineAddress = cvm.company.reqCabOnlineAddress == 1;

            cvm.company.companyPriceType    = cvm.company.companyPriceType.toString();
            cvm.company.transportMode       = cvm.company.transportMode.toString();

            //if(data.email_alerter && data.email_alerter.alerts)
            //{
            //    data.email_alerter.alerts.forEach(function (alert, index) {
            //        cvm.emailAlerts.push(alert.alertprofile);
            //    });
            //}
            //
            //if(data.sms_alerter && data.sms_alerter.alerts)
            //{
            //    data.sms_alerter.alerts.forEach(function (alert, index) {
            //        cvm.smsAlerts.push(alert.alertprofile);
            //    });
            //}

            DeliveryCompanyRepository.getList('/' + deliveryCompanyID + '/templates?with=weektimes').then(function(data) {
                cvm.weektimes = data;

                $scope.$emit('setLoading', false);
            });

            $scope.$emit('selectItem', { company: cvm.company, item: null });
        });

        DeliveryCompanyStatusRepository.get('/' + deliveryCompanyID, '').then(function(data) {
            if (data) {
                data.hide = data.hide == 1;
                cvm.companyStatus = data;
                cvm.originalCompanyStatus = angular.copy(data);
            }
        });

        DeliveryCompanyRepository.get('/' + deliveryCompanyID + '/messages', '').then(function(data) {
            cvm.messages = data;
        });

        DeliveryInterceptorRepository.getList().then(function(data) {
            cvm.interceptors = data;
        });

        DeliveryDistrictRepository.getList().then(function(data) {
            cvm.districts = [
                {
                    active: 0,
                    deliveryDistrictID: null,
                    name: translateService.instant('delivery.template.district_none_set'),
                },
                ...data,
            ];
        });
    };

    cvm.save = function() {
        $scope.$emit('showNotification', {
            id: 'dCompanySave',
            loading: true,
            alertType: 'alert-info',
            title: translateService.instant('general.loading'),
        });

        var promises = [];

        const company = clone(cvm.company);
        delete company.acceptTimeOptions;
        delete company.emailAlerter;
        delete company.smsAlerter;

        if (company.deliveryDistrictID === 0) {
            delete company.deliveryDistrictID;
        }

        promises.push(DeliveryCompanyRepository.update(company, cvm.company.deliveryCompanyID).then(function(data) {
            $scope.$emit('refreshCompanies');
        }));

        promises.push(DeliveryCompanyStatusRepository.update(cvm.companyStatus, cvm.companyStatus.deliveryCompanyID, cvm.originalCompanyStatus).then(function(data) {
            // done
        }));

        //promises.push(cvm.saveAlert(cvm.company.email_alerter.alerts,    cvm.emailAlerts,    cvm.company.email_alerter.alerterID,   1, 1, 0));
        //promises.push(cvm.saveAlert(cvm.company.sms_alerter.alerts,      cvm.smsAlerts,      cvm.company.sms_alerter.alerterID,     2, 0, 1));

        $q.all(promises).then(function() {
            $scope.$emit('showNotification', {
                id: 'dCompanySave',
                loading: false,
                alertType: 'alert-success',
                title: translateService.instant('general.saved'),
            });
        });
    };

    cvm.saveAlert = function(existingItems, newItems, alerterID, alerterTypeID, alertEmail, alertSms) {
        // specify the list of which items to add (splice/delete from this array when items existed before)
        var itemsToAdd = angular.copy(newItems);
        // specify the list of which items to delete (push to this array)
        var itemsToDelete = [];

        // loop through every existing item (the old database)
        existingItems.forEach(function(oldAlert) {
            var found = false;

            // check if the item still exists (loop through "active" "database")
            itemsToAdd.forEach(function(newAlert) {
                if (oldAlert.alertprofile.alertProfileID == newAlert.alertProfileID) {
                    // the item is found! it should not be added or removed, it should be left "unchanged", so remove it from "toAdd"-array
                    found = true;
                    itemsToAdd.splice(itemsToAdd.indexOf(newAlert), 1);
                }
            });

            if (!found) {
                // the item does not exists anymore and should be deleted
                itemsToDelete.push(oldAlert.alertID);
            }
        });

        var promises = [];

        itemsToDelete.forEach(function(deleteAlertID) {
            promises.push(AlertRepository.remove(deleteAlertID));
        });

        itemsToAdd.forEach(function(newAlertProfile) {
            promises.push(AlertRepository.create({
                alertProfileID:     newAlertProfile.alertProfileID,
                alerterID:          alerterID,
                alerterTypeID:      alerterTypeID,
                alertEmail:         alertEmail,
                alertSms:           alertSms
            }));
        });

        return $q.all(promises);
    };

    cvm.showLoggins = function() {
        $scope.$emit('changeItem', { company: cvm.company, item: 'driver' });
    };

    cvm.deleteItem = function() {
        var modalOptions = {
            closeButtonText: translateService.instant('general.cancel'),
            actionButtonText: translateService.instant('general.delete'),
            actionButtonClass: 'btn-danger',
            headerText: translateService.instant('delivery.company.confirm_delete_short'),
            bodyText: translateService.instant('delivery.company.confirm_delete'),
        };

        modalService.showModal({}, modalOptions).then(function(result) {
            if (result) {
                $scope.$emit('deleteCompany', cvm.company);
            }
        });
    };

    cvm.refreshProfiles = function(search, compare) {

        if (search.length < 1) {
            return false;
        }

        // console.log('Search:', search, search.length, 'Compare:', compare);

        AlertProfileRepository.search({ profileName: search }).then(function(data) {
            cvm.availableProfiles = data.plain();
        });
    };

    cvm.sendDeliveryMessage = function(message) {
        DeliveryMessageRepository.create({
            deliveryCompanyID: cvm.company.deliveryCompanyID,
            message: message,
        }).then(function(obj) {
            cvm.curMessage = '';
            cvm.messageSent = true;

            cvm.messages.splice(0, 0, obj);

            if (cvm.messages.length > 5) {
                cvm.messages.splice(5, 1);
            }
        });
    };

    cvm.duplicateCompany = function(obj) {
        var dupobj = {
            companyName: obj.companyName,
            deliveryCompanyID: obj.deliveryCompanyID,
        };

        $scope.$emit('duplicateCompany', obj);
    };

    cvm.closeCompany = function() {
        var modalInstance = $modal.open({
            template: deliveryCompanyCloseTemplate,
            controller: 'ClosedeliverycompanyModalCtrl as vm',
            windowClass: 'ro-modal closedeliverycompanymodal modalService',
            resolve: {
                company: function() {
                    return {
                        deliveryCompanyID: cvm.company.deliveryCompanyID,
                        closed: cvm.company.closed,
                        closedMessage: cvm.company.closedMessage,
                        closedMeters: cvm.company.closedMeters,
                        closedMetersDefault: cvm.company.closedMetersDefault,
                    };
                },
            },
        });

        modalInstance.result.then(function(company) {
            $scope.$emit('showNotification', {
                id: 'dCompanySave',
                loading: true,
                alertType: 'alert-info',
                title: translateService.instant('general.loading'),
            });

            var promises = [];

            promises.push(DeliveryCompanyRepository.update(company, company.deliveryCompanyID).then(function(data) {
                cvm.company.closed = company.closed;
                cvm.company.closedMessage = company.closedMessage;
                cvm.company.closedMeters = company.closedMeters;
                $scope.$emit('refreshCompanies');
            }));

            $q.all(promises).then(function() {
                $scope.$emit('showNotification', {
                    id: 'dCompanySave',
                    loading: false,
                    alertType: 'alert-success',
                    title: translateService.instant('general.saved'),
                });
            });
        });
    };

    cvm.reload();
    return cvm;
}
