import { AbstractRepository } from "../abstract/abstract-repository";

export class AlertRepository extends AbstractRepository {
    constructor(Restangular) {
        super(Restangular, 'alerts');
    }

    public testing() {
        console.log('testing');
    }
}