angular.module( 'cmsApp.alert.member.factory', [
    'ui.router',
    'ui.bootstrap'
])

.factory('AlertMemberRepository', function (Restangular, AbstractRepository) {

    function AlertMemberRepository() {
        AbstractRepository.call(this, Restangular, 'alertmembers');
    }

    AbstractRepository.extend(AlertMemberRepository);

    return new AlertMemberRepository();
})

;
