
import {combineLatest as observableCombineLatest,  BehaviorSubject, Observable } from 'rxjs';

import {map} from 'rxjs/operators';
import { DriverFinance } from 'app/http/delivery/driver/finance/driver-finance';
import * as _ from 'lodash';

enum PropertyType {
    SUM, AVG
}

interface SortParams {
    propertyName: string;
    reverse: boolean;
}

export interface DistanceFilter {
    min?: number;
    max?: number;
}

export class Summary {

    public static readonly PROPERTY_TYPES: any = {
        'deliveryCount': PropertyType.SUM,
        'problemCount': PropertyType.SUM,
        'averageConfirmTime': PropertyType.AVG,
        'averagePickupTime': PropertyType.AVG,
        'averageGmDistance': PropertyType.AVG,
        'totalGmDistance': PropertyType.SUM
    };

    public static readonly PROPERTIES: string[] = [
        'driverName',
        'deliveryCount',
        'problemCount',
        'averageConfirmTime',
        'averagePickupTime',
        'averageGmDistance',
        'totalGmDistance'
    ];

    public static readonly PROPERTY_HEADERS: any = {
        'driverName': 'delivery_driver_finance.name_label',
        'deliveryCount': 'delivery_driver_finance.delivery_count_label',
        'problemCount': 'delivery_driver_finance.problem_count_label',
        'averageConfirmTime': 'delivery_driver_finance.avg_confirm_time_label',
        'averagePickupTime': 'delivery_driver_finance.avg_pickup_time_label',
        'averageGmDistance': 'delivery_driver_finance.avg_gm_distance_label',
        'totalGmDistance': 'delivery_driver_finance.tot_gm_distance_label'
    };

    public sort$: Observable<SortParams>;
    public entries$: Observable<DriverFinance[]>;
    public sum: any[];

    protected entries: BehaviorSubject<DriverFinance[]>;
    protected distanceFilter: BehaviorSubject<DistanceFilter>;
    protected sort: BehaviorSubject<SortParams>;

    constructor(
        protected name: string,
        protected readonly originalEntries: DriverFinance[]
    ) {
        this.entries = new BehaviorSubject(originalEntries);

        this.sort = new BehaviorSubject({
            propertyName: 'deliveryCount',
            reverse: true
        });
        this.sort$ = this.sort.asObservable();

        this.distanceFilter = new BehaviorSubject({
            min: undefined,
            max: undefined
        });

        this.entries$ = observableCombineLatest(
                this.entries,
                this.sort,
                this.distanceFilter
            ).pipe(
            map(([entries, sort, filter]) => {
                const filteredEntries = entries.filter((entry) => {
                    if (filter.min !== undefined && entry.totalGmDistance < filter.min) {
                        return false;
                    }
                    if (filter.max !== undefined && entry.totalGmDistance > filter.max) {
                        return false;
                    }
                    return true;
                });

                return _.orderBy(
                    filteredEntries,
                    sort.propertyName,
                    sort.reverse ? 'desc' : 'asc'
                );
            }));

        this.entries$.subscribe((entries) => {
            if (entries.length === 0) {
                return;
            }

            const sum = [];

            for (const property in Summary.PROPERTY_TYPES) {
                sum[property] = 0;

                for (const entry of entries) {
                    sum[property] += entry[property];
                }

                if (Summary.PROPERTY_TYPES[property] === PropertyType.AVG) {
                    sum[property] /= entries.length;
                }
            }

            this.sum = sum;
        });

    }

    public filter(filter: DistanceFilter): void {
        this.distanceFilter.next(filter);
    }

    public orderBy(propertyName: string): void {
        const currentSort = this.sort.getValue();
        this.sort.next({
            propertyName,
            reverse: currentSort.propertyName === propertyName ? !currentSort.reverse : false
        });
    }

    public getName(): string {
        return this.name;
    }

    public getProperties(): string[] {
        return Summary.PROPERTIES;
    }

    public getPropertyHeaders(): any {
        return Summary.PROPERTY_HEADERS;
    }

    public getPropertyTypes(): any {
        return Summary.PROPERTY_TYPES;
    }
}
