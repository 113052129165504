import { ModuleWithProviders, NgModule } from '@angular/core';
import { ArticleListConfig } from "./article-list-config";


declare module "@angular/core" {
    interface ModuleWithProviders<T = any> {
        ngModule: Type<T>;
        providers?: Provider[];
    }
}

@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [],
})

export class ArticleListConfigModule {
    public static forRoot(config: ArticleListConfig): ModuleWithProviders {
        return {
            ngModule: ArticleListConfigModule,
            providers: [
                {
                    provide: ArticleListConfig,
                    useValue: config
                }
            ]
        };
    }
}
