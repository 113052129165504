import { Pipe, PipeTransform } from '@angular/core';
import { ENV } from '../../enviroment';
import { CurrencySupport } from '@ro-ngx/core';

export const roCurrencyFilter = ['roCurrencyPipe',
    (roCurrencyPipe: RoCurrencyPipe): (value: number, options?: Options) => string =>
        (value: number, options?: Options): string => roCurrencyPipe.transform(value, options)
];

export interface Options {
    currency?: string;
    normalize?: boolean;
    showSymbol?: boolean;
}

@Pipe({ name: 'rocurrency' })
export class RoCurrencyPipe implements PipeTransform {
    constructor(
        protected currencySupport: CurrencySupport
    ) {}

    public transform(value: number, { currency, normalize = true, showSymbol = true }: Options = {}): string {
        if (! currency) {
            currency = ENV.APP_CURRENCY;
        }

        if (normalize) {
            value = this.currencySupport.divide(value);
        }

        const symbol: string = this.currencySupport.symbol(currency);
        let formatted: string = value.toFixed(ENV.CURRENCY_DECIMAL_POINTS as any)
            .replace('.', ',')
            .replace(/(\d)(?=(\d{3})+,)/g, '$1 ');

        if (showSymbol) {
            formatted += ' ' + symbol;
        }

        return formatted;
    }
}
