
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BaseService } from 'app/http/base/base.service';
import { HungrigClient } from '@ro-ngx/core';
import { City } from './city';
import { Observable } from 'rxjs';

@Injectable()
export class CityService extends BaseService {
    public resourceUrl: string = 'campaigngroups/';

    constructor(public hungrigClient: HungrigClient) {
        super(hungrigClient);
    }

    public getGroupCities(groupID: number): Observable<City[]> {
        const uri = `${groupID}/cities`;
        return this.hungrigClient.get(this.url(uri)).pipe(
            map(this.toResponse),
            map((response) => response.data),);
    }

    public createGroupCity(groupID: number, city: City): Observable<City> {
        const uri = `${groupID}/cities`;
        return this.hungrigClient.post(this.url(uri), city).pipe(
            map(this.toResponse),
            map((response) => response.data),);
    }

    public deleteGroupCity(groupID: number, city: City): Observable<City> {
        const uri = `${groupID}/cities/${city.campaignGroupCityID}`;
        return this.hungrigClient.delete(this.url(uri)).pipe(
            map(this.toResponse),
            map((response) => response.data),);
    }
}
