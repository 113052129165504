import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { finalize, flatMap, map, tap, filter } from "rxjs/operators";
import { Observable ,  BehaviorSubject } from "rxjs";
import { Article, ArticleStorage } from "@ro-ngx/articles";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DialogService, FormManager, NotificationStorage } from "@ro-ngx/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: 'article',
    template: require('./article.component.html'),
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ArticleComponent implements OnInit {

    public isLoading$: BehaviorSubject<boolean>;
    public isSaving$: BehaviorSubject<boolean>;
    public isDeleting$: BehaviorSubject<boolean>;

    public articleID$: Observable<number>;
    public article$: Observable<Article>;
    public formManager: FormManager;
    public formGroup: FormGroup;

    constructor(
        protected notificationStorage: NotificationStorage,
        protected translateService: TranslateService,
        protected articleStorage: ArticleStorage,
        protected activatedRoute: ActivatedRoute,
        protected dialogService: DialogService,
        protected formBuilder: FormBuilder,
        protected router: Router) {
        this.isLoading$ = new BehaviorSubject(false);
        this.isSaving$ = new BehaviorSubject(false);
        this.isDeleting$ = new BehaviorSubject(false);

        this.formGroup = this.createFormGroup();
        this.formManager = new FormManager(this.formGroup as any);
    }

    public ngOnInit(): void {
        this.articleID$ = this.activatedRoute.params
            .pipe(map((params) => +params['articleID']));

        this.article$ = this.articleID$
            .pipe(tap(() => this.isLoading$.next(true)))
            .pipe(flatMap((articleID) => this.getArticle(articleID)
                .pipe(tap(() => this.isLoading$.next(false)))
            ));

        this.article$.subscribe((values) => this.formGroup.patchValue(values));
    }

    public save(): void {
        if (! this.formGroup.valid) {
            return;
        }

        const values = this.formGroup.value;
        this.isSaving$.next(true);
        this.articleStorage.updateArticle(values.articleID, values)
            .pipe(finalize(() => this.isSaving$.next(false)))
            .subscribe((article) => {
                this.formGroup.patchValue(article);
                this.notificationStorage.success(
                    this.translateService.instant('general.saved') + '!'
                );
            });
    }

    public delete(): void {
        const values = this.formGroup.value;
        this.dialogService.confirm({
                title: this.translateService.instant('article-list.article.dialog_title'),
                body: this.translateService.instant('article-list.article.dialog_description', {
                    name: values.name
                })
            })
            .pipe(filter((value) => value))
            .subscribe(() => {
                this.isDeleting$.next(true);
                this.articleStorage.deleteArticle(values.articleID)
                    .pipe(finalize(() => this.isDeleting$.next(false)))
                    .subscribe(() => {
                        this.router.navigate(['../'], { relativeTo: this.activatedRoute })
                            .then();

                        this.notificationStorage.success(
                            this.translateService.instant('general.deleted') + '!'
                        );
                    });
            });
    }

    protected getArticle(articleID: number): Observable<Article> {
        return this.articleStorage.getArticle(articleID);
    }

    protected createFormGroup(): FormGroup {
        return this.formBuilder.group({
            articleID: [null],
            name: ['', Validators.required],
            slug: ['', [
                Validators.required,
                Validators.pattern(/^[a-z0-9]+(?:-[a-z0-9]+)*$/)
            ]]
        });
    }
}
