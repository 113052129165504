import * as angular from 'angular';

import 'app/delivery/assign/deliveryassign.factory';

angular.module( 'cmsApp.delivery.assign', [
    'cmsApp.delivery.assign.factory'
])
.config(DeliveryAssignCtrlConfig)
.controller('DeliveryAssignCtrl', DeliveryAssignCtrl);

function DeliveryAssignCtrlConfig( $stateProvider ) {

}

function DeliveryAssignCtrl( $scope, $state, $sce, $q, modalService, DeliveryCompanyRepository, DeliveryAssignRepository, deliveryCompanyID, translateService ) {
    /* jshint validthis: true */
    var cvm = this;

    cvm.company = {};
    cvm.assigns = [];
    
    cvm.addAssign = null;
    cvm.companies = [];
    
    cvm.sortableOptions = {
        orderChanged: function(event) {
            var promises = [];
            $scope.$emit('showNotification', {
                id: 'dSaveOrder',
                loading: true,
                alertType: 'alert-info',
                title: translateService.instant('general.loading')
            });
            
            angular.forEach(cvm.assigns, function(item, key){
                var newdata = {
                    deliveryAssignID: item.deliveryAssignID,
                    sortOrder: cvm.assigns.indexOf(item)
                };
                
                promises.push(DeliveryAssignRepository.update(newdata, item.deliveryAssignID));
            });
            
            $q.all(promises).then(function() {
                $scope.$emit('showNotification', {
                    id: 'dSaveOrder',
                    loading: false,
                    alertType: 'alert-success',
                    title: translateService.instant('general.saved')
                });
            });
        }
    };

    ////////////////////

    cvm.activate = function() {
        DeliveryCompanyRepository.get("", deliveryCompanyID).then(function(data) {
            cvm.company = data;
            $scope.$emit('selectItem', { company: cvm.company, item: 'assign' });
            $scope.$emit('setLoading', false);
            
            DeliveryCompanyRepository.getList('/' + deliveryCompanyID + '/assign').then(function(data) {
                cvm.assigns = data;
            });
            
            DeliveryCompanyRepository.getList().then(function(data) {
                cvm.companies = data;
            });
        });
    };
    
    cvm.selectAssign = function(item, model) {
        $scope.$emit('showNotification', {
            id: 'dSelectAssign',
            loading: true,
            alertType: 'alert-info',
            title: translateService.instant('general.loading')
        });
        
        var newObj = {
            deliveryCompanyID:  cvm.company.deliveryCompanyID,
            assignCompanyID:    item.deliveryCompanyID,
            sortOrder:          cvm.assigns.length
        };
        
        DeliveryAssignRepository.create(newObj).then(function(data) {
            DeliveryCompanyRepository.getList('/' + deliveryCompanyID + '/assign').then(function(data) {
                cvm.assigns = data;
                cvm.addAssign = null;
                
                $scope.$emit('showNotification', {
                    id: 'dSelectAssign',
                    loading: false,
                    alertType: 'alert-success',
                    title: translateService.instant('general.saved')
                });
            });
        });
    };
    
    cvm.removeAssign = function(assign) {
        var modalOptions = {
            closeButtonText: translateService.instant('general.cancel'),
            actionButtonText: translateService.instant('general.delete'),
            actionButtonClass: 'btn-danger',
            headerText: translateService.instant('delivery.assign.confirm_delete_short'),
            bodyText: translateService.instant('delivery.assign.confirm_delete')
        };

        modalService.showModal({}, modalOptions).then(function (result) {
            if(result) {
                $scope.$emit('showNotification', {
                    id: 'dRemoveAssign',
                    loading: true,
                    alertType: 'alert-info',
                    title: translateService.instant('general.loading')
                });
                
                DeliveryAssignRepository.remove(assign.deliveryAssignID).then(function(data) {
                    cvm.assigns.splice(cvm.assigns.indexOf(assign), 1);
                    $scope.$emit('showNotification', {
                        id: 'dRemoveAssign',
                        loading: false,
                        alertType: 'alert-success',
                        title: translateService.instant('general.saved')
                    });
                });
            }
        });
    };

    cvm.activate();
    return cvm;
}