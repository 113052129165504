import * as angular from 'angular';

import './eatheretable.factory';

angular.module( 'cmsApp.eathere', [
    'cmsApp.eatheretable.factory'
])

.config(function config( $stateProvider ) {

})

.controller( 'EathereCtrl', function EathereCtrl( $state, $q, $scope, AuthRepository, EatHereTableRepository, translateService ) {
    /* jshint validthis: true */
    var vm = this;
    
    vm.tables = [];
    
    vm.sortableOptions = {};

    vm.activate = function() {
        EatHereTableRepository.getList('').then(function(data) {
            vm.tables = data;
        });
    };
    
    vm.createTable = function() {
        EatHereTableRepository.create({ tableName: translateService.instant('eathere.default_table_name'), sortOrder: vm.tables.length }).then(function(obj) {
            vm.tables.push(obj);
        });
    };
    
    vm.save = function() {
        $scope.$emit('showNotification', { id: 'eatHereTableSave', loading: true, alertType: 'alert-info', title: translateService.instant('general.loading') });

        var promises = [];

        angular.forEach(vm.tables, function(obj) {
            obj.sortOrder = vm.tables.indexOf(obj);
            promises.push(EatHereTableRepository.update(obj, obj.eatHereTableID));
        });

        $q.all(promises).then(function() {
            $scope.$emit('showNotification', { id: 'eatHereTableSave', loading: false, alertType: 'alert-success', title: translateService.instant('general.saved') });
        });
    };
    
    vm.updateTableName = function (table, newName) {
        $scope.$emit('showNotification', { id: 'TableName', loading: true, alertType: 'alert-info', title: translateService.instant('general.loading') });

        EatHereTableRepository.update({ eatHereTableID: table.eatHereTableID, tableName: newName }, table.eatHereTableID).then(function () {
            $scope.$emit('showNotification', { id: 'TableName', loading: false, alertType: 'alert-success', title: translateService.instant('general.saved') });
        });
        return true;
    };
    
    vm.activate();
    return vm;
})

;
