let template = require('./summary-view.component.html');

import { Summary } from './summary';

export class SummaryViewComponent {
    public static readonly controller = SummaryViewComponent;
    public static readonly controllerAs: string = 'vm';
    public static readonly template: string = template;
    public static readonly bindings = {
        summary: '<',
        distance: '<',
    };

    public sort = {
        reverse: false,
        propertyName: 'deliveryCompany.companyName'
    };

    public summary: Summary;

    public sortBy (propertyName) {
        this.sort.reverse = (this.sort.propertyName === propertyName) ? !this.sort.reverse : false;
        this.sort.propertyName = propertyName;
    }

    public distanceFilter ({min, max}) {
        return function (entry) {
            let include = true;
                
            if (min !== null && entry.averageGmDistance < min) include = false;
            if (max !== null && entry.averageGmDistance > max) include = false;

            return include;
        }
    }
}
