import * as _ from 'lodash';
import * as angular from 'angular';

import 'app/delivery/restaurant/deliveryrestaurant.factory';

const template = require('./clientkeySelector.tpl.html');

angular.module( 'cmsApp.clientkeySelector', [])
    .directive('clientkeySelector', clientkeySelector);

function clientkeySelector() {
    return {
        restrict: 'EA',
        scope: {
            clientkeySelector: '=',
            doSearch: '='
        },
        controller: function( $scope, $state, $interval, $filter, DeliveryRestaurantRepository ) {
            /* jshint validthis: true */
            var vm = this;
            
            // Setup those variables
            vm.restaurants = [];
            vm.brands = [];
            vm.cuisines = [];
            vm.restaurantsForSearch = [];
            vm.selectedRestaurants = [];

            ////////////////////
            
            /**
             * Activate is our main() function
             */
            vm.activate = function() {
                DeliveryRestaurantRepository.getList('/all/').then(function(data) {
                    vm.restaurants = data;
                    vm.brands = data.sideData.brands;
                    vm.cuisines = data.sideData.cuisines;
                    
                    vm.reload();
                });
            };
            
            /**
             * Perform a reload, this is basiclly a reverse refreshModel()
             */
            vm.reload = function() {
                angular.forEach(vm.restaurants, function(rest) {
                    if(vm.clientkeySelector.indexOf(rest.clientKey) >= 0 && vm.selectedRestaurants.indexOf(rest) == -1) {
                        vm.selectedRestaurants.push(rest);
                    }
                });
            };
            
            /**
             * Search for restaurants. This is a custom function because
             * ui-select (the plugin we use) lags badly.
             */
            vm.restaurantsSearch = function(search) {
                var restaurantsSearch = $filter('filter')(vm.restaurants, { restaurantName: search });
                var brandSearch = $filter('filter')(vm.brands, { brand: search });
                var cuisineSearch = $filter('filter')(vm.cuisines, { cuisine: search });
                var item;

                var results = restaurantsSearch.slice(0, 8);

                var base = 0;
                if(results.length > 1) {
                    base = 1;
                }

                if(brandSearch && brandSearch.length && search !== '') {
                    item =  brandSearch[0];
                    item.restaurantName = 'Brand: ' + item.brand;
                    results.splice(base, 0, item);
                }

                if(cuisineSearch && cuisineSearch.length && search !== '') {
                    item =  cuisineSearch[0];
                    item.restaurantName = 'Kokkonst: ' + item.cuisine;
                    results.splice(base + 1, 0, item);
                }

                vm.restaurantsForSearch = results;
            };
            
            /**
             * Add an item to the select2 box.
             * If it's a brand or cuisine item (with multiple restaurants),
             * add each of the restaurants to the list instead of the
             * brand/cuisine item.
             */
            vm.select = function(item) {
                if(item.restaurants) {
                    vm.selectedRestaurants.splice(vm.selectedRestaurants.indexOf(item), 1);

                    var filteredRestaurants = _.filter(item.restaurants, function(restaurant) {
                        return vm.selectedRestaurants.indexOf(restaurant) == -1;
                    });

                    vm.selectedRestaurants = [].concat(vm.selectedRestaurants, filteredRestaurants);
                }

                vm.refreshModel();
            };
            
            /**
             * Remove an item, refresh the model
             */
            vm.doremove = function() {
                vm.refreshModel();
            };
            
            /**
            * Refresh the model bound to this directive.
            * Since it should only return an array of clientkeys
            * Rather than an array of restaurant objects
            */
            vm.refreshModel = function() {
                var tmp = [];
                angular.forEach(vm.selectedRestaurants, function(rest) {
                    if(rest.clientKey) {
                        tmp.push(rest.clientKey);
                    }
                });
                vm.clientkeySelector = tmp;
                vm.debouncedSearch();
            };
            
            /**
             * Debounced version of the search
             */
            vm.debouncedSearch = _.debounce(function() {
                $scope.$apply(function() {
                    vm.doSearch();
                });
            }, 200);
            
            // Let's go!
            vm.activate();
        },
        controllerAs: 'vm',
        bindToController: true,
        template: template
    };
}

