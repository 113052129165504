angular.module( 'cmsApp.user.factory', [
    'ui.router',
    'ui.bootstrap'
])

.factory('UserRepository', function (Restangular, AbstractRepository) {

    function UserRepository() {
        this.access_token = null;
        this.refresh_token = null;
        this.user = null;
        this.logged_in = false;

        AbstractRepository.prototype.initUser = function(access_token, refresh_token) {
            this.access_token = access_token;
            this.refresh_token = refresh_token;
        };

        AbstractRepository.prototype.isLoggedIn = function() {
            return this.logged_in;
        };

        AbstractRepository.prototype.setUser = function(user) {
            this.logged_in = true;
            this.user = user;
        };

        AbstractRepository.prototype.getUser = function() {
            return this.user;
        };

        AbstractRepository.prototype.setTokens = function(access_token, refresh_token) {
            this.access_token = access_token;
            this.refresh_token = refresh_token;
        };

        AbstractRepository.prototype.getAccessToken = function() {
            return this.access_token;
        };

        AbstractRepository.prototype.logoutUser = function() {
            this.logged_in = false;
            this.user = null;
            this.access_token = null;
            this.refresh_token = null;

            // TODO: logout at api?
        };

        AbstractRepository.prototype.authUser = function(credentials) {
            var oauth_credentials = {
                'grant_type': 'password',
                'client_id': 'testid',
                'client_secret': 'testsecret',
                'username': credentials.email,
                'password': credentials.password,
                'scope': 'testscope',
                'state': 123456789
            };
            return this.restangular.all('customers/auth').customPOST(oauth_credentials);
        };

        AbstractRepository.prototype.me = function() {
            return this.get("/me", null, null, {'Authorization': this.access_token});
        };

        AbstractRepository.call(this, Restangular, 'admin');
    }

    AbstractRepository.extend(UserRepository);

    return new UserRepository();
})

;
