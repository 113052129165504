import { AbstractRepository } from '../abstract/abstract-repository';
import { IPromise } from 'angular';


export enum RescheduleRequestState {
    REQUESTED = 'requested',
    WAITING = 'waiting',
    CONFIRMED = 'confirmed',
    DENIED = 'denied'
}


export interface MinimalDeliveryOrder {
    orderID: number;
    deliveryCompanyID: number | null;
    deliveryCompanyName: string | null;
    deliveryDriverName: string | null;
    restaurantName: string | null;
    transportMode: number | null;
    distanceInMeters: number;
    rescheduleRequestState: RescheduleRequestState | null;
    isLockedOnCarrier: boolean;
    isDriverLateForCheckin: boolean;
    isDriverLateForDelivery: boolean;
    wasAssignedByTimeout: boolean;
    wasDeliveredOnTime: boolean;
    hasAdminComment: boolean;
    hasDriverComment: boolean;
    hasCustomerMessage: boolean;
    hasRestaurantClaimedMissingDeliveryOrder: boolean;
    customerOrderedAt: string;
    driverShouldPickupAt: string | null;
    driverCompletedPickupAt: string | null;
    driverCheckedInAt: string | null;
    driverShouldDeliverAt: string | null;
    driverDeliveredAt: string | null;

}

export class MinimalDeliveryOrderRepository extends AbstractRepository {
    constructor(Restangular) {
        super(Restangular, 'delivery/minimal-orders');
    }

    public getMinimalOrders(query: any): IPromise<MinimalDeliveryOrder[]> {
        return this.getList('', query)
            .then((response: any) => response);
    }

    public getMinimalOrder(deliveryOrderID: number): IPromise<MinimalDeliveryOrder> {
        return this.get('', deliveryOrderID, {}, {})
            .then((response: any) => response.plain());
    }
}
