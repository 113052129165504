angular.module( 'cmsApp.delivery.timeout.schedule.factory', [
])

.factory('DeliveryTimeoutSchedule', function (Restangular, AbstractRepository) {

    function DeliveryTimeoutSchedule() {

        DeliveryTimeoutSchedule.prototype.saveSortOrder = function(timeoutSchedules) {
            return this.customUpdate(timeoutSchedules, this.route + '/sortOrder');
        };

        AbstractRepository.call(this, Restangular, 'delivery/timeout/schedule');
    }

    AbstractRepository.extend(DeliveryTimeoutSchedule);

    return new DeliveryTimeoutSchedule();
})

;