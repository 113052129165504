import * as angular from 'angular';

import 'app/appthemes/apptheme/appthemecolor/appthemecolor.factory';

var template = require('./appthemecolor.tpl.html');

angular.module( 'cmsApp.appthemes.apptheme.color', [
    'cmsApp.appthemes.apptheme.color.factory'
])
.directive('roAppthemeColor', roAppthemeColor);

function roAppthemeColor() {
    return {
        restrict: 'EA',    
        scope: {
            roAppthemeColor: '=',
            ahex: '=',
            rgba: '=',
            bold: '='
        },
        controller: function( $scope, AppthemeColorFactory ) {
            /* jshint validthis: true */
            var vm = this;
            
            vm.activate = function() {
                // we're on!
            };
            
            vm.rgbaChange = function() {
                vm.ahex = AppthemeColorFactory.rgb2ahex(vm.rgba);
            };
            
            vm.ahexChange = function() {
                var possibleRGBA = AppthemeColorFactory.rgbaToStr(AppthemeColorFactory.ahexToRgba(vm.ahex));
                if(possibleRGBA) {
                    vm.rgba = possibleRGBA;
                }
            };

            vm.activate();
        },
        controllerAs: 'vm',
        bindToController: true,
        template: template
    };
}

