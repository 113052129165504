import { Routes } from '@angular/router';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { FinanceComponent as DriverFinanceComponent } from 'app/components/delivery/driver/finance/finance.component';
import { TestorderComponent } from './components/testorder/testorder.component';
import { LoginComponent } from './components/login';
import { AuthAdminGuard, AuthGuestGuard } from '@ro-ngx/authentication';
import { IframeComponent } from './components/iframe/iframe.component';
import { PermissionName } from '@ro-ngx/users';
import { DriversMapGuard } from './shared/guards/drivers-map.guard';
import { GlobalExceptionsComponent } from './components/global-exceptions/global-exceptions.component';
import { FinanceReportComponent } from './finance-components/finance-report/finance-report.component';

export const routes: Routes = [
    {
        path: '',
        loadChildren: 'app/components/home/home.module#HomeModule',
        canActivate: [AuthAdminGuard],
    },
    {
        path: 'dashboard',
        loadChildren: 'app/components/dashboard/dashboard.module#DashboardModule',
        canActivate: [AuthAdminGuard]
    },
    {
        path: 'article-list',
        loadChildren: '@ro-ngx/article-list#ArticleListModule',
        canActivate: [AuthAdminGuard]
    },
    {
        path: 'delivery-distance-price',
        loadChildren: '@ro-ngx/delivery-distance-price#DeliveryDistancePriceModule',
        canActivate: [AuthAdminGuard],
        data: {
            title: 'route.delivery_distance_price_title',
            permissionGroups: [
                [PermissionName.DELIVERY_COMPANIES_READ_ALL]
            ]
        }
    },
    {
        path: 'testorder',
        component: TestorderComponent,
        canActivate: [AuthAdminGuard],
        data: {
            title: 'route.testorder_title',
            permissionGroups: [
                [PermissionName.ORDER_TESTS_READ_ALL]
            ]
        }
    },
    {
        path: 'global-exceptions',
        loadChildren: 'app/components/global-exceptions/global-exceptions.module.ts#GlobalExceptionsModule',
        canActivate: [AuthAdminGuard],
    },
    {
        path: 'market-settings',
        loadChildren: 'app/components/marketsettings/market-settings.module#MarketSettingsModule',
        canActivate: [AuthAdminGuard],
    },
    {
        path: 'compensation-history',
        loadChildren: '@ro-ngx/compensation-history#CompensationHistoryModule',
        canActivate: [AuthAdminGuard],
        data: {
            title: 'route.compensation_history_title',
            permissionGroups: [
                [PermissionName.COMPENSATIONS_READ_ALL]
            ]
        }
    },
    {
        path: 'customer-occurrences',
        loadChildren: '@ro-ngx/customer-occurrences#CustomerOccurrencesModule',
        canActivate: [AuthAdminGuard],
        data: {
            title: 'route.customer_occurrences_title'
        }
    },
    {
        path: 'transaction-history',
        redirectTo: 'order-history'
    },
    {
        path: 'order-history',
        loadChildren: '@ro-ngx/order-history#OrderHistoryModule',
        canActivate: [AuthAdminGuard],
        data: {
            title: 'route.transaction_history_title',
            permissionGroups: [
                [PermissionName.ORDERS_READ_ALL]
            ]
        }
    },
    {
        path: 'delivery-times',
        loadChildren: '@ro-ngx/delivery#DeliveryTimesModule',
        canActivate: [AuthAdminGuard],
        data: {
            title: 'route.delivery_times'
        }
    },
    {
        path: 'delivery-districts',
        loadChildren: '@ro-ngx/delivery#DeliveryDistrictsModule',
        canActivate: [AuthAdminGuard]
    },
    {
        path: 'campaign-group',
        loadChildren: 'app/components/campaign-group/campaign-group.module#CampaignGroupModule',
        canActivate: [AuthAdminGuard],
        data: {
            permissionGroups: [
                [PermissionName.CAMPAIGN_GROUPS_READ_ALL]
            ]
        }
    },
    {
        path: 'status',
        loadChildren: 'app/components/page-status/page-status.module#PageStatusModule',
        canActivate: [AuthAdminGuard],
        data: {
            permissionGroups: [
                [PermissionName.RESTAURANT_STATUS_READ_ALL]
            ]
        }
    },
    {
        path: 'coupons',
        loadChildren: '@ro-ngx/coupons#CouponManagerModule',
        canActivate: [AuthAdminGuard],
        data: {
            permissionGroups: [
                [PermissionName.COUPON_CODES_READ_ALL]
            ]
        }
    },
    {
        path: 'city-covers',
        loadChildren: 'app/components/campaign/campaign.module#CampaignModule',
        canActivate: [AuthAdminGuard],
        data: {
            permissionGroups: [
                [PermissionName.CAMPAIGN_GROUPS_READ_ALL]
            ]
        }
    },
    {
        path: 'driver-location',
        loadChildren: 'app/components/driver-location/driver-location.module#DriverLocationModule',
        canActivate: [DriversMapGuard, AuthAdminGuard],
        data: {
            title: 'route.driver_location'
        }
    },
    {
        path: 'custom-places',
        loadChildren: 'app/components/custom-place/custom-place.module#CustomPlaceModule',
        canActivate: [AuthAdminGuard],
        data: {
            permissionGroups: [
                [PermissionName.CUSTOM_PLACES_EDIT]
            ]
        }
    },
    {
        path: 'announcements',
        loadChildren: 'app/components/announcement/announcement.module#AnnouncementModule',
        canActivate: [AuthAdminGuard],
        data: {
            title: 'route.announcements',
            permissionGroups: [
                [PermissionName.ANNOUNCEMENTS_EDIT]
            ]
        }
    },
    {
        path: 'offices',
        loadChildren: 'app/components/offices/offices.module#OfficesModule',
        canActivate: [AuthAdminGuard],
        data: {
            title: 'route.offices_page_title',
        },
    },
    {
        path: 'push-notifications',
        loadChildren: '@ro-ngx/push-notification#PushNotificationModule',
        canActivate: [AuthAdminGuard],
        data: {
            title: 'route.push_notifications_title',
            permissionGroups: [
                [PermissionName.PUSH_NOTIFICATIONS_READ_ALL]
            ]
        }
    },
    {
        path: 'delivery/driver/finance',
        component: DriverFinanceComponent,
        canActivate: [AuthAdminGuard],
        data: {
            title: 'route.delivery_driver_finance_title',
            permissionGroups: [
                [PermissionName.DELIVERY_DRIVER_FINANCES_READ_ALL]
            ]
        }
    },
    {
        path: 'finance-report',
        component: FinanceReportComponent,
        canActivate: [AuthAdminGuard],
        data: {
            title: 'route.finance-report_title',
            permissionGroups: [
                [PermissionName.DELIVERY_DRIVER_FINANCES_READ_ALL]
            ]
        }
    },
    {
        path: 'customer-admin',
        loadChildren: 'app/components/customer-admin/customer-admin.module#CustomerAdminModule',
        canActivate: [AuthAdminGuard],
        data: {
            permissionGroups: [
                [PermissionName.ORDER_CUSTOMERS_READ_ALL]
            ]
        }
    },
    {
        path: 'customers/export',
        loadChildren: '@ro-ngx/customer-export#CustomerExportModule',
        canActivate: [AuthAdminGuard],
        data: {
            title: 'route.customer_export_title',
            permissionGroups: [
                [PermissionName.ORDER_CUSTOMER_EXPORTS_ALL]
            ]
        }
    },
    {
        path: 'restaurants',
        loadChildren: 'app/components/restaurant/list/restaurant-list.module#RestaurantListModule',
        canActivate: [AuthAdminGuard],
        data: {
            permissionGroups: [
                [PermissionName.RESTAURANTS_READ_ALL]
            ]
        }
    },
    {
        path: 'restaurant-summaries',
        loadChildren: 'app/components/restaurant/summary/restaurant-summary.module#RestaurantSummaryModule',
        canActivate: [AuthAdminGuard],
        data: {
            permissionGroups: [
                [PermissionName.RESTAURANT_SUMMARIES_READ_ALL]
            ]
        }
    },
    {
        path: 'delivery-driver-summaries',
        loadChildren: '@ro-ngx/delivery#DeliveryDriverSummariesModule',
        canActivate: [AuthAdminGuard]
    },
    {
        path: 'order-driver-review-messages',
        loadChildren: 'app/components/order-driver-review/message/order-driver-review-message.module#OrderDriverReviewMessageModule',
        canActivate: [AuthAdminGuard]
    },
    {
        path: 'page-settings',
        loadChildren: 'app/components/page-settings/page-settings.module#PageSettingsModule',
        canActivate: [AuthAdminGuard],
        data: {
            permissionGroups: [
                [PermissionName.PAGE_SETTINGS]
            ]
        }
    },
    {
        path: 'send-grid-events',
        loadChildren: 'app/components/send-grid-event/send-grid-event.module#SendGridEventModule',
        canActivate: [AuthAdminGuard],
        data: {
            permissionGroups: [
                [PermissionName.SEND_GRID_EVENTS_READ_ALL]
            ]
        }
    },
    {
        path: '_mdo',
        loadChildren: '@ro-ngx/delivery#DeliveryManageModule',
        canActivate: [AuthAdminGuard],
        data: {
            title: 'route.delivery_manage_title',
        }
    },
    {
        path: 'user-profile',
        loadChildren: '@ro-ngx/user-profile#UserProfileModule',
        canActivate: [AuthAdminGuard]
    },
    {
        path: 'login',
        component: LoginComponent,
        canActivate: [AuthGuestGuard],
        data: {
            title: 'route.login_title'
        }
    },
    {
        path: 'iframe',
        component: IframeComponent,
        children: []
    },
    {
        path: '**',
        component: PageNotFoundComponent
    }
];
