import { duration } from 'moment';

export function durationFilter () {
    return function (input, unit) {
        input = input || '';
        unit  = unit  || 'seconds';

        if (isNaN(parseInt(input)) || parseInt(input) === 0) {
            return 'N/A';
        }

        return duration(input, unit).humanize();
    };
}
