angular.module( 'cmsApp.delivery.timeout.schedule', [
	'cmsApp.delivery.timeout.schedule.factory',
	'cmsApp.delivery.timeout.schedule.component'
])
.config(DeliveryTimeoutScheduleCtrlConfig)
.controller('DeliveryTimeoutScheduleCtrl', DeliveryTimeoutScheduleCtrl);

function DeliveryTimeoutScheduleCtrlConfig( $stateProvider ) {

}

function DeliveryTimeoutScheduleCtrl( $scope, $state, modalService, DeliveryCompanyRepository, DeliveryTimeoutSchedule, translateService ) {
    /* jshint validthis: true */
    var cvm = this;

    cvm.timeoutSchedules = [];

    activate();

    //////////////////
    
    function activate() {
		DeliveryTimeoutSchedule.getList().then(function(response) {
			cvm.timeoutSchedules = response;

            $scope.$emit('setLoading', false);
		});
		DeliveryCompanyRepository.getList().then(function(response) {
			cvm.deliveryCompanies = response;
		});
	}

    $scope.$on('emitScheduleSaved', function(event, item) {
        $scope.$broadcast('scheduleSaved', item);
    });

    cvm.dragSchedulesListeners = {
        accept: function(sourceItemHandleScope, destSortableScope) {
            return sourceItemHandleScope.itemScope.sortableScope.$id === destSortableScope.$id;
        },
        orderChanged: function(event) {
            cvm.saveSortOrder();
        }
    };

    cvm.saveSortOrder = function() {
        cvm.setTimeoutSchdeulePositions(cvm.timeoutSchedules);

        DeliveryTimeoutSchedule.saveSortOrder(cvm.timeoutSchedules).then(function(response) {
            angular.merge(cvm.timeoutSchedules, response);
        });
    };

    cvm.setTimeoutSchdeulePositions = function(schedules) {
        angular.forEach(schedules, function(schedule) {
            schedule.sortOrder = schedules.indexOf(schedule);
        });
    };

	cvm.createScheduleModal = function() {
		var modalOptions = {
		    closeButtonShow: true,
		    textInputShow: true,
		    textInputLabel: translateService.instant('delivery.timeout.schedule.name'),
		    closeButtonText: translateService.instant('general.cancel'),
		    actionButtonText: translateService.instant('general.ok'),
		    actionButtonClass: 'btn-success',
		    headerText: translateService.instant('delivery.timeout.schedule.create_schedule_heading'),
		    bodyText: '',
		    textInputValue: ''
		};

		modalService.showModal({}, modalOptions).then(function(scheduleName) {
			if (scheduleName) {
				cvm.createSchedule(scheduleName);
			} 
		});
	};

	cvm.createSchedule = function(scheduleName) {
		$scope.$emit('showNotification', { id: 'dTimeoutScheduleCreate', loading: true, alertType: 'alert-info', title: 'Sparar...', message: 'Dina ändringar sparas' });

		DeliveryTimeoutSchedule.create('', {
			scheduleName: scheduleName,
            sortOrder: cvm.timeoutSchedules.length
		}).then(function(response) {
			response.timeoutsteps = [];
			cvm.timeoutSchedules.push(response);
			
			$scope.$emit('showNotification', { id: 'dTimeoutScheduleCreate', loading: false, alertType: 'alert-success', title: 'Sparat!', message: 'Dina ändringar har blivit sparade' });
		});
	};

	return cvm;
}