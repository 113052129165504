export class OrderRowHeaderColumnCarrierTransportModeComponent {
    public static readonly controller = OrderRowHeaderColumnCarrierTransportModeComponent;
    public static readonly controllerAs = 'vm';
    public static readonly template = require('./order-row-header-column-carrier-transport-mode.component.html');
    public static readonly bindings = {
        order: '<'
    };

    public order: any;

    public getTransportMode(): number {
        if (typeof this.order.transportMode !== 'undefined') {
            return this.order.transportMode;
        }

        if (this.order.deliverydriver) {
            return this.order.deliverydriver.transportMode;
        }

        return null;
    }
}
