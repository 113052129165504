import * as angular from 'angular';

import 'app/appthemes/apptheme/appthemecolor/appthemecolor.directive';
import 'app/appthemes/apptheme/appthemefont/appthemefont.directive';
import 'app/appthemes/apptheme/appthemefontarea/appthemefontarea.directive';

angular.module( 'cmsApp.appthemes.apptheme', [
    'colorpicker.module',
    'cmsApp.appthemes.apptheme.color',
    'cmsApp.appthemes.apptheme.font',
    'cmsApp.appthemes.apptheme.fontarea'
])
.config(AppthemeCtrlConfig)
.controller('AppthemeCtrl', AppthemeCtrl);

function AppthemeCtrlConfig( $stateProvider ) {

}

function AppthemeCtrl( $scope, $state, $sce, AppthemeRepository, modalService, AppthemeColorFactory, appThemeID ) {
    /* jshint validthis: true */
    var atvm = this;

    atvm.apptheme = {};
    atvm.appthemeRGBA = {};
    
    atvm.transitionEffects = {
        0: 'Slide',
        1: 'Crossfade'
    };
    
    atvm.show = {
        header:     false,
        dialog:     false,
        textfields: false,
        signin:     false,
        mode:       false,
        general:    false,
        action:     false,
        level1:     false,
        level2:     false,
        options:    false,
        tags:       false,
        cart:       false,
        checkout:   false,
        settings:   false,
        multiapp:   false
    };
    
    atvm.statusBarLightOptions = {
        0: 'Dark',
        1: 'Light'
    };
    
    atvm.boolInt = {
        0: 'Off',
        1: 'On'
    };

    ////////////////////

    atvm.activate = function() {
        AppthemeRepository.get("", appThemeID).then(function(data) {
            atvm.apptheme = data;
            atvm.appthemeRGBA = AppthemeColorFactory.convertObjectColorsToRGBA(data);
            
            atvm.apptheme.imageTransitionEffect = atvm.apptheme.imageTransitionEffect.toString();
            atvm.apptheme.statusBarLight        = atvm.apptheme.statusBarLight.toString();
            atvm.apptheme.parentShadow          = atvm.apptheme.parentShadow.toString();
            
            atvm.apptheme.sectionDelimiter      = atvm.apptheme.sectionDelimiter.toString();
            
            $scope.$emit('selectItem', { appThemeID: atvm.apptheme.appThemeID });
            $scope.$emit('setLoading', false);
        });
    };

    atvm.save = function() {
        $scope.$emit('showNotification', { id: 'aThemeSave', loading: true, alertType: 'alert-info', title: 'Sparar...', message: 'Dina ändringar sparas' });

        AppthemeRepository.update(atvm.apptheme, atvm.apptheme.appThemeID).then(function(data) {
            $scope.$emit('showNotification', { id: 'aThemeSave', loading: false, alertType: 'alert-success', title: 'Sparat!', message: 'Dina ändringar har blivit sparade' });
        });

        $scope.$emit('updateTheme', atvm.apptheme);
    };

    atvm.removeTheme = function() {
        var modalOptions = {
            closeButtonText: 'Avbryt',
            actionButtonText: 'Ta bort',
            actionButtonClass: 'btn-danger',
            headerText: 'Är du säker?',
            bodyText: 'Är du säker på att du vill ta bort det här temat?'
        };

        modalService.showModal({}, modalOptions).then(function (result) {
            if(result) {
                $scope.$emit('removeTheme', atvm.apptheme);
            }
        });
    };
    
    atvm.duplicateTheme = function() {
        $scope.$emit('duplicateTheme', atvm.apptheme);
    };
    
    atvm.activate();
    return atvm;
}