import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

/**
 * @link http://blog.devcross.net/2016/03/20/angular-2-seo/
 * Reminder
 */
@Injectable()
export class SeoService {

    /**
     * Angular 2 Title Service
     */
    private titleService: Title;

    constructor(titleService: Title) {
        this.titleService = titleService;
    }

    public getTitle(): string {
        return this.titleService.getTitle();
    }

    public setTitle(newTitle: string): void {
        this.titleService.setTitle(newTitle);
    }
}
