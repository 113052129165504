import { format } from 'date-fns';

export class OrderRowHeaderColumnDateFormatComponent {
    public static readonly controller = OrderRowHeaderColumnDateFormatComponent;
    public static readonly controllerAs = 'vm';
    public static readonly template = require('./order-row-header-column-date-format.component.html');
    public static readonly bindings = {
        order: '<'
    };

    public order: any;

    public getPickupAtDate() {
        return format(this.getDriverShouldPickupAt(), 'DD/MM');
    }

    public getDriverShouldPickupAt() {
        if (this.order.driverShouldPickupAt) {
            return this.order.driverShouldPickupAt;
        }

        return this.order.createTimestamp;
    }
}
