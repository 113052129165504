import * as angular from 'angular';

import 'app/delivery/timeout/schedule/timeoutschedule.factory';
import 'app/delivery/timeout/step/step.factory';
import 'app/delivery/timeout/step/timeoutstep.modal.create';
import 'app/delivery/timeout/step/timeoutstep.component';

var modalTemplate = require('../step/timeoutstep.modal.create.tpl.html');
var template= require('./timeoutschedule.component.tpl.html');

angular.module( 'cmsApp.delivery.timeout.schedule.component', [
	'cmsApp.delivery.timeout.schedule.factory',
	'cmsApp.delivery.timeout.step',
	'cmsApp.delivery.timeout.step.modal.create',
	'cmsApp.delivery.timeout.step.component'
])
.component('roDeliveryTimeoutSchedule', {
	bindings: {
		schedules: '=',
		schedule: '=',
		editmode: '='
	},
	controller: function($scope, $filter, $modal, modalService, DeliveryTimeoutSchedule, DeliveryCompanyRepository, DeliveryTimeoutStep, translateService) {
		var cvm = this;

		cvm.timeoutSchedules = [];

		cvm.deliveryCompanies = [];

		activate();

		////////////////////

		function activate() {
			DeliveryCompanyRepository.getList().then(function(response) {
				cvm.deliveryCompanies = response;

			});
		}

		cvm.dragStepsListeners = {
			accept: function(sourceItemHandleScope, destSortableScope) {
				return sourceItemHandleScope.itemScope.sortableScope.$id === destSortableScope.$id;
			}
		};

		cvm.setTimeoutStepPositions = function(steps) {
			angular.forEach(steps, function(step) {
				step.step = steps.indexOf(step);
			});
		};

        $scope.$on('scheduleSaved', function(event, schedule) {
            if (schedule.deliveryTimeoutScheduleID === cvm.schedule.deliveryTimeoutScheduleID) {
                angular.merge(cvm.schedule, schedule);
            }
        });

		cvm.saveSchedule = function(schedule) {
			$scope.$emit('showNotification', { id: 'dInterceptorScheduleSave', loading: true, alertType: 'alert-info', title: translateService.instant('general.loading') });

			cvm.setTimeoutStepPositions(schedule.timeoutsteps);

            $scope.$emit('emitScheduleSaving', schedule);

			DeliveryTimeoutSchedule.update(schedule, schedule.deliveryTimeoutScheduleID).then(function(response) {
				angular.merge(schedule, response);
                $scope.$emit('emitScheduleSaved', response);
				$scope.$emit('showNotification', { id: 'dInterceptorScheduleSave', loading: false, alertType: 'alert-success', title: translateService.instant('general.saved') });
			});
		};

		cvm.createStep = function(schedule) {
            var modalInstance = $modal.open({
		        template: modalTemplate,
		        controller: 'CreatedeliveryTimeoutStepModalCtrl as cvm',
		        windowClass: 'ro-modal createdeliverytimeoutstepmodal modalService',
		        resolve: {
		        	schedule: function() {
		        		return schedule;
		        	},
		        	deliveryCompanies: function() {
		        		return cvm.deliveryCompanies;
		        	}
		        }
		    });

			modalInstance.result.then(function(scheduleStep) {

				schedule.timeoutsteps.push(scheduleStep);

				cvm.saveSchedule(schedule);
			});
		};

		cvm.removeSchedule = function(schedule) {
			$scope.$emit('showNotification', { id: 'dTimeoutScheduleDelete', loading: true, alertType: 'alert-info', title: translateService.instant('general.loading') });

			DeliveryTimeoutSchedule.remove(schedule.deliveryTimeoutScheduleID).then(function(response) {
				if (cvm.schedules) {
					var scheduleIndex = cvm.schedules.indexOf(schedule);
					cvm.schedules.splice(scheduleIndex, 1);
				}

				$scope.$emit('showNotification', { id: 'dTimeoutScheduleDelete', loading: false, alertType: 'alert-success', title: translateService.instant('general.saved') });
			});
		};


		return cvm;
	},
	controllerAs: 'cvm',
	template: template
});
