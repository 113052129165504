
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BaseService } from '../../base/base.service';
import { HungrigClient } from '@ro-ngx/core';
import { Observable } from 'rxjs';
import { ManagerDevice } from './manager-device';

@Injectable()
export class ManagerDeviceService extends BaseService {
    public resourceUrl: string = 'cms/restaurants/';

    constructor(public hungrigClient: HungrigClient) {
        super(hungrigClient);
    }

    public getDevices(clientKey: string): Observable<ManagerDevice[]> {
        return this.hungrigClient.get(this.url(`${clientKey}/managerdevices`)).pipe(
            map(this.toResponse),
            map((response) => response.data),
            catchError(this.handleError));
    }

    public createDevice(clientKey: string, device: Partial<ManagerDevice>): Observable<ManagerDevice> {
        return this.hungrigClient.post(this.url(`${clientKey}/managerdevices`), device).pipe(
            map(this.toResponse),
            map((response) => response.data),
            catchError(this.handleError));
    }

    public updateDevice(clientKey: string, id: number, device: Partial<ManagerDevice>): Observable<ManagerDevice> {
        return this.hungrigClient.put(this.url(`${clientKey}/managerdevices/${id}`), device).pipe(
            map(this.toResponse),
            map((response) => response.data),
            catchError(this.handleError));
    }

    public removeDevice(clientKey: string, id: number): Observable<boolean> {
        return this.hungrigClient.delete(this.url(`${clientKey}/managerdevices/${id}`)).pipe(
            map(this.toResponse),
            map((response) => response.data),
            catchError(this.handleError));
    }
}
