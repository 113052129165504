
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { URLSearchParams } from '@angular/http';
import { BaseService } from '../base/base.service';
import { HungrigClient, Paginate, ErrorFactory, PaginateFactory, ResponseFactory } from '@ro-ngx/core';
import { Campaign } from './campaign';
import { Observable } from 'rxjs';

@Injectable()
export class CampaignService extends BaseService {
    public resourceUrl: string = 'cms/campaigns';



    constructor(hungrigClient: HungrigClient) {
        super(hungrigClient);
    }

    public getCampaigns(page: number = 1): Observable<Paginate<Campaign[]>> {
        const search: URLSearchParams = new URLSearchParams();
        search.set('page', page.toString());
        return this.hungrigClient.get(this.url(), { search }).pipe(
            map(PaginateFactory.from),
           catchError(ErrorFactory.throwObservable));
    }

    public getCampaign(id: number): Observable<Campaign> {
        return this.hungrigClient.get(this.url(`/${id}`)).pipe(
            map(ResponseFactory.toData),
           catchError(ErrorFactory.throwObservable));
    }

    public createCampaign(campaign: Partial<Campaign>): Observable<Campaign> {
        return this.hungrigClient.post(this.url(), campaign).pipe(
            map(ResponseFactory.toData),
           catchError(ErrorFactory.throwObservable));
    }

    public updateCampaign(id: number, campaign: Partial<Campaign>): Observable<Campaign> {
        return this.hungrigClient.put(this.url(`/${id}`), campaign).pipe(
            map(ResponseFactory.toData),
           catchError(ErrorFactory.throwObservable));
    }
}
