export class OrderRowHeaderColumnCarrierDeliveryCompanyNameComponent {
    public static readonly controller = OrderRowHeaderColumnCarrierDeliveryCompanyNameComponent;
    public static readonly controllerAs = 'vm';
    public static readonly template = require('./order-row-header-column-carrier-delivery-company-name.component.html');
    public static readonly bindings = {
        order: '<'
    };

    public order: any;

    constructor(protected SearchSupportService) {}

    public getDeliveryCompanyName(): string {
        if (typeof this.order.deliveryCompanyName !== 'undefined') {
            return this.SearchSupportService.truncateText(this.order.deliveryCompanyName);
        }

        if (this.order.deliverycompany) {
            return this.SearchSupportService.truncateText(this.order.deliverycompany.companyName);
        }

        return ''
    }
}
