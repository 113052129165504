import * as angular from 'angular';

import 'common/modalService/modalService';
import 'app/alert/deliveryalerts/deliveryalerts';
import 'app/alert/restaurantalerts/restaurantalerts';
import 'app/alert/blacklist/blacklist.component';

declare var keys: any;

var profileTemplate = require('./profile/newprofilemodal/newprofile.modal.tpl.html');

angular.module( 'cmsApp.alert', [
    'common.modalService',
    'ui.router',
    'ui.bootstrap',
    'cmsApp.alert.profile',
    'cmsApp.alert.deliveryalerts',
    'cmsApp.alert.restaurantalerts',
    'cmsApp.alert.blacklist.component'
])
.controller('AlertCtrl', AlertCtrl);

function AlertCtrl( $q, $scope, $state, $sce, $modal, AlertGroupRepository, AlertMemberRepository, AlertProfileRepository, modalService ) {
    /* jshint validthis: true */
    var vm = this;

    vm.groups = [];
    vm.selectedGroupID = null;
    vm.selectedProfileID = null;

    vm.loadingEnabled = false;

    $scope.$on('setLoading', function(event, data) {
        vm.loadingEnabled = data;
    });

    $scope.$on('selectItem', function(event, data) {
        vm.selectItem(data.groupID, data.profileID);
    });

    $scope.$on('updateGroup', function(event, data) {
        if(vm.groups && data)
        {
            vm.groups.forEach(function (group, groupindex) {
                if(group.alertGroupID == data.alertGroupID)
                {
                    var groupCopy = angular.copy(data);
                    groupCopy.alertmembers = angular.copy(group.alertmembers);

                    vm.groups.splice(groupindex, 1);
                    vm.groups.splice(groupindex, 0, groupCopy);
                }
            });
        }
    });

    $scope.$on('updateProfile', function(event, data) {
        if(vm.groups && data)
        {
            vm.groups.forEach(function (group, groupindex){
                group.alertmembers.forEach(function (member, memberindex) {
                    if(member.alertprofile.alertProfileID == data.alertProfileID)
                    {
                        var memberCopy = angular.copy(member);

                        memberCopy.alertprofile = angular.copy(data);

                        group.alertmembers.splice(memberindex, 1);
                        group.alertmembers.splice(memberindex, 0, memberCopy);
                    }
                });
            });
        }
    });

    $scope.$on('removeGroup', function(event, data) {
        if(vm.groups && data)
        {
            vm.groups.forEach(function (group, groupindex) {
                if(group.alertGroupID == data.alertGroupID)
                {   
                    var promises = [];

                    group.alertmembers.forEach(function (member, memberindex) {
                        promises.push(AlertMemberRepository.remove(member.alertMemberID));
                    });

                    $q.all(promises).then(function () {
                        AlertGroupRepository.remove(group.alertGroupID).then(function() {
                            vm.groups.splice(groupindex, 1);
                            $state.go('alert');
                        });
                    });
                 }
            });
        }
    });

    $scope.$on('removeMember', function(event, data) {
        if(vm.groups && data)
        {
            vm.groups.forEach(function (group, groupindex) {
                if(group.alertGroupID == vm.selectedGroupID)
                {   
                    group.alertmembers.forEach(function (member, memberindex) {
                        if(member.alertprofile.alertProfileID == data)
                        {
                            AlertMemberRepository.remove(member.alertMemberID);
                            group.alertmembers.splice(memberindex, 1);

                            $state.go('alert.group', { alertGroupID: vm.selectedGroupID });
                        }
                    });
                 }
            });
        }
    });

    $scope.$on('removeProfile', function(event, data) {
        if(vm.groups && data)
        {
            var promises = [];

            vm.groups.forEach(function (group, groupindex) {
                
                group.alertmembers.forEach(function (member, memberindex) {
                    if(member.alertprofile.alertProfileID == data)
                    {
                        promises.push(AlertMemberRepository.remove(member.alertMemberID));
                        group.alertmembers.splice(memberindex, 1);
                    }
                });
            });

            $q.all(promises).then(function () {
                AlertProfileRepository.remove(data).then(function() {
                    $state.go('alert.group', { alertGroupID: vm.selectedGroupID });
                });
            });
        }
    });

    ////////////////////

    vm.activate = function() 
    {
        AlertGroupRepository.getList('?with=alertmembers.alertprofile').then(function(data) {
            vm.groups = data;
        });
    };

    vm.selectByID = function(items, key, index)
    {
        var found = { empty: true };

        if(items)
        {
            items.forEach(function (element) {
                if(key.indexOf('.') < 0)
                {
                    if(element[key] == index)
                    {
                        found = element;
                    }
                }
                else
                {
                    keys = key.split('.');
                    key = angular.copy(element);
                    keys.forEach(function (thisKey){
                        if(key[thisKey])
                        {
                            key = key[thisKey];
                        }
                    });

                    if(key == index)
                    {
                        found = element;
                    }
                }
            });
        }

        return found;
    };

    vm.selectItem = function(groupID, profileID)
    {
        if(groupID && groupID >= 0)
        {
            if( (groupID != vm.selectedGroupID && !profileID) || profileID != vm.selectedProfileID )
            {
                vm.loadingEnabled = true;
            }

            vm.selectedGroupID = groupID;

            if(profileID)
            {
                vm.selectedProfileID = profileID;
                $state.go('alert.profile', { alertGroupID: groupID, alertProfileID: profileID });
            }
            else
            {
                vm.selectedProfileID = null;
                $state.go('alert.group', { alertGroupID: groupID });
            }
        }
        else 
        {    
            vm.selectedGroupID = groupID;
            
            if(groupID === -1) {
                $state.go('alert.restaurantalerts');
            } else if(groupID === -3) {
                $state.go('alert.blacklist');
            } else if(groupID === -4) {
                $state.go('alert.alerts');
            } else {
                $state.go('alert.deliveryalerts');
            }
        }
    };

    vm.createAlertGroup = function() {
        var modalOptions = {
            textInputShow: true,
            textInputLabel: 'Gruppnamn',
            closeButtonText: 'Avbryt',
            actionButtonText: 'Skapa',
            actionButtonClass: 'btn-success',
            headerText: 'Ange namn på gruppen du vill skapa',
            bodyText: 'Ange namn på gruppen du vill skapa'
        };

        modalService.showModal({}, modalOptions).then(function (result) {
            if(result && result !== true) {
                AlertGroupRepository.create({ groupName: result }).then(function(obj) {
                    obj.alertmembers = [];
                    vm.groups.push(obj);
                });
            }
        });
    };

    vm.createProfile = function(group) {
        if(group && group.alertGroupID)
        {
            var modalInstance = $modal.open({
                template: profileTemplate,
                controller: 'NewprofileModalCtrl as vm',
                windowClass: 'ro-modal newprofilemodal modalService',
                resolve: {}
            });

            modalInstance.result.then(function (data) {
                if(data.alertProfileID) 
                {
                    AlertMemberRepository.create({ alertGroupID: group.alertGroupID, alertProfileID: data.alertProfileID }).then(function(obj) {
                        obj.alertprofile = data;
                        group.alertmembers.push(obj);
                    });
                }
            }, function() {
                // dismissed
            });
        }
    };

    vm.activate();

    return vm;
}