import { JsonObject, JsonProperty } from "json2typescript";
import { DateConverter } from "@ro-ngx/json2typescript";
import { ArticleBody } from "./article-body";

@JsonObject
export class Article {

    @JsonProperty('articleID', Number)
    public articleID: number = undefined;

    @JsonProperty('slug', String)
    public slug: string = undefined;

    @JsonProperty('name', String)
    public name: string = undefined;

    @JsonProperty('createdAt', DateConverter, true)
    public createdAt?: string;

    @JsonProperty('updatedAt', DateConverter, true)
    public updatedAt?: string;

    @JsonProperty('deletedAt', DateConverter, true)
    public deletedAt?: string | null;

    @JsonProperty('articleBodies', [ArticleBody], true)
    public articleBodies: ArticleBody[] = undefined;
}
