import * as angular from 'angular';

import 'app/othersettings/admincontact/admincontact.factory';

angular.module( 'cmsApp.othersettings.admincontact', [
    'ui.select',
    'ngSanitize',
    'cmsApp.admincontact.factory'
])
.config(AdmincontactCtrlConfig)
.controller('AdmincontactCtrl', AdmincontactCtrl);

function AdmincontactCtrlConfig( $stateProvider ) {

}

function AdmincontactCtrl( $scope, $state, $sce, AdmincontactRepository, translateService  ) {
    /* jshint validthis: true */
    var acvm = this;

    acvm.admincontacts = [];

    ////////////////////

    acvm.activate = function() {
        $scope.$emit('selectItem', { item: 'admincontact', subitem: null } );

        AdmincontactRepository.getList('').then(function(data) {
            acvm.admincontacts = data;

            $scope.$emit('setLoading', false);
        });
    };

    acvm.updateContact = function(contact) {
        $scope.$emit('showNotification', { id: 'aUpdateContact', loading: true, alertType: 'alert-info', title: translateService.instant('general.loading') });

        AdmincontactRepository.update(contact, contact.id).then(function (data) {
            $scope.$emit('showNotification', { id: 'aUpdateContact', loading: false, alertType: 'alert-success', title: translateService.instant('general.saved') });
        });
    };

    acvm.activate();
    return acvm;
}
