import { find } from 'lodash';
import { DeliveryOrderAction, DeliveryOrderActionType } from '@ro-ngx/delivery';
import { differenceInMinutes, format } from "date-fns";

export class OrderRowHeaderColumnDeliveryAtUnitsComponent {
    public static readonly controller = OrderRowHeaderColumnDeliveryAtUnitsComponent;
    public static readonly controllerAs = 'vm';
    public static readonly template = require('./order-row-header-column-delivery-at-units.component.html');
    public static readonly bindings = {
        order: '<'
    };

    public order: any;

    public getTime(): string {
        if (this.wasDelivered()) {
            return format(this.wasDeliveredAt(), 'HH:mm');
        }

        return format(this.shouldDeliverAt(), 'HH:mm');
    }

    public getClass() {
        if ( ! this.wasDelivered()) {
            const minutesUntilDelivery = differenceInMinutes(this.shouldDeliverAt(), new Date());

            if (minutesUntilDelivery < -10) {
                return 'ro-text-danger font-weight-bold';
            }

            return '';
        }


        const differenceInMin = differenceInMinutes(this.wasDeliveredAt(), this.shouldDeliverAt());

        if (differenceInMin < -10) {
            return 'ro-text-danger';
        }

        if (differenceInMin < -5) {
            return '';
        }

        if (differenceInMin < 5) {
            return 'ro-text-success font-weight-bold';
        }

        return 'ro-text-danger font-weight-bold';
    }

    public shouldDeliverAt() {
        if (typeof this.order.driverShouldDeliverAt !== 'undefined') {
            return this.order.driverShouldDeliverAt;
        }

        return this.order.order.deliveryTimestamp;
    }

    public wasDelivered() {
        if (typeof this.order.driverDeliveredAt !== 'undefined') {
            return !!this.order.driverDeliveredAt;
        }

        return !! this.wasDeliveredAt();
    }

    public wasDeliveredAt() {
        if (typeof this.order.driverDeliveredAt !== 'undefined') {
            return this.order.driverDeliveredAt;
        }

        const deliverAction = find(this.order.deliveryorderactions, {
            type: DeliveryOrderActionType.Delivered
        }) as DeliveryOrderAction;

        return deliverAction ? deliverAction.timestamp : null;
    }
}
