angular.module( 'cmsApp.alertviewer.view.log.repository', [

])

.factory('AlertViewLogRepository', function (Restangular, AbstractRepository) {

    function AlertViewLogRepository() {
        AbstractRepository.call(this, Restangular, 'alertviewlog');
    }

    AbstractRepository.extend(AlertViewLogRepository);

    return new AlertViewLogRepository();
})

;
