angular.module( 'cmsApp.alert.profile.alert.service', [

])

.factory('AlertService', function (Restangular, AbstractRepository) {

    function AlertService() {
        AbstractRepository.call(this, Restangular, 'alerts');
    }

    AbstractRepository.extend(AlertService);

    return new AlertService();
})

;