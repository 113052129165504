
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BaseService } from '../../base/base.service';
import { HungrigClient } from '@ro-ngx/core';
import { Observable } from 'rxjs';
import { RestaurantTimeSettings } from './restaurant-time-settings';

@Injectable()
export class RestaurantTimeSettingsService extends BaseService {

    public resourceUrl: string = 'cms/restaurants/';

    constructor(public hungrigClient: HungrigClient) {
        super(hungrigClient);
    }

    public getDailySettings(clientKey: string, day: string): Observable<RestaurantTimeSettings> {
        return this.hungrigClient.get(this.url(`${clientKey}/timesettings/${day}`)).pipe(
            map(this.toResponse),
            map((response) => response.data),
            catchError(this.handleError));
    }

    public updateDailySettings(
        clientKey: string, day: string, settings: Partial<RestaurantTimeSettings>
    ): Observable<RestaurantTimeSettings> {
        return this.hungrigClient.put(this.url(`${clientKey}/timesettings/${day}`), settings).pipe(
            map(this.toResponse),
            map((response) => response.data),
            catchError(this.handleError));
    }

    public getSpecialDays(clientKey: string): Observable<RestaurantTimeSettings[]> {
        return this.hungrigClient.get(this.url(`${clientKey}/timesettings/special`)).pipe(
            map(this.toResponse),
            map((response) => response.data),
            catchError(this.handleError));
    }

    public createSpecialDay(
        clientKey: string, settings: Partial<RestaurantTimeSettings>
    ): Observable<RestaurantTimeSettings> {
        return this.hungrigClient.post(this.url(`${clientKey}/timesettings/special`), settings).pipe(
            map(this.toResponse),
            map((response) => response.data),
            catchError(this.handleError));
    }

    public deleteSpecialDay(clientKey: string, id: number): Observable<boolean> {
        return this.hungrigClient.delete(this.url(`${clientKey}/timesettings/special/${id}`)).pipe(
            map(this.toResponse),
            map((response) => response.data),
            catchError(this.handleError));
    }

    public savePreOrderSettings(settings,clientKey) {
        console.log(clientKey)
        return this.hungrigClient.put(this.url(`${clientKey}`), settings).pipe(
            map(this.toResponse),
            map((response) => response.data),
            catchError(this.handleError));
    }

    public getPreOrderSettings(clientKey: string) {
        return this.hungrigClient.get(this.url(`${clientKey}`)).pipe(
            map(this.toResponse),
            map((response) => response.data),
            catchError(this.handleError));
    }
}
