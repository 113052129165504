import { Pipe, PipeTransform } from '@angular/core';
import { duration, DurationInputArg2 } from 'moment';

@Pipe({ name: 'duration' })
export class DurationPipe implements PipeTransform {
    public transform(input: number, unit: DurationInputArg2): string {
        unit = unit || 'seconds';

        if (input === 0) {
            return 'N/A';
        }

        return duration(input, unit).humanize();
    }
}
