angular.module( 'cmsApp.delivery.financesettings.factory', [])

.factory('DeliveryFinanceSettingsRepository', function (Restangular, AbstractRepository) {

    function DeliveryFinanceSettingsRepository() {
        AbstractRepository.call(this, Restangular, 'delivery/financesettings', 'financeSettingsID');
    }

    AbstractRepository.extend(DeliveryFinanceSettingsRepository);

    return new DeliveryFinanceSettingsRepository();
})

;
