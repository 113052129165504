import { Pipe, PipeTransform } from '@angular/core';

type MetricUnit = 'km' | 'm';

interface MetricConversionMap  {
    [key: string]: {
        [key: string]: number
    };
}

@Pipe({ name: 'distance' })
export class DistancePipe implements PipeTransform {
    public static readonly CONVERSION_MAP: MetricConversionMap = {
        km: {
            m: 1000
        },
        m: {
            km: 0.001
        },
    };

    public transform(input: number, to: MetricUnit = 'km', from: MetricUnit = 'm'): number {
        return input * DistancePipe.CONVERSION_MAP[from][to];
    }
}
