export class OrderRowHeaderColumnIdNumericComponent {
    public static readonly controller = OrderRowHeaderColumnIdNumericComponent;
    public static readonly controllerAs = 'vm';
    public static readonly template = require('./order-row-header-column-id-numeric.component.html');
    public static readonly bindings = {
        order: '<'
    };

    public order: any;
}
