import { Injectable } from '@angular/core';
import { BaseService, HungrigClient } from '@ro-ngx/core';
import { RestaurantSummary } from './restaurant-summary';
import { Observable } from 'rxjs';

@Injectable()
export class RestaurantSummaryService extends BaseService {
    constructor(public hungrigClient: HungrigClient) {
        super(hungrigClient);
    }

    public getRestaurantSummaries(clientKey: string): Observable<RestaurantSummary[]> {
        const url = `${this.hungrigClient.baseUrl}/restaurants/${clientKey}/summaries`;
        return this.get(url);
    }
}
