const template = require('./summary.component.html');

import * as moment from 'moment';
import * as _ from 'lodash';
import { Summary } from './summary';
import { NotificationStorage } from '@ro-ngx/core';
import { endOfDay } from 'date-fns';

export class SummaryComponent {
    public static readonly controller = SummaryComponent;
    public static readonly controllerAs: string = 'vm';
    public static readonly template: string = template;

    // Get all properties which have a type, otherwise they are
    // not sortable in any meaningful way on a global level
    public readonly SUM_SORTABLE_PROPERTIES = _.pick(
        Summary.PROPERTY_HEADERS,
        _.intersection(
            Object.keys(Summary.PROPERTY_HEADERS),
            Object.keys(Summary.PROPERTY_TYPES)
        )
    );

    public sort = {
        reverse: false,
        propertyName: 'deliveryCount'
    };
    public distance = {
        min: null,
        max: null
    };
    public deliverySummaryRepository;
    public summaries: Summary[];
    public groupingOptions;
    public groupBy;
    public economyPeriods;
    public economyPeriod;
    public startDate: Date = moment()
        .subtract(1, 'months')
        .startOf('day')
        .toDate();
    public endDate: Date = moment()
        .endOf('day')
        .toDate();

    constructor (
        DeliverySummaryRepository,
        private notificationStorage: NotificationStorage,
        private $q
    ) {
        this.deliverySummaryRepository = DeliverySummaryRepository;
    }

    public $onInit () {
        const notification = this.notificationStorage.loading('Hämtar valmöjligheter...');

        const groupingOptions = this.deliverySummaryRepository.getGroupingOptions()
            .then(options => {
                options = options.plain();
                this.groupingOptions = options.available;
                this.groupBy = options.default;
            }, error => {
                this.notificationStorage.dismiss(notification).danger('Misslyckades.');
            });

        const economyPeriods = this.deliverySummaryRepository.getEconomyPeriods()
            .then(periods => {
                this.economyPeriods = periods.plain();
            }, error => {
                this.notificationStorage.dismiss(notification).danger('Misslyckades.');
            });

        this.$q.all([groupingOptions, economyPeriods]).then(() => {
            this.notificationStorage.dismiss(notification).success('Lyckat!');
            this.fetchSummary();
        });

    }

    public fetchSummary () {
        const notification = this.notificationStorage.loading();
        this.deliverySummaryRepository.getSummary(
                this.startDate.toISOString(), endOfDay(this.endDate).toISOString(), this.groupBy
            )
            .then(summary => {
                this.notificationStorage.dismiss(notification).success('Lyckat!');
                this.summaries = [];
                _.forOwn(summary.plain(), (entries, name) => {
                    this.summaries.push(new Summary(name, entries));
                });
            }, error => {
                this.notificationStorage.dismiss(notification).danger('Misslyckades.');
            });
    }

    public sortSumBy (property) {
        return function (summary: Summary) {
            return summary.getSum()[property];
        }
    }

    public economyPeriodUpdated () {
        this.startDate = new Date(this.economyPeriod[0]);
        this.endDate = new Date(this.economyPeriod[1]);
    }
}
