angular.module( 'cmsApp.alertviewer.viewer.repository', [

])

.factory('AlertViewerRepository', function (Restangular, AbstractRepository) {

    function AlertViewerRepository() {
        AbstractRepository.call(this, Restangular, 'alertviewer');
    }

    AbstractRepository.extend(AlertViewerRepository);

    return new AlertViewerRepository();
})

;
