angular.module( 'cmsApp.alertviewer.alertview.modal.complete', [
])
.controller('CompleteAlertviewModalController', CompleteAlertviewModalController);

function CompleteAlertviewModalController($modalInstance, alert) {
    var vm = this;

    vm.alert = alert;

    activate();

    ////////////////////
    
    function activate() {
        
    }

    vm.ok = function() {
        $modalInstance.close(vm.alert);
    };

    vm.cancel = function() {
        $modalInstance.dismiss('cancel');
    };

    return vm;
}