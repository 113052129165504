export class OrderRowHeaderColumnCarrierAssignedByTimeoutComponent {
    public static readonly controller = OrderRowHeaderColumnCarrierAssignedByTimeoutComponent;
    public static readonly controllerAs = 'vm';
    public static readonly template = require('./order-row-header-column-carrier-assigned-by-timeout.component.html');
    public static readonly bindings = {
        order: '<'
    };

    public order: any;

    public wasOrderAssignedByTimeout() {
        if (typeof this.order.wasAssignedByTimeout !== 'undefined') {
            return this.order.wasAssignedByTimeout;
        }

        return this.calculateWasAssignedByTimeout();
    }

    public calculateWasAssignedByTimeout() {
        return this.isDeliveryOrderAssigned()
            ? this.getDeliveryOrderAssigned().assignType === 1
            : false;
    }

    public getDeliveryOrderAssigned() {
        return this.isDeliveryOrderAssigned()
            ? this.order.deliveryorderassign[this.order.deliveryorderassign.length - 1]
            : null;
    }

    public isDeliveryOrderAssigned() {
        return !! this.order.deliveryorderassign.length;
    }
}
