angular.module( 'cmsApp.delivery.template', [
    'ui.router',
    'ui.bootstrap',
    'xeditable',
    'cmsApp.delivery.interceptor.factory'
])
.config(DeliveryTemplateCtrlConfig)
.controller('DeliveryTemplateCtrl', DeliveryTemplateCtrl);

function DeliveryTemplateCtrlConfig( $stateProvider ) {

}

function DeliveryTemplateCtrl( $scope, $state, $sce, modalService, DeliveryCompanyRepository,
    DeliveryTemplateRepository, DeliveryInterceptorRepository, deliveryCompanyID, translateService ) {
    /* jshint validthis: true */
    var cvm = this;

    var ustatus = {};

    cvm.company = {};

    cvm.templates = {};

    cvm.priceplans = [];

    cvm.repo = DeliveryTemplateRepository;
    
    cvm.interceptors = [];

    ////////////////////

    cvm.activate = function() {
        DeliveryCompanyRepository.getList('/' + deliveryCompanyID + '/priceplans').then(function(data) {
            cvm.priceplans = data;
        });

        DeliveryCompanyRepository.get("", deliveryCompanyID).then(function(data) {
            cvm.company = data;
            $scope.$emit('selectItem', { company: cvm.company, item: 'templates' });

            DeliveryCompanyRepository.getList('/' + deliveryCompanyID + '/templates?with=deliverytemplates').then(function(data) {
                
                // append deliveryzipspans array (empty), this gets loaded automatically on selected = true (click on deliverytemplates)
                angular.forEach(data, function(company, ci) {
                    angular.forEach(company.deliverytemplates, function(template, ti) {
                        template.deliveryzipspans = [];
                    });
                });
                
                cvm.templates = data;

                $scope.$emit('setLoading', false);
            });
        });
        
        DeliveryInterceptorRepository.getList().then(function(data) {
            cvm.interceptors = data;
        });
    };

    cvm.updateTemplateName = function (template, newName) {
        $scope.$emit('showNotification', {
            id: 'dTemplateName',
            loading: true,
            alertType: 'alert-info',
            title: translateService.instant('general.loading')
        });

        cvm.repo.update({ deliveryTemplateID: template.deliveryTemplateID, templateName: newName }, template.deliveryTemplateID).then(function () {
            $scope.$emit('showNotification', {
                id: 'dTemplateName',
                loading: false,
                alertType: 'alert-success',
                title: translateService.instant('general.saved')
            });
        });
        return true;
    };

    cvm.save = function (template, newName) {
        $scope.$emit('showNotification', {
            id: 'dTemplateSave',
            loading: true,
            alertType: 'alert-info',
            title: translateService.instant('general.loading')
        });
        
        cvm.repo.update(template, template.deliveryTemplateID).then(function () {
            $scope.$emit('showNotification', {
                id: 'dTemplateSave',
                loading: false,
                alertType: 'alert-success',
                title: translateService.instant('general.saved')
            });
        });
        return true;
    };

    cvm.createTemplate = function() {
        var modalOptions = {
            textInputShow: true,
            textInputLabel: translateService.instant('delivery.template.name'),
            closeButtonText: translateService.instant('general.cancel'),
            actionButtonText: translateService.instant('general.create'),
            actionButtonClass: 'btn-success',
            headerText: translateService.instant('delivery.template.create_template_heading'),
            bodyText: ''
        };

        modalService.showModal({}, modalOptions).then(function (result) {
            if(result && result !== true) {
                cvm.repo.create({ templateName: result, deliveryCompanyID: cvm.company.deliveryCompanyID }).then(function(obj) {
                    obj.deliverytemplates = [];
                    cvm.templates.push(obj);
                });
            }
        });
    };

    cvm.duplicateTemplate = function(tpl) {
        var modalOptions = {
            textInputShow:      true,
            textInputLabel:     translateService.instant('delivery.template.name'),
            closeButtonText:    translateService.instant('general.cancel'),
            actionButtonText:   translateService.instant('general.duplicate'),
            actionButtonClass:  'btn-success',
            headerText:         translateService.instant('delivery.template.duplicate_template_heading'),
            bodyText:           '',
            textInputValue:     tpl.templateName
        };

        modalService.showModal({}, modalOptions).then(function (result) {
            if(result && result !== true) {
                cvm.repo.duplicate(tpl.deliveryTemplateID, { templateName: result }).then(function(obj) {
                    cvm.templates.push(obj);
                });
            }
        });
    };

    cvm.deleteItem = function(item) {
        var modalOptions = {
            closeButtonText: translateService.instant('general.cancel'),
            actionButtonText: translateService.instant('general.delete'),
            actionButtonClass: 'btn-danger',
            headerText: translateService.instant('delivery.template.confirm_delete_short'),
            bodyText: translateService.instant('delivery.template.confirm_delete')
        };

        modalService.showModal({}, modalOptions).then(function (result) {
            if(result) {
                item.selected = false;
                var index = cvm.templates.indexOf(item);
                if(index > -1) {
                    DeliveryTemplateRepository.remove(item.deliveryTemplateID);
                    cvm.templates.splice(index, 1);
                }
            }
        });
    };

    cvm.activate();
    return cvm;
}