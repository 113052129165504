import { Component, OnInit, OnDestroy } from '@angular/core';
import { RestaurantService } from "../../http/restaurant/restaurant.service";
import { NotificationStorage, NotificationInstance } from '@ro-ngx/core';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { TestorderService } from '../../http/rocms/testorder/testorder.service';
import { Testorder } from '../../http/rocms/testorder/testorder';
import { TestorderStatus } from '../../http/rocms/testorder/testorder-status';
import { Observable } from 'rxjs';
import { BaseComponent } from '../base.component';

@Component({
    selector: 'testorder',
    template: require('./testorder.component.html')
})
export class TestorderComponent extends BaseComponent implements OnInit, OnDestroy {

    /**
     * @type {Array}
     */
    public testorders: Testorder[] = [];

    /**
     * @type {TestorderStatus}
     */
    public TestorderStatus: typeof TestorderStatus = TestorderStatus;

    /**
     * @type {Array}
     */
    public selections: any[] = [];

    /**
     * @type {setTimeout|number}
     */
    public testorderPolling: any;

    /**
     * @type {string}
     */
    public selectRestaurantPlaceholder: string = '';

    /**
     * @type {{sendTestorders: boolean}}
     */
    public loading: { [key: string]: boolean } = {
        'sendTestorders': false
    };

    public test$: Observable<any>;

    constructor(
        protected restaurantService: RestaurantService,
        protected testorderService: TestorderService,
        protected notifications: NotificationStorage,
        protected translate: TranslateService
    ) {
        super();
    }

    /**
     * NgOnInit
     */
    public ngOnInit(): void {
        this.translate.get('testorder.choose_restaurants')
            .subscribe((value) => this.selectRestaurantPlaceholder = value);

        this.updateTestorders(true);

        this.test$ = this.restaurantService.getRestaurantsSlim();
    }

    /**
     * NgOnDestroy
     */
    public ngOnDestroy(): void {
        clearTimeout(this.testorderPolling);
    }

    /**
     * Updates the testorder list
     */
    public updateTestorders(repeat?: boolean): void {
        const loader = this.testordersLoadingNotification();

        this.testorderService.getTestorders()
            .subscribe(
                (testOrders) => {
                    this.testorders = testOrders;
                    loader.dismiss();
                },
                (error) => console.error(error),
                () => {
                    if (repeat) {
                        const wait = 5000;
                        this.testorderPolling = setTimeout(() => {
                            this.updateTestorders(true);
                        }, wait);
                    }
                }
            );
    }

    /**
     * Human friendly timestamp
     *
     * @param timestamp
     * @returns {string}
     */
    public orderTimestamp(timestamp?: string): string {
        return timestamp ? moment(timestamp).format('HH:mm') : '';
    }

    /**
     * Sending testorders to selected restaurants
     *
     * @param selections
     */
    public sendTestorders(selections: any[]): void {
        this.loading['sendTestorders'] = true;

        const clientKeys = selections.map((s) => s.clientKey);
        this.testorderService.sendTestorders(clientKeys)
            .subscribe(() => {
                this.updateTestorders();

                this.loading['sendTestorders'] = false;
                this.sendTestorderNotification();
            });
    }

    /**
     * Getting value from ng-select
     *
     * @param selections
     */
    public refreshValue(selections: any[]): void {
        this.selections = selections;
    }

    /**
     * Make send Testorder notification
     *
     * @returns {NotificationInstance}
     */
    protected sendTestorderNotification(): NotificationInstance {
        return this.notifications.success(
            this.translate.instant('general.success') + '!',
            this.translate.instant('testorder.testorders_dispatched') + '.'
        );
    }

    /**
     * Make update Testorders notification
     *
     * @returns {NotificationInstance}
     */
    protected testordersLoadingNotification(): NotificationInstance {
        return this.notifications.loading(
            this.translate.instant('general.updating') + '...',
            this.translate.instant('general.updating') + ' ' + this.translate.instant('testorder.the_orderlist') + '...'
        );
    }
}
