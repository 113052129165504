import { NavbarLink } from './navbar-link';
import { NavbarDropdown } from './navbar-dropdown';
import { NavbarMergeWhen } from './navbar-merge-when';
import { NavbarMergeWithPermission } from './navbar-merge-with-permission';
import { NavbarItem } from './navbar-item';
import { PermissionName } from '@ro-ngx/users';
import { NavbarMergeWithPermissionGroup, NavbarPermissionGroup } from './navbar-merge-with-permission-group';


export function link(location: string | string[], translateKey: string, permissions: PermissionName[] = []): NavbarLink | NavbarMergeWithPermission {
    if (permissions.length) {
        return mergeWithPermission(permissions, [
            new NavbarLink(location, translateKey)
        ]);
    }

    return new NavbarLink(location, translateKey);
}

export function dropdown(translateKey: string, children: any[]): NavbarDropdown {
    return new NavbarDropdown(translateKey, children);
}

export function mergeWhen(booleanValue: boolean, items: any[]): NavbarMergeWhen {
    return new NavbarMergeWhen(booleanValue, items);
}

export function mergeWithPermission(permissions: PermissionName[], items: NavbarItem[]): NavbarMergeWithPermission {
    return new NavbarMergeWithPermission(permissions, items);
}

export function mergeWithPermissionGroup(permissionGroups: NavbarPermissionGroup[], items: NavbarItem[]): NavbarMergeWithPermissionGroup {
    return new NavbarMergeWithPermissionGroup(permissionGroups, items);
}
