
import {share} from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Upload } from './upload';
import { ImageType } from '../image-type';
import { UploadService } from './upload.service';
import { CollectionStorage } from '@ro-ngx/core';
import { Injectable } from '@angular/core';

@Injectable()
export class UploadStorage extends CollectionStorage<Upload> {

    public uploads$: Observable<Upload[]>;

    constructor(protected uploadService: UploadService) {
        super();

        this.uploads$ = this.subject.asObservable();
    }

    /**
     * Get uploads and store.
     *
     * @param imageType
     * @param clientKey
     * @returns {Observable<Upload[]>}
     */
    public getUploads(imageType: ImageType, clientKey?: string): Observable<Upload[]> {
        const obs = this.uploadService.getUploads(imageType, clientKey).pipe(share());

        return this.unionObservable(obs, 'imageUploadID');
    }

    /**
     * Delete upload and splice.
     *
     * @param imageUploadID
     * @returns {Observable<number|string>}
     */
    public deleteUpload(imageUploadID: number): Observable<number> {
        const obs = this.uploadService.deleteUpload(imageUploadID).pipe(share());

        return this.spliceObservable(obs, 'imageUploadID') as any;
    }

    /**
     * Add uploacds into store from outside of storage.
     *
     * @param addUploads
     */
    public addUploads(addUploads: Upload[]): void {
        this.unionSubject(addUploads, 'imageUploadID');
    }
}
