angular.module( 'cmsApp.admindenymessage.factory', [
    'ui.router',
    'ui.bootstrap'
])

.factory('AdminDenymessageRepository', function (Restangular, AbstractRepository) {

    function AdminDenymessageRepository() {
        AbstractRepository.call(this, Restangular, 'admindenymessages');
    }

    AbstractRepository.extend(AdminDenymessageRepository);

    return new AdminDenymessageRepository();
})

;
