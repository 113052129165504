angular.module( 'cmsApp.delivery.assign.factory', [
    'ui.router',
    'ui.bootstrap'
])

.factory('DeliveryAssignRepository', function (Restangular, AbstractRepository) {

    function DeliveryAssignRepository() {
        AbstractRepository.call(this, Restangular, 'delivery/companyassign');
    }

    AbstractRepository.extend(DeliveryAssignRepository);

    return new DeliveryAssignRepository();
})

;
