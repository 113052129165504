angular.module( 'cmsApp.alert.group.factory', [
    'ui.router',
    'ui.bootstrap'
])

.factory('AlertGroupRepository', function (Restangular, AbstractRepository) {

    function AlertGroupRepository() {
        AbstractRepository.call(this, Restangular, 'alertgroups');
    }

    AbstractRepository.extend(AlertGroupRepository);

    return new AlertGroupRepository();
})

;
