angular.module( 'cmsApp.delivery.timeout.step.modal.create', [
])
.controller('CreatedeliveryTimeoutStepModalCtrl', CreatedeliveryTimeoutStepModalCtrl);

function CreatedeliveryTimeoutStepModalCtrl( $modalInstance, schedule, deliveryCompanies ) {
    /* jshint validthis: true */
    var cvm = this;

    cvm.deliveryCompanies = deliveryCompanies;
    cvm.schedule          = schedule;

    cvm.required = false;
    cvm.submitted = false;

    cvm.timeoutStep = {
		timeout: null,
		deliveryTimeoutScheduleID: schedule.deliveryTimeoutScheduleID,
		timeoutCompanyID: null,
		step: schedule.timeoutsteps.length
    };

    activate();

    ////////////////////
    
    function activate() {
    	
    }

    cvm.ok = function() {
		$modalInstance.close(cvm.timeoutStep);
    };

    cvm.cancel = function() {
        $modalInstance.dismiss('cancel');
    };

    return cvm;
}