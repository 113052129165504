angular.module( 'cmsApp.delivery.zipspan.factory', [
    'ui.router',
    'ui.bootstrap'
])

.factory('DeliveryZipspanRepository', function (Restangular, AbstractRepository) {

    function DeliveryZipspanRepository() {
        AbstractRepository.call(this, Restangular, 'delivery/zipspans');
    }

    AbstractRepository.extend(DeliveryZipspanRepository);

    return new DeliveryZipspanRepository();
})

;
