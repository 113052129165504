import * as angular from 'angular';

import 'app/alertviewer/alertview/types/alertview-default.component';
import 'app/alertviewer/alertview/types/delivery/alertview-delivery.component';
import 'app/alertviewer/alertview/alertview-complete.modal.controller';
import 'app/alertviewer/alertviewer.service';

var template = require('./alertview.component.tpl.html');
var modalTemplate = require('./alertview-complete.modal.tpl.html');

angular.module( 'cmsApp.alertview.component', [
    'cmsApp.alertview.types.alertviewdefault.component',
    'cmsApp.alertview.types.alertviewdelivery.component',
    'cmsApp.alertviewer.alertview.modal.complete',
    'cmsApp.alertviewer.service',
    'angularMoment'
])
.component('roAlertview', {
    bindings: {
        'alertviewlog': '=',
        'expanded': '<'
    },
    controller: roAlertviewController,
    controllerAs: 'vm',
    template: template
});

function roAlertviewController($timeout, $interval, $modal, $scope, roAlertviewService, moment) {  
    var vm = this;

    vm.data = {};

    vm.alerts = roAlertviewService;

    vm.expanded = vm.expanded ? true : false;

    vm.thirtyMinutesAgo = moment().subtract(30, 'minutes').unix();

    vm.todaysTimesettings = {};

    vm.loading = false;

    vm.forceDanger = false;

    vm.forcePriorityTimer = 0;

    //////////////

    function activate() {
        vm.data = vm.alertviewlog;

        vm.evaluatePriority();
        vm.evaluateAlertSound();
        vm.evaluateForceDanger();
        vm.setTodaysTimesettings();
    }

    vm.evaluatePriority = function() {
        vm.originalPriority = vm.data.priority;

        if (!vm.data.hasOwnProperty('createdByRefresh')) {
            return null;
        }

        vm.data.priority = 1000;
        vm.forcePriorityTimer = 10;
        var interval = $interval(function() {
            vm.forcePriorityTimer -= 1;

            if (vm.forcePriorityTimer === 0) {
                vm.data.priority = vm.originalPriority;
                $interval.cancel(interval);
            } 
        }, 1000);
    };

    vm.evaluateAlertSound = function() {
        if (!vm.data.hasOwnProperty('createdByRefresh')) {
            return null;
        }

        if (vm.data.hasOwnProperty('madeEvaluateSound')) {
            return null;
        }

        vm.data.madeEvaluateSound = true;
        return vm.playAlertSound();
    };

    vm.playAlertSound = function() {
        var sound = new Audio('/assets/sounds/warning-sound.wav');        
        sound.play();
    };

    vm.playDangerSound = function() {
        var sound = new Audio('/assets/sounds/danger-sound.wav');
        sound.play();
    };

    vm.setTodaysTimesettings = function() {
        angular.forEach(vm.data.timesettings, function(time) {
            if (time.date === vm.data.createLineMoment.format('YYYY-MM-DD')) {
                vm.todaysTimesettings = time;
            }

            if (vm.todaysTimesettings.dayNo !== 0 && time.dayNo === (vm.data.createLineMoment.format('d') * 1) + 1) {
                vm.todaysTimesettings = time;
            }
        });

        vm.todaysTimesettings.openingTimeMoment = moment(vm.todaysTimesettings.openingTime, 'HH:mm:ss').add(vm.todaysTimesettings.orderBuffer, 'minutes');
        vm.todaysTimesettings.closingTimeMoment = moment(vm.todaysTimesettings.closingTime, 'HH:mm:ss');
    };

    vm.completeAlert = function(alert) {
        vm.loading = true;

        roAlertviewService.completeAlert(alert).then(function() {
            vm.loading = false;
        });
    };

    vm.completeModal = function() {
        var modalInstance = $modal.open({
            template: modalTemplate,
            controller: 'CompleteAlertviewModalController as vm',
            windowClass: 'ro-modal complete-alert-modal modalService',
            resolve: {
                alert: function() {
                    return vm.alert;
                }
            }
        });

        modalInstance.result.then(function(data) {
            vm.loading = true;
            data.status = 1;

            var updatedAlert = angular.extend({}, vm.data, data);
            roAlertviewService.updateAlert(updatedAlert).then(function() {
                vm.loading = false;
            });
        });
    };

    vm.togglePin = function(alert) {
        vm.loading = true;

        var updatedAlert = angular.extend({}, alert, {pin: !alert.pin});
        roAlertviewService.updateAlert(updatedAlert).then(function() {
            vm.loading = false;
        });
    };

    vm.evaluateForceDanger = function() {
        if (vm.data.createLineMoment.unix() > vm.thirtyMinutesAgo && vm.data.status === 0) {
            var millisecondsDifference = vm.data.createLineMoment.diff(moment().subtract(30, 'minutes'));

            $timeout(function() {
                if (vm.data.status === 0) {
                    vm.forceDanger = true;
                    vm.playDangerSound();
                }
            }, millisecondsDifference);
        } 
        
        if (vm.data.createLineMoment.unix() < vm.thirtyMinutesAgo) {
            vm.forceDanger = true;
        }
    };

    activate();

    return vm;
}