angular.module( 'cmsApp.alert.deliveryalerts', [
])
.config(DeliveryAlertsCtrlConfig)
.controller('DeliveryAlertsCtrl', DeliveryAlertsCtrl);

function DeliveryAlertsCtrlConfig( $stateProvider ) {

}

function DeliveryAlertsCtrl( $scope, $state ) {
    /* jshint validthis: true */
    var vm = this;

    ////////////////////

    vm.activate = function() {
        $scope.$emit('selectItem', { groupID: -2, profileID: null } );
        $scope.$emit('setLoading', false);
    };
    
    vm.activate();
    return vm;
}