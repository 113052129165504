import { Component, OnInit, OnDestroy, AfterViewInit,
    ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { ImageLibraryStateStorage } from '../image-library-state.storage';
import { Subscription } from 'rxjs';
import { ImageLibraryState } from '../image-library-state';
import { ImageLibraryStateMode } from '../image-library-state-mode';
import Cropper from 'cropperjs';
import { Upload } from '../http/upload/upload';
import { Image } from '../http/image';
import { ImageStorage } from '../http/image.storage';
import { ImageTypeDimension } from '../http/image-type-dimension';
import { ImageTypeDimensions } from '../http/image-type-dimensions';
import { SubscriptionCollection } from '@ro-ngx/core';

@Component({
    selector: 'image-crop',
    template: `
        <div class="row">
            <div class="col-xs-12">
                <div class="form-group">
                    <img #cropper
                         style="max-width: 100%;"
                         [src]="upload.publicPath"
                         src=""
                         alt="Image cropping">
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-xs-12">
                <button-loadable
                    [btnClass]="'btn-block btn-success'"
                    [loading]="loading['create']"
                    (tryClick)="saveCroppedImage()">{{ 'image_library.crop_image' | translate }}</button-loadable>
            </div>
        </div>
    `
})
export class ImageCropComponent implements OnInit, OnDestroy, AfterViewInit {

    public loading: { [key: string]: boolean } = {
        'create': false
    };

    /**
     * @type {Upload}
     */
    public upload: Upload;

    /**
     * @type {Cropper}
     */
    public cropper: Cropper;

    /**
     * @type {ImageLibraryState}
     */
    public state: ImageLibraryState;

    @ViewChild('cropper')
    public cropperElement: ElementRef;

    @Output()
    public onCrop: EventEmitter<Image>;

    /**
     * @type {SubscriptionCollection}
     */
    protected subscribers: SubscriptionCollection;

    constructor(
        protected imageLibraryStateStorage: ImageLibraryStateStorage,
        protected imageStorage: ImageStorage) {
        this.subscribers = new SubscriptionCollection();
        this.onCrop = new EventEmitter();
    }

    /**
     * NgOnInit
     */
    public ngOnInit(): void {
        this.subscribers.set('libraryStateHandle', this.libraryStateHandle());
    }

    /**
     * NgOnDestory
     */
    public ngOnDestroy(): void {
        this.subscribers.unsubscribeAll();
        this.cropper.destroy();
    }

    /**
     * NgAfterViewInit
     */
    public ngAfterViewInit(): void {
        this.cropper = this.createCropper(this.cropperElement.nativeElement);
    }

    /**
     * Get current dimensions from current state.
     *
     * @returns {ImageTypeDimension}
     */
    public getCurrentDimension(): ImageTypeDimension {
        return ImageTypeDimensions.get(this.state.imageType);
    }

    /**
     * Save Cropped image.
     */
    public saveCroppedImage(): void {
        const cropData = this.cropper.getData(true);

        this.loading['create'] = true;
        this.imageStorage.createImage(this.upload, cropData)
            .subscribe((image) => this.onCrop.emit(image));
    }

    /**
     * Create the cropperjs instance.
     *
     * @param cropperElement
     * @returns {cropperjs}
     */
    protected createCropper(cropperElement: HTMLImageElement): Cropper {
        const dimensions = this.getCurrentDimension();
        console.log(dimensions)

        return new Cropper(cropperElement, {
            aspectRatio: dimensions.width / dimensions.height,
            checkCrossOrigin: false,
            minContainerHeight: 300,
            ready: () => {
                const imageData = this.cropper.getImageData();

                this.cropper.setCropBoxData({
                    height: imageData.height,
                    width: imageData.width
                } as Cropper.CropBoxData);
            },
            scalable: false,
            zoomable: false,
            viewMode: 1
        });
    }

    /**
     * Handle libraryState changes.
     *
     * @returns {Subscription}
     */
    protected libraryStateHandle(): Subscription {
        return this.imageLibraryStateStorage.state$
            .subscribe((state) => {
                if (state.mode === ImageLibraryStateMode.Crop) {
                    if ( ! state.modeOptions.hasOwnProperty('upload')) {
                        throw new Error('Cropper must have Upload');
                    }

                    this.state = state;
                    this.upload = state.modeOptions['upload'];
                }
            });
    }
}
