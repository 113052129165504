angular.module( 'cmsApp.appthemes.apptheme.color.factory', [])

.factory('AppthemeColorFactory', function () {

    function AppthemeColorFactory() {
        //Function to convert ordinary RGB to HEX
        this.rgb2hex = function(rgb){
            rgb = rgb.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
            return (rgb && rgb.length === 4) ? "#" +
                ("0" + parseInt(rgb[1],10).toString(16)).slice(-2) +
                ("0" + parseInt(rgb[2],10).toString(16)).slice(-2) +
                ("0" + parseInt(rgb[3],10).toString(16)).slice(-2) : '';
        };
        
        //Function to convert RGB to AHEX 
        this.rgb2ahex = function(rgb){
            rgb = rgb.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,?[\s+]?(\d+\.*\d*)?/i);
            var tsFix: any = parseFloat(rgb[4])*255;

            return (rgb && rgb.length === 5) ? ("#" +
                ("0" + parseInt(tsFix, 10).toString(16)).slice(-2) +
                ("0" + parseInt(rgb[1],10).toString(16)).slice(-2) +
                ("0" + parseInt(rgb[2],10).toString(16)).slice(-2) +
                ("0" + parseInt(rgb[3],10).toString(16)).slice(-2)).toUpperCase() : '';
        };
        
        // Ordinary HEX to RGB
        this.hexToRgb = function(hex) {
            var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
            return result ? {
                r: parseInt(result[1], 16),
                g: parseInt(result[2], 16),
                b: parseInt(result[3], 16)
            } : null;
        };
        
        // AHEX to RGBA
        this.ahexToRgba = function(hex) {
            var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
            return result ? {
                r: parseInt(result[2], 16),
                g: parseInt(result[3], 16),
                b: parseInt(result[4], 16),
                a: (parseInt(result[1], 16)/255)
            } : null;
        };
        
        // RGB/A object to string
        this.rgbaToStr = function(rgba): any {
            if(!rgba) {
                return;
            }
            var output = false;
            if(rgba.r !== undefined && rgba.g !== undefined && rgba.b !== undefined) {
                var type = 'rgb';
                var values = [rgba.r, rgba.g, rgba.b];
                if(rgba.a !== undefined) {
                    type = 'rgba';
                    values.push(rgba.a);
                }
                return type + '(' + values.join(',') + ')';
            }
            return output;
        };
        
        // convert object colors
        this.convertObjectColorsToRGBA = function(obj) {
            var ColorFactory = this;
            var output = angular.copy(obj);
            angular.forEach(output, function(value, key) {
                if(typeof value == 'string' && value.substring(0,1) == '#' && value.length == '#AARRGGBB'.length) {
                    output[key] = ColorFactory.rgbaToStr(ColorFactory.ahexToRgba(value));
                }
            });
            return output;
        };
        
        // test
        this.test = function(obj) {
            console.log(obj);
        };
    }

    return new AppthemeColorFactory();
})

;
