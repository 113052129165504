import { ModuleWithProviders, NgModule } from '@angular/core';
import { Json2typescriptModule } from "@ro-ngx/json2typescript";
import { ArticleBodyService, ArticleService } from "./services";
import { ArticleStorage } from "./storages/article.storage";
import { ArticleBodyStorage } from "./storages/article-body.storage";

@NgModule({
    imports: [
        Json2typescriptModule
    ],
    exports: [],
    declarations: [],
    providers: [],
})
export class ArticlesModule {
    public static forRoot(): ModuleWithProviders {
        return {
            ngModule: ArticlesModule,
            providers: [
                ArticleService,
                ArticleStorage,
                ArticleBodyService,
                ArticleBodyStorage
            ]
        };
    }
}
