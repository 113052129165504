import { Component, Input } from '@angular/core';
import { Summary } from './summary';

@Component({
    selector: 'delivery-driver-finance-summary-table',
    template: require('./summary-table.component.html')
})
export class SummaryTableComponent {
    @Input()
    public summary: Summary;
}
