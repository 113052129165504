export class OrderRowHeaderColumnCarrierComponent {
    public static readonly controller = OrderRowHeaderColumnCarrierComponent;
    public static readonly controllerAs = 'vm';
    public static readonly template = require('./order-row-header-column-carrier.component.html');
    public static readonly bindings = {
        order: '<'
    };

    public order: any;
}
