angular.module( 'cmsApp.delivery.priceplan.span.factory', [
    'ui.router',
    'ui.bootstrap'
])

.factory('DeliveryPriceplanSpanRepository', function (Restangular, AbstractRepository) {

    function DeliveryPriceplanSpanRepository() {

        AbstractRepository.prototype.savePriceplanSpan = function(data) {
            data = angular.copy(data);

            data.mon = (data.selectedWeekDays.indexOf('mon') != -1);
            data.tue = (data.selectedWeekDays.indexOf('tue') != -1);
            data.wed = (data.selectedWeekDays.indexOf('wed') != -1);
            data.thu = (data.selectedWeekDays.indexOf('thu') != -1);
            data.fri = (data.selectedWeekDays.indexOf('fri') != -1);
            data.sat = (data.selectedWeekDays.indexOf('sat') != -1);
            data.sun = (data.selectedWeekDays.indexOf('sun') != -1);

            if(data.minTime)
            {
                data.minTime = data.minTime.toTimeString().substr(0,8);
            }
            else
            {
                data.minTime = '00:00:00';
            }

            if(data.maxTime)
            {
                data.maxTime = data.maxTime.toTimeString().substr(0,8);
            }
            else
            {
                data.minTime = '00:00:00';
            }

            return this.restangular.one('delivery/priceplanspans').customPUT(data, data.deliverySpanID);
        };

        AbstractRepository.call(this, Restangular, 'delivery/priceplanspans');
    }

    AbstractRepository.extend(DeliveryPriceplanSpanRepository);

    return new DeliveryPriceplanSpanRepository();
})

;
