import { isNumber } from 'util';
import * as angular from 'angular';

angular.module( 'common.ZipcodeService', [
    'ui.bootstrap',
    'common.zipcode.factory'
])
.service('ZipcodeService', ZipcodeService);

function ZipcodeService( $modal, ZipcodeRepository, ENV ) {
    var service = this;

    service.prettifyZipCode = function (zipcode) {
        return zipcode;
        /*if(isNaN(zipcode) === false) {
            zipcode = zipcode.toString();
        }else{
            zipcode = zipcode.replace(/[^0-9]+/g,'');
        }
        return zipcode.substr(0,3)+' '+zipcode.toString().substr(3,2);*/
    };

    service.uglifyZipCode = function (zipcode) {
        return zipcode;
        /*if(isNaN(zipcode) === false) {
            return zipcode;
        }
        return parseInt(zipcode.replace(/[^0-9]+/g,''), 10);*/
    };

    service.prettifyZipSpan = function (zipcode1, zipcode2) {
        if(service.uglifyZipCode(zipcode1) > service.uglifyZipCode(zipcode2))
        {
            return service.prettifyZipCode(zipcode2)+' - '+service.prettifyZipCode(zipcode1);
        }
        else
        {
            return service.prettifyZipCode(zipcode1)+' - '+service.prettifyZipCode(zipcode2);
        }
    };

    service.uglifyZipSpan = function(zipspan) {
        var zipobjs = zipspan.split('-');
        return [service.uglifyZipCode(zipobjs[0]), service.uglifyZipCode(zipobjs[1])];
    };

    service.getZipCodes = function (search) {
        search = service.uglifyZipCode(search);
        return ZipcodeRepository.getList('/search/'+search).then(function(data) {
            var ZipCodes = [];
            angular.forEach(data, function (obj) {
                var zipcode = service.prettifyZipCode(obj.zipcode);
                if(ZipCodes.indexOf(zipcode) == -1)
                {
                   ZipCodes.push(zipcode);
                }
            });

            return ZipCodes;
        });
    };
    
    service.getZipCodesRaw = function (search) {
        search = service.uglifyZipCode(search);
        return ZipcodeRepository.getList('/search/'+search).then(function(data) {
            angular.forEach(data, function (obj) {
                obj.niceZip = service.prettifyZipCode(obj.zipcode);
            });
            return data;
        });
    };
    
    service.groupZipsToObj = function (data, deliveryAreaID) {
        const output = [];

        angular.forEach(data, (postalCode: number | string) => {
            const nobj: any = {};

            if (typeof postalCode === 'number') {
                postalCode = postalCode.toString();
            }

            if (postalCode.indexOf('-') >= 0) {
                const parts = postalCode.split('-');
                nobj.minZip = service.formatPostalCode(parts[0]);
                nobj.maxZip = service.formatPostalCode(parts[1]);
            } else {
                nobj.minZip = service.formatPostalCode(postalCode);
                nobj.maxZip = service.formatPostalCode(postalCode);
            }

            nobj.deliveryAreaID = deliveryAreaID;

            output.push(nobj);
        });
        
        return output;
    };

    service.formatPostalCode = (postalCode: string|number): string => {
        if (typeof postalCode === 'number') {
            postalCode = postalCode.toString();
        }

        switch (ENV.APP_COUNTRY) {
            case 'PL':
                return postalCode.substr(0, 2) + '-' + postalCode.substr(2, 3);
            case 'SE':
            default:
                return postalCode;
        }
    };
    
    service.getByRadius = function (deliveryAreaID, centerZip, minRadius, maxRadius) {
        centerZip = service.uglifyZipCode(centerZip);
        
        return ZipcodeRepository.getList('/getbyradius/'+centerZip+'/'+minRadius+'/'+maxRadius).then(function(data) {
            const grouped = service.groupNums(data);
            return service.groupZipsToObj(grouped, deliveryAreaID);
        });
    };
    
    service.getByRadiusRaw = function (centerZip, minRadius, maxRadius) {
        centerZip = service.uglifyZipCode(centerZip);
        
        return ZipcodeRepository.getList('/getbyradiusraw/'+centerZip+'/'+minRadius+'/'+maxRadius).then(function(data) {
            return data;
        });
    };
    
    service.getByPolygon = function (data, centerZip, minRadius, maxRadius) {
        centerZip = service.uglifyZipCode(centerZip);
        
        return ZipcodeRepository.customUpdate(data, '/delivery/zipcodes/getbypolygon/'+centerZip+'/'+minRadius+'/'+maxRadius).then(function(data) {
            return data;
        });
    };
    
    service.groupNums = function(input) {
        // filter out duplicates
        input = input.filter(function(item, pos) {
            return input.indexOf(item) == pos;
        });

        // remove spaces and parse to ints
        for (var i = 0; i < input.length; i++) {
            input[i] = parseInt(input[i].replace(/[\- ]/g, ''), 10);
        }
        
        //sort
        input.sort();
        
        // groupNums func ported from php
        var ret = [];
        var temp = [];
        var val, next;
        
        for (i = 0; i < input.length; i++) {
            val     = input[i];
            next    = input[i + 1];
            
            if(next == (val + 1)) {
                temp.push(val);
            } else {
                if(temp.length > 0) {
                    temp.push(val);
                    ret.push(temp[0] + '-' + temp[temp.length - 1]);
                    temp = [];
                } else {
                    ret.push(val);
                }
            }
        }
        
        return ret;
    };
}