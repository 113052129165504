import * as angular from 'angular';

import 'common/modalService/modalService';

import 'app/delivery/timeout/schedule/timeoutschedule.factory';

var template = require('./deliveryinterceptorspan.tpl.html');

angular.module( 'cmsApp.delivery.interceptor.span', [
	'cmsApp.delivery.timeout.schedule.factory',
    'common.modalService',
    'ui.select',
    'ngSanitize'
])
.directive('roDeliveryInterceptorSpan', roDeliveryInterceptorSpan);

function roDeliveryInterceptorSpan() {
    return {
        restrict: 'EA',
        scope: {
            repo: '=',
            roDeliveryInterceptorSpan: '=',
            interceptorEdit: '='
        },
        controller: function( $scope, $filter, modalService, DeliveryCompanyRepository, DeliveryTimeoutSchedule, translateService ) {
            /* jshint validthis: true */
            var vm = this;

            vm.templates = [];

            vm.roDeliveryTimeoutSchedules = [];
            vm.roDeliveryTimeoutSchedule  = {};

            vm.interceptPriceOptions = {
                0: translateService.instant('delivery.interceptor_span.standard_area_template'),
                1: translateService.instant('delivery.interceptor_span.target_area_template'),
                2: translateService.instant('delivery.interceptor_span.target_pricetype')
            };

            $scope.$watch('vm.roDeliveryInterceptorSpan.selected', function(newValue, oldValue) {
                if(newValue) {
                    vm.refreshTemplates();
                }

                if (newValue === true && oldValue === false) {
	                DeliveryTimeoutSchedule.getList().then(function(response) {
	            		vm.roDeliveryTimeoutSchedules = response;

	            		vm.deliveryTimeoutScheduleChange();
	            	});
                }
            });

            activate();

            //////////////////////////////

            function activate() {

            }

            vm.refresh = function() {
                // id is for legacy reasons, don't know what
                // it would break if it were to be changed.
                vm.weekDays = [
                    { id: 'Mån', name: translateService.instant('date.days.monday') },
                    { id: 'Tis', name: translateService.instant('date.days.tuesday') },
                    { id: 'Ons', name: translateService.instant('date.days.wednesday') },
                    { id: 'Tors', name: translateService.instant('date.days.thursday') },
                    { id: 'Fre', name: translateService.instant('date.days.friday') },
                    { id: 'Lör', name: translateService.instant('date.days.saturday') },
                    { id: 'Sön', name: translateService.instant('date.days.sunday') }
                ];

                vm.roDeliveryInterceptorSpan.selected = false;

                vm.roDeliveryInterceptorSpan.interceptPrice = vm.roDeliveryInterceptorSpan.interceptPrice.toString();

                vm.roDeliveryInterceptorSpan.selectedWeekDays = [];

                if(vm.roDeliveryInterceptorSpan.mon) {
                    vm.roDeliveryInterceptorSpan.selectedWeekDays.push('Mån');
                }
                if(vm.roDeliveryInterceptorSpan.tue) {
                    vm.roDeliveryInterceptorSpan.selectedWeekDays.push('Tis');
                }
                if(vm.roDeliveryInterceptorSpan.wed) {
                    vm.roDeliveryInterceptorSpan.selectedWeekDays.push('Ons');
                }
                if(vm.roDeliveryInterceptorSpan.thu) {
                    vm.roDeliveryInterceptorSpan.selectedWeekDays.push('Tors');
                }
                if(vm.roDeliveryInterceptorSpan.fri) {
                    vm.roDeliveryInterceptorSpan.selectedWeekDays.push('Fre');
                }
                if(vm.roDeliveryInterceptorSpan.sat) {
                    vm.roDeliveryInterceptorSpan.selectedWeekDays.push('Lör');
                }
                if(vm.roDeliveryInterceptorSpan.sun) {
                    vm.roDeliveryInterceptorSpan.selectedWeekDays.push('Sön');
                }

                if(vm.roDeliveryInterceptorSpan.minTime && vm.roDeliveryInterceptorSpan.minTime != '00:00:00')
                {
                    vm.roDeliveryInterceptorSpan.minTime = new Date(1970, 0, 1, vm.roDeliveryInterceptorSpan.minTime.substr(0,2), vm.roDeliveryInterceptorSpan.minTime.substr(3,2));
                }
                else
                {
                    vm.roDeliveryInterceptorSpan.minTime = null;
                }

                if(vm.roDeliveryInterceptorSpan.maxTime && vm.roDeliveryInterceptorSpan.maxTime != '00:00:00')
                {
                    vm.roDeliveryInterceptorSpan.maxTime = new Date(1970, 0, 1, vm.roDeliveryInterceptorSpan.maxTime.substr(0,2), vm.roDeliveryInterceptorSpan.maxTime.substr(3,2));
                }
                else
                {
                    vm.roDeliveryInterceptorSpan.maxTime = null;
                }
            };

            vm.deleteItem = function() {
                var modalOptions = {
                    closeButtonText: translateService.instant('general.cancel'),
                    actionButtonText: translateService.instant('general.delete'),
                    actionButtonClass: 'btn-danger',
                    headerText: translateService.instant('delivery.interceptor_span.confirm_delete_short'),
                    bodyText: translateService.instant('delivery.interceptor_span.confirm_delete')
                };

                modalService.showModal({}, modalOptions).then(function (result) {
                    if(result) {
                        vm.roDeliveryInterceptorSpan.selected = false;
                        $scope.$emit('deleteInterceptorSpan', vm.roDeliveryInterceptorSpan);
                    }
                });
            };

            vm.selectItem = function() {
                vm.roDeliveryInterceptorSpan.selected = !vm.roDeliveryInterceptorSpan.selected;

                var anySelected = false;

                vm.interceptorEdit.deliveryinterceptorspans.forEach(function (element) {
                    if(element.selected) {
                        anySelected = true;
                    }
                });

                vm.interceptorEdit.hideSaveButtons = anySelected;
            };

            vm.getObjByID = function(objects, idKey, id) {
                var item = {};

                angular.forEach(objects, function(obj, key) {
                    if(obj[idKey] == id) {
                        item = obj;
                    }
                });

                return item;
            };

            vm.refreshTemplates = function() {
                $scope.$emit('showNotification', {
                    id: 'dITPLLoad',
                    loading: true,
                    alertType: 'alert-info',
                    title: translateService.instant('general.loading')
                });


                const companyName = (
                    vm.getObjByID(vm.interceptorEdit.companies, 'deliveryCompanyID', vm.roDeliveryInterceptorSpan.deliveryCompanyID).companyName || translateService.instant('delivery.interceptor_span.no_one')
                );

                vm.interceptPriceOptions = {
                    0: translateService.instant('delivery.interceptor_span.standard_area_template'),
                    1: translateService.instant('delivery.interceptor_span.company_area_template', { companyName }),
                    2: translateService.instant('delivery.interceptor_span.company_pricetype', { companyName })
                };

                DeliveryCompanyRepository.getList('/' + vm.roDeliveryInterceptorSpan.deliveryCompanyID + '/templates?with=deliverytemplates').then(function(data) {
                    vm.templates = data;
                    $scope.$emit('showNotification', {
                        id: 'dITPLLoad',
                        loading: false,
                        alertType: 'alert-info',
                        title: translateService.instant('general.success'),
                        timeout: 100
                    });
                });
            };

            vm.deliveryTimeoutScheduleChange = function() {
            	vm.roDeliveryTimeoutSchedule = $filter('filter')(vm.roDeliveryTimeoutSchedules, {deliveryTimeoutScheduleID: vm.roDeliveryInterceptorSpan.deliveryTimeoutScheduleID}, true)[0];
            };

            vm.refresh();
        },
        controllerAs: 'vm',
        bindToController: true,
        template: template
    };
}
