declare var $window: any;

angular.module( 'common.roResizable', [
    'ui.bootstrap'
])
.directive('roResizable', roResizable);

function roResizable() {
    return function($scope) {
        $scope.initializeWindowSize = function() {
            $scope.windowHeight = $window.innerHeight;
            return $scope.windowWidth = $window.innerWidth;
        };
        $scope.initializeWindowSize();
        return angular.element($window).bind('resize', function() {
            $scope.initializeWindowSize();
            return $scope.$apply();
        });
    };

}