angular.module('common.errorcatcher', [])
    /*.factory('$exceptionHandler', function () {
        return function errorCatcherHandler(exception, cause) {
            console.log('common.errorcatcher');
            console.error(exception.stack);
            //Raven.captureException(exception);
        };
    });*/

    .config(function ($provide) {
        $provide.decorator("$exceptionHandler", function ($delegate, $window) {
            return function (exception, cause) {
                var formatted = '';
                var properties = '';
                formatted += 'Exception: "' + exception.toString() + '"\n';
                formatted += 'Caused by: ' + cause + '\n';

                properties += (exception.message) ? 'Message: ' + exception.message + '\n' : '';
                properties += (exception.fileName) ? 'File Name: ' + exception.fileName + '\n' : '';
                properties += (exception.lineNumber) ? 'Line Number: ' + exception.lineNumber + '\n' : '';
                properties += (exception.stack) ? 'Stack Trace: ' + exception.stack + '\n' : '';

                if (properties) {
                  formatted += properties;
                }

                //console.log(formatted);


                $delegate(exception, cause);
                /*$window.ga(
                    'send',
                    'event',
                    'AngularJS error',
                    exception.message,
                    exception.stack,
                    0,
                    true
                );*/
            };
        });
    });