import * as angular from 'angular';

import 'app/alert/alerter-type/alerter-type.service';

var template = require('./alerts.component.tpl.html');

angular.module( 'cmsApp.alert.alerts.component', [
    'cmsApp.alert.alerter-type.service'
])
.component('roAlertAlertsComponent', {
    bindings: {

    },
    controller: AlertAlertsComponent,
    controllerAs: 'vm',
    template: template
});

function AlertAlertsComponent($state, $filter, AlerterTypeService) {
    var vm = this;

    vm.alerterTypes = [];

    vm.$onInit = function() {
        AlerterTypeService.getList('', {with: 'alerts.alertprofiles'}).then(function(response) {
            vm.alerterTypes = vm.filterInvalidAlerterTypes(response.plain());
        });
    };

    vm.goToProfile = function(profile) {
        $state.go('alert.profile', {
            alertGroupID: profile.alertGroupID, 
            alertProfileID: profile.alertProfileID
        });
    };

    vm.filterInvalidAlerterTypes = function(objects) {
        var invalidAlerterTypes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];

        return $filter('filter')(objects, function(value, index, array) {
            if (invalidAlerterTypes.indexOf(value.alerterTypeID) === -1) {
                return true;
            }

            return false;
        });
    };
}