import { Component, OnInit } from '@angular/core';
import { ENV } from '../../enviroment';
import { AuthorizedService } from '@ro-ngx/authentication';
import { PermissionName, User } from '@ro-ngx/users';
import { NavbarSerializer } from './navbar-serializer';
import { dropdown, link, mergeWhen, mergeWithPermissionGroup } from './navbar-builder';
import { NavbarItem } from './navbar-item';
import { TranslateService } from '@ngx-translate/core';
import { CompensationsConfig } from '@ro-ngx/compensations';
import { UserConnectionType } from '@ro-ngx/users';

@Component({
    selector: 'navbar',
    template: require('./navbar.component.html'),
    styles: [
        '.logout-button { position: relative; }',
        '.logout-button span { position: absolute; top: 5px; right: 10px; }',
        '.language-button {background-color: #D70F64; border: none; color: white; padding-top: 20px; text-align:center; text-decoration: none; display: inline-block; font-size: 16px; overflow: hidden; outline: none; padding-bottom: 18px;}',
        '.language-button:focus { text-decoration: none;}',
        '.language-divider { color: white; font-family: inherit; font-size: 16px; }',
        '.Clicked {background-color: #b50952;}',
    ],
})
export class NavbarComponent implements OnInit {
    public brand: string = ENV.APP_BRAND;
    public collapse: boolean = true;
    public displayNavbar: boolean = false;
    public links: NavbarItem[];
    public user: User;
    public enClicked: boolean = false;
    public seClicked: boolean = false;

    constructor(
        protected compensationsConfig: CompensationsConfig,
        protected authorizedService: AuthorizedService,
        protected translateService: TranslateService,
        protected navbarSerializer: NavbarSerializer,
    ) {

    }


    public ngOnInit(): void {
        if(localStorage.getItem('language') == 'sv') {
            this.seClicked = true;
        }else if(localStorage.getItem('language') == 'en') {
            this.enClicked = true;
        }
        this.links = this.getNavbar();

        this.authorizedService.user$
            .subscribe((user) => {
                this.user = user;
                this.displayNavbar = user ? user.hasUserConnection(UserConnectionType.ADMIN, '*') : false;
                this.links = this.getNavbar();
            });
    }

    public logout(event: Event): void {
        event.preventDefault();

        localStorage.clear();
        sessionStorage.clear();
        window.location.reload(true);
    }

    public getNavbar(): NavbarItem[] {
        return this.navbarSerializer.serialize([
            link('/restaurants', 'navbar.restaurants_nav', [PermissionName.RESTAURANTS_READ_ALL]),

            dropdown('navbar.orders_nav', [
                link('/order-history', 'navbar.transaction_history_nav', [PermissionName.ORDERS_READ_ALL]),

                // Hide ManageDrviersOrder link until test-approved
                // mergeWithPermissionGroup([
                //     [
                //         PermissionName.DELIVERY_COMPANIES_READ,
                //         PermissionName.DELIVERY_DRIVERS_READ,
                //         PermissionName.DELIVERY_ORDERS_READ,
                //         PermissionName.DELIVERY_ORDERS_UPDATE,
                //     ],
                //     [
                //         PermissionName.DELIVERY_DRIVERS_READ_ALL,
                //         PermissionName.DELIVERY_ORDERS_READ_ALL,
                //     ],
                // ], [
                //     link('/_mdo', 'Manage'),
                // ]),

                link('/delivery-times', 'navbar.delivery_times_nav', [PermissionName.ORDERS_READ_ALL]),

                mergeWithPermissionGroup([
                    [PermissionName.DELIVERY_ORDERS_READ_ALL],
                    [PermissionName.DELIVERY_ORDERS_READ],
                ], [
                    link('/deliveryfinance', 'navbar.delivery_finance_nav'),
                ]),

                mergeWhen(this.compensationsConfig.enabled, [
                    link('/compensation-history', 'navbar.compensation_history_nav', [PermissionName.COMPENSATIONS_READ_ALL]),
                ]),

                link('/testorder', 'navbar.testorder_nav', [PermissionName.ORDER_TESTS_READ_ALL]),
            ]),

            /*dropdown('navbar.display_not_used', [
                link('/article-list', 'navbar.article_list', [PermissionName.ARTICLES_READ_ALL]),
                link('/announcements', 'navbar.announcements_nav', [PermissionName.ANNOUNCEMENTS_EDIT]),
                link('/custom-places', 'navbar.custom_places_nav', [PermissionName.CUSTOM_PLACES_EDIT]),
                link('/page-settings', 'navbar.page_settings_nav', [PermissionName.PAGE_SETTINGS]),
                link('/offices', 'navbar.offices_nav'),
            ]),*/

            dropdown('navbar.customer_admin_nav', [
                link('/customer-admin', 'navbar.customer_admin_nav', [PermissionName.ORDER_CUSTOMERS_READ_ALL]),
                link('/customer-occurrences', 'navbar.customer_occurrences_nav', [PermissionName.ORDER_CUSTOMERS_READ_ALL]),
            ]),

            dropdown('navbar.economy_nav', [

                link('/restaurant-summaries', 'navbar.summaries_nav', [PermissionName.RESTAURANT_SUMMARIES_READ_ALL]),
                link('/financeanalyzer', 'navbar.finance_analyzer_nav', [PermissionName.RESTAURANT_FINANCES_READ_ALL]),
                //link('/delivery/company/summary', 'navbar.delivery_company_summary_nav', [PermissionName.DELIVERY_COMPANY_FINANCES_READ_ALL]),
               // link('/delivery/driver/finance', 'navbar.delivery_driver_summary_nav', [PermissionName.DELIVERY_DRIVER_FINANCES_READ_ALL]),
                link('/finance-report', 'navbar.report_finance_nav', [PermissionName.DELIVERY_DRIVER_FINANCES_READ_ALL]),
            ]),

            dropdown('navbar.marketing_nav', [
                link('/customers/export/admin', 'navbar.customer_export_nav', [PermissionName.ORDER_CUSTOMER_EXPORTS_ALL]),
                link('/campaign-group', 'navbar.campaign_groups_nav', [PermissionName.CAMPAIGN_GROUPS_READ_ALL]),
                link('/coupons', 'navbar.coupons_nav', [PermissionName.COUPON_CODES_READ_ALL]),
                link('/push-notifications', 'navbar.push_notifications_nav', [PermissionName.PUSH_NOTIFICATIONS_READ_ALL]),
            ]),

            dropdown('navbar.advanced_nav', [
                mergeWhen((ENV.APP_WHITE_LABEL as any) === 1, [
                    link('/apptheme', 'navbar.apptheme_nav', [PermissionName.RESTAURANTS_READ_ALL]),
                ]),
                link('/alert/alerts', 'navbar.alerts_nav', [PermissionName.ALERTS_READ_ALL]),
                link('/alertviewer', 'navbar.alertviewer_nav', [PermissionName.ALERT_VIEWERS_READ_ALL]),
                link('/status', 'navbar.status_nav', [PermissionName.RESTAURANT_STATUS_READ_ALL]),
                link('/delivery', 'navbar.delivery_nav', [PermissionName.DELIVERY_COMPANIES_READ_ALL]),
                link('/global-exceptions', 'navbar.global_exceptions', [PermissionName.DELIVERY_COMPANIES_READ_ALL]),
                link('/market-settings', 'navbar.market-settings', [PermissionName.RESTAURANT_FINANCES_READ_ALL]),
                link('/delivery-districts', 'navbar.delivery_districts'),
                link('/city-covers', 'navbar.city_covers_nav', [PermissionName.CAMPAIGN_GROUPS_READ_ALL]),


                /*mergeWithPermissionGroup([
                    [PermissionName.DELIVERY_DRIVERS_READ],
                    [PermissionName.DELIVERY_DRIVERS_READ_ALL],
                ], [
                    link('/driver-location', 'navbar.driver_on_map', []),
                ]),*/


             //   link('/send-grid-events', 'navbar.send_grid_events_nav', [PermissionName.SEND_GRID_EVENTS_READ_ALL]),
            ]),
        ]);
    }

    public setLanguageChange(resp){
        localStorage.setItem('language',resp)
        this.translateService.use(localStorage.getItem('language'))

        this.authorizedService.user$
            .subscribe((user) => {
                this.user = user;
                this.displayNavbar = user ? user.hasUserConnection(UserConnectionType.ADMIN, '*') : false;
                this.links = this.getNavbar();
            });

        if(resp == 'sv'){
            this.enClicked = false;
            this.seClicked = true;
        }else{
            this.seClicked = false;
            this.enClicked = true;
        }
    }
}
