angular.module( 'common.roForminput', [
    'ui.bootstrap'
])
.directive('roForminput', roForminput);

function roForminput() {
    return {
        restrict: 'A',
        scope: {
            roForminput: '='
        },
        link: function (scope, element, attrs) {
            // set the initial value of the textbox
            element.val(scope.roForminput);
            element.data('old-value', scope.roForminput);

            // detect outside changes and update our input
            scope.$watch('roForminput', function (val) {
                element.val(scope.roForminput);
            });

            // on blur, update the value in scope
            element.bind('propertychange keyup paste', function (blurEvent) {
                if (element.data('old-value') != element.val()) {
                    console.log('value changed, new value is: ' + element.val());
                    scope.$apply(function () {
                        scope.roForminput = element.val();
                        element.data('old-value', element.val());
                    });
                }
            });
        }
    };
}