angular.module( 'cmsApp.delivery.template.area.factory', [
    'ui.router',
    'ui.bootstrap'
])

.factory('DeliveryTemplateAreaRepository', function (Restangular, AbstractRepository) {

    function DeliveryTemplateAreaRepository() {
        AbstractRepository.call(this, Restangular, 'delivery/areas');
    }

    AbstractRepository.extend(DeliveryTemplateAreaRepository);

    return new DeliveryTemplateAreaRepository();
})

;
