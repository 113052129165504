angular.module( 'cmsApp.alert.restaurantalerts', [
])
.config(RestaurantAlertsCtrlConfig)
.controller('RestaurantAlertsCtrl', RestaurantAlertsCtrl);

function RestaurantAlertsCtrlConfig( $stateProvider ) {

}

function RestaurantAlertsCtrl( $scope, $state ) {
    /* jshint validthis: true */
    var vm = this;

    ////////////////////

    vm.activate = function() {
        $scope.$emit('selectItem', { groupID: -1, profileID: null } );
        $scope.$emit('setLoading', false);
    };
    
    vm.activate();
    return vm;
}