import { Component, OnInit, Input, EventEmitter, Output, OnDestroy, ViewChild } from '@angular/core';
import { ImageLibraryState } from './image-library-state';
import { ImageLibraryStateStorage } from './image-library-state.storage';
import { ImageLibraryStateMode } from './image-library-state-mode';
import { Subscription } from 'rxjs';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Image } from './http/image';
import { ImageType } from './http/image-type';
import { ImageTypeDimensions } from './http/image-type-dimensions';
import { SubscriptionCollection } from '@ro-ngx/core';

@Component({
    selector: 'image-library',
    template: `
        <div class="modal image-library-wrapper fade" bsModal #modal="bs-modal" role="dialog" aria-labelledby="modalLabel" aria-hidden="true" (onHide)="resetState()">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" (click)="close()"><span aria-hidden="true">&times;</span></button>
                        <h3 class="modal-title">{{ 'image_library.image_library' | translate }} <small><i>{{ 'image_library.size' | translate }} ({{ getDimensionText() }})</i></small>
                            <li href dropdown class="dropdown list-collection">
                                <a href dropdownToggle class="dropdown-toggle" (click)="false">
                                    <span class="caret"></span>
                                </a>
                                <ul *dropdownMenu class="dropdown-menu" role="menu">
                                    <li>
                                        <a (click)="setImageType(imageTypeSelector.ProductCoverImage)">255x255</a>
                                    </li>
                                    <li >
                                        <a (click)="setImageType(imageTypeSelector.RestaurantLogoImage)">512x512</a>
                                    </li>
                                    <li >
                                        <a (click)="setImageType(imageTypeSelector.RestaurantHomeSlide)">640X220</a>
                                    </li>
                                    <li>
                                        <a (click)="setImageType(imageTypeSelector.RestaurantTileImage)">768X384</a>
                                    </li>
                                    <li >
                                        <a (click)="setImageType(imageTypeSelector.RestaurantCoverImage)">1920X550</a>
                                    </li>
                                </ul>
                            </li>
                        </h3>
                    </div>
                    <div class="modal-body">
                        <image-stored *ngIf="state.mode == mode.Stored" (onImage)="selectImage($event)"></image-stored>
                        <image-upload *ngIf="state.mode == mode.Upload"></image-upload>
                        <image-crop *ngIf="state.mode == mode.Crop" (onCrop)="selectImage($event)"></image-crop>
                    </div>
                </div>
            </div>
        </div>
    `
})
export class ImageLibraryComponent implements OnInit, OnDestroy {

    public state: ImageLibraryState = ImageLibraryStateStorage.defaultState;

    @ViewChild('modal')
    public modal: ModalDirective;

    @Output()
    public onImage: EventEmitter<Image> = new EventEmitter();

    public dimensions: string;

    public imageTypeSelector: typeof ImageType = ImageType;

    @Input()
    set imageType(imageType: ImageType) {
        this.setImageType(imageType);
    }

    @Input()
    set clientKey(clientKey: string) {
        this.imageLibraryStateStorage.setClientKey(clientKey);
    }

    public mode: typeof ImageLibraryStateMode = ImageLibraryStateMode;

    protected subscriptions: SubscriptionCollection;

    constructor(protected imageLibraryStateStorage: ImageLibraryStateStorage) {
        this.subscriptions = new SubscriptionCollection();
    }

    /**
     * NgOnInit
     */
    public ngOnInit(): void {
        this.subscriptions.set('stateHandle', this.stateHandle());
    }

    /**
     * NgOnDestroy
     */
    public ngOnDestroy(): void {
        this.subscriptions.unsubscribeAll();
    }

    public setImageType(imageType: ImageType): this {
        this.imageLibraryStateStorage.setImageType(imageType);

        return this;
    }

    public navigatoMode(mode: ImageLibraryStateMode): void {
        this.imageLibraryStateStorage.changeMode(mode);
    }

    public getDimensionText(): string {
        return ImageTypeDimensions.text(this.state.imageType);
    }

    public selectImage(image: Image): void {
        this.onImage.emit(image);
        this.imageLibraryStateStorage.changeMode(ImageLibraryStateMode.Stored);
        this.close();
    }

    public open(): this {
        this.modal.show();

        return this;
    }

    public close(): this {
        this.modal.hide();

        return this;
    }

    public resetState(): void {
        const ms = 600;
        setTimeout(() => {
            this.imageLibraryStateStorage.changeMode(
                ImageLibraryStateMode.Stored
            );
        }, ms);
    }

    protected stateHandle(): Subscription {
        return this.imageLibraryStateStorage.state$
            .subscribe((state) => {
                this.dimensions = ImageTypeDimensions.text(state.imageType);

                if (typeof this.modal !== 'undefined' && typeof this.modal.config !== 'undefined') {
                    this.modal.config.ignoreBackdropClick = (this.state.mode === ImageLibraryStateMode.Crop);
                }

                this.state = state;
            });
    }
}
