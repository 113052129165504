var template = require('./deliverytemplate.edit.tpl.html');

angular.module( 'cmsApp.delivery.template.edit', [
    'ui.bootstrap',
    'common.modalService',
    'ui.select',
    'ngSanitize'
])
.directive('roDeliveryTemplateEdit', roDeliveryTemplateEdit);

function roDeliveryTemplateEdit() {
    return {
        restrict: 'EA',    
        scope: {
            repo: '=',
            company: '=',
            priceplans: '=',
            interceptors: '=',
            roDeliveryTemplateEdit: '='
        },
        controller: function( $scope, modalService, DeliveryTemplateAreaRepository, translateService ) {
            /* jshint validthis: true */
            var vm = this;

            vm.save = function() {
                $scope.$emit('showNotification', {
                    id: 'dTemplateEditSave',
                    loading: true,
                    alertType: 'alert-info',
                    title: translateService.instant('general.loading')
                });

                 vm.repo.update(vm.roDeliveryTemplateEdit, vm.roDeliveryTemplateEdit.deliveryTemplateID).then(function () {
                    $scope.$emit('showNotification', {
                        id: 'dTemplateEditSave',
                        loading: false,
                        alertType: 'alert-success',
                        title: translateService.instant('general.saved')
                    });
                 });
            };

            vm.updateAreaName = function (area, newName) {
                $scope.$emit('showNotification', {
                    id: 'dAreaName',
                    loading: true,
                    alertType: 'alert-info',
                    title: translateService.instant('general.loading')
                });

                DeliveryTemplateAreaRepository.update({ deliveryAreaID: area.deliveryAreaID, areaName: newName }, area.deliveryAreaID).then(function () {
                    $scope.$emit('showNotification', {
                        id: 'dAreaName',
                        loading: false,
                        alertType: 'alert-success',
                        title: translateService.instant('general.saved')
                    });
                });
                return true;
            };

            vm.createArea = function() {
                var modalOptions = {
                    textInputShow:      true,
                    textInputLabel:     translateService.instant('delivery.template.area_name'),
                    closeButtonText:    translateService.instant('general.cancel'),
                    actionButtonText:   translateService.instant('general.create'),
                    actionButtonClass:  'btn-success',
                    headerText:         translateService.instant('delivery.template.create_area_heading'),
                    bodyText:           ''
                };

                modalService.showModal({}, modalOptions).then(function (result) {
                    if(result && result !== true) {
                        DeliveryTemplateAreaRepository.create({ areaName: result, deliveryTemplateID: vm.roDeliveryTemplateEdit.deliveryTemplateID }).then(function(obj) {
                            obj.deliveryPriceplanID = 0;
                            obj.active = 0;
                            vm.roDeliveryTemplateEdit.deliverytemplates.push(obj);
                        });
                    }
                });
            };

            vm.duplicateArea = function(area) {
                var modalOptions = {
                    textInputShow:      true,
                    textInputLabel:     translateService.instant('delivery.template.area_name'),
                    closeButtonText:    translateService.instant('general.cancel'),
                    actionButtonText:   translateService.instant('general.duplicate'),
                    actionButtonClass:  'btn-success',
                    headerText:         translateService.instant('delivery.template.duplicate_area_heading'),
                    bodyText:           '',
                    textInputValue:     area.areaName
                };

                modalService.showModal({}, modalOptions).then(function (result) {
                    if(result && result !== true) {
                        DeliveryTemplateAreaRepository.duplicate(area.deliveryAreaID, { areaName: result }).then(function(obj) {
                            vm.roDeliveryTemplateEdit.deliverytemplates.push(obj);
                        });
                    }
                });
            };

            vm.deleteItem = function(item) {
                var modalOptions = {
                    closeButtonText: translateService.instant('general.cancel'),
                    actionButtonText: translateService.instant('general.delete'),
                    actionButtonClass: 'btn-danger',
                    headerText: translateService.instant('delivery.template.confirm_delete_short'),
                    bodyText: translateService.instant('delivery.template.confirm_area_delete')
                };

                modalService.showModal({}, modalOptions).then(function (result) {
                    if(result) {
                        item.selected = false;
                        var index = vm.roDeliveryTemplateEdit.deliverytemplates.indexOf(item);
                        if(index > -1) {
                            DeliveryTemplateAreaRepository.remove(item.deliveryAreaID);
                            vm.roDeliveryTemplateEdit.deliverytemplates.splice(index, 1);
                        }
                    }
                });
            };

            vm.selectItem = function(toggleItem) {
                toggleItem.selected = !toggleItem.selected;

                var anySelected = false;
                vm.roDeliveryTemplateEdit.deliverytemplates.forEach(function (area) {
                    if(area.selected)
                    {
                        anySelected = true;
                    }
                });

                vm.roDeliveryTemplateEdit.hideSaveButtons = anySelected;
            };
        },
        controllerAs: 'vm',
        bindToController: true,
        template: template
    };
}

