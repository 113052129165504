import * as angular from 'angular';
import { AbstractRepository } from '../abstract/abstract-repository';
import { IPromise, IService } from 'restangular';

export class DeliveryOrderRepository extends AbstractRepository {

    constructor(Restangular: IService) {
        super(Restangular, 'delivery/orders');
    }

    getDeliveryOrder(deliveryOrderID) {
        return this.get('', deliveryOrderID)
            .then((response) => response.plain());
    }

    getDeliveryOrders(query) {
        return this.getList('', query)
            .then((response: any) => ({
                orders: response.plain(),
                meta: response.metadata,
                sideData: response.sideData
            }));
    }

    confirmDeliveryOrder(deliveryOrderID, deliveryOrder) {
        return this.customUpdate({
            'deliveryCompanyID': deliveryOrder.deliveryCompanyID,
            'deliveryDriverID': (deliveryOrder.deliveryDriverID ? deliveryOrder.deliveryDriverID : 0)
        }, this.route + '/' + deliveryOrderID + '/confirmDeliveryOrder');
    }

    generateFleetOrder(deliveryOrderID): IPromise<any> {
        return this.restangular.one(this.route + '/' + deliveryOrderID + '/generate-fleet-order').customPUT({});
    }

    changeDeliveryCompany(deliveryOrderID, deliveryCompanyID, deliveryDriverID, confirmOrder, removeTimeout, comment) {
        return this.restangular.one(this.route + '/' + deliveryOrderID + '/changeDeliveryCompany').customPUT({
            deliveryOrderID: deliveryOrderID,
            deliveryCompanyID: deliveryCompanyID,
            deliveryDriverID: deliveryDriverID,
            confirmOrder: confirmOrder,
            removeTimeout: removeTimeout,
            comment: comment
        }, '');
    }

    reschedule(deliveryOrderID, pickupLine) {
        return this.restangular.one('/delivery-order-reschedule-requests').customPOST({ deliveryOrderID, pickupLine });
    }


    rescheduleCreateLine(deliveryOrderID, createLine) {
        return this.restangular.one('/delivery/orders/' + deliveryOrderID + '/reschedule-create-line').customPUT({
            deliveryOrderID,
            createLine
        });
    }

}


angular.module('cmsApp.deliveryorder.factory', [])
    .service('deliveryOrderRepository', DeliveryOrderRepository)
    .factory('DeliveryOrderRepository', (Restangular) => {
        return new DeliveryOrderRepository(Restangular);
    });
