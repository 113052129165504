
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BaseService } from '../../base/base.service';
import { HungrigClient } from '@ro-ngx/core';
import { Observable } from 'rxjs';
import { RestaurantNote } from './restaurant-note';

@Injectable()
export class RestaurantNoteService extends BaseService {
    public resourceUrl: string = 'cms/restaurants/';

    constructor(public hungrigClient: HungrigClient) {
        super(hungrigClient);
    }

    public getNote(clientKey: string, type: string): Observable<RestaurantNote> {
        return this.hungrigClient.get(this.url(`${clientKey}/notes/${type}`)).pipe(
            map(this.toResponse),
            map((response) => response.data),
            catchError(this.handleError));
    }

    public updateNote(clientKey: string, type: string, note: any): Observable<RestaurantNote> {
        return this.hungrigClient.put(this.url(`${clientKey}/notes/${type}`), note).pipe(
            map(this.toResponse),
            map((response) => response.data),
            catchError(this.handleError));
    }
}
