angular.module( 'cmsApp.delivery.interceptor.factory', [
])

.factory('DeliveryInterceptorRepository', function ($q, $timeout, Restangular, AbstractRepository, translateService) {

    function DeliveryInterceptorRepository() {
        
        var repo = this;
        
        repo.cache = [];
        repo.promise = null;
        
        AbstractRepository.prototype.saveInterceptor = function(data) {
            return this.restangular.one('delivery/interceptors').customPUT(data, data.deliveryInterceptorID);
        };

        AbstractRepository.prototype.createInterceptor = function(item) {
            return this.restangular.all('delivery/interceptors').post(item);
        };

        AbstractRepository.prototype.removeInterceptor = function(item) {
            return this.restangular.one('delivery/interceptors', item.deliveryInterceptorID).remove(null, null);
        };

        DeliveryInterceptorRepository.prototype.saveSortOrder = function(interceptors) {
            return this.customUpdate(interceptors, this.route + '/sortOrder');
        };
        
        repo.getList = function (path, params, headers) {
            if(path || params || headers) {
                return this.restangular.all(this.route + (!!path ? path : "")).getList(params, headers);
            }
            
            if(!repo.cache.length) {
                if(!repo.promise) {
                    repo.promise = $q(function(resolve, reject) {
                        repo.restangular.all(repo.route).getList(params, headers).then(function (data) {
                            data.unshift({
                                active: 0,
                                deliveryInterceptorID: 0,
                                interceptorName: translateService.instant('delivery.template.interceptor_none_set')
                            });
                            repo.cache = data;
                            resolve(data);
                        }, function (error) {
                            reject(error);
                        });
                    });
                    
                    $timeout(function(){
                        repo.cache = [];
                        repo.promise = null;
                    }, 5000);
                }
                return repo.promise;
            } else {
                if(!repo.promise) {
                    repo.promise = $q(function(resolve, reject) {
                        resolve(repo.cache);
                    });
                }
                
                return repo.promise;
            }
        };
        
        AbstractRepository.call(this, Restangular, 'delivery/interceptors');
    }

    AbstractRepository.extend(DeliveryInterceptorRepository);

    return new DeliveryInterceptorRepository();
})

;
