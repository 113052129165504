import * as angular from 'angular';

import 'app/delivery/company/deliverycompany.factory';
import { AuthorizedService } from '@ro-ngx/authentication';
import { filter, first } from 'rxjs/operators';
import { format, subMinutes } from 'date-fns';
import { DeliveryDriverService } from '@ro-ngx/delivery';

var template = require('./change-delivery-company.component.tpl.html');

angular.module('cmsApp.deliveryfinance.deliveryorder.changedeliverycompany.component', [
    'cmsApp.delivery.company.factory'
])
.component('roChangeDeliveryCompany', {
    bindings: {
        'deliveryOrder': '<',
        'deliveryCompanies': '<',
        'onChange': '&'
    },
    controller: ChangeDeliveryCompanyComponent,
    controllerAs: 'vm',
    template: template
});

function ChangeDeliveryCompanyComponent(
    $scope,
    DeliveryCompanyRepository,
    DeliveryOrderRepository,
    translateService,
    authorizedService: AuthorizedService,
    deliveryDriverService: DeliveryDriverService
) {
    var vm = this;

    vm.deliveryCompanyID = 0;
    vm.deliveryDriverID = 0;
    vm.removeTimeout = 1;
    vm.comment = '';
    vm.confirmOrder = 0;

    vm.deliveryDrivers = [];
    vm.searchOnlineDrivers = true;

    vm.yesOrNo = [
        {
            value: 1,
            text: 'Ja'
        },
        {
            value: 0,
            text: 'Nej'
        }
    ];

    vm.subscriptions = [];

    vm.$onInit = function() {
        vm.deliveryDriverID = vm.deliveryOrder.deliveryDriverID;

        if (vm.deliveryOrder.deliveryCompanyID > 0) {
            vm.deliveryCompanyID = vm.deliveryOrder.deliveryCompanyID;
            vm.refreshDeliveryDrivers();
        }

        vm.subscriptions.push(
            authorizedService.user$
                .pipe(filter((user) => !!user), first())
                .subscribe((user) => vm.comment = `${user.firstName} ${user.lastName}`)
        );
    };

    vm.$onDestroy = function() {
        vm.subscriptions.forEach((subscription) => subscription.unsubscribe());
    };

    vm.setDeliveryCompanyID = function(deliveryCompanyID) {
        vm.deliveryCompanyID = deliveryCompanyID;
        vm.deliveryDriverID = 0;

        vm.refreshDeliveryDrivers();
    };

    vm.refreshDeliveryDrivers = function() {
        const options = {
            deliveryCompanyID: [vm.deliveryCompanyID]
        };

        if (vm.searchOnlineDrivers) {
            options['hasRequestAfter'] = format(subMinutes(new Date(), 5));
        }

        deliveryDriverService.getDrivers(options)
            .subscribe((deliveryDrivers) => {
                vm.deliveryDrivers = deliveryDrivers;
                vm.deliveryDrivers.unshift({
                    deliveryDriverID: 0,
                    driverName: translateService.instant('deliveryfinance.delivery_order.no_carrier')
                });
            });
    };

    vm.changeDeliveryCompany = function() {
        $scope.$emit('showNotification', { id: 'dChangeCompany', loading: true, alertType: 'alert-info', title: translateService.instant('general.loading') });

        return DeliveryOrderRepository.changeDeliveryCompany(vm.deliveryOrder.deliveryOrderID, vm.deliveryCompanyID, vm.deliveryDriverID, vm.confirmOrder, vm.removeTimeout, vm.comment)
            .then(function(response) {
                vm.deliveryOrder = response.plain();
                vm.change();

                $scope.$emit('showNotification', { id: 'dChangeCompany', loading: false, alertType: 'alert-success', title: translateService.instant('general.saved') });
            }, function(response) {
                $scope.$emit('showNotification', { id: 'dChangeCompany', loading: false, alertType: 'alert-danger', title: response.data.error_message });
            });
    };

    vm.change = function() {
        vm.onChange({
            $value: vm.deliveryOrder
        });
    };

    return vm;
}
