import * as angular from 'angular';

import 'app/alertviewer/alertviewer.service';
import 'app/alertviewer/alertview/alertview.component';

var template = require('./alertviewer.tpl.html');

angular.module( 'cmsApp.alertviewer.component', [
    'cmsApp.alertviewer.service',
    'cmsApp.alertviewer.viewer.repository',
    'cmsApp.alertview.component',
    'angularMoment',
    'ngAnimate'
])
.component('roAlertviewer', {
    bindings: {

    },
    controller: roAlertviewerController,
    controllerAs: 'vm',
    template: template
});

function roAlertviewerController(roAlertviewService, AlertViewerRepository, $interval, $timeout, $scope, $filter, moment) {
    let vm = this;

    vm.moment = moment;

    vm.alerts = roAlertviewService;
    vm.sortAlerts = roAlertviewService.sortAlerts;

    vm.searchInput = roAlertviewService.searchInput;
    vm.alertViewers = [];

    activate();

    ////////////////////

    function activate() {
        roAlertviewService.getAlertviews();

        $timeout(() => {
            console.warn('Browser will reload. Since a new day is starting.');
            window.location.reload();
        }, moment().endOf('day').valueOf() - moment().valueOf());

        $timeout(() => {
            $interval(() => {
                if (moment().startOf('day').isAfter(roAlertviewService.lastSearchPerformed)) {
                    console.warn('Browser will reload. Since last alert refresh was too long ago.');
                    window.location.reload();
                } else {
                    roAlertviewService.refreshAlerts();
                }
            }, 1000*10);
            // Tell's the browser to start refreshing at closest 10
        }, (10 - (moment().format('s') % 10)) * 1000);

        AlertViewerRepository.getList('', { "with": 'alertprofiles' })
            .then(function(data) {
                data = data.plain();

                angular.forEach(data, function(viewer) {
                    viewer.alertViewerID *= 1;
                });

                angular.extend(vm.alertViewers, vm.alertViewers, data);
            });
    }

    vm.search = function() {
        roAlertviewService.getAlertviews();
    };

    $scope.$watch('vm.searchInput', function(newValue, oldValue) {
        if (newValue === oldValue) {
            return null;
        }

        vm.search();
    }, true);

    vm.refreshAlerts = function() {
        roAlertviewService.refreshAlerts();
    };

    vm.haveAlertProfiles = function() {
        if (vm.alertViewers.length > 0) {
            var alertviewer = $filter('filter')(vm.alertViewers, {alertViewerID: vm.searchInput.alertViewerID}, true)[0];

            return alertviewer.alertprofiles.length > 0;
        }

        return false;
    };

    return vm;
}
