import { ModuleWithProviders, NgModule } from '@angular/core';
import { Json2typescriptConfig } from "./json2typescript-config";
import { JsonConvert } from "json2typescript";

@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [],
})
export class Json2typescriptModule {
    public static forRoot(config: Json2typescriptConfig): ModuleWithProviders {
        return {
            ngModule: Json2typescriptModule,
            providers: [
                {
                    provide: JsonConvert,
                    useValue: new JsonConvert(
                        config.operationMode, config.valueCheckingMode, config.ignorePrimitiveChecks
                    )
                }
            ]
        };
    }
}
