
import {catchError, map} from 'rxjs/operators';
import {BaseService} from '../base/base.service';
import {Injectable} from '@angular/core';
import {
    ErrorFactory, HungrigClient, Paginate, PaginateFactory,
    ResponseFactory, FileResponseFactory, FileResponse
} from '@ro-ngx/core';
import {Observable} from 'rxjs';
import {RestaurantSummary} from './restaurant-summary';
import {RequestOptions, ResponseContentType, URLSearchParams} from '@angular/http';
import {ParamsUtil} from '@ro-ngx/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class SummaryService extends BaseService {
    public resourceUrl: string = 'cms/summaries';

    constructor(hungrigClient: HungrigClient,
               private http:HttpClient) {
        super(hungrigClient);
    }

    public getDates(): Observable<string[]> {

        this.resourceUrl = "cms/wl/summaries"
        return this.hungrigClient.get(this.url('/dates')).pipe(
            map(ResponseFactory.toData),
           catchError(ErrorFactory.throwObservable));
    }

    public getRestaurantSummaries(
        filters?: { [key: string]: string }, page: number = 1, perPage: number = 15
    ): Observable<Paginate<RestaurantSummary[]>> {

        this.resourceUrl = 'cms/summaries';
        const search: URLSearchParams = new URLSearchParams();
        search.set('page', page.toString());
        search.set('perPage', perPage.toString());
        if (filters) {
            for (const filter in filters) {
                search.set(filter, filters[filter]);
            }
        }
        return this.hungrigClient.get(this.url('/restaurants'), {search}).pipe(
            map(PaginateFactory.from),
           catchError(ErrorFactory.throwObservable));
    }

    public getRestaurantSummaryExport(id: number[], format: string): Observable<FileResponse> {
        const search = ParamsUtil.toHttpParams({
            id,
            format
        });

        return this.hungrigClient.get(this.url('/export'), {search, responseType: ResponseContentType.Blob}).pipe(
            map(FileResponseFactory.fromResponse));
    }

    public getTotalSummaryData(date:string):Observable<any> {
        this.resourceUrl = 'cms/wl/summaries';

        return this.hungrigClient.get(this.url(`/total/${date}`)).pipe(
            map(ResponseFactory.toData),
           catchError(ErrorFactory.throwObservable));
    }


    public getRestaurantSummaryFiles(id: number|number[], type?: 'cost' | 'sales'): Observable<FileResponse> {
        const search: URLSearchParams = new URLSearchParams();

       this.resourceUrl = 'cms/summaries';

        if (type) {
            search.set('type', type);
        }

        if (Array.isArray(id)) {
            search.set('id', id.join(','));
        } else {
            search.set('id', id.toString());
        }

        return this.hungrigClient.get(this.url('/download'), { search, responseType: ResponseContentType.Blob }).pipe(
            map(FileResponseFactory.fromResponse));
    }

    public getRestaurantSummaryFile(clientKey: string, date: string): Observable<FileResponse> {

        this.resourceUrl = "cms/wl/summaries"


        return this.hungrigClient.get(this.url(`/fetch/${clientKey}/${date}`), {responseType: ResponseContentType.Blob}).pipe(
            map(FileResponseFactory.fromResponse));
    }

    public getTotalSummaryFile(date: string): Observable<FileResponse> {
        const search: URLSearchParams = new URLSearchParams();
        search.set('date', date);

        return this.hungrigClient.get(this.url('/total/download'), {search, responseType: ResponseContentType.Blob}).pipe(
            map(FileResponseFactory.fromResponse));
    }

    public getUnpaidXml(date: string): Observable<FileResponse> {

        this.resourceUrl = "cms/wl/summaries"

        return this.hungrigClient.get(this.url(`/unpaidxml/${date}`), { responseType: ResponseContentType.Blob}).pipe(
            map(FileResponseFactory.fromResponse));
    }

    public getTotalBankfileName(options): Observable<any> {

        this.resourceUrl = 'rocms/bankfile'


        return this.hungrigClient
            .post(this.url(),options).pipe(
            map(ResponseFactory.toData),
           catchError(ErrorFactory.throwObservable));
    }

    public getTotalBankfile(fileName) {


        this.resourceUrl = `rocms/bankfile/${fileName}`

        return this.hungrigClient
            .get(this.url(),{ responseType: ResponseContentType.Blob}).pipe(
            map(FileResponseFactory.fromResponse));
    }

    public getTotalCostSummaryFile(date: string): Observable<FileResponse> {
        const params = {
            date
        };

        return this.hungrigClient
            .get(this.url('/total/cost/download'), {search: params, responseType: ResponseContentType.Blob}).pipe(
            map(FileResponseFactory.fromResponse));
    }

    public getTotalSalesSummaryFile(date: string): Observable<FileResponse> {
        const params = {
            date
        };

        return this.hungrigClient
            .get(this.url('/total/sales/download'), {search: params, responseType: ResponseContentType.Blob}).pipe(
            map(FileResponseFactory.fromResponse));
    }

    public regenerateRestaurantSummary(clientKey: string, date: string, invoiceID:string): Observable<RestaurantSummary> {


        this.resourceUrl = "cms/wl/summaries"

        return this.hungrigClient.get(this.url(`/generate/${clientKey}/${date}/${invoiceID}`)).pipe(
            map(data => {

                // @ts-ignore
                return data.headers.status == 200 ? true : false;
            }),
           catchError(ErrorFactory.throwObservable));
    }

    public regenerateTotalSummary(date: string): Observable<boolean> {
        const search: URLSearchParams = new URLSearchParams();
        search.set('date', date);

        return this.hungrigClient.get(this.url('/total/regenerate'), {search}).pipe(
            map(ResponseFactory.toData),
           catchError(ErrorFactory.throwObservable));
    }

    public sendRestaurantSummaries(summaryID: number, options) {

        this.resourceUrl = "cms/wl/summaries"

        return this.hungrigClient.post(this.url(`/${summaryID}/sendEmail`),options).pipe(
            map(this.toResponse),
            map((response) => response.data),
            catchError(this.handleError));
    }

    public sendPaidSummaries(options) {

        this.resourceUrl = "cms/wl/summaries"

        return this.hungrigClient.post(this.url(`/togglePaid`),options).pipe(
            map(this.toResponse),
            map((response) => response.data),
            catchError(this.handleError));
    }

    public sendExcluded(options) {

        this.resourceUrl = "cms/wl/summaries"

        return this.hungrigClient.post(this.url(`/toggleExcludeFromCollection`),options).pipe(
            map(this.toResponse),
            map((response) => response.data),
            catchError(this.handleError));
    }

    public sendComment(options) {

        this.resourceUrl = "cms/wl/summaries"

        return this.hungrigClient.post(this.url(`/saveComment`),options).pipe(
            map(this.toResponse),
            map((response) => response.data),
            catchError(this.handleError));
    }
}
