require('./deliveryinterceptor.edit.directive.ts');
require('./deliveryinterceptor.factory.ts');
require('../interceptorspan/deliveryinterceptorspan.directive.ts');

angular.module( 'cmsApp.delivery.interceptor', [
    'cmsApp.delivery.interceptor.edit',
    'cmsApp.delivery.interceptor.factory',
    'cmsApp.delivery.interceptor.span'
])
.config(DeliveryInterceptorCtrlConfig)
.controller('DeliveryInterceptorCtrl', DeliveryInterceptorCtrl);

function DeliveryInterceptorCtrlConfig( $stateProvider ) {

}

function DeliveryInterceptorCtrl( $scope, $state, modalService, DeliveryInterceptorRepository, translateService ) {
    /* jshint validthis: true */
    var ivm = this;

    ivm.interceptors = [];

    ivm.repo = DeliveryInterceptorRepository;

    ivm.dragInterceptorListeners = {
        accept: function(sourceItemHandleScope, destSortableScope) {
            return sourceItemHandleScope.itemScope.sortableScope.$id === destSortableScope.$id;
        },
        orderChanged: function(event) {
            ivm.saveSortOrder();
        }
    };

    $scope.$on('deleteInterceptor', function(event, item) {
        var index = ivm.interceptors.indexOf(item);
        if(index > -1) {
            DeliveryInterceptorRepository.remove(item.deliveryInterceptorID);
            ivm.interceptors.splice(index, 1);
        }
    });

    $scope.$on('emitScheduleSaved', function(event, item) {
        $scope.$broadcast('scheduleSaved', item);
    });

    ivm.activate = function() {
        $scope.$emit('setLoading', false);
        $scope.$emit('selectItem', { company: null, item: 'interceptor' } );

        DeliveryInterceptorRepository.getList('', {}, {}).then(function(data) {
            ivm.interceptors = data;
        });
    };

    ivm.createInterceptor = function() {
        var modalOptions = {
            textInputShow: true,
            textInputLabel: translateService.instant('delivery.interceptor.name'),
            closeButtonText: translateService.instant('general.cancel'),
            actionButtonText: translateService.instant('general.create'),
            actionButtonClass: 'btn-success',
            headerText: translateService.instant('delivery.interceptor.create_interceptor_heading'),
            bodyText: ''
        };

        modalService.showModal({}, modalOptions).then(function (result) {
            if(result && result !== true) {
                DeliveryInterceptorRepository.create({ interceptorName: result, sortOrder: ivm.interceptors.length }).then(function(obj) {
                    obj.deliveryinterceptorspans = [];
                    ivm.interceptors.push(obj);
                });
            }
        });
    };

    ivm.saveSortOrder = function() {
        ivm.setTimeoutInterceptorPositions(ivm.interceptors);
    
        var interceptors = [];

        angular.forEach(ivm.interceptors, function(interceptor) {
            interceptors.push({
                deliveryInterceptorID: interceptor.deliveryInterceptorID,
                sortOrder:             interceptor.sortOrder
            });
        });

        $scope.$emit('showNotification', {
            id: 'iSaveSortOrder',
            loading: true,
            alertType: 'alert-info',
            title: translateService.instant('general.loading')
        });

        DeliveryInterceptorRepository.saveSortOrder(interceptors).then(function(data) {
            angular.merge(ivm.interceptors, data);

            $scope.$emit('showNotification', {
                id: 'iSaveSortOrder',
                loading: false,
                alertType: 'alert-info',
                title: translateService.instant('general.success')
            });
        });
    };

    ivm.setTimeoutInterceptorPositions = function(interceptors) {
        angular.forEach(interceptors, function(interceptor) {
            interceptor.sortOrder = interceptors.indexOf(interceptor);
        });
    };

    ivm.activate();
    return ivm;
}
