angular.module( 'common.zipcode.factory', [
    'ui.router',
    'ui.bootstrap'
])

.factory('ZipcodeRepository', function (Restangular, AbstractRepository) {

    function ZipcodeRepository() {

        /*AbstractRepository.prototype.savePriceplanSpan = function(data) {
            data = angular.copy(data);

            return this.restangular.one('delivery/priceplanspans').customPUT(data, data.deliverySpanID);
        };*/

        AbstractRepository.call(this, Restangular, 'delivery/zipcodes');
    }

    AbstractRepository.extend(ZipcodeRepository);

    return new ZipcodeRepository();
})

;
