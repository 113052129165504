import { ImageTypeDimension } from './image-type-dimension';
import { ImageType } from './image-type';

class ImageTypeDimensions {

    public static dimensions: ImageTypeDimension[] = [];

    public static addDimension(dimension: {
        imageType: ImageType;
        width: number;
        height: number;
    }): void {
        const imageTypeDimension = new ImageTypeDimension(dimension.imageType, dimension.width, dimension.height);

        this.dimensions[dimension.imageType] = imageTypeDimension;
    }

    public static get(imageType: ImageType): ImageTypeDimension {
        return this.dimensions[imageType];
    }

    public static placeholder(imageType: ImageType): string {
        return 'http://placehold.it/' + this.text(imageType);
    }

    public static text(imageType: ImageType): string {
        const dimension = this.get(imageType);

        return dimension.text();
    }
}

ImageTypeDimensions.addDimension({ imageType: ImageType.ProductCoverImage, width: 255, height: 255 });
ImageTypeDimensions.addDimension({ imageType: ImageType.RestaurantLogoImage, width: 512, height: 512 });
ImageTypeDimensions.addDimension({ imageType: ImageType.RestaurantTileImage, width: 768, height: 384 });
ImageTypeDimensions.addDimension({ imageType: ImageType.RestaurantCoverImage, width: 1920, height: 550 });
ImageTypeDimensions.addDimension({ imageType: ImageType.RestaurantHomeSlide, width: 640, height: 220 });

export { ImageTypeDimensions };
