import { Svg } from './svg';
import { DomSanitizer } from '@angular/platform-browser';
import { Injectable } from '@angular/core';

/* tslint:disable */
const svgs: any = {
    [Svg.BRAND_LOGO]: require('Brand/svg/brand-logo.inline.svg'),
    [Svg.BRAND_TEXT_LOGO]: require('Brand/svg/brand-text-logo.inline.svg'),
    [Svg.TRANSPORT_CAR]: require('Svg/transport-car.inline.svg'),
    [Svg.TRANSPORT_BICYCLE]: require('Svg/transport-bicycle.inline.svg')
};
/* tslint:enable */

@Injectable()
export class SvgCollection {

    public static svgs: string[] = svgs;

    constructor(protected domSanitizer: DomSanitizer) {}

    public get(key: Svg): string {
        return SvgCollection.svgs[key];
    }

    public getSanitized(key: Svg): any {
        return this.domSanitizer.bypassSecurityTrustHtml(
            this.get(key)
        );
    }
}
