import { NgModule } from '@angular/core';
import { TextareaMarkdownComponent } from "./textarea-markdown.component";
import { CommonModule } from "@angular/common";

@NgModule({
    imports: [
        CommonModule
    ],
    exports: [
        TextareaMarkdownComponent
    ],
    declarations: [
        TextareaMarkdownComponent
    ],
    providers: [],
})
export class MarkdownModule {
}
