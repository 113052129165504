import * as angular from 'angular';

import 'common/rofilters/rozip';
import 'common/rofilters/ronewline';
import 'common/rofilters/rotrusthtml';

angular.module('common.rofilters', [
    'common.rofilters.rozip',
    'common.rofilters.newline',
    'common.rofilters.trusthtml'
]); // define common namespace for ro filters