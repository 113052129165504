angular.module('zipcodeFilter', [
    'common.ZipcodeService'
])
.filter('zipcodesToString', function (ZipcodeService) {
    return function(code, joinBy) {
        var postalCodes = [];

        if (typeof joinBy === 'undefined') {
            joinBy = ', ';
        }

        function addMissingCodes(min, max) {
            if (typeof min === 'string') {
                min = min.replace('-', '');
            }

            if (typeof max === 'string') {
                max = max.replace('-', '');
            }

            var minZip = parseInt(min);
            var maxZip = parseInt(max);

            var codes = [
                ZipcodeService.formatPostalCode(maxZip),
                ZipcodeService.formatPostalCode(minZip)
            ];

            var missingCount = maxZip - minZip;

            for(var i = 1; i < missingCount; i++) {
                codes.push(ZipcodeService.formatPostalCode(minZip + i));
            }

            return codes;
        }

        angular.forEach(code, function flexZone(value, key) {
            if (value.minZip == value.maxZip) {
                postalCodes = postalCodes.concat([value.minZip]);
            } else {
                var moreCodes = addMissingCodes(value.minZip, value.maxZip);
                postalCodes = postalCodes.concat(moreCodes);
            }
        });

        postalCodes.sort(function(a, b) {
            return a - b;
        });

        return postalCodes.join(joinBy);
    };
});
