import { Injectable } from '@angular/core';
import { BaseService, HungrigClient, Paginate, PaginateFactory, ParamsUtil } from "@ro-ngx/core";
import { Observable } from "rxjs";
import { Article } from "../models/article";
import { map } from "rxjs/operators";
import { JsonConvert } from "json2typescript";

export interface GetArticlesOptions {
    includes?: string[];
}

export interface GetPaginatedArticlesOptions {
    perPage?: number;
    page?: number;
    includes?: string[];
}

export interface GetArticleOptions {
    includes?: string[];
}

@Injectable()
export class ArticleService extends BaseService {
    constructor(
        public hungrigClient: HungrigClient,
        protected jsonConvert: JsonConvert
    ) {
        super(hungrigClient);
        this.apiUrl = hungrigClient.baseUrl;
    }

    public getArticles(options?: GetArticlesOptions): Observable<Article[]> {
        return this.get(`/article`, { search: ParamsUtil.toHttpParams(options) })
            .pipe(map((articles: Article[]) => this.jsonConvert.deserialize(articles, Article) as any));
    }

    public getPaginatedArticles(options?: GetPaginatedArticlesOptions): Observable<Paginate<Article[]>> {
        return this.hungrigClient.get(`${this.hungrigClient.baseUrl}/article/paginated`, {
                search: ParamsUtil.toHttpParams(options || {})
            })
            .pipe(
                map(PaginateFactory.from),
                map((pagination: Paginate<any>) => {
                    pagination.data = this.jsonConvert.deserializeArray(pagination.data, Article);
                    return pagination;
                })
            );
    }

    public getArticle(articleID: number, options?: GetArticleOptions): Observable<Article> {
        const search = options ? ParamsUtil.toHttpParams(options) : undefined;
        return this.get(`/article/${articleID}`, { search })
            .pipe(map((articles: Article) => this.jsonConvert.deserialize(articles, Article) as any));
    }

    public createArticle(data: Partial<Article>): Observable<Article> {
        return this.post(`/article`, data)
            .pipe(map((articles: Article) => this.jsonConvert.deserialize(articles, Article) as any));
    }

    public updateArticle(articleID: number, data: Partial<Article>): Observable<Article> {
        return this.put(`/article/${articleID}`, data)
            .pipe(map((articles: Article) => this.jsonConvert.deserialize(articles, Article) as any));
    }

    public deleteArticle(articleID: number): Observable<string | number> {
        return this.delete(`/article/${articleID}`)
            .pipe(map(() => articleID));
    }
}
