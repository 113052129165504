import { AuthenticationTokenStorage } from '@ro-ngx/authentication';
import * as angular from 'angular';
import { filter } from 'rxjs/operators';

// tslint:disable

angular.module('cmsApp.auth-bridge', [])
    .controller('AuthBridgeCtrl', AuthBridgeCtrl);

function AuthBridgeCtrl(AuthRepository, authenticationTokenStorage: AuthenticationTokenStorage) {
    authenticationTokenStorage.authorizationToken$
        .pipe(filter((user) => !!user))
        .subscribe((token) => {
            AuthRepository.setAccessToken(token.access_token);
            AuthRepository.logged_in = true;
        }, () => {
            AuthRepository.logout();
        });
}
