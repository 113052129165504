
import {map, filter} from 'rxjs/operators';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Translator, HungrigClient, DialogService } from '@ro-ngx/core';
import { ENV } from './enviroment';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { Observable ,  Subscription } from 'rxjs';
import { SeoService } from './global/seo/seo.service';
import * as moment from 'moment';
import { AuthenticationTokenStorage, AuthorizationInterceptor } from '@ro-ngx/authentication';
import { interval } from 'rxjs';

@Component({
    selector: 'app',
    template: require('./app.component.html')
})
export class AppComponent implements OnInit {
    protected versionPoll: Subscription;

    public constructor(
        protected authenticationTokenStorage: AuthenticationTokenStorage,
        protected authorizationInterceptor: AuthorizationInterceptor,
        protected hungrigClient: HungrigClient,
        protected translate: TranslateService,
        protected route: ActivatedRoute,
        protected router: Router,
        protected seoService: SeoService,
        protected dialogService: DialogService

    ) {
        const translator = new Translator();

        translator.fromWebpackContext(require['context']('@ro-ngx/core/src/i18n', true, /^\.\/.*\.json$/));
        translator.fromWebpackContext(require['context']('../resources/i18n', true, /^\.\/.*\.json$/));

        Object.keys(translator.translations).forEach((key) => {
            translate.setTranslation(key, translator.translations[key]);
        });

        translate.addLangs(['en','sv'])
        translate.setDefaultLang('en');
        translate.use(localStorage.getItem('language'))
    }

    public ngOnInit(): void {
        this.authenticationTokenStorage.authorizationToken$.subscribe((token) => {
            if (token) {
                const header = this.authenticationTokenStorage.toHeader(token);
                this.hungrigClient.requestOptions.headers.set(header.key, header.value);
            }
        });

        this.hungrigClient.addResponseHook(
            (response) => this.authorizationInterceptor.handleResponse(response)
        );

        this.setTitle();
        this.ensureVersionValidity();
    }

    protected setTitle(): void {
        this.router.events.pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(() => {
                const title: string = this.getDeepestTitle(this.router.routerState.snapshot.root);

                if (title !== undefined) {
                    this.seoService.setTitle(this.translate.instant(title));
                }
            });
    }

    protected getDeepestTitle(node: ActivatedRouteSnapshot): string {
        if (node.firstChild) {
            const childTitle: string = this.getDeepestTitle(node.firstChild);
            if (childTitle !== undefined) {
                return childTitle;
            }
        }
        return node.data['title'] || undefined;
    }

    protected ensureVersionValidity(): void {
        if (ENV.ENV === 'production') {
            const intervalValue: number = 5000;
            this.versionPoll = interval(intervalValue).subscribe(() => {
                this.hungrigClient.get('/build.version', { search: { t: moment().valueOf() } }).pipe(
                    map((response) => response.json()))
                    .subscribe((response) => {
                        if (response.version !== ENV.APP_VERSION) {
                            console.log('Version mismatch', response.version, ENV.APP_VERSION);
                            this.versionPoll.unsubscribe();
                            this.dialogService.confirm(this.translate.instant('dialog.version_mismatch'))
                                .subscribe((reload) => reload ? window.location.reload(true) : undefined);
                        }
                    });
            });
        }
    }
}
