import { Injectable } from '@angular/core';
import { BaseService, HungrigClient } from '@ro-ngx/core';
import { ENV } from '../../enviroment';
import { Observable } from 'rxjs';
import { OrderContact } from './order-contact';

@Injectable()
export class OrderContactService extends BaseService {
    constructor(public hungrigClient: HungrigClient) {
        super(hungrigClient);
        this.apiUrl = ENV.API_LOCATION;
    }

    public sendMessage(orderID: number, contents: { recipient: string, message: string }): Observable<OrderContact> {
        return this.post<OrderContact>(`/rocms/transactionhistory/orders/${orderID}/contact`, contents);
    }

    public getHistory(orderID: number): Observable<OrderContact[]> {
        return this.get<OrderContact[]>(`/rocms/transactionhistory/orders/${orderID}/contact`);
    }
}
