var template = require('./timeoutstep.tpl.html');

angular.module( 'cmsApp.delivery.timeout.step.component', [
	'cmsApp.delivery.timeout.step',
])
.component('roDeliveryTimeoutStep', {
	bindings: {
		schedulestep: '=',
		schedule: '=',
		deliverycompanies: '='
	},
	controller: function($scope, $filter, $modal, modalService, DeliveryTimeoutStep, translateService) {	
		var cvm = this;

		activate();

		////////////////////

		function activate() {

		}

		cvm.truncateText = function(text) {
		    var replaceItems = {
		        'restaurang':           '',
		        'Grönt o Gott':         'GoG',
		        "Grönt o' Gott":        'GoG',
		        'Grönt o´ gott':        'GoG',
		        'Pizza Hut':            'PH',
		        'Salads And Smoothies': 'SaS',
		        'V.E.S.P.A':            'Ves',
		        'La Pizza':             'Lapizz',
		        'I Chill Out Sushi':    'COu'
		    };

		    angular.forEach(replaceItems, function(value, key) {
		        text = text.replace(new RegExp(key, 'gi'), value);
		    });

		    text = text.trim();

		    var textParts = text.split(' ');

		    if(textParts.length > 1 && text.length > 6) {
		        var part1 = textParts[0].substr(0, 3).trim();
		        var part2length = 6 - part1.length;
		        var middle = '';
		        var part2 = textParts[textParts.length - 1].substr(0, part2length).trim();

		        // if part 2 is not long enough (perhaps it's just a digit) then include the penultimate.
		        if(part2.length < 3 && (textParts.length - 2) !== 0) {
		            middle = textParts[textParts.length - 2].substr(0, 3 - part2.length).trim();
		        }

		        text = part1 + middle + part2;
		    } else {
		        text = text.substr(0, 6);
		    }

		    return text;
		};

		cvm.removeStep = function(step) {
			$scope.$emit('showNotification', { id: 'dInterceptorTimeoutStepRemove', loading: true, alertType: 'alert-info', title: translateService.instant('general.loading') });

			DeliveryTimeoutStep.remove(step.deliveryTimeoutStepID).then(function() {
				var stepIndex = cvm.schedule.timeoutsteps.indexOf(step);
				cvm.schedule.timeoutsteps.splice(stepIndex, 1);
				
				$scope.$emit('showNotification', { id: 'dInterceptorTimeoutStepRemove', loading: false, alertType: 'alert-success', title: translateService.instant('general.saved') });
			});
		};

		return cvm;
	},
	controllerAs: 'cvm',
	template: template
});