import * as angular from 'angular';

import "./deliveryinterceptor.factory";
import "../interceptorspan/deliveryinterceptorspan.factory";
import "../timeout/schedule/timeoutschedule.component";
import * as moment from "moment";

var template = require('./deliveryinterceptor.edit.tpl.html');

angular.module('cmsApp.delivery.interceptor.edit', [
        'cmsApp.delivery.interceptor.factory',
        'cmsApp.delivery.interceptor.span.factory',
        'cmsApp.delivery.timeout.schedule.component'
    ])
    .directive('roDeliveryInterceptorEdit', roDeliveryInterceptorEdit);

function roDeliveryInterceptorEdit() {
    return {
        restrict: 'EA',
        scope: {
            repo: '=',
            roDeliveryInterceptorEdit: '='
        },
        controller: function( $scope, $q, modalService, DeliveryInterceptorSpanRepository, DeliveryCompanyRepository, translateService ) {
            /* jshint validthis: true */
            var vm = this;
            
            vm.sortableOptions = {
            	accept: function(sourceItemHandleScope, destSortableScope) {
            		return sourceItemHandleScope.itemScope.sortableScope.$id === destSortableScope.$id;
            	}
            };
            vm.roDeliveryInterceptorEdit.companies = [];
            
            vm.activate = function() {
                DeliveryCompanyRepository.getList().then(function(data) {
                    vm.roDeliveryInterceptorEdit.companies = data;
                });
            };

            // Watch for the item to get selected
            $scope.$watch('vm.roDeliveryInterceptorEdit.selected', function(newValue, oldValue) {
                if (newValue === true && typeof oldValue === 'undefined') {

                    $scope.$emit('showNotification', {
                        id: 'dInterceptorLoad',
                        loading: true,
                        alertType: 'alert-info',
                        title: translateService.instant('general.loading')
                    });

                    vm.repo.get('', vm.roDeliveryInterceptorEdit.deliveryInterceptorID, {with: 'deliveryinterceptorspans'}).then(function(data) {
                        data = data.plain();
                        angular.merge(vm.roDeliveryInterceptorEdit, data);

                        $scope.$emit('showNotification', {
                            id: 'dInterceptorLoad',
                            loading: false,
                            alertType: 'alert-success',
                            title: translateService.instant('general.success')
                        });
                    });
                }
            });
            
            $scope.$on('deleteInterceptorSpan', function(event, item) {
                var index = vm.roDeliveryInterceptorEdit.deliveryinterceptorspans.indexOf(item);
                if(index > -1) {
                    DeliveryInterceptorSpanRepository.remove(item.deliveryInterceptorSpanID);
                    vm.roDeliveryInterceptorEdit.deliveryinterceptorspans.splice(index, 1);
                }
            });

            $scope.$on('emitScheduleSaving', function() {
                vm.save();
            });

            vm.save = function() {
                $scope.$emit('showNotification', {
                    id: 'dInterceptorSave',
                    loading: true,
                    alertType: 'alert-info',
                    title: translateService.instant('general.loading')
                });

                var promises = [];

                promises.push(vm.repo.saveInterceptor(vm.roDeliveryInterceptorEdit));

                angular.forEach(vm.roDeliveryInterceptorEdit.deliveryinterceptorspans, function(obj) {
                    obj.priority = vm.roDeliveryInterceptorEdit.deliveryinterceptorspans.indexOf(obj);
                    promises.push(DeliveryInterceptorSpanRepository.saveInterceptorSpan(obj));
                });

                $q.all(promises).then(function() {
                    $scope.$emit('showNotification', {
                        id: 'dInterceptorSave',
                        loading: false,
                        alertType: 'alert-success',
                        title: translateService.instant('general.saved')
                    });
                });
            };

            vm.createSpan = function() {
                DeliveryInterceptorSpanRepository.create({ deliveryInterceptorID: vm.roDeliveryInterceptorEdit.deliveryInterceptorID }).then(function(obj) {
                    vm.roDeliveryInterceptorEdit.deliveryinterceptorspans.push(obj);
                });
            };

            vm.deleteItem = function() {
                var modalOptions = {
                    closeButtonText: translateService.instant('general.cancel'),
                    actionButtonText: translateService.instant('general.delete'),
                    actionButtonClass: 'btn-danger',
                    headerText: translateService.instant('delivery.interceptor.confirm_delete_short'),
                    bodyText: translateService.instant('delivery.interceptor.confirm_delete')
                };

                modalService.showModal({}, modalOptions).then(function (result) {
                    if(result) {
                        vm.roDeliveryInterceptorEdit.selected = false; 
                        $scope.$emit('deleteInterceptor', vm.roDeliveryInterceptorEdit);
                    }
                });
            };
            
            vm.selectItem = function (ips) {
                ips.selected = !ips.selected;

                var anySelected = false;

                vm.roDeliveryInterceptorEdit.deliveryinterceptorspans.forEach(function (element) {
                    if(element.selected) {
                        anySelected = true;
                    }
                });

                vm.roDeliveryInterceptorEdit.hideSaveButtons = anySelected;
            };

            vm.formatSpanEntry = function (date: string) {
                return date ? moment(date).format('HH:mm') : translateService.instant('delivery.interceptor.always');
            };
            
            vm.activate();
            
            return vm;
        },
        controllerAs: 'vm',
        bindToController: true,
        template: template
    };
}

