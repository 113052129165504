angular.module( 'cmsApp.delivery.priceplan', [
    'ui.router',
    'ui.bootstrap'
])
.config(DeliveryPriceplanCtrlConfig)
.controller('DeliveryPriceplanCtrl', DeliveryPriceplanCtrl);

function DeliveryPriceplanCtrlConfig( $stateProvider ) {

}

function DeliveryPriceplanCtrl( $scope, $state, $sce, modalService, DeliveryCompanyRepository, DeliveryPriceplanRepository, deliveryCompanyID, translateService, DeliveryDistancePriceStorage ) {
    /* jshint validthis: true */
    var cvm = this;

    var ustatus = {};

    cvm.company = {};

    cvm.priceplans = {};

    cvm.repo = DeliveryPriceplanRepository;

    ////////////////////

    $scope.$on('deletePriceplan', function(event, item) {
        var index = cvm.priceplans.indexOf(item);
        if(index > -1) {
            DeliveryPriceplanRepository.remove(item.deliveryPriceplanID);
            cvm.priceplans.splice(index, 1);
        }
    });

    function activate() {
        DeliveryDistancePriceStorage.getDistancePrices(['pricespans'])
            .subscribe();

        DeliveryCompanyRepository.get("", deliveryCompanyID).then(function(data) {
            cvm.company = data;
            $scope.$emit('selectItem', { company: cvm.company, item: 'priceplans' } );
        });

        DeliveryCompanyRepository.getList('/' + deliveryCompanyID + '/priceplans?with=deliverypriceplanspans').then(function(data) {
            cvm.priceplans = data;

            $scope.$emit('setLoading', false);
        });
    }

    cvm.createPriceplan = function() {
        var modalOptions = {
            textInputShow: true,
            textInputLabel: translateService.instant('delivery.priceplan.name'),
            closeButtonText: translateService.instant('general.cancel'),
            actionButtonText: translateService.instant('general.create'),
            actionButtonClass: 'btn-success',
            headerText: translateService.instant('delivery.priceplan.create_priceplan_heading'),
            bodyText: ''
        };

        modalService.showModal({}, modalOptions).then(function (result) {
            if(result && result !== true) {
                DeliveryPriceplanRepository.create({ priceplanName: result, deliveryCompanyID: cvm.company.deliveryCompanyID }).then(function(obj) {
                    obj.deliverypriceplanspans = [];
                    cvm.priceplans.push(obj);
                });
            }
        });
    };

    activate();
    return cvm;
}
