import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { flatMap, map, startWith, switchMap, tap, finalize, filter } from "rxjs/operators";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { BehaviorSubject ,  Observable } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { Article, ArticleBody, ArticleService, ArticleBodyStorage } from "@ro-ngx/articles";
import { TranslateService } from "@ngx-translate/core";
import { NotificationStorage, DialogService, FormManager } from "@ro-ngx/core";
import { ArticleListConfig } from "../../article-list-config";

@Component({
    selector: 'article-body',
    template: require('./article-body.component.html'),
    styles: [
        require('./article-body.component.less')
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ArticleBodyComponent implements OnInit {

    public isLoading$: BehaviorSubject<boolean>;
    public isCreating$: BehaviorSubject<boolean>;
    public isSaving$: BehaviorSubject<boolean>;
    public isDeleting$: BehaviorSubject<boolean>;
    public isMissing$: BehaviorSubject<boolean>;

    public locale: FormControl;
    public formGroup: FormGroup;
    public formManager: FormManager;
    public articleID: number;
    public article: Article;

    public locale$: Observable<string>;
    public language$: Observable<string>;
    public articleID$: Observable<number>;
    public articleBodies$: Observable<ArticleBody[]>;
    public articleBody$: Observable<ArticleBody>;

    public supportedLocales: string[];

    constructor(
        protected notificationStorage: NotificationStorage,
        protected articleBodyStorage: ArticleBodyStorage,
        protected articleListConfig: ArticleListConfig,
        protected translateService: TranslateService,
        protected articleService: ArticleService,
        protected activatedRoute: ActivatedRoute,
        protected dialogService: DialogService,
        protected formBuilder: FormBuilder) {
        this.isLoading$ = new BehaviorSubject(false);
        this.isCreating$ = new BehaviorSubject(false);
        this.isSaving$ = new BehaviorSubject(false);
        this.isDeleting$ = new BehaviorSubject(false);
        this.isMissing$ = new BehaviorSubject(false);

        this.formGroup = this.createFormGroup();
        this.formManager = new FormManager(this.formGroup as any);
        this.supportedLocales = this.articleListConfig.supportedLocales;
        this.locale = new FormControl(this.supportedLocales[0]);
    }

    public ngOnInit(): void {
        this.articleID$ = this.activatedRoute.params
            .pipe(map((params) => +params['articleID']))
            .pipe(tap((articleID) => this.articleID = articleID));

        this.getArticleBodies()
            .subscribe((articleBodies) => this.articleBodyStorage.unionSubject(articleBodies, 'articleBodyID'));

        this.articleBodies$ = this.articleID$.pipe(
            switchMap((articleID) => this.articleBodyStorage.articleBody$.pipe(
                map((articleBodies) => articleBodies.filter((articleBody) => articleBody.articleID === articleID))
            ))
        );

        this.locale$ = this.locale.valueChanges.pipe(startWith(this.locale.value));
        this.language$ = this.locale$.pipe(map((locale) => this.translateService.instant('general.locale.' + locale)));
        this.articleBody$ = this.locale$.pipe(
            switchMap((locale) => this.articleBodies$.pipe(
                map((articleBodies) => articleBodies.find((articleBody) => articleBody.locale === locale))
            ))
        );

        this.articleBody$.subscribe((articleBody) => {
            const isMissing = !articleBody;
            this.isMissing$.next(isMissing);
            this.formGroup.patchValue(articleBody || this.getDefaultFormValues());
        });
    }

    public create(): void {
        const values = this.formGroup.value;
        this.isCreating$.next(true);
        this.articleBodyStorage.createArticleBody(values)
            .pipe(finalize(() => this.isCreating$.next(false)))
            .subscribe(() => {
                this.notificationStorage.success(
                    this.translateService.instant('article-list.article_body.created_language_version', {
                        language: this.translateService.instant('general.locale.' + values.locale)
                    }) + '!'
                );
            });
    }

    public save(): void {
        if (!this.formGroup.valid) {
            return;
        }

        const values = this.formGroup.value;
        this.isSaving$.next(true);
        this.articleBodyStorage.updateArticleBody(values.articleBodyID, values)
            .pipe(finalize(() => this.isSaving$.next(false)))
            .subscribe(() => {
                this.notificationStorage.success(
                    this.translateService.instant('article-list.article_body.saved_language', {
                        language: this.translateService.instant('general.locale.' + values.locale)
                    }) + '!'
                );
            });
    }

    public delete(): void {
        this.dialogService.confirm({
                title: this.translateService.instant('article-list.article_body.dialog_delete_title', {
                    language: this.translateService.instant('general.locale.' + this.locale.value)
                }),
                body: this.translateService.instant('article-list.article_body.dialog_delete_description', {
                    language: this.translateService.instant('general.locale.' + this.locale.value)
                })
            })
            .pipe(filter((confirmed) => confirmed))
            .subscribe(() => {
                const values = this.formGroup.value;
                this.isDeleting$.next(true);
                this.articleBodyStorage.deleteArticleBody(values.articleBodyID)
                    .pipe(finalize(() => this.isDeleting$.next(false)))
                    .subscribe(() => {
                        this.formGroup.patchValue(this.getDefaultFormValues());
                        this.notificationStorage.success(
                            this.translateService.instant('article-list.article_body.deleted_language', {
                                language: this.translateService.instant('general.locale.' + values.locale)
                            }) + '!'
                        );
                    });
            });
    }

    protected getArticleBodies(): Observable<ArticleBody[]> {
        return this.articleID$
            .pipe(
                tap(() => this.isLoading$.next(true)),
                flatMap((articleID) => this.articleService.getArticle(articleID, {
                    includes: ['articleBodies']
                })),
                tap(() => this.isLoading$.next(false)),
                tap((article) => this.article = article),
                map((article) => article.articleBodies)
            );
    }

    protected getDefaultFormValues(): any {
        return {
            articleID: this.articleID,
            articleBodyID: null,
            locale: this.locale.value,
            title: this.translateService.instant('article-list.article_body.create.title', {
                language: this.translateService.instant('general.locale.' + this.locale.value)
            }),
            body: this.translateService.instant('article-list.article_body.create.body', {
                language: this.translateService.instant('general.locale.' + this.locale.value)
            })
        };
    }

    protected createFormGroup(): FormGroup {
        return this.formBuilder.group({
            articleID: [null],
            articleBodyID: [null],
            locale: [''],
            title: ['', Validators.required],
            body: ['']
        });
    }
}
