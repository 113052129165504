
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BaseService } from '../../base/base.service';
import { HungrigClient, ResponseFactory, ErrorFactory } from '@ro-ngx/core';
import { Observable } from 'rxjs';
import { Company } from './company';
import { isUndefined } from 'lodash';
import { RequestOptionsArgs, URLSearchParams } from '@angular/http';

@Injectable()
export class CompanyService extends BaseService {

    public resourceUrl: string = 'delivery/companies';

    constructor(public hungrigClient: HungrigClient) {
        super(hungrigClient);
    }

    public getCompanyList(deliveryCompanyIDs?: number[]): Observable<Company[]> {
        const params: URLSearchParams = new URLSearchParams();

        if (!isUndefined(deliveryCompanyIDs)) {
            params.set('deliveryCompanyID', deliveryCompanyIDs.join(','));
        }

        return this.hungrigClient.get(this.url(), { search: params } as any).pipe(
            map(ResponseFactory.toData),
           catchError(ErrorFactory.throwObservable));
    }

    public getCompanies(perPage: number, page: number, search: string): Observable<Company[]> {
        return this.hungrigClient.get(this.url(`/paginate`), { search: { perPage, page, search }}).pipe(
            map(ResponseFactory.toData),
           catchError(ErrorFactory.throwObservable));
    }

    public getCompanyTemplates(deliveryCompanyID: number): Observable<any> {
        return this.hungrigClient.get(this.url(`/${deliveryCompanyID}/templates`)).pipe(
            map(ResponseFactory.toData),
           catchError(ErrorFactory.throwObservable));
    }

    public getAcceptTimeAlertCompanies(): Observable<Company[]> {
        return this.hungrigClient.get(this.url('/accepttimealert')).pipe(
            map(ResponseFactory.toData),
           catchError(ErrorFactory.throwObservable));
    }
}
