angular.module( 'cmsApp.user', [
    'ui.router'
])
.config(UserCtrlConfig)
.controller('UserCtrl', UserCtrl);

function UserCtrlConfig( $stateProvider ) {

}

function UserCtrl( $scope, modalService, UserRepository ) {
    /* jshint validthis: true */
    var vm = this;

    vm.isLoggedIn = false;
    vm.submitted = false;
    vm.user = {};
    vm.formUser = {};

    activate();

    $scope.$on('loginSuccess', function(event, data) {
        vm.user = UserRepository.getUser();
        vm.formUser = angular.copy(vm.user);
    });

    ////////////////////

    function activate() {
        vm.isLoggedIn = UserRepository.isLoggedIn();
        vm.user = UserRepository.getUser();
        vm.formUser = angular.copy(vm.user);
    }


    return vm;
}