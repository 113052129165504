import { addSeconds, isAfter } from 'date-fns';

const SECONDS_BETWEEN_BEEPS = 4;
const WARNING_SOUND = '/assets/sounds/warning-sound.wav';

export class BeepingService {

    protected beepedAt: Date;

    public beep(): void {
        if ( ! this.haveBeepedRecently()) {
            this.beepedAt = new Date();
            this.playAudio();
        }
    }

    protected haveBeepedRecently(): boolean {
        if (this.beepedAt) {
            return isAfter(new Date(), addSeconds(this.beepedAt, SECONDS_BETWEEN_BEEPS));
        }

        return false;
    }

    protected playAudio(): void {
        this.createAudio().play().then();
    }

    protected createAudio(): HTMLAudioElement {
        return new Audio(WARNING_SOUND);
    }
}
