
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BaseService } from '../../base';
import { ErrorFactory, HungrigClient, ResponseFactory } from '@ro-ngx/core';
import { Observable } from 'rxjs';
import { DeliveryDistancePrice } from './delivery-distance-price';
import { ParamsUtil } from '@ro-ngx/core';

@Injectable()
export class DeliveryDistancePriceService extends BaseService {

    constructor(public hungrigClient: HungrigClient) {
        super(hungrigClient);
    }

    public getDistancePrices(relations: string[] = []): Observable<DeliveryDistancePrice[]> {
        return this.hungrigClient.get(this.url('rocms/deliverydistanceprices'), {
                params: ParamsUtil.toHttpParams({ with: relations })
            }).pipe(
            map(ResponseFactory.toData),
            catchError(ErrorFactory.throwObservable));
    }
}
