
import {map} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { NotificationStorage } from '@ro-ngx/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SafeHtml } from '@angular/platform-browser';
import { SvgCollection } from '../../global/svg/svg-collection';
import { Svg } from '../../global/svg/svg';
import { AuthenticationService, AuthenticationTokenStorage } from '@ro-ngx/authentication'

@Component({
    selector: 'login',
    template: require('./login.component.html')
})
export class LoginComponent implements OnInit {
    public form: FormGroup;
    public error: string;
    public loading: boolean = false;
    public brandLogo: SafeHtml;
    protected redirectTo: string;

    public constructor(
        protected authenticationService: AuthenticationService,
        protected auth: AuthenticationTokenStorage,
        protected formBuilder: FormBuilder,
        protected notificationStorage: NotificationStorage,
        protected router: Router,
        protected route: ActivatedRoute,
        protected translateService: TranslateService,
        protected svgCollection: SvgCollection
    ) {
        this.brandLogo = this.svgCollection.getSanitized(Svg.BRAND_LOGO);
    }

    public ngOnInit(): void {
        this.form = this.formBuilder.group({
            username: [null, Validators.required],
            password: [null, Validators.required]
        });

        this.route.queryParams.pipe(
            map((params) => params['url'] || null))
            .subscribe((url: string) => this.redirectTo = url);
    }

    public submit(): void {
        this.error = '';

        this.markFormAsTouched();

        if (! this.form.valid) {
            this.error = this.translateService.instant('validation.invalid_form');
            return;
        }

        this.login();
    }

    protected markFormAsTouched(): void {
        for (const name in this.form.controls) {
            const control = this.form.controls[name];
            if (control instanceof FormControl) {
                control.markAsTouched();
            }
        }
    }

    protected login(): void {
        this.loading = true;
        this.authenticationService.authenticate({
                email: this.form.controls.username.value,
                password: this.form.controls.password.value,
            })
            .subscribe(
                () => {
                    this.notificationStorage.success(this.translateService.instant('login.login_success'));
                    this.router.navigate([this.redirectTo || '/']).then(() => console.log('Navigate complete'));
                    this.loading = false;
                },
                () => {
                    this.error = this.translateService.instant('login.invalid_credentials');
                    this.loading = false;
                }
            );
    }
}
