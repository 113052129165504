
import {catchError, map} from 'rxjs/operators';
import { BaseService } from "../base/base.service";
import { Injectable } from "@angular/core";
import { ErrorFactory, HungrigClient, Paginate, PaginateFactory, ResponseFactory, Response } from "@ro-ngx/core";
import { Observable } from 'rxjs';
import { RestaurantFraudSettings, RestaurantFinanceSettings } from './';
import { RestaurantManagerSettings } from './restaurant-manager-settings';
import { RestaurantOnlinestatus } from './restaurant-onlinestatus';
import { Restaurant } from './restaurant';
import { RestaurantMeta } from './restaurant-meta';
import { RestaurantAdwordsSettings } from './restaurant-adwords-settings';
import { URLSearchParams } from "@angular/http";
import { RestaurantContact } from './restaurant-contact';

@Injectable()
export class RestaurantService extends BaseService {

    public resourceUrl: string = 'cms/restaurants/';

    constructor(public hungrigClient: HungrigClient) {
        super(hungrigClient);
    }

    public getRestaurants(
        page: number = 1, filters?: {[key: string]: string}
    ): Observable<Paginate<Restaurant[]>> {
        const search = new URLSearchParams();
        search.set('page', page.toString());

        if (filters) {
            for (const filter in filters) {
                search.set(filter, filters[filter]);
            }
        }

        return this.hungrigClient.get(this.url(), { search }).pipe(
            map(PaginateFactory.from),
            catchError(ErrorFactory.throwObservable));
    }

    public getRestaurant(clientKey: string): Observable<Restaurant> {
        return this.hungrigClient.get(this.url(clientKey)).pipe(
            map(this.toResponse),
            map((response) => response.data),
            catchError(this.handleError));
    }

    public getRestaurantsSlim(): Observable<Restaurant[]> {
        return this.hungrigClient.get(this.url('list')).pipe(
            map(ResponseFactory.toData),
            catchError (ErrorFactory.throwObservable));
    }

    public updateRestaurant(clientKey: string, restaurant: Partial<Restaurant>): Observable<Restaurant> {
        return this.hungrigClient.put(this.url(clientKey), restaurant).pipe(
            map(ResponseFactory.toData),
            catchError (ErrorFactory.throwObservable));
    }

    public createRestaurant(restaurantName: string): Observable<Restaurant> {
        const countryCode = 'se'
        return this.hungrigClient.post(this.url(), { restaurantName, countryCode }).pipe(
            map(ResponseFactory.toData),
            catchError (ErrorFactory.throwObservable));
    }

    public getLocations(): Observable<any[]> {
        return this.hungrigClient.get(this.url('locations')).pipe(
            map(this.toResponse),
            map((response) => response.data),
            catchError(this.handleError));
    }

    public getRestaurantLoginUrl(clientKey: string, legacy: boolean = false): Observable<string> {
        const search: URLSearchParams = new URLSearchParams();
        if (legacy) {
            search.append('legacy', '1');
        }
        return this.hungrigClient.get(this.url('rocms/auth/url/' + clientKey, false), { search }).pipe(
            map(ResponseFactory.toData),
            catchError(ErrorFactory.throwObservable));
    }

    public getRestaurantMeta(clientKey: string, generate?: boolean): Observable<RestaurantMeta> {
        const search: URLSearchParams = new URLSearchParams();
        if (generate !== undefined) {
            search.set('generate', generate ? '1' : '0');
        }
        return this.hungrigClient.get(this.url(`${clientKey}/meta`), { search }).pipe(
            map(ResponseFactory.toData),
            catchError(ErrorFactory.throwObservable));
    }

    public updateRestaurantMeta(
        clientKey: string, meta: Partial<RestaurantMeta>, generateAdwords: boolean = false
    ): Observable<RestaurantMeta> {
        const search: URLSearchParams = new URLSearchParams();
        search.set('generateAdwords', generateAdwords ? '1' : '0');
        return this.hungrigClient.put(this.url(`${clientKey}/meta`), meta, { search }).pipe(
            map(this.toResponse),
            map((response) => response.data),
            catchError(ErrorFactory.throwObservable));
    }

    public getRestaurantFraudSettings(clientKey: string): Observable<RestaurantFraudSettings> {
        return this.hungrigClient.get(this.url(clientKey + '/fraudsettings')).pipe(
            map(this.toResponse),
            map((response) => response.data),
            catchError(this.handleError));
    }

    public updateRestaurantFraudSettings(
        clientKey: string, settings: Partial<RestaurantFraudSettings>
    ): Observable<RestaurantFraudSettings> {
        return this.hungrigClient.put(this.url(clientKey + '/fraudsettings'), settings).pipe(
            map(this.toResponse),
            map((response) => response.data),
            catchError(this.handleError));
    }

    public getRestaurantFinanceSettings(clientKey: string): Observable<RestaurantFinanceSettings> {
        return this.hungrigClient.get(this.url(clientKey + '/financesettings')).pipe(
            map(this.toResponse),
            map((response) => response.data),
            catchError(this.handleError));
    }

    public updateRestaurantFinanceSettings(
        clientKey: string, settings: Partial<RestaurantFinanceSettings>
    ): Observable<RestaurantFinanceSettings> {
        return this.hungrigClient.put(this.url(clientKey + '/financesettings'), settings).pipe(
            map(this.toResponse),
            map((response) => response.data),
            catchError(this.handleError));
    }

    public getRestaurantManagerSettings(clientKey: string): Observable<RestaurantManagerSettings> {
        return this.hungrigClient.get(this.url(clientKey + '/managersettings')).pipe(
            map(this.toResponse),
            map((response) => response.data),
            catchError(this.handleError));
    }

    public updateRestaurantManagerSettings(
        clientKey: string, settings: Partial<RestaurantManagerSettings>
    ): Observable<RestaurantManagerSettings> {
        return this.hungrigClient.put(this.url(clientKey + '/managersettings'), settings).pipe(
            map(this.toResponse),
            map((response) => response.data),
            catchError(this.handleError));
    }

    public getRestaurantOnlinestatus(clientKey: string): Observable<RestaurantOnlinestatus> {
        return this.hungrigClient.get(this.url(clientKey + '/onlinestatus')).pipe(
            map(this.toResponse),
            map((response) => response.data),
            catchError(this.handleError));
    }

    public updateRestaurantOnlinestatus(
        clientKey: string, settings: Partial<RestaurantOnlinestatus>
    ): Observable<RestaurantOnlinestatus> {
        return this.hungrigClient.put(this.url(clientKey + '/onlinestatus'), settings).pipe(
            map(this.toResponse),
            map((response) => response.data),
            catchError(this.handleError));
    }

    public getRestaurantAdwordsSettings(
        clientKey: string, generate: boolean = false
    ): Observable<RestaurantAdwordsSettings> {
        const search: URLSearchParams = new URLSearchParams();
        if (generate) {
            search.set('generate', '1');
        }
        return this.hungrigClient.get(this.url(`${clientKey}/adwords`), { search }).pipe(
            map(ResponseFactory.toData),
            catchError(ErrorFactory.throwObservable));
    }

    public updateRestaurantAdwordsSettings(
        clientKey: string, settings: Partial<RestaurantAdwordsSettings>
    ): Observable<RestaurantAdwordsSettings> {
        return this.hungrigClient.put(this.url(`${clientKey}/adwords`), settings).pipe(
            map(this.toResponse),
            map((response) => response.data),
            catchError(this.handleError));
    }

    public getRestaurantContact(clientKey: string): Observable<RestaurantContact> {
        return this.hungrigClient.get(this.url(clientKey + '/contact')).pipe(
            map(this.toResponse),
            map((response) => response.data),
            catchError(this.handleError));
    }

    public updateRestaurantContact(clientKey: string,
            contact: Partial<RestaurantContact>): Observable<RestaurantContact> {
        return this.hungrigClient.put(this.url(`${clientKey}/contact`), contact).pipe(
            map(this.toResponse),
            map((response) => response.data),
            catchError(this.handleError));
    }

    public setOnline(clientKey: string, online: boolean): Observable<RestaurantOnlinestatus> {
        return this.hungrigClient.put(this.url(`/rocms/status/setOnline/${clientKey}`, false), { online }).pipe(
            map(ResponseFactory.toData),
            catchError(ErrorFactory.throwObservable));
    }
}
