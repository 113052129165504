import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    forwardRef,
    OnInit,
    ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import * as SimpleMDE from "simplemde";
import { BehaviorSubject } from "rxjs";

export type TouchListener = () => void;
export type ChangeListener = (_: any) => void;

@Component({
    selector: 'textarea-markdown',
    template: `<textarea #markdown></textarea>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => TextareaMarkdownComponent),
        multi: true
    }]
})
export class TextareaMarkdownComponent implements OnInit, AfterViewInit, ControlValueAccessor {
    @ViewChild('markdown') public container: ElementRef;
    public value$: BehaviorSubject<string>;
    public value: string;
    public disabled: boolean;
    protected onChangeListener: ChangeListener[] = [];
    protected onTouchListener: TouchListener[] = [];
    protected simpleMDE: SimpleMDE;

    constructor() {
        this.value$ = new BehaviorSubject('');
    }

    public ngOnInit(): void {
        this.value$.subscribe((value) => this.value = value);
    }

    public ngAfterViewInit(): void {
        this.simpleMDE = new SimpleMDE({
            element: this.container.nativeElement,
            spellChecker: false,
            hideIcons: [
                'side-by-side',
                'fullscreen'
            ]
        });

        this.value$.subscribe((value) => this.simpleMDE.value(value || ''));
        this.simpleMDE.codemirror.on('change', () => {
            this.value = this.simpleMDE.value();
            this.onChange();
        });
    }

    public setDisabledState(disabled: boolean): void {
        this.disabled = disabled;
    }

    public registerOnChange(fn: any): void {
        this.onChangeListener.push(fn);
    }

    public registerOnTouched(fn: any): void {
        this.onTouchListener.push(fn);
    }

    public onTouch(): void {
        this.onTouchListener.forEach((listener) => listener());
    }

    public onChange(): void {
        this.onChangeListener.forEach((listener) => listener(this.value));
    }

    public writeValue(obj: any): void {
        this.value$.next(obj);
    }

}
