angular.module( 'cmsApp.delivery.message.factory', [
    'ui.router',
    'ui.bootstrap'
])

.factory('DeliveryMessageRepository', function (Restangular, AbstractRepository) {

    function DeliveryMessageRepository() {
        AbstractRepository.call(this, Restangular, 'delivery/messages');
    }

    AbstractRepository.extend(DeliveryMessageRepository);

    return new DeliveryMessageRepository();
})

;
