import * as angular from 'angular';

import "app/appthemes/apptheme/appthemefont/appthemefont.directive";
import "app/appthemes/apptheme/appthemecolor/appthemecolor.directive";

var template = require('./appthemefontarea.tpl.html');

angular.module('cmsApp.appthemes.apptheme.fontarea', [
        'cmsApp.appthemes.apptheme.font',
        'cmsApp.appthemes.apptheme.color'
    ])
    .directive('roAppthemeFontArea', roAppthemeFontArea);

function roAppthemeFontArea() {
    return {
        restrict: 'EA',
        scope: {
            roAppthemeFontArea: '=',
            type: '=',
            size: '=',
            ahex: '=',
            rgba: '='
        },
        controller: function() { },
        controllerAs: 'vm',
        bindToController: true,
        template: template
    };
}

