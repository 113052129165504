
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BaseService } from "app/http/base/base.service";
import { HungrigClient } from "@ro-ngx/core";
import { URLSearchParams } from "@angular/http";
import { Observable } from 'rxjs';

@Injectable()
export class FinanceService extends BaseService {

    public resourceUrl: string = 'finance/delivery/driver';

    constructor(public hungrigClient: HungrigClient) {
        super(hungrigClient);
    }

    public getSummary(start: string, end: string): Observable<any> {
        const params = new URLSearchParams() as any;
        params.set('start', start);
        params.set('end', end);

        return this.hungrigClient.get(this.url(), { search: params }).pipe(
            map(this.toResponse),
            map((response) => response.data),);
    }
}
