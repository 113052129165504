angular.module( 'cmsApp.shared.cache.repository', [
])

.factory('CacheRepository', function (Restangular, AbstractRepository) {

    function CacheRepository() {
        AbstractRepository.call(this, Restangular, 'cache');
    }

    AbstractRepository.extend(CacheRepository);

    return new CacheRepository();
})

;

