import * as angular from 'angular';
import { format, isAfter, subMinutes } from 'date-fns';
import { DeliveryOrderRepository } from '../../deliveryorder.factory';
import { NotificationInstance, NotificationStorage } from '@ro-ngx/core';
import { TranslateService } from '@ngx-translate/core';

export class DeliveryFleetComponent {
    public static readonly controller = DeliveryFleetComponent;
    public static readonly controllerAs = 'vm';
    public static readonly template = require('./delivery-fleet.component.html');
    public static readonly bindings = {
        order: '<',
        whenGenerated: '&',
    };

    public order: any;
    public whenGenerated: (...any) => any;
    public loader: NotificationInstance;
    public states = {
        NOT_GENERATED: 'not_generated',
        GENERATED: 'generated',
        GENERATING: 'generating',
    };

    constructor(
        protected deliveryOrderRepository: DeliveryOrderRepository,
        protected notificationStorage: NotificationStorage,
        protected translateService: TranslateService
    ) {}

    public getState() {
        if (this.order.fleetID) {
            return this.states.GENERATED;
        }

        if (this.loader) {
            return this.states.GENERATING;
        }

        if (isAfter(new Date(this.order.createLine), subMinutes(new Date(), 1))) {
            return this.states.GENERATING;
        }

        return this.states.NOT_GENERATED;
    }

    public generateFleetOrder() {
        this.loader = this.notificationStorage.loading(
            this.translateService.instant('deliveryfinance.delivery_fleet.generating'),
            this.translateService.instant('deliveryfinance.delivery_fleet.generating_text'),
        );

        this.deliveryOrderRepository.generateFleetOrder(this.order.deliveryOrderID)
            .then(() => {
                this.notificationStorage.dismiss(this.loader);
                this.notificationStorage.success(
                    this.translateService.instant('deliveryfinance.delivery_fleet.generated'),
                    this.translateService.instant('deliveryfinance.delivery_fleet.generated_text')
                );
                this.loader = undefined;
                this.whenGenerated({ $value: this.order });
            }, (error) => {
                this.notificationStorage.dismiss(this.loader);
                this.notificationStorage.danger(error.data.error_message);
                this.loader = undefined;
                this.whenGenerated({ $value: this.order });
            })
    }

}

