import * as angular from 'angular';

export interface PredefinedReplacement {
    searchFor: string;
    replaceWith: string;
}

export interface TruncatedText {
    [key: string]: string;
}

class SearchSupportService {

    protected predefinedReplacements: PredefinedReplacement[] = [
        { searchFor: 'restaurang', replaceWith: '' },
        { searchFor: 'Grönt o Gott', replaceWith: 'GoG' },
        { searchFor: 'Grönt o\' Gott', replaceWith: 'GoG' },
        { searchFor: 'Grönt o´ gott', replaceWith: 'GoG' },
        { searchFor: 'Pizza Hut', replaceWith: 'PH' },
        { searchFor: 'Salads And Smoothies', replaceWith: 'SaS' },
        { searchFor: 'V.E.S.P.A', replaceWith: 'Ves' },
        { searchFor: 'La Pizza', replaceWith: 'Lapizz' },
        { searchFor: 'I Chill Out Sushi', replaceWith: 'COu' },
    ];

    protected truncatedTexts: TruncatedText = {};

    public truncateText(originalText) {
        if (this.truncatedTexts[originalText]) {
            return this.truncatedTexts[originalText];
        }

        let text = originalText;
        let textParts = this.replacePredefinedReplacements(text)
            .split(' ');

        if(textParts.length > 1 && text.length > 6) {
            let part1 = textParts[0].substr(0, 3).trim();
            let part2length = 6 - part1.length;
            let middle = '';
            let part2 = textParts[textParts.length - 1].substr(0, part2length).trim();

            // if part 2 is not long enough (perhaps it's just a digit) then include the penultimate.
            if(part2.length < 3 && (textParts.length - 2) !== 0) {
                middle = textParts[textParts.length - 2].substr(0, 3 - part2.length).trim();
            }

            text = part1 + middle + part2;
        } else {
            text = text.substr(0, 6);
        }

        this.truncatedTexts[originalText] = text;
        return text;
    }

    protected replacePredefinedReplacements(text) {
        this.predefinedReplacements.forEach((replacement) => {
            text = text.replace(new RegExp(replacement.searchFor, 'gi'), replacement.replaceWith);
        });

        return text.trim();
    }
}

angular.module('common.SearchSupportService', [])
    .service('SearchSupportService', SearchSupportService);
