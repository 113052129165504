import * as angular from 'angular';

import 'common/modalService/modalService';
import 'app/delivery/financesettings/deliveryfinancesettings.factory';
import 'app/delivery/financesettings/deliveryfinancecustom.factory';

import { find } from 'lodash';

angular.module( 'cmsApp.delivery.financesettings', [
    'ui.router',
    'ui.bootstrap',
    'common.modalService',
    'ui.select',
    'cmsApp.delivery.financesettings.factory',
    'cmsApp.delivery.financecustom.factory',
    'ngSanitize'
])
.config(DeliveryFinanceSettingsCtrlConfig)

// = = = FIX FOR ANGULAR UI / BOOTSTRAP INITIAL FORMATTING BUG
// See https://github.com/angular-ui/bootstrap/issues/2659
.directive('datepickerPopup', function (){
  return {
    restrict: 'EAC',
    require: 'ngModel',
    link: function(scope, element, attr, controller: any) {
      //remove the default formatter from the input directive to prevent conflict
      controller.$formatters.shift();
    }
  };
})
// // = = = END OF FIX FOR ANGULAR UI / BOOTSTRAP INITIAL FORMATTING BUG

.controller('DeliveryFinanceSettingsCtrl', DeliveryFinanceSettingsCtrl);

function DeliveryFinanceSettingsCtrlConfig( $stateProvider ) {

}

function DeliveryFinanceSettingsCtrl( $scope, $state, $q, modalService, DeliveryCompanyRepository,
    DeliveryFinanceSettingsRepository, DeliveryFinanceCustomRepository, DeliveryDistancePriceStorage, deliveryCompanyID, translateService, ENV ) {
    /* jshint validthis: true */
    var cvm = this;

    var ustatus = {};

    cvm.company = {};

    cvm.financesettings = {};

    cvm.financecustoms = [];

    cvm.dateOptions = {
        formatYear: 'yy',
        startingDay: 1
    };

    cvm.companyPriceTypeOptions = {
        0: translateService.instant('delivery.finance.fixed_price'),
        1: translateService.instant('delivery.finance.price_per_kilometer')
    };

    if (ENV.APP_COUNTRY === 'SE') {
        cvm.companyPriceTypeOptions[2] = 'CabOnline';
    }

    cvm.companyPriceTypeOptions[3] = translateService.instant('delivery.finance.distance_prices');

    cvm.distancePrices = [];
    cvm.distancePricesSubscription = undefined;
    ////////////////////

    cvm.activate = function () {
        DeliveryCompanyRepository.get('/' + deliveryCompanyID, '').then(function (data) {
            cvm.company = data;

            DeliveryCompanyRepository.get('/' + deliveryCompanyID + '/financesettings', '').then(function (data) {
                data.active = data.active == 1;
                cvm.financesettings = data;

                cvm.distancePricesSubscription = DeliveryDistancePriceStorage.getDistancePrices()
                    .subscribe((distancePrices) => {
                        cvm.distancePrices = distancePrices;
                        if (cvm.financesettings.finalizePriceType == 3) {
                            cvm.deliveryDistancePrice = find(cvm.distancePrices, {
                                dDistancePriceID: cvm.financesettings.dDistancePriceID
                            });
                        }
                    })

                DeliveryCompanyRepository.get('/' + deliveryCompanyID + '/financecustom', '').then(function (data) {

                    angular.forEach(data, function(custom, key){
                        custom = cvm.returnOere(custom, true);
                        custom.dateOptions = { open: false };
                        custom.date = new Date(custom.date);
                        cvm.financecustoms.push(custom);
                    });

                    $scope.$emit('setLoading', false);
                });
            });

            $scope.$emit('selectItem', { company: cvm.company, item: 'financesettings' } );
        });
    };

    cvm.save = function() {
        $scope.$emit('showNotification', {
            id: 'dFinanceSettingsSave',
            loading: true,
            alertType: 'alert-info',
            title: translateService.instant('general.loading')
        });

        var promises = [];

        promises.push(DeliveryFinanceSettingsRepository.update(cvm.financesettings, cvm.financesettings.financeSettingsID));

        angular.forEach(cvm.financecustoms, function(custom, key) {
            custom = angular.copy(custom);
            //custom.date = custom.date.toLocaleDateString('sv-SE');
            custom.date = custom.date.toISOString().substr(0, "2011-10-05".length);
            promises.push(DeliveryFinanceCustomRepository.update(cvm.returnOere(custom), custom.deliveryFinanceCustomID));
        });

        $q.all(promises).then(function () {
            $scope.$emit('showNotification', {
                id: 'dFinanceSettingsSave',
                loading: false,
                alertType: 'alert-success',
                title: translateService.instant('general.saved')
            });
        });
    };

    cvm.calcTotal = function(custom) {
        custom.totalAmount = ((parseFloat(custom.amount) * 100 || 0) + (parseFloat(custom.amountTax) * 100 || 0)) / 100 || 0;
        return custom.totalAmount;
    };

    cvm.calcTax = function(custom) {
        if(custom.useAutoTax) {
            custom.amountTax = custom.amount / 4;
        }
    };

    cvm.returnOere = function(custom, reverse) {
        custom = angular.copy(custom);

        if(reverse)
        {
            custom.amount       = custom.amount / 100;
            custom.amountTax    = custom.amountTax / 100;
            custom.totalAmount  = custom.totalAmount / 100;
        }
        else
        {
            custom.amount       = custom.amount * 100;
            custom.amountTax    = custom.amountTax * 100;
            custom.totalAmount  = custom.totalAmount * 100;
        }

        return custom;
    };

    cvm.selectDeliveryDistancePrice = function(deliveryDistancePrice) {
        cvm.deliveryDistancePrice = deliveryDistancePrice;
        cvm.financesettings.dDistancePriceID = deliveryDistancePrice ? deliveryDistancePrice.dDistancePriceID : null;
    };

    cvm.createCustom = function() {
        var modalOptions = {
            textInputShow: true,
            textInputLabel: translateService.instant('delivery.finance.description'),
            closeButtonText: translateService.instant('general.cancel'),
            actionButtonText: translateService.instant('general.create'),
            actionButtonClass: 'btn-success',
            headerText: translateService.instant('delivery.finance.add_custom_heading'),
            bodyText: ''
        };

        modalService.showModal({}, modalOptions).then(function (result) {
            if(result && result !== true) {
                DeliveryFinanceCustomRepository.create({ description: result, deliveryCompanyID: cvm.company.deliveryCompanyID }).then(function(custom) {
                    custom.dateOptions = { open: false };
                    custom.date = new Date();
                    cvm.financecustoms.push(cvm.returnOere(custom, true));
                });
            }
        });
    };

    cvm.deleteCustom = function(custom) {
        var modalOptions = {
            closeButtonText: translateService.instant('general.cancel'),
            actionButtonText: translateService.instant('general.delete'),
            actionButtonClass: 'btn-danger',
            headerText: translateService.instant('delivery.finance.confirm_delete_short'),
            bodyText: translateService.instant('delivery.finance.confirm_delete')
        };

        modalService.showModal({}, modalOptions).then(function (result) {
            if(result) {
                var index = cvm.financecustoms.indexOf(custom);
                if(index > -1) {
                    DeliveryFinanceCustomRepository.remove(custom.deliveryFinanceCustomID);
                    cvm.financecustoms.splice(index, 1);
                }
            }
        });
    };

    cvm.toggleDatepicker = function($event, value) {
        $event.preventDefault();
        $event.stopPropagation();

        value.open = !value.open;
    };

    cvm.activate();
    return cvm;
}