import { Component, OnDestroy, OnInit } from '@angular/core';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { Subscription } from 'rxjs';
import { ImageLibraryStateStorage } from '../image-library-state.storage';
import { ImageLibraryStateMode } from '../image-library-state-mode';
import { Upload } from '../http/upload/upload';
import { UploadStorage } from '../http/upload/upload.storage';
import { ImageService } from '../http/image.service';
import { ImageType } from '../http/image-type';
import { HungrigClient, Response, SubscriptionCollection } from '@ro-ngx/core';

@Component({
    selector: 'image-upload',
    template: `
        <div class="row">
            <div class="col-md-8">
                <div class="row">
                    <loading-linear [loadingIf]="loading['uploads'] && uploads.length < 1" ></loading-linear>
                    <div class="col-md-3" *ngFor="let upload of uploads">
                        <div href
                             #uploadElement
                             [style.height.px]="uploadElement.offsetWidth"
                             (click)="selectUpload(upload)" class="thumbnail" [ngClass]="{'selected': selectedUpload?.imageUploadID === upload.imageUploadID}">
                            <img src="" [src]="upload.publicPath" style="max-width: 100%;">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="row">
                    <div class="col-xs-12">
                        <div class="form-group">
                            <button class="btn btn-block btn-success" (click)="cropImage(selectedUpload)" [disabled]="! selectedUpload">{{ 'image_library.choose_image' | translate }}</button>
                            <button-loadable (tryClick)="deleteUpload(selectedUpload)" [loading]="loading['delete']" [btnClass]="'btn-block btn-danger'" [disabled]="! selectedUpload" class="btn-block">
                                {{ 'image_library.delete_image' | translate }}
                            </button-loadable>
                        </div>
                    </div>
                </div>
                <div ng2FileDrop
                     [uploader]="uploader"
                     class="my-drop-zone text-center">
                    <label for="file-upload" class="btn btn-success">
                        <i class="fa fa-plus-circle" aria-hidden="true"></i> {{ 'image_library.add_images' | translate }}
                    </label>
                    <input id="file-upload" ng2FileSelect type="file" [uploader]="uploader" multiple>
                    <p>
                        {{ 'image_library.drop_images_to_upload' | translate }}
                    </p>
                </div>
                <div class="row queue-row">
                    <div class="col-xs-12" *ngFor="let item of uploader.queue">
                        <div class="upload-item-wrapper">
                            <p class="upload-item-title">{{ item.file.name }}</p>
                            <div class="progress">
                                <div class="progress-bar"
                                     [class.progress-bar-success]="! item.isError"
                                     [class.progress-bar-danger]="item.isError"
                                     role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100" 
                                        [style.width]="( ! item.isError ? item.progress + '%' : '100%')">
                                    <span *ngIf="item.isError">
                                        <span *ngIf="item.file.size > this.maxUploadSize">
                                            {{ 'image_library.image_to_large' | translate }}
                                        </span>
                                        <span *ngIf="item.file.size < this.maxUploadSize">
                                            Unknown error ({{ item._xhr.status }})
                                        </span>
                                    </span>
                                    <span *ngIf="! item.isError">
                                        {{ item.progress + '%' }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    `
})
export class ImageUploadComponent implements OnInit, OnDestroy {

    public loading: { [key: string]: boolean } = {
        'uploads': false,
        'delete': false,
        'uploading': false
    };

    public uploader: FileUploader;

    public uploads: Upload[];

    public selectedUpload: Upload;

    public maxUploadSize: number = 5242880;

    protected subscriptions: SubscriptionCollection;

    constructor(
        protected imageLibraryStateStorage: ImageLibraryStateStorage,
        protected uploadStorage: UploadStorage,
        protected hungrigClient: HungrigClient,
        protected imageService: ImageService) {
        this.subscriptions = new SubscriptionCollection();
    }

    public ngOnInit(): void {
        this.uploader = this.createFileUploader();

        this.subscriptions.set('libraryStateHandle', this.libraryStateHandle());

        this.uploader.onBeforeUploadItem = () => {
            this.uploader.options.headers = [
                { name: 'Authorization', value: this.getAccessToken()}
            ];

            this.loading['uploading'] = true;
        };

        this.uploader.onCompleteAll = () => {
            this.loading['uploading'] = false;
        };

        this.uploader.onSuccessItem = (item: FileItem, response: string) => {
            const hungrigResponse = new Response<Upload[]>(JSON.parse(response));

            this.uploadStorage.addUploads(
                hungrigResponse.toData()
            );
        };

        this.uploader.onErrorItem = (item: FileItem, response: string) => {
            console.log(item, response);
        };
    }

    /**
     * NgOnDestory
     */
    public ngOnDestroy(): void {
        this.subscriptions.unsubscribeAll();
    }

    /**
     * Select upload.
     *
     * @param upload
     */
    public selectUpload(upload: Upload): void {
        this.selectedUpload = upload;
    }

    /**
     * Goto crop view for upload image.
     *
     * @param upload
     */
    public cropImage(upload: Upload): void {
        this.imageLibraryStateStorage.changeMode(ImageLibraryStateMode.Crop, {
            upload: upload
        });
    }

    /**
     * Delete a upload.
     *
     * @param upload
     */
    public deleteUpload(upload: Upload): void {
        this.loading['delete'] = true;
        this.uploadStorage.deleteUpload(upload.imageUploadID)
            .subscribe(() => this.loading['delete'] = false);
    }

    /**
     * Upload a fileUploader.
     *
     * @returns {FileUploader}
     */
    protected createFileUploader(): FileUploader {
        const query = [
            'imageType=' + this.imageLibraryStateStorage.getImageType(),
            this.imageLibraryStateStorage.getClientKey() ? 'clientKey=' + this.imageLibraryStateStorage.getClientKey() : undefined
        ].filter((string) => string !== undefined).join('&');

        return new FileUploader({
            url: this.imageService.url('/upload?' + query),
            autoUpload: true,
            headers: []
        });
    }

    protected libraryStateHandle(): Subscription {
        return this.imageLibraryStateStorage.state$
            .subscribe((state) => {
                if (state.mode === ImageLibraryStateMode.Upload) {
                    this.subscriptions.set('uploadsHandle', this.uploadsHandle(state.imageType));
                }
            });
    }

    protected uploadsHandle(imageType: ImageType): Subscription {
        this.loading['uploads'] = true;
        this.uploadStorage.getUploads(imageType, this.imageLibraryStateStorage.getClientKey())
            .subscribe(() => this.loading['uploads'] = false);

        return this.uploadStorage.uploads$
            .subscribe((uploads) => this.uploads = uploads);
    }

    /**
     * Get AccessToken.
     *
     * @returns {string|null}
     */
    protected getAccessToken(): string {
        const headers = this.hungrigClient.requestOptions.headers;

        return headers.has('Authorization') ? headers.get('Authorization') : null;
    };
}
