import * as angular from 'angular';

import "app/alert/blacklist/modal/add-to-blacklist.controller";
import "app/alert/alerter-type/alerter-type.service";
import "app/delivery/company/deliverycompany.factory";
import "app/alert/blacklist/blacklist.service";
import "app/restaurant/restaurant.service";

var template = require('./blacklist.component.tpl.html');
var modalTemplate = require('./modal/add-to-blacklist.controller.tpl.html');

angular.module('cmsApp.alert.blacklist.component', [
        'cmsApp.alert.blacklist.modal.addtoblacklist.controller',
        'cmsApp.alert.alerter-type.service',
        'cmsApp.delivery.company.factory',
        'cmsApp.alert.blacklist.service',
        'cmsApp.restaurant.service'
    ])
    .component('roAlertBlacklist', {
        bindings: {},
        controller: AlertBlacklistComponent,
        controllerAs: 'vm',
        template: template
    });

function AlertBlacklistComponent($scope, $modal, $filter, AlertBlacklistService, RestaurantService, AlerterTypeService, DeliveryCompanyRepository, translateService) {
    var vm = this;

    vm.blacklist = [];

    vm.restaurants = [];
    vm.restaurantsByClientKey = {};

    vm.deliveryCompanies = [];
    vm.deliveryCompaniesByDeliveryCompanyID = {};

    vm.alertTypes = [];
    vm.alertTypesByAlertTypeID = {};

    vm.$onInit = function() {
        AlertBlacklistService.getList().then(function(response) {
            vm.blacklist = response.plain();
        });

        DeliveryCompanyRepository.getList().then(function(response) {
            vm.deliveryCompanies = response.plain();

            for (var index in vm.deliveryCompanies) {
                var deliveryCompany = vm.deliveryCompanies[index];

                vm.deliveryCompaniesByDeliveryCompanyID[deliveryCompany.deliveryCompanyID] = deliveryCompany;
            }
        });

        AlerterTypeService.getList().then(function(response) {
            vm.alertTypes = vm.filterInvalidAlerterTypes(response.plain());

            for (var index in vm.alertTypes) {
                var alertType = vm.alertTypes[index];

                vm.alertTypesByAlertTypeID[alertType.alerterTypeID] = alertType;
            }
        });

        RestaurantService.getList('/list').then(function(response) {
            vm.restaurants = response.plain();

            for (var index in vm.restaurants) {
                var restaurant = vm.restaurants[index];

                vm.restaurantsByClientKey[restaurant.clientKey] = restaurant;
            }
        });
    };

    vm.addToMutelistModal = function() {
        var modalInstance = $modal.open({
            template: modalTemplate,
            controller: 'AddToBlacklistController as vm',
            windowClass: 'ro-modal modalService',
            resolve: {
                restaurants: function() {
                    return vm.restaurants;
                },
                deliveryCompanies: function() {
                    return vm.deliveryCompanies;
                },
                alertTypes: function() {
                    return vm.alertTypes;
                }
            }
        });

        modalInstance.result.then(function (data) {
            vm.addBlacklistObjectToBlacklist(data);
        }, function() {
        
        });
    };

    vm.addBlacklistObjectToBlacklist = function(blacklistObject) {
        $scope.$emit('showNotification', {
            id: 'blacklistRemove',
            loading: true,
            alertType: 'alert-info',
            title: translateService.instant('general.loading'),
            message: translateService.instant('alert.blacklist.adding_to_blacklist')
        });

        AlertBlacklistService.create('', blacklistObject).then(function(response) {
            vm.blacklist.push(response.plain());

            $scope.$emit('showNotification', {
                id: 'blacklistRemove',
                loading: false,
                alertType: 'alert-success',
                title: translateService.instant('general.success'),
                message: translateService.instant('alert.blacklist.added_to_blacklist')
            });
        });
    };

    vm.removeFromBlacklist = function(blacklisted) {
        $scope.$emit('showNotification', {
            id: 'blacklistRemove',
            loading: true,
            alertType: 'alert-info',
            title: translateService.instant('general.loading'),
            message: translateService.instant('alert.blacklist.removing_from_blacklist')
        });

        AlertBlacklistService.remove(blacklisted.alertBlacklistID).then(function(response) {
            vm.blacklist.splice(vm.blacklist.indexOf(blacklisted, 1));

            $scope.$emit('showNotification', {
                id: 'blacklistRemove',
                loading: false,
                alertType: 'alert-success',
                title: translateService.instant('general.success'),
                message: translateService.instant('alert.blacklist.removed_from_blacklist')
            });
        });
    };

    vm.filterInvalidAlerterTypes = function(objects) {
        var invalidAlerterTypes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];

        return $filter('filter')(objects, function(value, index, array) {
            if (invalidAlerterTypes.indexOf(value.alerterTypeID) === -1) {
                return true;
            }

            return false;
        });
    };
}