import { get } from 'lodash';

export class OrderRowHeaderColumnCarrierDeliveryDriverNameComponent {
    public static readonly controller = OrderRowHeaderColumnCarrierDeliveryDriverNameComponent;
    public static readonly controllerAs = 'vm';
    public static readonly template = require('./order-row-header-column-carrier-delivery-driver-name.component.html');
    public static readonly bindings = {
        order: '<'
    };

    public order: any;

    constructor(protected SearchSupportService) {}

    public getDeliveryDriverName(): string {
        if (typeof this.order.deliveryDriverName !== 'undefined') {
            if (this.order.deliveryDriverName === null) {
                return '';
            }

            return this.SearchSupportService.truncateText(this.order.deliveryDriverName);
        }

        return this.SearchSupportService.truncateText(
            get(this.order, 'deliverydriver.driverName', '')
        );
    }
}
