import * as angular from 'angular';
import { HungrigClient } from "@ro-ngx/core";

import './auth.factory';

angular.module( 'cmsApp.auth', [
    'cmsApp.auth.factory'
])

.config(function config( $stateProvider ) {

})

.controller( 'AuthCtrl', function AuthCtrl( $state, $location, AuthRepository, grant, token, targetState, hungrigClient: HungrigClient ) {
    /* jshint validthis: true */
    var vm = this;
    
    vm.error = false;
    
    vm.successMessage = false;
    
    vm.activate = function() {

        if(!grant && !token && !targetState) {
            AuthRepository.setAccessToken('');
            console.log('logout');
            vm.error = 'logout';
            return;
        }
        
        console.log('auth activate', grant, token, targetState);
        
        AuthRepository.grant_type = grant;
        AuthRepository.setAccessToken(token);
        localStorage.setItem('auth.access_token', token);
        
        AuthRepository.getStatus().then(function(data) {
            hungrigClient.requestOptions.headers.set('Authorization', 'Bearer ' + token);

            // data.clientKey returns user_id (number/int) when admin, otherwise string
            if(isFinite(data.clientKey) || ['eathere'].indexOf(targetState) >= 0 || targetState.indexOf('pm/') >= 0 || targetState.indexOf('driverfinance') >= 0) {
                AuthRepository.is_logged_in = true;
                vm.successMessage = 'Lyckades! Vill gå vidare till ' + targetState;
                
                //$state.go(targetState);
                window.location.href = targetState;
                
            } else {
                var error = 'You need to be administrator to do this';
                AuthRepository.setAccessToken('');
                console.log('auth activate error', error);
                vm.error = error;
            }
        }, function(error) {
            AuthRepository.setAccessToken('');
            console.log('auth activate error', error);
            vm.error = error;
        });
    };
    
    vm.activate();
    
    return vm;
})

;
