import { DeliveryOrderStatus } from '@ro-ngx/delivery';

export class OrderRowHeaderColumnCarrierLockedOnCarrierComponent {
    public static readonly controller = OrderRowHeaderColumnCarrierLockedOnCarrierComponent;
    public static readonly controllerAs = 'vm';
    public static readonly template = require('./order-row-header-column-carrier-locked-on-carrier.component.html');
    public static readonly bindings = {
        order: '<'
    };

    public order: any;

    public isLockedOnCarrier(): boolean {
        if (typeof this.order.isLockedOnCarrier !== 'undefined') {
            return this.order.isLockedOnCarrier;
        }

        return this.calculateLockedOnCarrier();
    }

    public calculateLockedOnCarrier() {
        if (this.isDeliveryOrderAssigned()) {
            const deliveryOrderAssigned = this.getDeliveryOrderAssigned();

            if (deliveryOrderAssigned.assignType === 0
                || deliveryOrderAssigned.assignType === 2)
            {
                return true
            }
        }

        return ! this.order.timeoutLine
            && this.order.status == DeliveryOrderStatus.UNCONFIRMED;
    }

    public getDeliveryOrderAssigned() {
        return this.isDeliveryOrderAssigned()
            ? this.order.deliveryorderassign[this.order.deliveryorderassign.length - 1]
            : null;
    }

    public isDeliveryOrderAssigned() {
        return !! this.order.deliveryorderassign.length;
    }
}
