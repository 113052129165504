import * as angular from 'angular';
import * as moment from 'moment';
import { IQService } from "angular";
var template = require('./deliverypriceplan.edit.tpl.html');

angular.module('cmsApp.delivery.priceplan.edit', [
    'ui.bootstrap',
    'common.modalService',
    'ui.select',
    'ngSanitize'
])
.directive('roDeliveryPriceplanEdit', roDeliveryPriceplanEdit);

function roDeliveryPriceplanEdit() {
    return {
        restrict: 'EA',    
        scope: {
            repo: '=',
            roDeliveryPriceplanEdit: '='
        },
        controller: function( $q: IQService, $scope, modalService, DeliveryPriceplanSpanRepository, translateService ) {
            /* jshint validthis: true */
            var vm = this;

            vm.sortableOptions = {};

            $scope.$on('deletePriceplanSpan', function(event, item) {
                var index = vm.roDeliveryPriceplanEdit.deliverypriceplanspans.indexOf(item);
                if(index > -1) {
                    DeliveryPriceplanSpanRepository.remove(item.deliverySpanID);
                    vm.roDeliveryPriceplanEdit.deliverypriceplanspans.splice(index, 1);
                }
            });

            vm.save = function() {
                $scope.$emit('showNotification', {
                    id: 'dPriceplanSave',
                    loading: true,
                    alertType: 'alert-info',
                    title: translateService.instant('general.loading')
                });

                var promises = [];

                promises.push(vm.repo.savePriceplan(vm.roDeliveryPriceplanEdit));

                angular.forEach(vm.roDeliveryPriceplanEdit.deliverypriceplanspans, function(obj) {
                    obj.priority = vm.roDeliveryPriceplanEdit.deliverypriceplanspans.indexOf(obj);
                    obj.sign = vm.roDeliveryPriceplanEdit.sign;
                    promises.push(DeliveryPriceplanSpanRepository.savePriceplanSpan(obj));
                });

                $q.all(promises).then(function() {
                    vm.roDeliveryPriceplanEdit.sign = '';
                    $scope.$emit('showNotification', {
                        id: 'dPriceplanSave',
                        loading: false,
                        alertType: 'alert-success',
                        title: translateService.instant('general.saved')
                    });
                });
            };

            vm.createSpan = function() {
                DeliveryPriceplanSpanRepository.create({ deliveryPriceplanID: vm.roDeliveryPriceplanEdit.deliveryPriceplanID }).then(function(obj) {
                    vm.roDeliveryPriceplanEdit.deliverypriceplanspans.push(obj);
                });
            };

            vm.deleteItem = function() {
                var modalOptions = {
                    closeButtonText: translateService.instant('general.cancel'),
                    actionButtonText: translateService.instant('general.delete'),
                    actionButtonClass: 'btn-danger',
                    headerText: translateService.instant('delivery.price_plan.confirm_delete_short'),
                    bodyText: translateService.instant('delivery.price_plan.confirm_delete')
                };

                modalService.showModal({}, modalOptions).then(function (result) {
                    if(result) {
                        vm.roDeliveryPriceplanEdit.selected = false; 
                        $scope.$emit('deletePriceplan', vm.roDeliveryPriceplanEdit);
                    }
                });
            };

            vm.selectItem = function (pps) {
                pps.selected = !pps.selected;

                var anySelected = false;

                vm.roDeliveryPriceplanEdit.deliverypriceplanspans.forEach(function (element) {
                    if(element.selected) {
                        anySelected = true;
                    }
                });

                vm.roDeliveryPriceplanEdit.hideSaveButtons = anySelected;
            };

            vm.formatSpanEntry = function (date: string) {
                return date ? moment(date).format('HH:mm') : translateService.instant('delivery.priceplan.always');
            };

            vm.dayNames = {
                mon: translateService.instant('date.days.monday_abbr'),
                tue: translateService.instant('date.days.tuesday_abbr'),
                wed: translateService.instant('date.days.wednesday_abbr'),
                thu: translateService.instant('date.days.thursday_abbr'),
                fri: translateService.instant('date.days.friday_abbr'),
                sat: translateService.instant('date.days.saturday_abbr'),
                sun: translateService.instant('date.days.sunday_abbr')
            };
        },
        controllerAs: 'vm',
        bindToController: true,
        template: template
    };
}

