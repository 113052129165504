angular.module( 'cmsApp.delivery.template.factory', [
    'ui.router',
    'ui.bootstrap'
])

.factory('DeliveryTemplateRepository', function (Restangular, AbstractRepository) {

    function DeliveryTemplateRepository() {
        AbstractRepository.call(this, Restangular, 'delivery/templates');
    }

    AbstractRepository.extend(DeliveryTemplateRepository);

    return new DeliveryTemplateRepository();
})

;
