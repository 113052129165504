import { AbstractRepository } from 'app/abstract/abstract-repository';

export class AdwordsRepository extends AbstractRepository {
    constructor (Restangular) {
        super(Restangular, '');
    }

    public getSettings(clientKey) {
        return this.restangular.one('restaurants', clientKey)
            .one('meta/adwords')
            .get();
    };

    public generateSettings(clientKey) {
        return this.restangular.one('restaurants', clientKey)
            .one('meta/adwords')
            .get({ generate: 1 });
    };

    public createSettings(clientKey, settings) {
        return this.restangular.one('restaurants', clientKey)
            .one('meta/adwords')
            .customPOST(settings);
    };

    public updateSettings(clientKey, settings) {
        return this.restangular.one('restaurants', clientKey)
            .one('meta/adwords')
            .customPUT(settings);
    };
}
