import { JsonObject, JsonProperty } from "json2typescript";
import { DateConverter } from "@ro-ngx/json2typescript";

@JsonObject
export class ArticleBody {

    @JsonProperty('articleBodyID', Number)
    public articleBodyID: number = undefined;

    @JsonProperty('articleID', Number)
    public articleID: number = undefined;

    @JsonProperty('title', String)
    public title: string = undefined;

    @JsonProperty('body', String)
    public body: string = undefined;

    @JsonProperty('locale', String)
    public locale: string = undefined;

    @JsonProperty('createdAt', DateConverter, true)
    public createdAt: Date | null = undefined;

    @JsonProperty('updatedAt', DateConverter, true)
    public updatedAt: Date | null = undefined;

    @JsonProperty('deletedAt', DateConverter, true)
    public deletedAt: Date | null = undefined;
}
