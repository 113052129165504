
import {catchError, map} from 'rxjs/operators';
import { BaseService } from '../base/base.service';
import { Injectable } from '@angular/core';
import { HungrigClient, ErrorFactory, ResponseFactory } from '@ro-ngx/core';
import { Observable } from 'rxjs';
import { AppTheme } from './app-theme';

@Injectable()
export class AppThemeService extends BaseService {
    public resourceUrl: string = 'appthemes/';

    constructor(hungrigClient: HungrigClient) {
        super(hungrigClient);
    }

    public getAppThemes(): Observable<AppTheme[]> {
        return this.hungrigClient.get(this.url()).pipe(
            map(ResponseFactory.toData),
            catchError(ErrorFactory.throwObservable));
    }
}
