
import {map, share} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Image } from './image';
import { ImageType } from './image-type';
import { ImageService } from './image.service';
import { Upload } from './upload/upload';
import { CollectionStorage } from '@ro-ngx/core';

@Injectable()
export class ImageStorage extends CollectionStorage<Image> {

    /**
     * @type {Observable<Image[]>}
     */
    public images$: Observable<Image[]>;

    constructor(protected imageService: ImageService) {
        super();

        this.images$ = this.subject.asObservable();
    }

    /**
     * Get images and put them into storage
     *
     * @param imageType
     * @returns {Observable<Image[]>}
     */
    public getImages(imageType: ImageType, clientKey?: string): Observable<Image[]> {
        const obs = this.imageService.getImages(imageType, clientKey).pipe(share());

        return this.unionObservable(obs, 'imageID');
    }

    /**
     * Get single image and put in storage
     *
     * @param imageID
     * @returns {Observable<Image>}
     */
    public getImage(imageID: number): Observable<Image> {
        const obs = this.imageService.getImage(imageID).pipe(share());

        return this.singleUnionObservable(obs, 'imageID');
    }

    /**
     * Create/crop and uploaded image and store new.
     *
     * @param upload
     * @param cropperData
     * @returns {Observable<Image>}
     */
    public createImage(upload: Upload, cropperData: any): Observable<Image> {
        const obs = this.imageService.createImage(upload, cropperData).pipe(share());

        return this.singleUnionObservable(obs, 'imageID');
    }

    /**
     * Delete an image.
     *
     * @param imageID
     * @returns {Observable<number|string>}
     */
    public deleteImage(imageID: number): Observable<number> {
        const obs = this.imageService.deleteImage(imageID).pipe(share());

        return this.spliceObservable(obs, 'imageID') as any;
    }

    /**
     * Find image by id
     *
     * @param imageID
     * @returns {Observable<Image>}
     */
    public image$(imageID: number): Observable<Image> {
        return this.find$(imageID, 'imageID');
    }

    /**
     * ImageType's
     *
     * @param imageType
     * @returns {Observable}
     */
    public imageType$(imageType: ImageType): Observable<Image[]> {
        return this.images$.pipe(
            map((images) => images.filter((image) => image.imageType === imageType)));
    }
}
