
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BaseService } from '../../base/base.service';
import { ErrorFactory, HungrigClient, ResponseFactory } from '@ro-ngx/core';
import { Observable } from 'rxjs';
import { FinanceCustom } from './finance-custom';

@Injectable()
export class FinanceCustomService extends BaseService {
    public resourceUrl: string = 'cms/restaurants';

    constructor(hungrigClient: HungrigClient) {
        super(hungrigClient);
    }

    public getCustomizations(clientKey: string): Observable<FinanceCustom[]> {
        return this.hungrigClient.get(this.url(`/${clientKey}/financecustoms`)).pipe(
            map(ResponseFactory.toData),
            catchError(ErrorFactory.throwObservable));
    }

    public createCustomization(clientKey: string, custom: Partial<FinanceCustom>): Observable<FinanceCustom> {
        return this.hungrigClient.post(this.url(`/${clientKey}/financecustoms`), custom).pipe(
            map(ResponseFactory.toData),
            catchError(ErrorFactory.throwObservable));
    }

    public updateCustomization(
        clientKey: string, id: number, customization: Partial<FinanceCustom>
    ): Observable<FinanceCustom> {
        return this.hungrigClient.put(this.url(`/${clientKey}/financecustoms/${id}`), customization).pipe(
            map(ResponseFactory.toData),
            catchError(ErrorFactory.throwObservable));
    }

    public removeCustomization(clientKey: string, id: number): Observable<FinanceCustom> {
        return this.hungrigClient.delete(this.url(`/${clientKey}/financecustoms/${id}`)).pipe(
            map(ResponseFactory.toData),
            catchError(ErrorFactory.throwObservable));
    }
}
