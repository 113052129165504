import * as angular from 'angular';

import 'app/shared/cache/cache.repository';
import 'app/tech/cache/cache.component';
import 'app/status/status.repository';

var template = require('./tech.component.tpl.html');

angular.module('cmsApp.tech.component', [
        'cmsApp.shared.cache.repository',
        'cmsApp.tech.cache.component',
        'cmsApp.status.repository'
    ])
    .component('roTechComponent', {
        bindings: {},
        controller: roTechComponent,
        controllerAs: 'vm',
        template: template
    });

function roTechComponent($interval, $scope, StatusRepository, translateService) {  
    var vm = this;

    vm.status = undefined;

    vm.$onInit = function() {
        vm.updateStatus();

        $interval(vm.updateStatus, 8000);
    };

    vm.updateStatus = function() {
        StatusRepository.get('', 'itgarden', {})
            .then(function(response) {
                vm.status = response.plain();
            })
            .catch(function(response) {
                vm.status = undefined;
            });
    };

    vm.flushRedisDatabase = function() {
        $scope.$emit('showNotification', { id: 'flushRedisDatabase', loading: true, alertType: 'alert-info', title: translateService.instant('general.loading') });

        StatusRepository.get('', 'flushRedisDatabase', {})
            .then(function(response) {
                $scope.$emit('showNotification', { id: 'flushRedisDatabase', loading: false, alertType: 'alert-success', title: translateService.instant('general.success') });
            })
            .catch(function(error) {
                $scope.$emit('showNotification', { id: 'flushRedisDatabase', loading: false, alertType: 'alert-danger', title: translateService.instant('general.error') });
            });
    };
}