angular.module( 'cmsApp.auth.factory', [])

.factory('AuthRepository', function (Restangular, AbstractRepository) {

    function AuthRepository() {
        this.access_token = null;
        this.logged_in = false;
        this.grant_type = '';
        
        this.api_grants = {
            'cmsrestaurant': {
                'status_url': 'rocms/status'
            },
            'test': {
                'status_url': 'customers/me'
            },
            'driver': {
                'status_url': 'delivery/driver/me'
            }
        };

        AbstractRepository.prototype.isLoggedIn = function() {
            return this.logged_in;
        };
        
        AbstractRepository.prototype.getAccessToken = function() {
            return this.access_token;
        };
        
        AbstractRepository.prototype.setAccessToken = function(access_token) {
            this.access_token = access_token;
            Restangular.setDefaultHeaders({ Authorization: `Bearer ${access_token}` });
            localStorage.setItem('access_token', access_token);
        };
        
        AbstractRepository.prototype.getStatus = function(type) {
            var statusUrl = this.api_grants[type || this.grant_type].status_url || '';
            
            return this.restangular.one(statusUrl).customGET('', {}, { 'Authorization': this.access_token });
        };

        AbstractRepository.prototype.logout = function() {
            this.logged_in = false;
            this.access_token = null;
        };

        AbstractRepository.prototype.authUser = function(credentials) {
            var oauth_credentials = {
                'grant_type':       'password',
                'client_id':        'testid',
                'client_secret':    'testsecret',
                'username':         credentials.email,
                'password':         credentials.password,
                'scope':            'testscope',
                'state':            123456789
            };
            return this.restangular.all('customers/auth').customPOST(oauth_credentials);
        };
        
        AbstractRepository.prototype.authDriver = function(username, password) {
            var oauth_credentials = {
                'grant_type':       'driver',
                'client_id':        'driverid',
                'client_secret':    'driversecret',
                'username':         username,
                'password':         password,
                'scope':            'driverscope',
                'state':            123456789
            };
            this.grant_type = 'driver';
            return this.restangular.all('rocms/auth').customPOST(oauth_credentials);
        };

        AbstractRepository.prototype.me = function() {
            return this.get("/status", null, null, { 'Authorization': this.access_token });
        };

        AbstractRepository.call(this, Restangular, '');
    }

    AbstractRepository.extend(AuthRepository);

    return new AuthRepository();
})

;
