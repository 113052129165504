angular.module( 'cmsApp.alert.profile')
.controller('RemoveprofileModalCtrl', RemoveprofileModalCtrl);

function RemoveprofileModalCtrl( $modalInstance, AlertProfileRepository ) {
    /* jshint validthis: true */
    var vm = this;

    vm.removeProfileType = 0;

    activate();

    ////////////////////

    function activate() {
        // untz
    }

    vm.ok = function() {
        $modalInstance.close(vm.removeProfileType);  
    };

    vm.cancel = function() {
        $modalInstance.dismiss('cancel');
    };

    return vm;
}