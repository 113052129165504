angular.module( 'cmsApp.delivery.interceptor.span.factory', [
])

.factory('DeliveryInterceptorSpanRepository', function (Restangular, AbstractRepository) {

    function DeliveryInterceptorSpanRepository() {

        AbstractRepository.prototype.saveInterceptorSpan = function(data) {
            data = angular.copy(data);

            data.mon = (data.selectedWeekDays.indexOf('Mån') != -1);
            data.tue = (data.selectedWeekDays.indexOf('Tis') != -1);
            data.wed = (data.selectedWeekDays.indexOf('Ons') != -1);
            data.thu = (data.selectedWeekDays.indexOf('Tors')!= -1);
            data.fri = (data.selectedWeekDays.indexOf('Fre') != -1);
            data.sat = (data.selectedWeekDays.indexOf('Lör') != -1);
            data.sun = (data.selectedWeekDays.indexOf('Sön') != -1);

            if(data.minTime)
            {
                data.minTime = data.minTime.toTimeString().substr(0,8);
            }
            else
            {
                data.minTime = '00:00:00';
            }

            if(data.maxTime)
            {
                data.maxTime = data.maxTime.toTimeString().substr(0,8);
            }
            else
            {
                data.minTime = '00:00:00';
            }

            return this.restangular.one('delivery/interceptorspans').customPUT(data, data.deliveryInterceptorSpanID);
        };

        AbstractRepository.call(this, Restangular, 'delivery/interceptorspans');
    }

    AbstractRepository.extend(DeliveryInterceptorSpanRepository);

    return new DeliveryInterceptorSpanRepository();
})

;
