import { NgModule, enableProdMode, LOCALE_ID } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { UpgradeModule } from "@angular/upgrade/static";
import { DeliveryDistrictsConfigModule } from '@ro-ngx/delivery/src/modules/delivery-districts/delivery-districts-config.module';
import { DeliveryManageConfigModule } from '@ro-ngx/delivery/src/modules/delivery-manage/delivery-manage-config.module';
import { SeoService } from "./global/seo/seo.service";
import { TestorderComponent } from "./components/testorder/testorder.component";
import { FinanceReportComponent } from "./finance-components/finance-report/finance-report.component";
import { HttpModule } from "@angular/http";
import { HttpClientModule } from "@angular/common/http"
import { RoNg2Module, CurrencyModule, defaultTranslate, LocaleModule } from "@ro-ngx/core";
import { AppComponent } from "./app.component";
import { RouterModule } from "@angular/router";
import { routes } from "./routes";
import { PageNotFoundComponent } from "./components/page-not-found/page-not-found.component";
import { RestaurantService, RestaurantStorage } from "./http/restaurant";
import { DeliveryRestaurantService } from 'app/http/delivery/restaurant/delivery-restaurant.service';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CompanyService } from "./http/delivery/company/company.service";
import { DriverService } from "./http/delivery/driver/driver.service";
import { OrderService } from "./http/delivery/order/order.service";
import { FinanceService } from "./http/finance/finance.service";
import { FinanceStorage } from "./http/finance/finance.storage";
import { FinanceComponent } from "./components/delivery/driver/finance/finance.component";
import { SummaryTableComponent } from "./components/delivery/driver/finance/summary-table.component";
import { DriverStorage } from "./http/delivery/driver/driver.storage";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CompanyStorage } from "./http/delivery/company/company.storage";
import { ENV } from './enviroment';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TranslateModule } from '@ngx-translate/core';
import { LoginComponent } from 'app/components/login';
import { TestorderService } from './http/rocms/testorder/testorder.service';
import { CanDeactivateGuard } from 'app/global/guards';
import { DialogModule } from '@ro-ngx/core';
import { NavbarComponent } from 'app/components/navbar';
import { RestaurantTimeSettingsService } from './http/restaurant/time-settings/restaurant-time-settings.service';
import { RestaurantNoteService } from './http/restaurant/note/restaurant-note.service';
import { ManagerDeviceService } from './http/restaurant/manager-device/manager-device.service';
import { FinanceCustomService } from './http/restaurant/finance/finance-custom.service';
import { RoCurrencyPipe } from './global/pipes/rocurrency.pipe';
import { CampaignGroupService } from './http/campaign-group/campaign-group.service';
import { CityService as CampaignGroupCityService } from './http/campaign-group/city/city.service';
import {
    RestaurantService as CampaignGroupRestaurantService } from './http/campaign-group/restaurant/restaurant.service';
import { CouponService } from './http/coupon/coupon.service';
import { IframeComponent } from './components/iframe/iframe.component';
import { CampaignService } from './http/campaign/campaign.service';
import { UserService } from './http/user/user.service';
import { UserStorage } from './http/user/user.storage';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { RestaurantMetaTagService } from './http/restaurant/metatag/RestaurantMetaTagService';
import { MetaTagService } from './http/metatag/MetaTagService';
import { SummaryService } from './http/summary/summary.service';
import { PipeModule } from './global/pipes/pipe.module';
import { AppConfigService } from './http/app-config/app-config.service';
import { AppThemeService } from './http/app-theme/app-theme.service';
import { AppMultiClientService } from './http/app-multi-client/app-multi-client.service';
import { RestaurantNavigationService } from './http/restaurant/navigation/restaurant-navigation.service';
import { CampaignGroupStorage } from './http/campaign-group/campaign-group.storage';
import { SvgCollection } from './global/svg/svg-collection';
import { SendMessageComponent } from './components/delivery/actions/send-message.component';
import { OrderContactService } from './http/order/order-contact.service';
import { DeliveryDistancePriceService } from './http/delivery/distance/delivery-distance-price.service';
import { DeliveryDistancePriceStorage } from './http/delivery/distance/delivery-distance-price.storage';
import { RestaurantsModule } from '@ro-ngx/restaurants';
import { OrderTabCartModule } from '@ro-ngx/order-tab-cart';
import {
    DeliveryModule,
    DeliveryDriverModule,
    DeliveryOrderActionsModule,
    DeliveryDirectionsModule,
} from '@ro-ngx/delivery';
import { CompensationsModule } from '@ro-ngx/compensations';
import { AuthenticationModule } from '@ro-ngx/authentication';
import { UsersModule, UserActivityListModule } from '@ro-ngx/users';
import { NavbarSerializer } from './components/navbar/navbar-serializer';
import { PushNotificationConfigModule } from '@ro-ngx/push-notification';
import { ImageLibraryModule } from '@ro-ngx/image-library';
import { Json2typescriptModule } from '@ro-ngx/json2typescript';
import { ArticlesModule } from '@ro-ngx/articles';
import { ArticleListConfigModule } from '@ro-ngx/article-list';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { CouponsModule, CouponManagerConfigModule } from '@ro-ngx/coupons';
import { OrdersModule } from '@ro-ngx/orders';
import { RestaurantSummaryService } from './http/restaurant-summary';
import { DriversMapGuard } from './shared/guards/drivers-map.guard';
import { DistrictService } from './http/delivery/district/delivery-district.service';
import { registerLocaleData } from '@angular/common';
import localeSe from '@angular/common/locales/se';
import localeSeExtra from '@angular/common/locales/extra/se';


registerLocaleData(localeSe,'sv-SE',localeSeExtra);

import { MarketSettingsModule} from "./components/marketsettings/market-settings.module";
import { GlobalExceptionsComponent } from './components/global-exceptions/global-exceptions.component';
import { GlobalExceptionService } from './http/global-exception/global-exception.service';

declare module "@angular/core" {
    interface ModuleWithProviders<T = any> {
        ngModule: Type<T>;
        providers?: Provider[];
    }
}


@NgModule({
    bootstrap: [
        AppComponent,
    ],
    imports: [
        CurrencyModule.forRoot({
            currency: ENV.APP_CURRENCY,
            decimalPoints: parseInt(ENV.CURRENCY_DECIMAL_POINTS),
            multiplier: parseInt(ENV.APP_CURRENCY_MULTIPLIER)
        }),
        LocaleModule.forRoot({
            country: (ENV.APP_COUNTRY as any),
            language: (ENV.APP_LANGUAGE as any)
        }),
        Json2typescriptModule.forRoot({}),
        ArticlesModule.forRoot(),
        BsDropdownModule.forRoot(),
        BsDatepickerModule.forRoot(),
        HttpClientModule,
        DialogModule.forRoot(),
        RestaurantsModule.forRoot(),
        UpgradeModule,
        BrowserModule,
        ModalModule.forRoot(),
        HttpModule,
        RouterModule.forRoot(routes),
        TranslateModule.forRoot(defaultTranslate),
        RoNg2Module.forRoot({
            baseUrl: ENV.API_LOCATION,
            defaultTranslations: {
                brand: ENV.APP_BRAND
            }
        }),
        ArticleListConfigModule.forRoot({
            supportedLocales: [
                ENV.APP_LANGUAGE,
                'en'
            ]
        }),
        OrderTabCartModule.forRoot({
            displayRestricted: true,
            displayTaxRates: ENV.APP_BRAND !== 'Glodny'
        }),
        UsersModule.forRoot(),
        AuthenticationModule.forRoot({
            unauthorizedUrl: '/login',
            authorizedUrl: '/'
        }),
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        PipeModule,
        DeliveryModule.forRoot(),
        UserActivityListModule,
        DeliveryDriverModule,
        DeliveryOrderActionsModule,
        CompensationsModule.forRoot({
            enabled: ENV.APP_BRAND === 'Whitelabel',
            taxRates: JSON.parse(ENV.TAX_RATES)
        }),
        PushNotificationConfigModule.forRoot({
            canSelectOs: true
        }),
        CouponsModule.forRoot(),
        OrdersModule.forRoot(),
        ImageLibraryModule.foorRoot({
            apiUrl: ENV.API_LOCATION
        }),
        CouponManagerConfigModule.forRoot({
            asAdmin: true
        }),
        DeliveryDirectionsModule.forRoot({
            accessToken: ENV.MAP_BOX_ACCESS_TOKEN
        }),
        DeliveryManageConfigModule.forRoot({
            accessToken: ENV.MAP_BOX_ACCESS_TOKEN,
            appCountry: ENV.APP_COUNTRY,
        }),
        DeliveryDistrictsConfigModule.forRoot({
            accessToken: ENV.MAP_BOX_ACCESS_TOKEN,
            appCountry: ENV.APP_COUNTRY,
        }),
    ],
    providers: [
        NavbarSerializer,
        DeliveryDistancePriceService,
        DeliveryDistancePriceStorage,
        AppConfigService,
        AppMultiClientService,
        AppThemeService,
        UserService,
        UserStorage,
        CanDeactivateGuard,
        DeliveryRestaurantService,
        TestorderService,
        MetaTagService,
        RestaurantService,
        RestaurantStorage,
        RestaurantNoteService,
        RestaurantTimeSettingsService,
        RestaurantMetaTagService,
        RestaurantNavigationService,
        RestaurantSummaryService,
        CampaignService,
        CampaignGroupService,
        CampaignGroupStorage,
        CampaignGroupCityService,
        CampaignGroupRestaurantService,
        CouponService,
        OrderService,
        OrderContactService,
        DistrictService,
        CompanyService,
        CompanyStorage,
        DriverService,
        DriverStorage,
        FinanceService,
        FinanceStorage,
        FinanceCustomService,
        SeoService,
        ManagerDeviceService,
        RoCurrencyPipe,
        SummaryService,
        SvgCollection,
        DriversMapGuard,
        { provide: LOCALE_ID, useValue: `${ENV.APP_LANGUAGE}-${ENV.APP_COUNTRY}` }
    ],
    declarations: [
        NavbarComponent,
        AppComponent,
        FinanceComponent,
        LoginComponent,
        SummaryTableComponent,
        IframeComponent,
        PageNotFoundComponent,
        TestorderComponent,
        SendMessageComponent,
        FinanceReportComponent,
    ],
    entryComponents: [
        PageNotFoundComponent,
        TestorderComponent,
        SendMessageComponent
    ],
})
export class AppModule {

    constructor(
    ) {}

    public ngDoBootstrap(): void { }

}

export function main() {
    platformBrowserDynamic().bootstrapModule(AppModule).then(platformRef => {
        const upgrade = platformRef.injector.get(UpgradeModule) as UpgradeModule;

        upgrade.bootstrap(document.documentElement, ['cmsApp']);
    });
}

if (ENV.ENV === 'production') {
    enableProdMode();
}

main();
