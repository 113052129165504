import { differenceInMinutes } from "date-fns";
import { DeliveryOrderActionType } from '@ro-ngx/delivery';

const ONE_HOUR_IN_MINUTES = 60;

export class OrderRowHeaderColumnPickupInUnitsComponent {
    public static readonly controller: typeof OrderRowHeaderColumnPickupInUnitsComponent = OrderRowHeaderColumnPickupInUnitsComponent;
    public static readonly controllerAs: string = 'vm';
    public static readonly template: any = require('./order-row-header-column-pickup-in-units.component.html');
    public static readonly bindings: any = {
        order: '<'
    };

    public order: any;

    public getDifferenceWithUnits(): string {
        if (this.hasDriverPickedUp()) {
            return '';
        }

        const minutesUntilPickup = this.getDifferenceInMinutes();

        if (minutesUntilPickup > ONE_HOUR_IN_MINUTES) {
            const differenceInHours = Math.round(minutesUntilPickup / ONE_HOUR_IN_MINUTES);

            return `${differenceInHours}h+`;
        }

        return `${minutesUntilPickup}m`;
    }

    public getDifferenceInMinutes(): number {
        return differenceInMinutes(this.getDriverShouldPickupAt(), new Date());
    }

    public getDriverShouldPickupAt(): string|number {
        if (typeof this.order.driverShouldPickupAt !== 'undefined') {
            return this.order.driverShouldPickupAt;
        }

        return this.order.pickupLine;
    }

    public hasDriverPickedUp(): boolean {
        if (typeof this.order.driverCompletedPickupAt !== 'undefined') {
            return !! this.order.driverCompletedPickupAt;
        }

        return !! this.order.deliveryorderactions.find((action) => {
            return action.type === DeliveryOrderActionType.Delivered;
        });
    }
}
