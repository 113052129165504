import * as angular from 'angular';

import 'app/deliveryfinance/deliveryorder.factory';

var template = require('./accept-delivery-order.component.tpl.html');

angular.module('cmsApp.deliveryfinance.deliveryorder.acceptdeliveryorder.component', [
        'cmsApp.deliveryorder.factory'
    ])
    .component('roAcceptDeliveryOrder', {
        bindings: {
            'deliveryOrder': '<',
            'deliveryCompanies': '<',
            'deliveryDrivers': '<',
            'onChange': '&'
        },
        controller: AcceptDeliveryOrderComponent,
        controllerAs: 'vm',
        template: template
    });

function AcceptDeliveryOrderComponent($scope, $filter, DeliveryOrderRepository, translateService) {
    var vm = this;

    vm.deliveryCompanyID = 0;
    vm.deliveryDriverID = 0;

    vm.drivers = [];

    vm.$onInit = function() {
        vm.setDeliveryCompanyID(vm.deliveryOrder.deliveryCompanyID);
        vm.deliveryDriverID = vm.deliveryOrder.deliveryDriverID;
    };

    vm.setDeliveryCompanyID = function(deliveryCompanyID) {
        vm.deliveryCompanyID = deliveryCompanyID;

        vm.updateDriverCollection();
    };

    vm.updateDriverCollection = function() {
        vm.drivers = $filter('filter')(vm.deliveryDrivers, {deliveryCompanyID: vm.deliveryCompanyID}, true);
    };

    vm.confirmDeliveryOrder = function() {
        $scope.$emit('showNotification', { id: 'confirmDeliveryOrder', loading: true, alertType: 'alert-info', title: translateService.instant('general.loading') });

        DeliveryOrderRepository.confirmDeliveryOrder(vm.deliveryOrder.deliveryOrderID, {
                'deliveryCompanyID': vm.deliveryCompanyID,
                'deliveryDriverID': vm.deliveryDriverID
            })
            .then(function() {
                $scope.$emit('showNotification', { id: 'confirmDeliveryOrder', loading: false, alertType: 'alert-success', title: translateService.instant('general.saved') });

                vm.change();
            })
            .catch(function(response) {
                $scope.$emit('showNotification', { id: 'confirmDeliveryOrder', loading: false, alertType: 'alert-danger', title: response.status, message: response.data.error_message });
            });
    };

    vm.change = function() {
        vm.onChange({
            $value: vm.deliveryOrder
        });
    };

    return vm;
}