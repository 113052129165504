export class OrderRowHeaderColumnCarrierDriverCommentComponent {
    public static readonly controller = OrderRowHeaderColumnCarrierDriverCommentComponent;
    public static readonly controllerAs = 'vm';
    public static readonly template = require('./order-row-header-column-carrier-driver-comment.component.html');
    public static readonly bindings = {
        order: '<'
    };

    public order: any;

    public hasDriverComment() {
        if (typeof this.order.hasDriverComment !== 'undefined') {
            return this.order.hasDriverComment;
        }

        return !!this.order.driverComment;
    }
}
