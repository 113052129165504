angular.module( 'cmsApp.restaurant.service', [

])
.factory('RestaurantService', function(Restangular, AbstractRepository) {

    function RestaurantService() {
        AbstractRepository.call(this, Restangular, 'restaurants');
    }

    AbstractRepository.extend(RestaurantService);

    return new RestaurantService();
})

;
