import { ImageType } from './image-type';

export class ImageTypeDimension {

    public imageType: ImageType;

    public width: number;

    public height: number;

    constructor(imageType: ImageType, width: number, height: number) {
        this.imageType = imageType;
        this.width = width;
        this.height = height;
    }

    public text(): string {
        return this.width + 'x' + this.height;
    }
}
