angular.module( 'cmsApp.delivery.company.modal.close', [
])
.controller('ClosedeliverycompanyModalCtrl', ClosedeliverycompanyModalCtrl);

function ClosedeliverycompanyModalCtrl( $modalInstance, DeliveryDriverRepository, company ) {
    /* jshint validthis: true */
    var vm = this;

    vm.required = false;
    vm.submitted = false;

    vm.company = company;

    vm.closedMeters = company.closedMetersDefault;

    activate();

    ////////////////////

    function activate() {
    	// body...
    }

    vm.ok = function() {
        vm.company.closed = 1;
        vm.company.closedMeters = vm.closedMeters;
		$modalInstance.close(vm.company);
    };

    vm.reset = function() {
        vm.company.closed = 0;
        vm.company.closedMessage = '';
        vm.company.closedMeters = 0;
        $modalInstance.close(vm.company);
    };

    vm.cancel = function() {
        $modalInstance.dismiss('cancel');
    };

    return vm;
}