var template = require('./alertview-default.component.tpl.html');

angular.module( 'cmsApp.alertview.types.alertviewdefault.component', [])
    .component('roAlertviewDefault', {
        bindings: {
            'alertviewlog': '=',
            'todaystimesettings': '='
        },
        controller: roAlertviewDefaultController,
        controllerAs: 'vm',
        template: template
    });

function roAlertviewDefaultController() {
    var vm = this;

    vm.data = vm.alertviewlog;
    vm.todaysTimesettings = vm.todaystimesettings;
}
