
import {catchError, map} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { HungrigClient } from "@ro-ngx/core";
import { BaseService } from "../../base/base.service";
import { Observable } from 'rxjs';
import { Testorder } from './testorder';

@Injectable()
export class TestorderService extends BaseService {

    public resourceUrl: string = 'rocms/testorder';

    constructor(public hungrigClient: HungrigClient) {
        super(hungrigClient);
    }

    public getTestorders(): Observable<Testorder[]> {
        return this.hungrigClient.get(this.url()).pipe(
            map(this.toResponse),
            map((response) => response.data),
            catchError(this.handleError));
    }

    public sendTestorders(clientKeys: string[]): Observable<any> {
        return this.hungrigClient.post(this.url(), { clientKeys: clientKeys });
    }
}
