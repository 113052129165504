import { Injectable } from '@angular/core';
import { Company } from "./company";
import { BaseStorage } from "../../base/base.storage";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { CompanyService } from "./company.service";

@Injectable()
export class CompanyStorage extends BaseStorage {

    public companies$: Observable<Company[]>;

    protected companies: BehaviorSubject<Company[]>;

    constructor(protected companyService: CompanyService) {
        super();

        this.companies = new BehaviorSubject([]);
        this.companies$ = this.companies.asObservable();

        this.getCompanyList();
    }

    public getCompanyList(): Subscription {
        return this.companyService.getCompanyList()
            .subscribe((companies) => this.companies.next(companies));
    }
}
