
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { URLSearchParams, ResponseContentType } from '@angular/http';
import { BaseService } from '../base/base.service';
import { ErrorFactory, HungrigClient, Paginate, ResponseFactory, PaginateFactory, ParamsUtil } from '@ro-ngx/core';
import { Observable } from 'rxjs';
import { Coupon } from './coupon';
import { CouponGroup } from './coupon-group';

@Injectable()
export class CouponService extends BaseService {
    public resourceUrl: string = 'cms';

    constructor(hungrigClient: HungrigClient) {
        super(hungrigClient);
    }

    public getGroup(groupId: number): Observable<CouponGroup> {
        return this.hungrigClient.get(this.url(`/coupongroups/${groupId}`)).pipe(
            map(ResponseFactory.toData),
           catchError(ErrorFactory.throwObservable));
    }

    public getGroups(page: number = 1, filter: string = ''): Observable<Paginate<CouponGroup[]>> {
        const search: URLSearchParams = new URLSearchParams();
        search.set('page', page.toString());
        search.set('filter', filter);
        return this.hungrigClient.get(this.url('/coupongroups'), { search }).pipe(
            map(PaginateFactory.from),
           catchError(ErrorFactory.throwObservable));
    }

    public createGroup(group: Partial<CouponGroup>): Observable<CouponGroup> {
        return this.hungrigClient.post(this.url('/coupongroups'), group).pipe(
            map(ResponseFactory.toData),
           catchError(ErrorFactory.throwObservable));
    }

    public updateGroup(groupId: number, group: Partial<CouponGroup>): Observable<CouponGroup> {
        return this.hungrigClient.put(this.url(`/coupongroups/${groupId}`), group).pipe(
            map(ResponseFactory.toData),
           catchError(ErrorFactory.throwObservable));
    }

    public createGroupCoupon(groupId: number, coupon: Partial<Coupon>): Observable<Coupon | Coupon[]> {
        return this.hungrigClient.post(this.url(`/coupongroups/${groupId}/coupons`), coupon).pipe(
            map(ResponseFactory.toData),
           catchError(ErrorFactory.throwObservable));
    }

    public getGroupCoupons(groupId: number, page: number = 1, filter: string = ''): Observable<Paginate<Coupon[]>> {
        const search = ParamsUtil.toHttpParams({ page, filter });
        return this.hungrigClient.get(this.url(`/coupongroups/${groupId}/coupons`), { search }).pipe(
            map(PaginateFactory.from),
           catchError(ErrorFactory.throwObservable));
    }

    public getGroupCouponsCsv(groupId: number): Observable<Blob> {
        return this.hungrigClient.get(
                this.url(`/coupongroups/${groupId}/export`), { responseType: ResponseContentType.Blob }
            ).pipe(
            map((response) => response.blob()));
    }

    public getCoupon(couponId: number): Observable<Coupon> {
        return this.hungrigClient.get(this.url(`/coupons/${couponId}`)).pipe(
            map(ResponseFactory.toData),
           catchError(ErrorFactory.throwObservable));
    }

    public updateCoupon(couponId: number, coupon: Partial<Coupon>): Observable<Coupon> {
        return this.hungrigClient.put(this.url(`/coupons/${couponId}`), coupon).pipe(
            map(ResponseFactory.toData),
           catchError(ErrorFactory.throwObservable));
    }

    public deleteCoupon(couponId: number): Observable<boolean> {
        return this.hungrigClient.delete(this.url(`/coupons/${couponId}`)).pipe(
            map(ResponseFactory.toData),
           catchError(ErrorFactory.throwObservable));
    }

    public stickyGroupCoupon(sticky: Partial<CouponGroup>): Observable<Paginate<CouponGroup[]>> {
        return this.hungrigClient.post(this.url(`/coupongroups/sticky`), sticky).pipe(
            map(PaginateFactory.from),
           catchError(ErrorFactory.throwObservable));
    }
}
