var template = require('./editAlerts.tpl.html');

angular.module( 'cmsApp.editAlerts', [
    'ui.select'
])
.directive('editAlerts', editAlerts);

function editAlerts() {
    return {
        restrict: 'EA',    
        scope: {
            editAlerts: '=',
            adapter: '='
        },
        controller: function( $scope, $q, AlertRepository, AlerterRepository, AlertProfileRepository ) {
            /* jshint validthis: true */
            var vm = this;
            
            vm.alerts               = [];
            vm.alerts_original      = [];
            vm.availibleProfiles    = [];
            
            vm.alerterID            = 0;
            vm.alerterType          = angular.copy(vm.editAlerts);
            
            vm.alertEmail           = 0;
            vm.alertSMS             = 0;
            
            vm.activate = function() {
                vm.adapter = {};
                
                vm.adapter.save = function() {
                    vm.save();
                };
                
                AlerterRepository.getList('/alertertype/' + vm.alerterType).then(function(data) {
                    if(data && !data.error && data.length) {
                        data = data[0];
                        
                        vm.alerterID = data.alerterID;
                        
                        data.alerts.forEach(function (alert, index) {
                            vm.alerts.push(alert.alertprofile);
                            vm.alerts_original.push(angular.copy(alert));
                        });
                        
                        vm.adapter.alertlog = data.alertlog;
                    } else {
                        $scope.$emit('showNotification', { id: 'getAlertError', loading: false, alertType: 'alert-error', title: 'Fel', message: 'Något gick fel när vi försökte ladda in information' });
                    }
                });
            };
            
            vm.refreshProfiles = function(search, compare) {
                AlertProfileRepository.getList('/search/' + search).then(function(data) {
                    vm.availibleProfiles = [];

                    data.forEach(function (newProfile) {
                        var found = false;

                        compare.forEach(function (testProfile) {
                            if(newProfile.alertProfileID == testProfile.alertProfileID) {
                                found = true;
                            }
                        });

                        if(!found) {
                            vm.availibleProfiles.push(newProfile);
                        }
                    });
                });
            };
            
            vm.save = function () {
                $scope.$emit('showNotification', { id: 'editAlertSave', loading: true, alertType: 'alert-info', title: 'Sparar...', message: 'Dina ändringar sparas' });

                var promises = [];

                promises.push(vm.saveAlert(vm.alerts_original, vm.alerts, vm.alerterID, vm.alerterType, vm.alertEmail, vm.alertSMS));

                $q.all(promises).then(function () {
                    $scope.$emit('showNotification', { id: 'editAlertSave', loading: false, alertType: 'alert-success', title: 'Sparat!', message: 'Dina ändringar har blivit sparade' });
                });
            };
            
            vm.saveAlert = function (existingItems, newItems, alerterID, alerterTypeID, alertEmail, alertSMS) {
                // specify the list of which items to add (splice/delete from this array when items existed before)
                var itemsToAdd = angular.copy(newItems);
                // specify the list of which items to delete (push to this array)
                var itemsToDelete = [];

                // loop through every existing item (the old database)
                existingItems.forEach(function (oldAlert) {
                    var found = false;

                    // check if the item still exists (loop through "active" "database")
                    itemsToAdd.forEach(function (newAlert) {
                        if(oldAlert.alertprofile.alertProfileID == newAlert.alertProfileID)
                        {
                            // the item is found! it should not be added or removed, it should be left "unchanged", so remove it from "toAdd"-array
                            found = true;
                            itemsToAdd.splice(itemsToAdd.indexOf(newAlert), 1);
                        }
                    });

                    if(!found) {
                        // the item does not exists anymore and should be deleted
                        itemsToDelete.push(oldAlert.alertID);
                    }
                });

                var promises = [];

                itemsToDelete.forEach(function (deleteAlertID) {
                    promises.push(AlertRepository.remove(deleteAlertID));
                });

                itemsToAdd.forEach(function (newAlertProfile) {
                    promises.push(AlertRepository.create({
                        alertProfileID:     newAlertProfile.alertProfileID,
                        alerterID:          alerterID,
                        alerterTypeID:      alerterTypeID,
                        alertEmail:         alertEmail,
                        alertSMS:           alertSMS
                    }));
                });

                return $q.all(promises);
            };
            
            vm.activate();
            
            return vm;
        },
        controllerAs: 'vm',
        bindToController: true,
        template: template
    };
}