
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BaseService } from 'app/http/base/base.service';
import { HungrigClient, HungrigResponse } from '@ro-ngx/core';
import { Observable } from 'rxjs';
import { CampaignGroup } from './campaign-group';

@Injectable()
export class CampaignGroupService extends BaseService {
    public resourceUrl: string = 'campaigngroups/';

    constructor(public hungrigClient: HungrigClient) {
        super(hungrigClient);
    }

    public getGroups(): Observable<CampaignGroup[]> {
        return this.hungrigClient.get(this.url()).pipe(
            map(this.toResponse),
            map((response) => response.data),
            catchError(HungrigResponse.fromHungrigRequestToError));
    }

    public createGroup(group: CampaignGroup): Observable<CampaignGroup> {
        return this.hungrigClient.post(this.url(), group).pipe(
            map(this.toResponse),
            map((response) => response.data),
            catchError(HungrigResponse.fromHungrigRequestToError));
    }

    public updateGroup(id: number, group: CampaignGroup): Observable<CampaignGroup> {
        return this.hungrigClient.put(this.url(id.toString()), group).pipe(
            map(this.toResponse),
            map((response) => response.data),
            catchError(HungrigResponse.fromHungrigRequestToError));
    }

    public deleteGroup(id: number): Observable<CampaignGroup> {
        return this.hungrigClient.delete(this.url(id.toString())).pipe(
            map(this.toResponse),
            map((response) => response.data),
            catchError(HungrigResponse.fromHungrigRequestToError));
    }
}
