import { Component, Input, OnInit } from '@angular/core';
import { Order } from '../../../http/delivery/order';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { OrderContactService } from '../../../http/order/order-contact.service';
import { NotificationStorage } from '@ro-ngx/core';
import { OrderContactRecipient } from '../../../http/order/order-contact-recipient';
import { OrderContact } from '../../../http/order/order-contact';
import {finalize, startWith} from "rxjs/operators";

export const SMS_LENGTH = 255;

export interface MessageModel {
    id: string;
    translateOptions: Object;
}

@Component({
    template: require('./send-message.component.html'),
    styles: [ '.message { white-space: pre-wrap; }' ]
})
export class SendMessageComponent implements OnInit {
    /**
     * @type {Order}
     */
    @Input()
    public order: Order;

    /**
     * The message history
     * @type {OrderContact}
     */
    public messages: OrderContact[];

    /**
     * @type {{value: string, text: string, disabled: boolean}}
     */
    public recipientOptions: { value: string, text: string, disabled: boolean }[];

    /**
     * @type {{value: MessageModel, text: string}}
     */
    public messageOptions: { value: MessageModel, text: string }[];

    /**
     * @type {{value: MessageModel, text: string}}
     */
    public recipientMessageOptions: {[key: string]: { value: MessageModel, text: string }[]};

    /**
     * @type {number}
     */
    public messageLength: number;

    /**
     * @type {number}
     */
    public readonly preferredMessageLength: number = SMS_LENGTH;

    /**
     * @type {FormGroup}
     */
    public form: FormGroup;

    /**
     * @type {{[key: string]: boolean}}
     */
    public loading: {[key: string]: boolean} = {
        'create': false,
        'history': false
    };

    /**
     * @type {OrderContactRecipient}
     */
    public recipient: typeof OrderContactRecipient = OrderContactRecipient;

    /**
     * UTF-8 text encoder
     * @type {TextEncoding.TextEncoder}
     */
    protected textEncoder: TextEncoding.TextEncoder = new TextEncoder();

    /**
     * @type {Object}
     */
    protected defaultMessageTranslateOptions: Object;

    constructor(
        protected orderContactService: OrderContactService,
        protected translateService: TranslateService,
        protected notificationStorage: NotificationStorage,
        protected formBuilder: FormBuilder
    ) {

    }

    public ngOnInit(): void {
        this.initOptions();
        this.initForm();
        this.fetchMessageHistory();
    }

    /**
     * Send the selected message.
     *
     * @return void
     */
    public send(): void {
        this.loading['create'] = true;
        this.orderContactService.sendMessage(this.order.orderID, this.form.value).pipe(
            finalize(() => this.loading['create'] = false)
        ).subscribe(() => {
                this.notificationStorage
                    .success(this.translateService.instant('delivery.order.send_message.message_sent'));
                this.fetchMessageHistory();
            });
    }

    /**
     * Get the message corresponding to the given model.
     *
     * @param {MessageModel} model
     * @returns {string}
     */
    protected getMessage(model: MessageModel): string {
        const translateOptions: Object = Object.assign({}, this.defaultMessageTranslateOptions, model.translateOptions);
        return this.translateService.instant(`delivery.order.send_message.message_${model.id}`, translateOptions);
    }

    /**
     * Fetch the latest message history
     *
     * @return void
     */
    protected fetchMessageHistory(): void {
        this.loading['history'] = true;
        this.orderContactService.getHistory(this.order.orderID).pipe(
            finalize(() => this.loading['history'] = false)
        )
            .subscribe((messages) => this.messages = messages);
    }

    /**
     * @return void
     */
    protected initOptions(): void {
        this.recipientOptions = [
            {
                value: OrderContactRecipient.Customer,
                text: this.translateService.instant('delivery.order.send_message.option_customer'),
                disabled: false
            },
            {
                value: OrderContactRecipient.DeliveryDriver,
                text: this.translateService.instant('delivery.order.send_message.option_delivery_driver'),
                disabled: this.order.deliveryDriverID === 0
            }
        ];

        const start: number = 5;
        const end: number = 50;
        const step: number = 5;
        this.recipientMessageOptions = {};
        this.recipientMessageOptions[OrderContactRecipient.Customer] = _.range(start, end, step)
            .map((delay) => ({
                value: {
                    id: 'delay_customer',
                    translateOptions: { delay }
                },
                text: this.translateService.instant('delivery.order.send_message.option_delay', { delay }),
            }));
        this.recipientMessageOptions[OrderContactRecipient.DeliveryDriver] = _.range(start, end, step)
            .map((delay) => ({
                value: {
                    id: 'delay_delivery_driver',
                    translateOptions: { delay }
                },
                text: this.translateService.instant('delivery.order.send_message.option_delay', { delay }),
            }));

        this.recipientMessageOptions[OrderContactRecipient.DeliveryDriver].push({
            value: {
                id: 'updated_address_delivery_driver',
                translateOptions: {
                    address: this.order.order.ordercustomer.address
                }
            },
            text: this.translateService.instant('delivery.order.send_message.option_updated_address')
        });

        this.defaultMessageTranslateOptions = {
            restaurantName: this.order.order.restaurantcontact.restaurantName,
            customerName: this.order.order.ordercustomer.name
        };
    }

    /**
     * @return void
     */
    protected initForm(): void {
        this.form = this.formBuilder.group({
            recipient: OrderContactRecipient.Customer,
            messageModel: null,
            message: ''
        });

        this.form.get('message').valueChanges
            .subscribe((message) => this.messageLength = this.textEncoder.encode(message).length);

        this.form.get('messageModel').valueChanges
            .subscribe((model) => this.form.get('message').setValue(this.getMessage(model)));

        this.form.get('recipient').valueChanges.pipe(
            startWith(this.form.get('recipient').value)
        )
            .subscribe((recipient) => {
                this.form.get('message').setValue('');
                this.messageOptions = this.recipientMessageOptions[recipient];
            });
    }
}
