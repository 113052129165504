import { Pipe, PipeTransform } from '@angular/core';
import { chunk } from 'lodash';

@Pipe({ name: 'chunk' })
export class ChunkPipe implements PipeTransform {
    /**
     * Splits input to size sized chunks.
     */
    public transform<T>(input: T[], size: number): T[][] {
        return chunk(input, size);
    }
}
