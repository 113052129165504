angular.module( 'cmsApp.alert.profile')
.controller('NewprofileModalCtrl', NewprofileModalCtrl);

function NewprofileModalCtrl( $modalInstance, AlertProfileRepository ) {
    /* jshint validthis: true */
    var vm = this;

    vm.newProfileType = 0;
    vm.newProfileName = '';

    vm.profiles = [];
    vm.selectedProfile = null;

    vm.submitted = false;

    activate();

    ////////////////////

    function activate() {
        AlertProfileRepository.getList().then(function(data) {
            vm.profiles = data;
        });
    }

    vm.ok = function() {
        if(!vm.submitted)
        {
            vm.submitted = true;

            if(vm.newProfileType == '0' && vm.newProfileName)
            {
                AlertProfileRepository.create({ profileName: vm.newProfileName, active: 1 }).then(function(obj) {
                    vm.submitted = false;
                    $modalInstance.close(obj);
                });
            }
            else
            {
                vm.submitted = false;
                $modalInstance.close(vm.selectedProfile);
            }
        }
    };

    vm.cancel = function() {
        $modalInstance.dismiss('cancel');
    };

    return vm;
}