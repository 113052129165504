angular.module( 'cmsApp.delivery.timeout.step', [
])

.factory('DeliveryTimeoutStep', function (Restangular, AbstractRepository) {

    function DeliveryTimeoutStep() {

        AbstractRepository.call(this, Restangular, 'delivery/timeout/step');
    }

    AbstractRepository.extend(DeliveryTimeoutStep);

    return new DeliveryTimeoutStep();
})

;
