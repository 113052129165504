angular.module( 'cmsApp.delivery.company.status.factory', [
    'ui.router',
    'ui.bootstrap'
])

.factory('DeliveryCompanyStatusRepository', function (Restangular, AbstractRepository) {

    function DeliveryCompanyStatusRepository() {
        AbstractRepository.call(this, Restangular, 'delivery/companystatus', 'deliveryStatusID');
    }

    AbstractRepository.extend(DeliveryCompanyStatusRepository);

    return new DeliveryCompanyStatusRepository();
})

;
