import * as angular from 'angular';

import 'app/othersettings/admincontact/admincontact';
import 'app/othersettings/admindenymessage/admindenymessage';

angular.module( 'cmsApp.othersettings', [
    'ui.router',
    'ui.bootstrap',
    'cmsApp.othersettings.admincontact',
    'cmsApp.othersettings.denymessage'
])
.config(OthersettingsCtrlConfig)
.controller('OthersettingsCtrl', OthersettingsCtrl);

function OthersettingsCtrlConfig( $stateProvider ) {

}

function OthersettingsCtrl( $q, $scope, $state, $sce, translateService ) {
    /* jshint validthis: true */
    var vm = this;

    vm.selectedItem     = null;
    vm.selectedSubitem  = null;

    vm.loadingEnabled   = false;

    vm.items = [
        {
            titleName:  translateService.instant('othersettings.admincontact.header'),
            id:         'admincontact',
            subitems: []
        },
        {
            titleName:  translateService.instant('othersettings.admindenymessage.header'),
            id:         'admindenymessage',
            subitems: []
        }
    ];

    $scope.$on('selectItem', function(event, data) {
        if(data.item == null) {
            vm.selectedItem = null;
        }
        if(data.subitem == null) {
            vm.selectedSubitem = null;
        }

        vm.items.forEach(function(item, index) {
            if(data.item == item.id) {

                vm.selectedItem = item;

                if(item.subitems.length && data.subitem != null) {
                    item.subitems.forEach(function(subitem, subindex) {
                        if(data.subitem == subitem.id) {
                            vm.selectedSubitem = subitem;
                        }
                    });
                }
            }
        });
    });

    $scope.$on('setLoading', function(event, data) {
        vm.loadingEnabled = data;
    });

    ////////////////////

    vm.activate = function()
    {
        // sushiWOOOK
    };

    vm.selectItem = function(item, subitem)
    {
        vm.selectedItem     = item;
        vm.selectedSubitem  = subitem;

        $state.go('othersettings.' + item.id);
    };

    vm.activate();

    return vm;
}
