import * as angular from 'angular';

import 'app/othersettings/admindenymessage/admindenymessage.factory';

angular.module( 'cmsApp.othersettings.denymessage', [
    'ui.select',
    'ngSanitize',
    'cmsApp.admindenymessage.factory'
])
.config(AdminDenymessageCtrlConfig)
.controller('AdminDenymessageCtrl', AdminDenymessageCtrl);

function AdminDenymessageCtrlConfig( $stateProvider ) {

}

function AdminDenymessageCtrl( $scope, $state, $sce, modalService, AdminDenymessageRepository, DeliveryRestaurantRepository, translateService ) {
    /* jshint validthis: true */
    var advm = this;

    advm.denymessages = [];
    advm.restaurants = [];
    advm.filterRestaurant = [];

    ////////////////////

    advm.activate = function() {
        $scope.$emit('selectItem', { item: 'admindenymessage', subitem: null } );

        AdminDenymessageRepository.getList('').then(function(data) {
            advm.denymessages = data;
            
            DeliveryRestaurantRepository.getList('/all/').then(function(data) {
                data.splice(0, 0, {
                    clientKey:      '',
                    restaurantName: '(' + translateService.instant('othersettings.admindenymessage.no_restaurant') + ')'
                });
                
                advm.restaurants = data;
                
                $scope.$emit('setLoading', false);
            });    
        });
    };

    advm.updateMessage = function(message) {
        $scope.$emit('showNotification', { id: 'aUpdateDenyMessage', loading: true, alertType: 'alert-info', title: 'Sparar...', message: 'Dina ändringar sparas' });

        AdminDenymessageRepository.update(message, message.messageID).then(function (data) {
            $scope.$emit('showNotification', { id: 'aUpdateDenyMessage', loading: false, alertType: 'alert-success', title: 'Sparat!', message: 'Dina ändringar har blivit sparade' });
        });
    };

    advm.removeMessage = function(message) {
        var modalOptions = {
            closeButtonText: translateService.instant('general.cancel'),
            actionButtonText: translateService.instant('general.delete'),
            actionButtonClass: 'btn-danger',
            headerText: translateService.instant('othersettings.admindenymessage.confirm_delete_short'),
            bodyText: translateService.instant('othersettings.admindenymessage.confirm_delete')
        };

        modalService.showModal({}, modalOptions).then(function (result) {
            if(result) {
                AdminDenymessageRepository.remove(message.messageID).then(function (data) {
                    $scope.$emit('showNotification', { id: 'aRemoveMessage', loading: false, alertType: 'alert-success', title: translateService.instant('general.success')});
                        
                    advm.denymessages.splice(advm.denymessages.indexOf(message), 1);
                });
            }
        });
    };
    
    advm.createMessage = function() {
        var modalOptions = {
            textInputShow: true,
            textInputLabel: translateService.instant('othersettings.admindenymessage.message'),
            closeButtonText: translateService.instant('general.cancel'),
            actionButtonText: translateService.instant('general.create'),
            actionButtonClass: 'btn-success',
            headerText: translateService.instant('othersettings.admindenymessage.create_header'),
            bodyText: ''
        };

        modalService.showModal({}, modalOptions).then(function (result) {
            if(result && result !== true) {
                AdminDenymessageRepository.create({ message: result, clientKey: '' }).then(function(obj) {
                    advm.denymessages.push(obj);
                });
            }
        });
    };

    advm.activate();
    return advm;
}
