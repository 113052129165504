import * as angular from 'angular';

import '../../common/modalService/modalService';
import './interceptor/deliveryinterceptor';
import './company/deliverycompanycreate.mobal';

var deliveryCompanyTemplate = require('./company/deliverycompanycreate.mobal.tpl.html');

angular.module( 'cmsApp.delivery', [
    'common.modalService',
    'cmsApp.delivery.interceptor',
    'cmsApp.delivery.company.modal.create'
])
.config(DeliveryCtrlConfig)
.controller('DeliveryCtrl', DeliveryCtrl);

function DeliveryCtrlConfig( $stateProvider ) {

}

function DeliveryCtrl( $scope, $state, $sce, $modal, $location, $anchorScroll, modalService, DeliveryCompanyRepository ) {
    /* jshint validthis: true */
    var vm = this;

    var ustatus = {};

    vm.dCompanies = [];

    vm.sortAs = {};
    vm.sortModel = {};

    vm.selectedCompany = null;
    vm.selectedItem = null;

    vm.loadingEnabled = false;
    
    vm.filterCompanies = '';
    vm.filteredCompanies = [];

    $scope.$on('selectItem', function(event, data) {
        vm.selectedCompany = data.company;
        vm.selectedItem = data.item || null;
    });

    $scope.$on('changeItem', function(event, data) {
    	vm.selectItem(data.company, data.item);
    });

    $scope.$on('refreshCompanies', function(event) {
        getCompanyList();
    });
    
    $scope.$on('duplicateCompany', function(event, obj) {
        var modalOptions = {
            textInputShow:      true,
            textInputLabel:     'Namn på företag',
            closeButtonText:    'Avbryt',
            actionButtonText:   'Duplicera',
            actionButtonClass:  'btn-success',
            headerText:         'Duplicera företag',
            bodyText:           'Ange namn på det nya företaget. Duplicerar enbart företagsinställningar, prismallar, områdesmallar samt allt innehåll i dem.',
            textInputValue:     obj.companyName
        };

        modalService.showModal({}, modalOptions).then(function (result) {
            if(result && result !== true) {
                DeliveryCompanyRepository.duplicate(obj.deliveryCompanyID, { companyName: result }).then(function(obj) {
                    vm.dCompanies.push(obj);
                    vm.selectItem(obj, 'company');
                });
            }
        });
    });

    $scope.$on('deleteCompany', function(event, item) {
        var index = -1;
        angular.forEach(vm.dCompanies, function(obj, objIndex) {
            if(obj.deliveryCompanyID == item.deliveryCompanyID) {
                index = objIndex;
            }
        });

        if(index > -1) {
            DeliveryCompanyRepository.remove(item.deliveryCompanyID);
            vm.dCompanies.splice(index, 1);
            vm.selectedCompany = null;
            vm.selectedItem = null;

            $state.go('delivery');
        }
    });

    $scope.$on('setLoading', function(event, data) {
        vm.loadingEnabled = data;
        
        if(!data) {
            /*$location.hash('thetop');
            $anchorScroll();
            $location.hash('');*/
        }
    });

    ////////////////////

    vm.activate = function() {
        getCompanyList();
    };

    function getCompanyList() {
        DeliveryCompanyRepository.getList().then(function(data) {
            vm.dCompanies = data;
        });
    }

    vm.goToDistancePrices = function() {
        window.location.href = window.location.origin + '/delivery-distance-price/0';
    };

    vm.goToDriverSummaries = () => {
        window.location.href = window.location.origin + '/delivery-driver-summaries';
    };

    vm.goToDeliveryDistricts = () => {
        window.location.href = window.location.origin + '/delivery-districts';
    };

    vm.selectItem = function(company, item) {
        if(company === null && item === null) {
            vm.selectedCompany = null;
            vm.selectedItem = null;
            $state.go('delivery');
            return;
        }
        
        if( 
            (item == 'global' && vm.selectedItem != 'global') || 
            (item == 'polygon' && vm.selectedItem != 'polygon') || 
            (item == 'interceptor' && vm.selectedItem != 'interceptor') || 
            (item == 'timeoutschedule' && vm.selectedItem != 'timeoutschedule') || 
            (item == 'company' && company !== null && (vm.selectedCompany === null || vm.selectedCompany.deliveryCompanyID != company.deliveryCompanyID)) || 
            (item != 'company' && item != vm.selectedItem)
        )
        {
            vm.loadingEnabled = true;
        }

        vm.selectedCompany = company;

        if(item === 'company')
        {
            vm.selectedItem = null;
        }
        else
        {
            vm.selectedItem = item;
        }

        if(company)
        {
            $state.go('delivery.' + item, {deliveryCompanyID: vm.selectedCompany.deliveryCompanyID});
        }
        else
        {
            $state.go('delivery.' + item);
        }
    };

    vm.createCompany = function() {
    	var modalInstance = $modal.open({
            template: deliveryCompanyTemplate,
            controller: 'CreatedeliverycompanyModalCtrl as vm',
            windowClass: 'ro-modal createdeliverycompanymodal modalService',
            resolve: { }
        });

    	modalInstance.result.then(function(company) {
    		
            DeliveryCompanyRepository.create(company).then(function(response) {
                vm.dCompanies.push(response);
                vm.selectItem(response, 'company');
            });
    	});
    };
    
    vm.onFilterChange = function(filter, items) {
        if(items.length === 1) {
            vm.selectItem(items[0], 'company');
        }
    };
    
    vm.activate();

    return vm;
}
