angular.module( 'cmsApp.delivery.financecustom.factory', [])

.factory('DeliveryFinanceCustomRepository', function (Restangular, AbstractRepository) {

    function DeliveryFinanceCustomRepository() {
        AbstractRepository.call(this, Restangular, 'delivery/financecustom', 'deliveryFinanceCustomID');
    }

    AbstractRepository.extend(DeliveryFinanceCustomRepository);

    return new DeliveryFinanceCustomRepository();
})

;
