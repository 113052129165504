import { Injectable } from '@angular/core';
import { DriverService } from './driver.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { BaseStorage } from '../../base/base.storage';
import { Driver } from './driver';

@Injectable()
export class DriverStorage extends BaseStorage {

    public drivers$: Observable<Driver[]>;

    protected drivers: BehaviorSubject<Driver[]>;

    constructor(private driverService: DriverService) {
        super();

        this.drivers = new BehaviorSubject([]);
        this.drivers$ = this.drivers.asObservable();
    }
}
