angular.module('cmsApp.alert.blacklist.modal.addtoblacklist.controller', [])
    .controller('AddToBlacklistController', AddToBlacklistController);

function AddToBlacklistController($modalInstance, restaurants, deliveryCompanies, alertTypes) {
    /* jshint validthis: true */
    var vm = this;

    vm.restaurants = restaurants;

    vm.deliveryCompanies = deliveryCompanies;

    vm.alertTypes = alertTypes;

    vm.blacklistObject = {
        alerterTypeID: null,
        deliveryCompanyID: null,
        clientKey: null
    };

    ////////////////////
    
    vm.ngOnInit = function() {
        
    };

    vm.ok = function() {
        $modalInstance.close(vm.blacklistObject);
    };

    vm.cancel = function() {
        $modalInstance.dismiss('cancel');
    };

    return vm.ngOnInit();
}