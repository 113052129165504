angular.module( 'cmsApp.delivery.restaurant', [
    'ui.router',
    'ui.bootstrap',
    'xeditable',
    'cmsApp.delivery.interceptor.factory'
])
.config(DeliveryRestaurantCtrlConfig)
.controller('DeliveryRestaurantCtrl', DeliveryRestaurantCtrl);

function DeliveryRestaurantCtrlConfig( $stateProvider ) {

}

function DeliveryRestaurantCtrl( $scope, $state, $sce, modalService, DeliveryCompanyRepository, DeliveryRestaurantRepository, DeliveryInterceptorRepository, deliveryCompanyID, translateService, ENV ) {
    /* jshint validthis: true */
    var cvm = this;

    var ustatus = {};

    cvm.company = {};

    cvm.restaurants = {};

    cvm.templates = {};

    cvm.searchRestaurants = [];
    cvm.addRestaurant = null;

    cvm.repo = DeliveryRestaurantRepository;
    
    cvm.interceptors = [];

    cvm.brand = ENV.APP_BRAND;

    ////////////////////

    cvm.activate = function() {
        DeliveryCompanyRepository.get("", deliveryCompanyID).then(function(data) {
            cvm.company = data;
            $scope.$emit('selectItem', { company: cvm.company, item: 'restaurants' });

            DeliveryCompanyRepository.getList('/' + deliveryCompanyID + '/restaurants').then(function(data) {
                cvm.restaurants = data;

                DeliveryCompanyRepository.getList('/' + deliveryCompanyID + '/templates').then(function(data) {
                    cvm.templates = data;
                    cvm.templates.unshift({
                        'deliveryCompanyID': 0,
                        'deliveryPriceplanID': 0,
                        'deliveryTemplateID': 0,
                        'priceType': 0,
                        'templateName': translateService.instant('delivery.restaurant.unnamed')
                    });

                    $scope.$emit('setLoading', false);
                });
            });
        });
        
        DeliveryInterceptorRepository.getList().then(function(data) {
            cvm.interceptors = data;
        });
    };

    cvm.refreshRestaurants = function (search) {
        cvm.repo.getList('/search/'+search).then(function(data) {
            cvm.searchRestaurants = data;
        });
    };

    cvm.selectRestaurant = function (item, model) {
        item.deliveryrestaurant.restaurant = {
            restaurantName:     item.restaurantName,
            clientKey:          item.clientKey
        };
        cvm.restaurants.push(item.deliveryrestaurant);
        cvm.addRestaurant = null;
    };

    cvm.updateRestaurant = function(updateItem, restaurant, data) {
        var fieldLinks = {
            subitem: {
                hungrigPremiumTemplate:     'hungrig_premium_template',
                hungrigStandardTemplate:    'hungrig_standard_template',
                premiumTemplate:            'premium_template',
                standardTemplate:           'standard_template'
            },
            company: {
                hungrigPremiumTemplate:     'hungrigPremiumCompany',
                hungrigStandardTemplate:    'hungrigStandardCompany',
                premiumTemplate:            'premiumCompany',
                standardTemplate:           'standardCompany'
            }
        };

        var subitemFld  = fieldLinks.subitem[updateItem];
        var companyFld  = fieldLinks.company[updateItem];
        var found       = false;

        angular.forEach(cvm.templates, function(obj) {
            if(obj.deliveryTemplateID == data) {
                restaurant[subitemFld] = angular.copy(obj);
                found = true;
            }
        });

        if(found) {
            $scope.$emit('showNotification', {
                id: 'dRestaurantUpdate',
                loading: true,
                alertType: 'alert-info',
                title: translateService.instant('general.loading')
            });

            restaurant[updateItem]  = cvm.company.deliveryCompanyID;

            var updatedData = { deliveryRestaurantID: restaurant.deliveryRestaurantID };
            updatedData[companyFld] = cvm.company.deliveryCompanyID;
            updatedData[updateItem] = data;

            cvm.repo.update(updatedData, restaurant.deliveryRestaurantID).then(function () {
                $scope.$emit('showNotification', {
                    id: 'dRestaurantUpdate',
                    loading: false,
                    alertType: 'alert-success',
                    title: translateService.instant('general.success')
                });
            });

            return true;
        }

        return false;
    };
    
    cvm.updateInterceptors = function(restaurant) {
        console.log('save', restaurant);
        
        $scope.$emit('showNotification', {
            id: 'dRestaurantUpdate',
            loading: true,
            alertType: 'alert-info',
            title: translateService.instant('general.loading')
        });
        
        cvm.repo.update(restaurant, restaurant.deliveryRestaurantID).then(function () {
            $scope.$emit('showNotification', {
                id: 'dRestaurantUpdate',
                loading: false,
                alertType: 'alert-success',
                title: translateService.instant('general.success')
            });
        });
    };

    cvm.activate();
    return cvm;
}