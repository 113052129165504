angular.module( 'cmsApp.alerter.factory', [
    'ui.router',
    'ui.bootstrap'
])

.factory('AlerterRepository', function (Restangular, AbstractRepository) {

    function AlerterRepository() {
        AbstractRepository.call(this, Restangular, 'alerters');
    }

    AbstractRepository.extend(AlerterRepository);

    return new AlerterRepository();
})

;
