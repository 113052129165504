angular
    .module('cmsApp.delivery.district.factory', [])
    .factory('DeliveryDistrictRepository', function($q, $timeout, Restangular, AbstractRepository) {

        function DeliveryDistrictRepository() {

            var repo = this;

            repo.cache = [];
            repo.promise = null;

            repo.getList = function(path, params, headers) {
                if (path || params || headers) {
                    return this.restangular.all(this.route + (!!path ? path : "")).getList(params, headers);
                }

                if (!repo.cache.length) {
                    if (!repo.promise) {
                        repo.promise = $q(function(resolve, reject) {
                            repo.restangular.all(repo.route).getList(params, headers).then(function(data) {
                                repo.cache = data;
                                resolve(data);
                            }, function(error) {
                                reject(error);
                            });
                        });

                        $timeout(function() {
                            repo.cache = [];
                            repo.promise = null;
                        }, 5000);
                    }
                    return repo.promise;
                } else {
                    if (!repo.promise) {
                        repo.promise = $q(function(resolve, reject) {
                            resolve(repo.cache);
                        });
                    }

                    return repo.promise;
                }
            };

            AbstractRepository.call(this, Restangular, 'delivery/district');
        }

        AbstractRepository.extend(DeliveryDistrictRepository);

        return new DeliveryDistrictRepository();
    });
