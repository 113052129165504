let template = require('./summary-datepicker.component.html');

export class SummaryDatepickerComponent {
    public static readonly controller = SummaryDatepickerComponent;
    public static readonly controllerAs: string = 'vm';
    public static readonly template: string = template;
    public static readonly bindings: any = {
        model: '='
    };

    public open: boolean = false;
    
    public toggleOpen ($event) {
        $event.preventDefault();
        $event.stopPropagation();

        this.open = !this.open;
    }
}