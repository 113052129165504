
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Image } from './image';
import { Observable } from 'rxjs';
import Cropper from 'cropperjs';
import { Upload } from './upload/upload';
import { ImageType } from './image-type';
import { ImageLibraryConfig } from '../image-library.config';
import { ErrorFactory, HungrigClient, ResponseFactory } from '@ro-ngx/core';

@Injectable()
export class ImageService {

    constructor(public imageLibraryConfig: ImageLibraryConfig,
                public hungrigClient: HungrigClient) {
    }

    public url(url: string = ''): string {
        return this.imageLibraryConfig.apiUrl + '/image' + url;
    }

    public getImages(imageType: ImageType, clientKey?: string): Observable<Image[]> {
        const params = {
            imageType: imageType
        };

        if (clientKey) {
            params['clientKey'] = clientKey;
        }

        return this.hungrigClient.get(this.url(), { search: params }).pipe(
            map(ResponseFactory.toData),
            catchError(ErrorFactory.throwObservable));
    }

    public getImage(imageID: number): Observable<Image> {
        return this.hungrigClient.get(this.url('/' + imageID)).pipe(
            map(ResponseFactory.toData),
            catchError(ErrorFactory.throwObservable));
    }

    public createImage(upload: Upload, cropperData: Cropper.Data): Observable<Image> {
        const body = Object.assign(cropperData, upload, { imageUploadID: upload.imageUploadID });

        return this.hungrigClient.post(this.url(), body).pipe(
            map(ResponseFactory.toData),
            catchError(ErrorFactory.throwObservable));
    }

    public deleteImage(itemID: number): Observable<number> {
        return this.hungrigClient.delete(this.url('/' + itemID)).pipe(
            map(ResponseFactory.toData),
            catchError(ErrorFactory.throwObservable));
    }
}
