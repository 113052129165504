
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BaseService } from '../base/base.service';
import { ErrorFactory, HungrigClient, ResponseFactory } from '@ro-ngx/core';
import { Observable } from 'rxjs';
import { AppConfig } from './app-config';

@Injectable()
export class AppConfigService extends BaseService {
    public resourceUrl: string = 'appconfigs/';

    constructor(hungrigClient: HungrigClient) {
        super(hungrigClient);
    }

    public getRestaurantAppConfig(clientKey: string): Observable<AppConfig> {
        return this.hungrigClient.get(this.url(`cms/restaurants/${clientKey}/appconfig`, false)).pipe(
            map(ResponseFactory.toData),
            catchError(ErrorFactory.throwObservable));
    }

    public updateAppConfig(id: number, config: Partial<AppConfig>): Observable<AppConfig> {
        return this.hungrigClient.put(this.url(`${id}`), config).pipe(
            map(ResponseFactory.toData),
            catchError(ErrorFactory.throwObservable));
    }
}
