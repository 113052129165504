
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BaseService } from 'app/http/base/base.service';
import { HungrigClient } from '@ro-ngx/core';
import { Restaurant } from './restaurant';
import { Observable } from 'rxjs';

@Injectable()
export class RestaurantService extends BaseService {
    public resourceUrl: string = 'campaigngroups/';

    constructor(public hungrigClient: HungrigClient) {
        super(hungrigClient);
    }

    public getGroupRestaurants(groupID: number): Observable<Restaurant[]> {
        const uri = `${groupID}/restaurants`;
        return this.hungrigClient.get(this.url(uri)).pipe(
            map(this.toResponse),
            map((response) => response.data),);
    }

    public createGroupRestaurant(groupID: number, restaurant: Restaurant): Observable<Restaurant> {
        const uri = `${groupID}/restaurants`;
        return this.hungrigClient.post(this.url(uri), restaurant).pipe(
            map(this.toResponse),
            map((response) => response.data),);
    }

    public deleteGroupRestaurant(groupID: number, restaurant: Restaurant): Observable<Restaurant> {
        const uri = `${groupID}/restaurants/${restaurant.campaignGroupRestaurantID}`;
        return this.hungrigClient.delete(this.url(uri)).pipe(
            map(this.toResponse),
            map((response) => response.data),);
    }
}
