
import {map, first, flatMap, catchError} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import * as FileSaver from 'file-saver';
import { Injectable } from '@angular/core';
import { AuthenticationService, AuthorizedService } from "@ro-ngx/authentication";
import { ErrorFactory, HungrigClient, ResponseFactory, ParamsUtil } from '@ro-ngx/core';
import { Observable } from 'rxjs';
import { ResponseContentType } from '@angular/http';
import { ActivatedRoute } from '@angular/router';
import { FinanceReportService} from './finance-report.service'


@Component({
    selector: 'finance-report',
    template: require('./finance-report.component.html'),
    providers: [FinanceReportService],
    styles: [require('./finance-report.less')]
})

@Injectable()

export class FinanceReportComponent implements OnInit {
        constructor(
        protected authenticationService: AuthenticationService,
        protected authorizedService: AuthorizedService,
        public hungrigClient: HungrigClient,
        protected route: ActivatedRoute,
        protected financeReportService: FinanceReportService

    ) {

        const accessToken = localStorage.getItem('auth.access_token');

        if (accessToken && !this.hungrigClient.requestOptions.headers.has('Authorization')) {
            hungrigClient.requestOptions.headers.set('Authorization', 'Bearer ' + accessToken);
        }
        
    }

    public loading: boolean;
    public clicked: boolean;
    public formats: any[];

    public year: string = 
    moment().subtract(1, 'month').format('YYYY');

    public maxyear: string = moment().subtract(1, 'month').format('YYYY')

    public minyear: string;

    public month: string = moment().subtract(1, 'month').format('MM');
    public maxmonth: string;
    reportlist = [];
    header = true;


    public getCustomReport(formats: 'csv'): Observable<Blob> {
        const SetYear = this.year.toString();
        const SetMonth = this.month.toString();
        const CustomReportUrl = this.hungrigClient.baseUrl + `/finance/kpi-CustomFeeReport?year=${SetYear}&month=${SetMonth}`;

        return this.hungrigClient.get(CustomReportUrl, {
            responseType: ResponseContentType.Blob }).pipe(
            map((response) => response.blob()),
            catchError(ErrorFactory.throwObservable));
    }


    public getExportData(): void { 
      this.reportlist = [];
      this.loading = true;
      const SetYear = this.year.toString();
      const SetMonth = this.month.toString();

      this.financeReportService.getFinanceReport(SetYear, SetMonth)
      .subscribe((resp) => {
        this.reportlist.push(resp);
        this.loading = false;
        this.clicked = false;
      })
       
       const format = this.formats[0].value08
       this.authorizedService.user$.pipe(
             first(),
             flatMap(() => this.getCustomReport(format))

        )
       .subscribe((blob) => FileSaver.saveAs(blob, `CustomReport${SetYear}${SetMonth}.csv`));
    }  

    /**
     * NgOnInit
     */
    public ngOnInit(): void {
        this.formats = [{
            value: 'csv', text: 'CSV'
        }]
      
       const MaxMonth = moment().subtract(1, 'month').format('MM');
       this.maxmonth = MaxMonth;

       const MinYear = moment().subtract(3, 'years').format('YYYY');
       this.minyear = MinYear;
    }

    /**
     * NgOnDestroy
     */
    public ngOnDestroy(): void {
    }
    
}
