angular.module( 'cmsApp.delivery.driver.modal.create', [
])
.controller('CreatedeliverydriverModalCtrl', CreatedeliverydriverModalCtrl);

function CreatedeliverydriverModalCtrl( $modalInstance, deliveryCompany ) {
    /* jshint validthis: true */
    const vm = this;

    vm.deliveryCompany = deliveryCompany;

    vm.ok = function(driver) {
        $modalInstance.close(driver);
    };

    vm.cancel = function() {
        $modalInstance.dismiss('cancel');
    };

    return vm;
}
