import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { RestaurantService } from "./restaurant.service";
import { BaseStorage } from '../base/base.storage';

@Injectable()
export class RestaurantStorage extends BaseStorage {

    public restaurants$: Observable<any[]>;
    public locations$: Observable<any[]>;
    protected restaurants: BehaviorSubject<any[]>;
    protected locations: BehaviorSubject<any[]>;

    constructor(private restaurantService: RestaurantService) {
        super();
        this.restaurants  = new BehaviorSubject([]);
        this.locations    = new BehaviorSubject([]);
        this.restaurants$ = this.restaurants.asObservable();
        this.locations$   = this.locations.asObservable();
        this.initState();
    }

    protected initState(): void {
        this.restaurantService.getRestaurantsSlim()
            .subscribe((restaurants) => {
                this.restaurants.next(restaurants);
            });
        this.restaurantService.getLocations()
            .subscribe((locations) => {
                this.locations.next(locations);
            });
    }
}
