angular.module( 'cmsApp.status.repository', [])
.factory('StatusRepository', function (Restangular, AbstractRepository) {

    function StatusRepository() {
        AbstractRepository.call(this, Restangular, 'status');
    }

    AbstractRepository.extend(StatusRepository);

    return new StatusRepository();
})

;
