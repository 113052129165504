
import {share} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CollectionStorage } from '@ro-ngx/core';
import { Observable } from 'rxjs';
import { DeliveryDistancePrice } from './delivery-distance-price';
import { DeliveryDistancePriceService } from './delivery-distance-price.service';

@Injectable()
export class DeliveryDistancePriceStorage extends CollectionStorage<DeliveryDistancePrice> {

    public distancePrices$: Observable<DeliveryDistancePrice[]>;

    constructor(protected dDistancePriceService: DeliveryDistancePriceService) {
        super();

        this.distancePrices$ = this.subject.asObservable();
    }

    public getDistancePrices(relations: string[] = []): Observable<DeliveryDistancePrice[]> {
        const obs = this.dDistancePriceService.getDistancePrices(relations).pipe(share());

        return this.unionObservable(obs, 'dDistancePriceID');
    }
}
