import { AbstractRepository } from '../../../abstract/abstract-repository';

export class DeliverySummaryRepository extends AbstractRepository {
    
    constructor (Restangular) {
        super(Restangular, 'finance/delivery/company');
    }

    public getSummary (startDate: string, endDate: string, groupBy: string) {
        return this.restangular.one(this.route).get({
            start: startDate,
            end: endDate,
            groupBy: groupBy
        });
    } 

    public getGroupingOptions () {
        return this.restangular.one(this.route).one('grouping-options').get();
    }

    public getEconomyPeriods () {
        return this.restangular.one(this.route).one('economy-periods').get();
    }
}
