var template = require('./deliverytemplatearea.tpl.html');

angular.module( 'cmsApp.delivery.template.area', [
    'ui.bootstrap',
    'common.modalService',
    'common.ZipcodeService',
    'ui.select',
    'ngSanitize',
    'ngFileUpload'
])
.directive('roDeliveryTemplateArea', roDeliveryTemplateArea);

function roDeliveryTemplateArea() {
    return {
        restrict: 'EA',    
        scope: {
            repo:                   '=',
            company:                '=',
            priceplans:             '=',
            template:               '=',
            roDeliveryTemplateArea: '=',
            interceptors:           '='
        },
        controller: function( $q, $scope, $timeout, ENV, Upload, modalService, ZipcodeService, DeliveryTemplateAreaRepository,
            DeliveryZipspanRepository, DeliveryCompanyRepository, translateService ) {
            /* jshint validthis: true */
            var vm = this;

            vm.selectPostalCodes = [];
            vm.roDeliveryTemplateArea.postalCodes = [];

            vm.ZipCodes     = [];
            vm.fromZipCode  = '';
            vm.toZipCode    = '';
            
            vm.geoCodes     = [];
            vm.geoZipCode   = '';
            vm.geoFrom      = '0.0';
            vm.geoTo        = '0.0';
            
            vm.zipMode      = 0;
            
            vm.sort         = 'zip';
            vm.sortOptions  = {
                'zip':      'Postnummer',
                'created':  'Tillagd'
            };
            
            vm.fileProgress = -1;
            vm.fileError    = null;
            
            $scope.$watch('vm.roDeliveryTemplateArea.selected', function(newValue, oldValue) {
                if(newValue) {
                    vm.reload();
                }
            });

            vm.refresh = function () {
                vm.roDeliveryTemplateArea.postalCodes = angular.copy(vm.roDeliveryTemplateArea.deliveryzipspans);
                vm.roDeliveryTemplateArea.active = vm.roDeliveryTemplateArea.active == 1;
            };

            vm.reload = function () {
                if(vm.roDeliveryTemplateArea.selected) {
                    DeliveryTemplateAreaRepository.get("/" + vm.roDeliveryTemplateArea.deliveryAreaID + "/zipspans/" + vm.sort, "", {}, {}).then(function (data) {
                        vm.roDeliveryTemplateArea.deliveryzipspans = data;
                        vm.refresh();
                    });
                }
            };

            vm.refreshZipCodes = function (search) {
                if(search) {
                    ZipcodeService.getZipCodes(search).then(function(data) {
                        vm.ZipCodes = data;
                    });
                }
            };
            
            vm.ignore = function() {
                // 
            };
            
            vm.doFocus = function(focus) {
                if (focus == 'toZipCode') {
                    $scope.$broadcast('toZipCodeFocus');
                } else if(focus == 'addZipSpan') {
                    //$scope.$broadcast('addZipSpanFocus');
                }
            };

            vm.addZipSpan = function () {
                if(vm.fromZipCode && vm.toZipCode) {
                    
                    $scope.$emit('showNotification', { id: 'dZipCreate', loading: true, alertType: 'alert-info', title: translateService.instant('general.loading') });
                    
                    DeliveryZipspanRepository.create({
                        deliveryAreaID:     vm.roDeliveryTemplateArea.deliveryAreaID,
                        minZip:             vm.fromZipCode.replace(' ', ''),
                        maxZip:             vm.toZipCode.replace(' ', '')
                    }).then(function(data) {
                        $scope.$emit('showNotification', { id: 'dZipCreate', loading: false, alertType: 'alert-success', title: translateService.instant('general.saved') });
                        vm.roDeliveryTemplateArea.postalCodes.push(data);
                    }, function(error) {
                        if(error.data.data.duplicates) {
                            $scope.$emit('showNotification', { alertType: 'alert-danger', title: translateService.instant('general.error'), message: translateService.instant('delivery.templatearea.error_postal_code_exists')});
                            vm.reload(); // in case there are more duplicates the API removes them, so reload to check
                        }
                    });
                    
                    vm.fromZipCode = '';
                    vm.toZipCode = '';
                }
                
                vm.fromZipCode = '';
                vm.toZipCode = '';
            };
            
            vm.remove = function(item) {
                $scope.$emit('showNotification', { id: 'dZipRemove', loading: true, alertType: 'alert-info', title: translateService.instant('general.loading') });
                
                DeliveryZipspanRepository.remove(item.deliveryZipSpanID).then(function(data) {
                    $scope.$emit('showNotification', { id: 'dZipRemove', loading: false, alertType: 'alert-success', title: translateService.instant('general.saved') });
                });
            };

            vm.save = function () {
                $scope.$emit('showNotification', { id: 'dAreaSave', loading: true, alertType: 'alert-info', title: translateService.instant('general.loading') });

                // save the area
                // copy it first to extract the priceplanid correctly
                var saveArea = angular.copy(vm.roDeliveryTemplateArea);
                saveArea.deliveryPriceplanID = parseInt(saveArea.deliveryPriceplanID);
                
                var promises = [];
                promises.push(DeliveryTemplateAreaRepository.update(saveArea, saveArea.deliveryAreaID));
                
                $q.all(promises).then(function () {
                    $scope.$emit('showNotification', { id: 'dAreaSave', loading: false, alertType: 'alert-success', title: translateService.instant('general.saved') });
                    vm.reload();
                });
            };
            
            vm.loadGeo = function() {
                ZipcodeService.getByRadius(vm.roDeliveryTemplateArea.deliveryAreaID, vm.geoZipCode, vm.geoFrom, vm.geoTo).then(function(data) {
                    vm.geoCodes = data;
                });
            };
            
            vm.batchGeo = function() {
                $scope.$emit('showNotification', { id: 'dBatchGeoSave', loading: true, alertType: 'alert-info', title: translateService.instant('general.loading') });
                
                DeliveryZipspanRepository.create('/batch', vm.geoCodes).then(function(data) {
                    if(!data.length) {
                        $scope.$emit('showNotification', { alertType: 'alert-danger', title: translateService.instant('general.error'), message: translateService.instant('delivery.templatearea.error_postal_code_exists') });
                    } else {
                        $scope.$emit('showNotification', { id: 'dBatchGeoSave', loading: false, alertType: 'alert-success', title: translateService.instant('general.saved'), 
                            message: translateService.instant('delivery.templatearea.n_postal_codes_added', { n: data.length })});
                    }
                    
                    vm.roDeliveryTemplateArea.postalCodes = vm.roDeliveryTemplateArea.postalCodes.concat(data);
                }, function(error) {
                    $scope.$emit('showNotification', { alertType: 'alert-danger', title: translateService.instant('general.error') });
                    vm.reload();
                });
            };
            
            vm.batchFile = function() {
                $scope.$emit('showNotification', { id: 'dBatchFileSave', loading: true, alertType: 'alert-info', title: translateService.instant('general.loading') });
                
                DeliveryZipspanRepository.create('/batch', vm.fileCodes).then(function(data) {
                    if(!data.length) {
                        $scope.$emit('showNotification', { alertType: 'alert-danger', title: translateService.instant('general.error'), message: translateService.instant('delivery.templatearea.error_postal_code_exists') });
                    } else {
                        $scope.$emit('showNotification', { id: 'dBatchFileSave', loading: false, alertType: 'alert-success', title: translateService.instant('general.saved'), 
                            message: translateService.instant('delivery.templatearea.n_postal_codes_added', { n: data.length }) });
                    }
                    
                    vm.roDeliveryTemplateArea.postalCodes = vm.roDeliveryTemplateArea.postalCodes.concat(data);
                }, function(error) {
                    $scope.$emit('showNotification', { alertType: 'alert-danger', title: translateService.instant('general.error') });
                    vm.reload();
                });
            };
            
            vm.uploadFile = function (file) {
                vm.fileProgress = -1;
                vm.fileError    = null;
                
                Upload.upload({
                    url: ENV.BASE_URL + '/delivery/zipcodes/csv',
                    data: { file: file }
                }).then(function (resp) {
                    vm.fileProgress = 101;
                    var data = resp.data.data;
                    
                    vm.fileCodes = ZipcodeService.groupZipsToObj(data, vm.roDeliveryTemplateArea.deliveryAreaID);
                }, function (resp) {
                    vm.fileError = 'Fel: ' + resp.status;
                }, function (evt) {
                    var tsFix: any = 100.0 * evt.loaded / evt.total;
                    var progressPercentage = parseInt(tsFix);
                    
                    vm.fileProgress = progressPercentage;
                });
            };
            
            vm.refreshTextImport = function() {
                var matches = vm.textImport.match(/\d{2,3}[\- ]?\d{2,3}/g);
                
                if(!matches) {
                    vm.fileCodes = [];
                    return;
                }
                
                matches = ZipcodeService.groupNums(matches);
                
                vm.fileCodes = ZipcodeService.groupZipsToObj(matches, vm.roDeliveryTemplateArea.deliveryAreaID);
            };

            vm.refresh();
        },
        controllerAs: 'vm',
        bindToController: true,
        template: template
    };
}
