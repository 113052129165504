import * as angular from 'angular';

import 'app/appthemes/apptheme/appthemefont/appthemefont.factory';

var template = require('./appthemefont.tpl.html');

angular.module( 'cmsApp.appthemes.apptheme.font', [
    'cmsApp.appthemes.apptheme.font.factory'
])
.directive('roAppthemeFont', roAppthemeFont);

function roAppthemeFont() {
    return {
        restrict: 'EA',    
        scope: {
            roAppthemeFont: '=',
            font: '=',
            bold: '='
        },
        controller: function( $scope, AppthemeFontFactory ) {
            /* jshint validthis: true */
            var vm = this;
            
            vm.fonts = [];
            
            vm.activate = function() {
                // we're on!
                vm.fonts = AppthemeFontFactory.getFontList();
            };
            
            vm.activate();
        },
        controllerAs: 'vm',
        bindToController: true,
        template: template
    };
}

