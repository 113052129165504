import { SeoService } from "../global/seo/seo.service";
import IComponentController = angular.IComponentController;
const template = require('./help.component.html');

export class HelpComponent implements IComponentController {
    static controller: any = HelpComponent;
    static controllerAs: string = 'vm';
    static template = template;

    constructor(protected seoService: SeoService) {  }

    $onInit() {
        this.seoService.setTitle('Hjälp komponenten!');
    }
}
