angular.module('cmsApp.deliveryfinance.rescheduleActivities', [])
    .controller('RescheduleActivitiesModalCtrl', RescheduleActivitiesModalCtrl);

function RescheduleActivitiesModalCtrl($modalInstance, activities) {
    /* jshint validthis: true */
    const vm = this;

    vm.activities = activities;

    vm.cancel = () => {
        $modalInstance.dismiss('cancel');
    };

    return vm;
}
