import * as angular from 'angular';

import 'common/modalService/modalService';

angular.module( 'cmsApp.appthemes', [
    'common.modalService',
    'ui.router',
    'ui.bootstrap'
])
.config(AppthemesCtrlConfig)
.controller('AppthemesCtrl', AppthemesCtrl);

function AppthemesCtrlConfig( $stateProvider ) {

}

function AppthemesCtrl( $scope, $state, $sce, $location, $anchorScroll, modalService, AppthemeRepository, translateService ) {
    /* jshint validthis: true */
    var vm = this;
    
    vm.loadingEnabled = false;
    
    vm.appthemes = [];
    vm.selectedItem = 0;
    vm.subSelect = '';
    
    $scope.$on('setLoading', function(event, data) {
        vm.loadingEnabled = data;
    });
    
    $scope.$on('selectItem', function(event, data) {
        vm.selectItem(data.appThemeID, data.subItem);
    });
    
    $scope.$on('updateTheme', function(event, data) {
        if(vm.appthemes && data) {
            vm.appthemes.forEach(function (at, index) {
                if(at.appThemeID == data.appThemeID) {
                    vm.appthemes.splice(index, 1);
                    vm.appthemes.splice(index, 0, angular.copy(data));
                }
            });
        }
    });
    
    $scope.$on('removeTheme', function(event, data) {
        if(vm.appthemes && data) {
            vm.appthemes.forEach(function (at, index) {
                if(at.appThemeID == data.appThemeID) {   
                    AppthemeRepository.remove(at.appThemeID).then(function() {
                        vm.appthemes.splice(index, 1);
                        $state.go('appthemes');
                    });
                 }
            });
        }
    });
    
    $scope.$on('duplicateTheme', function(event, data) {
        var modalOptions = {
            textInputShow: true,
            textInputLabel: translateService.instant('app_themes.create_modal.name'),
            closeButtonText: translateService.instant('general.cancel'),
            actionButtonText: translateService.instant('app_themes.create_theme'),
            actionButtonClass: 'btn-success',
            headerText: translateService.instant('app_themes.create_modal.title'),
            bodyText: translateService.instant('app_themes.create_modal.body')
        };

        modalService.showModal({}, modalOptions).then(function (result) {
            if(result && result !== true) {
                AppthemeRepository.create(angular.extend({}, data, { appThemeName: result })).then(function(obj) {
                    vm.appthemes.push(obj);
                    vm.selectItem(obj.appThemeID);
                });
            }
        });
    });
    
    vm.activate = function () {
        // do some activating :D
        AppthemeRepository.getList().then(function(data) {
            vm.appthemes = data;
        });
    };
    
    vm.selectItem = function(appThemeID, subItem) {
        vm.selectedItem = appThemeID;
        vm.subSelect = subItem || null;
        vm.loadingEnabled = true;
        $state.go('appthemes.' + (subItem || 'apptheme'), { appThemeID: appThemeID });
    };
    
    vm.createTheme = function() {
        var modalOptions = {
            textInputShow: true,
            textInputLabel: translateService.instant('app_themes.create_modal.name'),
            closeButtonText: translateService.instant('general.cancel'),
            actionButtonText: translateService.instant('app_themes.create_theme'),
            actionButtonClass: 'btn-success',
            headerText: translateService.instant('app_themes.create_modal.title'),
            bodyText: translateService.instant('app_themes.create_modal.body')
        };

        modalService.showModal({}, modalOptions).then(function (result) {
            if(result && result !== true) {
                AppthemeRepository.create({ appThemeName: result }).then(function(obj) {
                    vm.appthemes.push(obj);
                    vm.selectItem(obj.appThemeID);
                });
            }
        });
    };
    
    
    vm.activate();
    
    return vm;
}