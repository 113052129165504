import { NgModule } from '@angular/core';
import { ArticlesModule } from "@ro-ngx/articles";
import { RouterModule } from "@angular/router";
import { ArticleListComponent } from "./article-list.component";
import { TranslateModule } from "@ngx-translate/core";
import { RoNg2Module } from "@ro-ngx/core";
import { SideBarComponent } from "./sidebar/sidebar.component";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { MainComponent } from "./main/main.component";
import { ArticleComponent } from "./main/article/article.component";
import { ArticleBodyComponent } from "./main/article-body/article-body.component";
import { MarkdownModule } from "@ro-ngx/markdown";

@NgModule({
    imports: [
        CommonModule,
        MarkdownModule,
        ArticlesModule,
        TranslateModule,
        ReactiveFormsModule,
        RoNg2Module,
        RouterModule.forChild([
            {
                path: '',
                component: ArticleListComponent,
                children: [
                    {
                        path: ':articleID',
                        component: MainComponent
                    }
                ]
            }
        ])
    ],
    exports: [],
    declarations: [
        ArticleListComponent,
        SideBarComponent,
        MainComponent,

        ArticleComponent,
        ArticleBodyComponent,
    ],
    providers: [],
})
export class ArticleListModule {
}
