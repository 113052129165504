import * as angular from 'angular';

declare var google: any;

import 'app/delivery/polygon/polygon.factory';
import 'common/ZipcodeService/ZipcodeService';

angular.module( 'cmsApp.delivery.polygon', [
    'ui.bootstrap',
    'ui.select',
    'cmsApp.delivery.polygon.factory',
    'common.ZipcodeService',
    'ngSanitize'
])
.config(DeliveryPolygonCtrlConfig)
.controller('DeliveryPolygonCtrl', DeliveryPolygonCtrl);

function DeliveryPolygonCtrlConfig( $stateProvider ) {

}

function DeliveryPolygonCtrl(
    $scope,
    $state,
    $sce,
    $q,
    $timeout,
    DeliveryPolygonRepository,
    DeliveryCompanyRepository,
    DeliveryTemplateAreaRepository,
    NgMap,
    ZipcodeService,
    modalService,
    translateService,
    ENV
) {
    /* jshint validthis: true */
    var pvm = this;
    
    pvm.zoomLevel   = 13;
    pvm.loading     = true;
    pvm.map         = null;
    pvm.showMap     = false;
    pvm.polygon     = null;
    pvm.output      = '';
    pvm.showImport  = false;
    pvm.zipcodes    = [];
    
    pvm.centerZip   = '';
    pvm.radius      = '';
    
    pvm.zipPositions = [];
    
    pvm.companies = [];
    pvm.templates = [];
    pvm.import = {
        deliveryCompanyID:  0,
        deliveryTemplate:   null,
        deliveryAreaID:     0
    };
    
    // default triangle coords
    pvm.polygonCoords = [];
    
    pvm.fullscreen = false;

    ////////////////////
    
    /**
     * Activate is our main() function
     */
    pvm.activate = function() {
        switch (ENV.APP_BRAND.toLowerCase()) {
            case 'glodny':
                pvm.polygonCoords = [
                    new google.maps.LatLng(52.4324098, 16.9515461),
                    new google.maps.LatLng(52.4207909, 16.9372982),
                    new google.maps.LatLng(52.4138285, 16.9407315),
                ];
                break;
            case 'Whitelabel':
            default:
                pvm.polygonCoords = [
                    new google.maps.LatLng(55.70019,13.178145),
                    new google.maps.LatLng(55.720189999999995,13.178145),
                    new google.maps.LatLng(55.71019,13.198145),
                ];
                break;
        }

        $scope.$emit('selectItem', { company: null, item: 'polygon' } );

        DeliveryCompanyRepository.getList().then(function(data) {
            pvm.companies = data;
        });

        setTimeout(function () {
            pvm.showMap = true;

            $scope.$apply(function() {

                $scope.$emit('setLoading', false);

                NgMap.getMap().then(function(map) {
                    pvm.map = map;

                    pvm.polygon = pvm.map.shapes.polygon;

                    pvm.polygon.setMap(pvm.map);

                    var bounds = new google.maps.LatLngBounds();

                    for (var i = 0; i < pvm.polygonCoords.length; i++) {
                        bounds.extend(pvm.polygonCoords[i]);
                    }

                    pvm.zoomLevel = pvm.getBoundsZoomLevel(bounds);
                    pvm.map.setCenter(bounds.getCenter());

                    pvm.loading = false;
                });
            }, 0);
        });
    };

    /**
     * Toggle fullscreen mode
     */
    pvm.toggleFullscreen = function() {
        pvm.loading = true;

        pvm.fullscreen = !pvm.fullscreen;
        
        var center = pvm.map.getCenter();
        pvm.showMap = false;
        
        $timeout(function() {
            pvm.showMap = true;
            
            $timeout(function() {
                pvm.map.setCenter(center);
                pvm.loading = false;
            }, 0);
        }, 0);
    };
    
    /**
     * Log the current coordinates of the polygon
     */
    pvm.logPolygonCoordinates = function() {
        var len = pvm.polygon.getPath().getLength();
        var htmlStr = "";
        
        for (var i = 0; i < len; i++) {
            htmlStr += "\n" + pvm.polygon.getPath().getAt(i).toUrlValue(5);
        }
        
        console.log(htmlStr);
    };
    
    /**
     * Fetch zips by radius (raw)
     * @param  {String}  centerZip   Our center zip, where we'll originate
     * @param  {Integer} minRadius  The minimum radius
     * @param  {Integer} maxRadius  The maximum radius
     */
    pvm.fetchByRadius = function (centerZip, minRadius, maxRadius) {
        pvm.loading = true;
        ZipcodeService.getByRadiusRaw(centerZip, minRadius, maxRadius).then(function(data) {
            pvm.zipPositions = data;
            
            pvm.loading = false;
        });
    };
    
    /**
     * Search for a zipcode 
     * 
     * @param  {String} search Zipcode to search for
     */
    pvm.refreshZipCodes = function (search) {
        if(search) {
            pvm.loading = true;
            ZipcodeService.getZipCodesRaw(search).then(function(data) {
                pvm.ZipCodes = data;
                pvm.loading = false;
            });
        }
    };
    
    pvm.resetZip = function () {
        pvm.map.setCenter(new google.maps.LatLng(pvm.centerZip.latitude, pvm.centerZip.longitude));
    };
    
    /**
     * Reset polygon coordinates after changing the zip.
     */
    pvm.resetBounds = function() {
        var rndNo = (0.01 + Math.random() / 10000);
        
        var newCoords = [
            new google.maps.LatLng(pvm.centerZip.latitude + rndNo, pvm.centerZip.longitude + rndNo),
            new google.maps.LatLng(pvm.centerZip.latitude - rndNo, pvm.centerZip.longitude + rndNo),
            new google.maps.LatLng(pvm.centerZip.latitude, pvm.centerZip.longitude - rndNo),
        ];
        
        pvm.polygonCoords = newCoords;
        
        var bounds = new google.maps.LatLngBounds();
        
        for (var i = 0; i < pvm.polygonCoords.length; i++) {
            bounds.extend(pvm.polygonCoords[i]);
        }
        
        pvm.zoomLevel = pvm.getBoundsZoomLevel(bounds);
        pvm.map.setCenter(bounds.getCenter());
    };
    
    /**
     * Show instructions modal of how to use this tool
     */
    pvm.showInstructions = function() {
        var modalOptions = {
            closeButtonShow: false,
            closeButtonText: '',
            actionButtonText: translateService.instant('general.ok'),
            actionButtonClass: 'btn-success',
            headerText: translateService.instant('delivery.polygon.instructions_heading'),
            bodyText: translateService.instant('delivery.polygon.instructions')
        };

        modalService.showModal({}, modalOptions);
    };
    
    /**
     * Export the polygon to zips
     */
    pvm.export = function() {
        pvm.loading = true;
        
        var data_x = [];
        var data_y = [];
        
        var vertices = pvm.polygon.getPath();
        
        for (var i = 0; i < vertices.getLength(); i++) {
            var xy = vertices.getAt(i);
            data_x.push(xy.lng());
            data_y.push(xy.lat());
        }
        var data = {
            x: data_x,
            y: data_y
        };
        
        ZipcodeService.getByPolygon(data, pvm.centerZip.zipcode, 0, pvm.radius).then(function(data) {
            pvm.loading = false;
            
            pvm.output = data.join(', ');
        });
    };
    
    /**
     * Refresh templates
     */
    pvm.refreshTemplates = function() {        
        pvm.loading = true;
        pvm.templates = [];
        
        DeliveryCompanyRepository.getList('/' + pvm.import.deliveryCompanyID + '/templates?with=deliverytemplates').then(function(data) {
            pvm.loading = false;
            pvm.templates = data;
        });
    };
    
    /**
     * Import geo from delivery area id
     */
    pvm.importArea = function() {
        pvm.loading = true;
        
        DeliveryTemplateAreaRepository.get("/" + pvm.import.deliveryAreaID + "/geoarea/", "", {}, {}).then(function (data) {
            pvm.zipPositions = data.markers;
            
            var newCoords = [];
            
            for (var i = 0; i < data.polygon.length; i++) {
                newCoords.push(new google.maps.LatLng(data.polygon[i][1], data.polygon[i][0]));
            }
            
            pvm.polygonCoords = newCoords;
            
            var bounds = new google.maps.LatLngBounds();
            
            for (i = 0; i < pvm.polygonCoords.length; i++) {
                bounds.extend(pvm.polygonCoords[i]);
            }
            
            pvm.map.setCenter(bounds.getCenter());
            
            pvm.zoomLevel = pvm.getBoundsZoomLevel(bounds);
            pvm.loading = false;
            pvm.showImport  = false;
        });
    };
    
    /**
     * Get appropriate zoom level for bounds
     */
    pvm.getBoundsZoomLevel = function(bounds) {
        var mapDim = pvm.getMapDimensions();
        var WORLD_DIM = { height: 256, width: 256 };
        var ZOOM_MAX = 21;

        function latRad(lat) {
            var sin = Math.sin(lat * Math.PI / 180);
            var radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
            return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
        }

        function zoom(mapPx, worldPx, fraction) {
            return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
        }

        var ne = bounds.getNorthEast();
        var sw = bounds.getSouthWest();

        var latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;

        var lngDiff = ne.lng() - sw.lng();
        var lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360;

        var latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
        var lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);

        return Math.min(latZoom, lngZoom, ZOOM_MAX);
    };
    
    /**
     * Get map dimensions for getboundszoomlevel
     *
     * @returns {{width: number, height: number}}
     */
    pvm.getMapDimensions = function() {
        var element = document.getElementById('mapholder');
        return { width: element.offsetWidth, height: element.offsetHeight };
    };
    
    pvm.activate();
    return pvm;
}