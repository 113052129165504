import { Component, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { ImageLibraryStateStorage } from '../image-library-state.storage';
import { Subscription } from 'rxjs';
import { ImageLibraryStateMode } from '../image-library-state-mode';
import { Image } from '../http/image';
import { ImageStorage } from '../http/image.storage';
import { ImageType } from '../http/image-type';
import { SubscriptionCollection } from '@ro-ngx/core';

@Component({
    selector: 'image-stored',
    template: `
        <div class="row">
            <div class="col-md-8">
                <div class="row">
                    <loading-linear [loadingIf]="loading['images'] && images.length < 1"></loading-linear>
                    <div class="col-md-3" *ngFor="let image of images">
                        <div href [ngClass]="{'selected': selectedImage?.imageID === image.imageID}" (click)="selectImage(image)" class="thumbnail">
                            <img src="" [src]="image.publicPath" style="max-width: 100%;">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <button class="btn btn-success btn-block" (click)="chooseImage(selectedImage)" [disabled]="! selectedImage">{{ 'image_library.choose_image' | translate }}</button>
                <button class="btn btn-primary btn-block" (click)="uploadView()">{{ 'image_library.new_image' | translate }}</button>
                <button-loadable class="btn-block" [loading]="loading['delete']" [btnClass]="'btn-danger btn-block'" (tryClick)="deleteImage(selectedImage)">{{ 'image_library.delete_image' | translate }}</button-loadable>
            </div>
        </div>
    `
})
export class ImageStoredComponent implements OnInit, OnDestroy {

    /**
     * Components loading.
     *
     * @type {{images: boolean}}
     */
    public loading: { [key: string]: boolean } = {
        'images': false,
        'delete': false
    };

    /**
     * @type {Array}
     */
    public images: Image[] = [];

    /**
     * @type {Image}
     */
    public selectedImage: Image;

    @Output()
    public onImage: EventEmitter<Image> = new EventEmitter();

    /**
     * @type {SubscriptionCollection}
     */
    protected subscriptions: SubscriptionCollection;

    constructor(
        protected imageLibraryStateStorage: ImageLibraryStateStorage,
        protected imageStorage: ImageStorage) {
        this.subscriptions = new SubscriptionCollection();
    }

    /**
     * NgOnInit
     */
    public ngOnInit(): void {
        this.subscriptions.set('imageLibraryStateHandle', this.imageLibraryStateHandle());
    }

    /**
     * NgOnDestroy
     */
    public ngOnDestroy(): void {
        this.subscriptions.unsubscribeAll();
    }

    public selectImage(image: Image): void {
        this.selectedImage = image;
    }

    /**
     * Select an image and emit.
     *
     * @param image
     */
    public chooseImage(image: Image): void {
        this.onImage.emit(image);
    }

    /**
     * Delete image.
     *
     * @param image
     */
    public deleteImage(image: Image): void {
        this.loading['delete'] = true;
        this.imageStorage.deleteImage(image.imageID)
            .subscribe(() => this.loading['delete'] = false);
    }

    /**
     * Change view to upload view.
     */
    public uploadView(): void {
        this.imageLibraryStateStorage.changeMode(
            ImageLibraryStateMode.Upload
        );
    }

    /**
     * ImageLibraryState handle.
     *
     * @returns {Subscription}
     */
    protected imageLibraryStateHandle(): Subscription {
        return this.imageLibraryStateStorage.state$
            .subscribe((state) => {
                if (state.mode === ImageLibraryStateMode.Stored) {
                    this.subscriptions.set('imagesHandle', this.imagesHandle(state.imageType));
                }
            });
    }

    /**
     * Images handle.
     *
     * @param imageType
     * @returns {Subscription}
     */
    protected imagesHandle(imageType: ImageType): Subscription {
        this.loading['images'] = true;
        this.subscriptions.set('imageget', this.imageStorage.getImages(imageType, this.imageLibraryStateStorage.getClientKey())
            .subscribe(() => this.loading['images'] = false));

        return this.imageStorage.imageType$(imageType)
            .subscribe((images) => this.images = images);
    }
}
