angular.module( 'cmsApp.apptheme.factory', [])

.factory('AppthemeRepository', function (Restangular, AbstractRepository) {

    function AppthemeRepository() {
        AbstractRepository.call(this, Restangular, 'appthemes');
    }

    AbstractRepository.extend(AppthemeRepository);

    return new AppthemeRepository();
})

;
