angular.module( 'cmsApp.alert.blacklist.service', [

])
.factory('AlertBlacklistService', function(Restangular, AbstractRepository) {

    function AlertBlacklistService() {
        AbstractRepository.call(this, Restangular, 'alertblacklist');
    }

    AbstractRepository.extend(AlertBlacklistService);

    return new AlertBlacklistService();
})

;
