import { NavbarMergeWhen } from './navbar-merge-when';
import { Injectable } from '@angular/core';
import { NavbarLink } from './navbar-link';
import { TranslateService } from '@ngx-translate/core';
import { NavbarDropdown } from './navbar-dropdown';
import { NavbarItem } from './navbar-item';
import { NavbarMergeWithPermission } from './navbar-merge-with-permission';
import { sortBy } from 'lodash';
import { AuthorizedService } from '@ro-ngx/authentication';
import { User, UserConnectionType } from '@ro-ngx/users';
import { NavbarMergeWithPermissionGroup, NavbarPermissionGroup } from './navbar-merge-with-permission-group';

@Injectable()
export class NavbarSerializer {

    protected user: User;

    constructor(
        protected authorizedService: AuthorizedService,
        protected translateService: TranslateService
    ) {
        this.authorizedService.user$
            .subscribe((user) => this.user = user);
    }

    public serialize(data: any[]): NavbarItem[] {
        return this.parseCollection([], data);
    }

    protected parseCollection(workingCollection: any[], collection: any[]): any[] {
        collection.forEach((item) => {
            this.parseItem(workingCollection, item);
        });

        return workingCollection;
    }

    protected parseItem(workingCollection: any[], item: NavbarItem): void {
        if (item instanceof NavbarLink) {
            workingCollection.push({
                title: this.translateService.instant(item.translateKey),
                routerLink: item.location
            });
        }

        else if (item instanceof NavbarDropdown) {
            let children = sortBy(this.parseCollection([], item.children), ['title']);

            if (children.length > 1) {
                workingCollection.push({
                    title: this.translateService.instant(item.translateKey),
                    children: children
                });
            }
            else if (children.length === 1) {
                workingCollection.push({
                    title: children[0].title,
                    routerLink: children[0].routerLink
                });
            }
        }

        else if (item instanceof NavbarMergeWhen && item.booleanValue) {
            this.parseCollection(workingCollection, item.items);
        }

        else if (item instanceof NavbarMergeWithPermission) {
            if (this.user) {
                const isMissing = !! item.permissions.find((permission) => this.user.hasPermission(permission) === false);

                if ( ! isMissing) {
                    this.parseCollection(workingCollection, item.items);
                }
            }
        }

        else if (item instanceof NavbarMergeWithPermissionGroup) {
            if (this.user) {
                const hasMatch = !! item.navbarPermissionGroups.find((permissionGroup) => {
                    return !! permissionGroup.find((permission) => this.user.hasPermission(permission) !== false);
                });

                if (hasMatch) {
                    this.parseCollection(workingCollection, item.items);
                }
            }
        }
    }
}
