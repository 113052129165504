export class OrderRowHeaderColumnRestaurantNameComponent {
    public static readonly controller = OrderRowHeaderColumnRestaurantNameComponent;
    public static readonly controllerAs = 'vm';
    public static readonly template = require('./order-row-header-column-restaurant-name.component.html');
    public static readonly bindings = {
        order: '<'
    };

    public order: any;

    constructor(protected SearchSupportService) {}

    public getRestaurantName(): string {
        if (this.order.restaurantName) {
            return this.SearchSupportService.truncateText(
                this.order.restaurantName
            );
        }

        return this.SearchSupportService.truncateText(
            this.order.restaurant.restaurantName
        );
    }
}
