import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthorizedService } from '@ro-ngx/authentication';
import { filter ,  map, tap } from 'rxjs/operators';
import { PermissionName } from '@ro-ngx/users';
import { Observable } from 'rxjs';

@Injectable()
export class DriversMapGuard implements CanActivate {
    constructor(
        public authorizedService: AuthorizedService,
        public router: Router,
    ) {
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.authorizedService.user$.pipe(
            filter((user) => !!user),
            map((user) => user.hasPermission(PermissionName.DELIVERY_DRIVERS_READ)
                || user.hasPermission(PermissionName.DELIVERY_DRIVERS_READ_ALL)
            ),
            tap((allowed) => {
                if (!allowed) {
                    this.router.navigateByUrl('/');
                }
            }),
        );
    }
}
