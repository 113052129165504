
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { RestaurantNavigation } from './restaurant-navigation';
import { Observable } from 'rxjs';
import { BaseService } from '../../base/base.service';
import { ErrorFactory, HungrigClient, ResponseFactory } from '@ro-ngx/core';

@Injectable()
export class RestaurantNavigationService extends BaseService {
    constructor(hungrigClient: HungrigClient) {
        super(hungrigClient);
    }

    public getNavigationLinks(clientKey: string): Observable<RestaurantNavigation[]> {
        const url = this.url(`cms/restaurants/${clientKey}/navigationlinks`);
        return this.hungrigClient.get(url).pipe(
            map(ResponseFactory.toData),
            catchError(ErrorFactory.throwObservable));
    }

    public updateNavigationLink(
        clientKey: string, id: number, link: Partial<RestaurantNavigation>
    ): Observable<RestaurantNavigation> {
        const url = this.url(`cms/restaurants/${clientKey}/navigationlinks/${id}`);
        return this.hungrigClient.put(url, link).pipe(
            map(ResponseFactory.toData),
            catchError(ErrorFactory.throwObservable));
    }

    public updateMultipleNavigationLinks(
        clientKey: string, links: Partial<RestaurantNavigation>[]
    ): Observable<RestaurantNavigation[]> {
        const url = this.url(`cms/restaurants/${clientKey}/navigationlinks`);
        return this.hungrigClient.put(url, links).pipe(
            map(ResponseFactory.toData),
            catchError(ErrorFactory.throwObservable));
    }

    public createNavigationLink(
        clientKey: string, link: Partial<RestaurantNavigation>
    ): Observable<RestaurantNavigation> {
        const url = this.url(`cms/restaurants/${clientKey}/navigationlinks`);
        return this.hungrigClient.post(url, link).pipe(
            map(ResponseFactory.toData),
            catchError(ErrorFactory.throwObservable));
    }

    public removeNavigationLink(clientKey: string, id: number): Observable<boolean> {
        const url = this.url(`cms/restaurants/${clientKey}/navigationlinks/${id}`);
        return this.hungrigClient.delete(url).pipe(
            map(ResponseFactory.toData),
            catchError(ErrorFactory.throwObservable));
    }
}
