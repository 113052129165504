import * as angular from 'angular';
import * as moment from 'moment';

import "app/deliveryfinance/deliveryorder.factory";
import "app/delivery/driver/deliverydriver.factory";

angular.module( 'cmsApp.driverfinance', [
    'cmsApp.deliveryorder.factory',
    'ui.bootstrap',
    'cmsApp.delivery.driver.factory'
])
.config(DriverFinanceCtrlConfig)

// = = = FIX FOR ANGULAR UI / BOOTSTRAP INITIAL FORMATTING BUG
// See https://github.com/angular-ui/bootstrap/issues/2659
.directive('datepickerPopup', function (){
  return {
    restrict: 'EAC',
    require: 'ngModel',
    link: function(scope, element, attr, controller: any) {
      //remove the default formatter from the input directive to prevent conflict
      controller.$formatters.shift();
    }
  };
})
// // = = = END OF FIX FOR ANGULAR UI / BOOTSTRAP INITIAL FORMATTING BUG

.controller('DriverFinanceCtrl', DriverFinanceCtrl);

function DriverFinanceCtrlConfig( $stateProvider ) {
     
}

function DriverFinanceCtrl($scope, $filter, $interval, DeliveryOrderRepository, ENV, AuthRepository, translateService) {
    /* jshint validthis: true */
    var vm = this;

    vm.ENV = ENV;

    vm.orders = {};

    vm.fromOptions  = { open: false };
    vm.toOptions    = { open: false };

    vm.searchtext = '';
    vm.searchCompanies = [];
    vm.searchRestaurants = [];
    vm.searchDrivers = [];
    vm.searchTags = [];

    vm.searchFrom = null;
    vm.searchTo = null;

    vm.searchConfirmReq = true;
    vm.searchHungrig    = true;
    vm.searchRo         = true;
    vm.searchDelivery   = true;
    vm.searchPremium    = true;

    vm.autoUpdate = false;

    vm.dCompanies = [];
    vm.restaurants = [];
    vm.drivers = [];
    vm.priorityTags = [];

    vm.meta = {
        current_page:   1,
        last_page:      null,
        per_page:       null,
        from:           null,
        to:             null,
        total:          null
    };
    
    vm.driver = {};
    vm.isTerminal = false;

    vm.restaurantsForSearch = [];

    vm.restaurantsSearch = function(search) {
        var results = $filter('filter')(vm.restaurants, { restaurantName: search });

        if (results.length > 10) {
            results.length = 10;
        }

        vm.restaurantsForSearch = results;
    };
    
    vm.sidedata = {};

    vm.selectedPeriod = translateService.instant('driverfinance.quick_choice');
    vm.periodNames = [translateService.instant('driverfinance.quick_choice')];
    vm.economyPeriods = {};

    vm.dateRegex = '\\d\\d\\d\\d-\\d\\d-\\d\\d';

    vm.dateOptions = {
        formatYear: 'yy',
        startingDay: 1
    };

    vm.updatePromise = null;
    
    vm.bonus = {};

    vm.thisMonth = moment().format('MMMM');

    ////////////////////

    vm.activate = function() {
        vm.searchFrom = new Date();
        vm.searchFrom.setMonth(vm.searchFrom.getMonth(), 1);
        vm.searchFrom = vm.searchFrom;
        vm.searchTo = new Date();
        
        AuthRepository.getStatus('driver').then(function(data) {
            vm.driver = data;
            vm.isTerminal = data.driver.accountType === 1;
        });
        
        DeliveryOrderRepository.getList('/bonus').then(function(data) {
            vm.bonus = data;
            
            vm.search();
        });
    };

    vm.truncateText = function(text) {
        var replaceItems = {
            'restaurang':           '',
            'Grönt o Gott':         'GoG',
            "Grönt o' Gott":        'GoG',
            'Grönt o´ gott':        'GoG',
            'Pizza Hut':            'PH',
            'Salads And Smoothies': 'SaS',
            'V.E.S.P.A':            'Ves',
            'La Pizza':             'Lapizz',
            'I Chill Out Sushi':    'COu'
        };

        angular.forEach(replaceItems, function(value, key) {
            text = text.replace(new RegExp(key, 'gi'), value);
        });

        text = text.trim();

        var textParts = text.split(' ');

        if(textParts.length > 1 && text.length > 6) {
            var part1 = textParts[0].substr(0, 3).trim();
            var part2length = 6 - part1.length;
            var middle = '';
            var part2 = textParts[textParts.length - 1].substr(0, part2length).trim();

            // if part 2 is not long enough (perhaps it's just a digit) then include the penultimate.
            if(part2.length < 3 && (textParts.length - 2) !== 0) {
                middle = textParts[textParts.length - 2].substr(0, 3 - part2.length).trim();
            }

            text = part1 + middle + part2;
        } else {
            text = text.substr(0, 6);
        }

        return text;
    };
    
    vm.getSearchData = function() {
        var searchRestaurants: any = [];
        angular.forEach(vm.searchRestaurants, function(rest) {
            searchRestaurants.push(rest.clientKey);
        });
        searchRestaurants = searchRestaurants.join(',');
        
        return {
            restaurants:    searchRestaurants,
            from:           (vm.searchFrom ? vm.searchFrom.toISOString().substr(0, 10) : ''),
            to:             (vm.searchTo ? vm.searchTo.toISOString().substr(0, 10) : ''),          // "2011-10-05".length
            page:           vm.meta.current_page
        };
    };
    
    /**
     * DRY or like deliveryfinance??
     */
    vm.search = function() {
        $scope.$emit('showNotification', { id: 'dFinanceLoading', loading: true, alertType: 'alert-info', title: translateService.instant('general.loading') });

        if(vm.updatePromise) {
            $interval.cancel(vm.updatePromise);
            vm.updatePromise = null;
        }

        var openItems = [];

        angular.forEach(vm.orders, function(order) {
            if(order.show) {
                openItems.push(order.deliveryOrderID);
            }
        });

        DeliveryOrderRepository.getList('/driver', vm.getSearchData()).then(function(data) {
            vm.orders   = data;
            vm.meta     = data.metadata;
            vm.sidedata = data.sideData;

            angular.forEach(vm.orders, function(order) {
                if(openItems.indexOf(order.deliveryOrderID) >= 0) {
                    order.show = true;
                }
            });

            if(!vm.updatePromise && vm.autoUpdate) {
                vm.updatePromise = $interval(vm.search, 30000);
            }

            $scope.$emit('showNotification', { id: 'dFinanceLoading', loading: false, alertType: 'alert-info', title: translateService.instant('general.success'), timeout: 250 });
        });
    };
    
    vm.selectPeriod = function() {
        if (!vm.economyPeriods[vm.selectedPeriod]) {
            return;
        }

        var selectedPeriod = vm.economyPeriods[vm.selectedPeriod];

        vm.searchFrom = new Date(selectedPeriod[0]);
        vm.searchTo = new Date(selectedPeriod[1]);
        
        vm.search();
    };
    
    vm.selectDefaultPeriod = function() {
        vm.selectedPeriod = translateService.instant('driverfinance.quick_choice');
        vm.search();
    };
    
    vm.toggleDatepicker = function($event, value) {
        $event.preventDefault();
        $event.stopPropagation();

        value.open = !value.open;
    };

    vm.paginationRange = function(meta) {
        var difference = 2;
        var from    = Math.max(meta.current_page - difference, 1);
        var to      = Math.min(meta.current_page + difference, meta.last_page);

        var range = [];
        for(var i=from; i<=to; i++) {
            range.push(i);
        }
        return range;
    };
    
    vm.setPage = function(page) {
        if(page > 0 && page <= vm.meta.last_page)
        {
            vm.meta.current_page = page;
            vm.search();
        }
    };

    vm.log = function (item) {
        console.dir(item);
    };

    vm.activate();

    return vm;
}
