
import {map, share} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BaseStorage } from 'app/http/base';
import { CampaignGroup, CampaignGroupService } from '.';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class CampaignGroupStorage extends BaseStorage {
    public groups$: Observable<CampaignGroup[]>;
    protected groups: BehaviorSubject<CampaignGroup[]>;

    constructor(protected campaignGroupService: CampaignGroupService) {
        super();
        this.groups = new BehaviorSubject([]);
        this.groups$ = this.groups.asObservable();
        this.initState();
    }

    public initState(): void {
        this.campaignGroupService.getGroups()
            .subscribe((groups) => {
                this.groups.next(groups);
            });
    }

    public addGroup(group: CampaignGroup): Observable<CampaignGroup> {
        const obs = this.campaignGroupService.createGroup(group).pipe(share());

        obs.subscribe((newGroup) => {
            const groups = this.groups.getValue();
            groups.push(newGroup);
            this.groups.next(groups);
        }, (error) => error);

        return obs;
    }

    public getGroup(id: number): Observable<CampaignGroup> {
        return this.groups.pipe(map((groups) => groups.find((group) => group.campaignGroupID === id)));
    }

    public updateGroup(id: number, group: CampaignGroup): Observable<CampaignGroup> {
        const obs = this.campaignGroupService.updateGroup(id, group).pipe(share());

        obs.subscribe((updatedGroup) => {
            const groups = this.groups.getValue();

            const index = groups.findIndex((el) => el.campaignGroupID === id);

            if (index !== -1) {
                groups[index] = updatedGroup;
            }

            this.groups.next(groups);
        }, (error) => error);

        return obs;
    }

    public deleteGroup(id: number): Observable<any> {
        const obs = this.campaignGroupService.deleteGroup(id).pipe(share());

        obs.subscribe(() => {
            const groups = this.groups.getValue();

            const index = groups.findIndex((el) => el.campaignGroupID === id);

            if (index !== -1) {
                groups.splice(index, 1);
            }

            this.groups.next(groups);
        }, (error) => error);

        return obs;
    }
}
