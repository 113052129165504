import * as angular from 'angular';
import { map, forOwn } from 'lodash';

import 'common/SearchSupportService/SearchSupportService';
import 'common/clientkeySelector/clientkeySelector.directive';

import 'app/financeanalyzer/financeanalyzer.factory';
import 'app/financeanalyzer/columnhead/columnhead.directive';
import { endOfDay, format } from 'date-fns';

angular.module( 'cmsApp.financeanalyzer', [
    'common.SearchSupportService',
    'cmsApp.financeanalyzer.factory',
    'cmsApp.financeanalyzer.columnHead',
    'cmsApp.clientkeySelector'
])

// = = = FIX FOR ANGULAR UI / BOOTSTRAP INITIAL FORMATTING BUG
// See https://github.com/angular-ui/bootstrap/issues/2659
.directive('datepickerPopup', function (){
  return {
    restrict: 'EAC',
    require: 'ngModel',
    link: function(scope, element, attr, controller: any) {
      //remove the default formatter from the input directive to prevent conflict
      controller.$formatters.shift();
    }
  };
})
// // = = = END OF FIX FOR ANGULAR UI / BOOTSTRAP INITIAL FORMATTING BUG

.controller('FinanceAnalyzerCtrl', FinanceAnalyzerCtrl);

/**
 * Please watch the FinanceAnalyzerService.php-file
 * in the API to get a more in-depth explanation of how this works.
 */

function FinanceAnalyzerCtrl(
        $q,
        $scope,
        $filter,
        $state,
        $interval,
        modalService,
        ENV,
        moment,
        SearchSupportService,
        FinanceAnalyzerRepository,
        translateService
    ) {
    /* jshint validthis: true */
    var vm = this;
    
    vm.ENV = ENV;

    if ( ! localStorage.getItem('financeanalyzer')) {
        localStorage.setItem('financeanalyzer', JSON.stringify({}));
    }

    // Raw data from the server
    vm.data = [];
    
    // options for datepickers
    vm.fromOptions  = { open: false };
    vm.toOptions    = { open: false };
    
    // Extra variables for dynamic columns
    vm.dynamicColumnCount = 11;
    vm.dynamicColumnWidth = 1;
    vm.columnLeftoverWidth = 1;
    
    // Search from and to data (datepickers)
    vm.searchFrom = null;
    vm.searchTo = null;
    
    // Datepicker options
    vm.dateOptions = {
        formatYear: 'yy',
        startingDay: 1
    };

    // Function feature variables
    vm.autoUpdate = false;
    vm.truncate = true;
    
    // Meta data for pagination etc, not really used for the finance analyzer
    vm.meta = {
        current_page:   1,
        last_page:      null,
        per_page:       null,
        from:           null,
        to:             null,
        total:          null
    };
    
    // Periods etc
    vm.selectedPeriod = translateService.instant('financeanalyzer.quick_choice');
    vm.periodNames = [
        translateService.instant('financeanalyzer.quick_choice')
    ];
    vm.economyPeriods = {};
    
    // Outer grouping options
    vm.groupOptions = {
        none:   'Ingen',
        city:   'Stad',
        brand:  'Brand'
    };
    
    // Promise for the auto updater
    vm.updatePromise = null;
    
    // The available finance cities, fetched from the settings call
    vm.financeCities = [];

    ////////////////////
    
    /**
     * Calculate the width of the dynamic columns.
     * This also prevents columns from being enabled if there's already 11 selected.
     */
    vm.calcColumnWidth = function(changed) {
        var count = 0;
        
        angular.forEach(vm.columns, function(column) {
            if(column.enabled) {
                count++;
            }
        });
        
        if(count > 12) {
            if(changed) {
                changed.enabled = false;
            }
        } else {
            vm.dynamicColumnCount = count;
            vm.dynamicColumnWidth = Math.floor(12/count);
            vm.columnLeftoverWidth = 1 + (12 % count);
        }
    };
    
    /**
     * Reload the search options/settings
     */
    vm.reloadSettings = function() {
        // Search options. This is modified directly and passed on to the search call.
        vm.searchOptions = {
            "periodStart": "",
            "periodEnd": "",
            
            "sortOrder": "acceptedAmount",
            "sortType": "desc",
            
            "onlyDeliveryProblems": false,
            "financeCity": "(ingen)",
            "clientKeys": [],
            "grouping": "none",
            
            "exportOnlySelectedColumns": false,
            
            "payTypes": {
                "AM": true,
                "COUPON": true,
                "ELEC": true,
                "GIFTCARD": true,
                "INVOICE": true,
                "KLARNA": true,
                "Maestro": true,
                "MASTERCARD": true,
                "MC": true,
                "PAYPAL": true,
                "SVEA": true,
                "TEST": true,
                "VIS": true
            },
            
            "refererTypes": {
                "APP": true,
                "CMS": true,
                "HungrigApp-i": true,
                "hungrigMobil": true,
                "hungrigWeb": true,
                "PLATTAN": true,
                "TEST": false,
                "WEB": true
            },
            
            "statusTypes": {
                0: false,
                1: true,
                2: true,
                3: true
            },
            
            "paymentStatusTypes": {
                0: false,
                1: true
            },
            
            "deliveryTypes": {
                0: true,
                1: true,
                2: true
            }
        };
        
        // Save the searchOptions in localStorage
        let financeAnalyzerFromLocalStorage = JSON.parse(localStorage.getItem('financeanalyzer'));
        financeAnalyzerFromLocalStorage = financeAnalyzerFromLocalStorage ? financeAnalyzerFromLocalStorage : {};

        if ( ! financeAnalyzerFromLocalStorage.hasOwnProperty('searchOptions')) {
            financeAnalyzerFromLocalStorage.searchOptions = vm.searchOptions;
        }
        else {
            vm.searchOptions = financeAnalyzerFromLocalStorage.searchOptions;
        }
        
        // Modify which areas should be opened by default
        vm.show = {
            sources:            false,
            paytypes:           false,
            statustypes:        false,
            paymentstatuses:    false,
            delivertypes:       false,
            functions:          false,
            columns:            false
        };
        
        // Save the show variables in localStorage
        if ( ! financeAnalyzerFromLocalStorage.hasOwnProperty('show')) {
            financeAnalyzerFromLocalStorage.show = vm.show;
        }
        else {
            vm.show = financeAnalyzerFromLocalStorage.show;
        }

        localStorage.setItem('financeanalyzer', JSON.stringify(financeAnalyzerFromLocalStorage) );
        
        // All available dynamic columns and their current state
        vm.columns = [
            {
                key: 'restaurantName',
                name: translateService.instant('financeanalyzer.column_name'),
                title: translateService.instant('financeanalyzer.column_name_descr'),
                enabled: true
            },
            {
                key: 'numberOfItems',
                name: translateService.instant('financeanalyzer.column_amount'),
                title: translateService.instant('financeanalyzer.column_amount_descr'),
                enabled: true
            },
            {
                key: 'numberOfHungrigItems',
                name: translateService.instant('financeanalyzer.column_hungrig_amount'),
                title: translateService.instant('financeanalyzer.column_hungrig_amount_descr'),
                enabled: true
            },
            {
                key: 'numberOfROItems',
                name: translateService.instant('financeanalyzer.column_ro_amount'),
                title: translateService.instant('financeanalyzer.column_ro_amount_descr'),
                enabled: true
            },
            {
                key: 'numberOfDeniedItems',
                name: translateService.instant('financeanalyzer.column_denied'),
                title: translateService.instant('financeanalyzer.column_denied_descr'),
                enabled: true
            },
            {
                key: 'acceptedAmount',
                name: translateService.instant('financeanalyzer.column_total_sales'),
                title: translateService.instant('financeanalyzer.column_total_sales_descr'),
                enabled: true,
                isCurrency: true
            },
            {
                key: 'hungrigAmount',
                name: translateService.instant('financeanalyzer.column_total_hungrig_sales'),
                title: translateService.instant('financeanalyzer.column_total_hungrig_sales_descr'),
                enabled: true,
                isCurrency: true
            },
            {
                key: 'roAmount',
                name: translateService.instant('financeanalyzer.column_total_ro_sales'),
                title: translateService.instant('financeanalyzer.column_total_ro_sales_descr'),
                enabled: true,
                isCurrency: true
            },
            {
                key: 'numberOfTakeaways',
                name: translateService.instant('financeanalyzer.column_takeaway_orders'),
                title: translateService.instant('financeanalyzer.column_takeaway_orders_descr'),
                enabled: false
            },
            {
                key: 'numberOfDeliveries',
                name: translateService.instant('financeanalyzer.column_home_delivery_orders'),
                title: translateService.instant('financeanalyzer.column_home_delivery_orders_descr'),
                enabled: true
            },
            {
                key: 'numberOfPremiumDeliveries',
                name: translateService.instant('financeanalyzer.column_premium_orders'),
                title: translateService.instant('financeanalyzer.column_premium_orders_descr'),
                enabled: false
            },
            {
                key: 'deliveryOrderPrice',
                name: translateService.instant('financeanalyzer.column_delivery_price'),
                title: translateService.instant('financeanalyzer.column_delivery_price_descr'),
                enabled: true,
                isCurrency: true
            },
            {
                key: 'deliveryFinalResult',
                name: translateService.instant('financeanalyzer.column_delivery_result'),
                title: translateService.instant('financeanalyzer.column_delivery_result_descr'),
                enabled: true,
                isCurrency: true
            },
            {
                key: 'deliveryProblems',
                name: translateService.instant('financeanalyzer.column_delivery_problems'),
                title: translateService.instant('financeanalyzer.column_delivery_problems_descr'),
                enabled: true
            },
            {
                key: 'financeCity',
                name: translateService.instant('financeanalyzer.column_city'),
                title: translateService.instant('financeanalyzer.column_city_descr'),
                enabled: false
            },
            {
                key: 'brand',
                name: translateService.instant('financeanalyzer.column_brand'),
                title: translateService.instant('financeanalyzer.column_brand_descr'),
                enabled: false
            }
        ];

        // calculate the column width
        vm.calcColumnWidth();
    };
    vm.reloadSettings();
    
    /**
     * Activate the application. This is called at the end of this file.
     */
    vm.activate = function() {
        vm.searchFrom = new Date();
        vm.searchFrom.setMonth( vm.searchFrom.getMonth() - 1 );
        vm.searchFrom = vm.searchFrom;
        vm.searchTo = new Date();
        
        FinanceAnalyzerRepository.get('/settings').then(function(data) {
            vm.economyPeriods = data.periods;
            vm.periodNames = vm.periodNames.concat(Object.keys(vm.economyPeriods));
            
            vm.financeCities = data.financeCities;
            
            vm.search();
        });
    };
    
    /**
     * Toggle multiple searchOptions items.
     */
    vm.toggleMultiple = function(object) {
        var args = Array.prototype.slice.call(arguments, 1);
        
        angular.forEach(args, function(value, key) {
            object[value] = !object[value];
        });
        
        vm.search();
    };
    
    /**
     * Truncate the text using SearchSupportService if applicable
     */
    vm.truncateText = function(text) {
        if(!vm.truncate) {
            return text;
        }
        return SearchSupportService.truncateText(text);
    };
    
    /**
     * Reset the select period dropdown
     */
    vm.selectDefaultPeriod = function() {
        vm.selectedPeriod = translateService.instant('financeanalyzer.quick_choice');
        vm.search();
    };

    /**
     * Select a period from the dropdown
     */
    vm.selectPeriod = function() {
        if (!vm.economyPeriods[vm.selectedPeriod]) {
            return;
        }

        var selectedPeriod = vm.economyPeriods[vm.selectedPeriod];

        vm.searchFrom = new Date(selectedPeriod[0]);
        vm.searchTo = new Date(selectedPeriod[1]);
        
        vm.search();
    };
    
    /**
     * Perform the search
     */
    vm.search = function() {
        if(vm.updatePromise) {
            $interval.cancel(vm.updatePromise);
            vm.updatePromise = null;
        }
        
        var searchOptions = angular.copy(vm.searchOptions);
        
        searchOptions.periodStart    = (vm.searchFrom ? vm.searchFrom.toISOString().substr(0, 10) : '');
        searchOptions.periodEnd      = vm.searchTo ? format(endOfDay(vm.searchTo), 'YYYY-MM-DD HH:mm:ss') : '';
        
        if(searchOptions.financeCity == '(ingen)') {
            searchOptions.financeCity = '';
        }
        
        FinanceAnalyzerRepository.customPOST(searchOptions, 'finance/analyzer').then(function(data) {
            vm.data = data.data;
            
            if(!vm.updatePromise && vm.autoUpdate) {
                vm.updatePromise = $interval(vm.search, 30000);
            }
        });
    };
    
    /**
     * Export current search
     */
    vm.export = function() {
        var searchOptions = angular.copy(vm.searchOptions);
        
        searchOptions.periodStart    = (vm.searchFrom ? vm.searchFrom.toISOString().substr(0, 10) : '');
        searchOptions.periodEnd      = vm.searchTo ? format(endOfDay(vm.searchTo), 'YYYY-MM-DD HH:mm:ss') : '';
        
        if(searchOptions.financeCity == '(ingen)') {
            searchOptions.financeCity = '';
        }
        
        // This posts the settings to the Export function in the API 
        // The API then returns a fileName which will be downloaded using window.location changing
        // (there is probably a nicer way tho)
        FinanceAnalyzerRepository.customPOST({ settings: searchOptions, columns: vm.columns }, 'finance/analyzer/export').then(function(fileName) {
            window.location.href = ENV.API_LOCATION + '/finance/analyzer/download/' + fileName;
        });
    };
    
    /**
     * Toggle a datepicker. (Open/close)
     */
    vm.toggleDatepicker = function($event, value) {
        $event.preventDefault();
        $event.stopPropagation();

        value.open = !value.open;
    };
    
    /**
     * Reset settings
     */
    vm.resetSettings = function() {
        localStorage.setItem('financeanalyzer', JSON.stringify({}));

        vm.reloadSettings();
        vm.search();
    };
    
    /**
     * Process text
     */
    vm.processText = function(text) {
        if(Number.isInteger(+text)) {
            return $filter('number')(text, 0) || 0;
        } else {
            return vm.truncateText(text || '0');
        }
    };
    
    // Let's go!
    vm.activate();

    return vm;
}
