import { Component, OnInit } from '@angular/core';
import { FinanceService as DriverFinanceService } from 'app/http/delivery/driver/finance/finance.service';
import { FinanceStorage } from 'app/http/finance/finance.storage';
import { EconomyPeriod } from 'app/http/finance/economy-period';
import { Summary, DistanceFilter } from './summary';
import { NotificationStorage } from '@ro-ngx/core';
import * as moment from 'moment';
import * as _ from 'lodash';

@Component({
    selector: 'delivery-driver-finance',
    template: require('./finance.component.html'),
    providers: [ DriverFinanceService ]
})
export class FinanceComponent implements OnInit {
    public summaries: Summary[];
    public economyPeriods: EconomyPeriod[];
    public economyPeriod: EconomyPeriod;
    public sortableProperties: any = _.map(
        Summary.PROPERTY_HEADERS,
        (header, name) => {
            return { header, name };
        }
    );
    public sort: any = {
        propertyName: 'deliveryCount',
        reverse: true
    };
    public start: string = moment()
        .subtract(1, 'month')
        .format('YYYY-MM-DD');
    public end: string = moment()
        .format('YYYY-MM-DD');
    public distanceFilter: DistanceFilter = {
        min: undefined,
        max: undefined
    };
    private readonly KILOMETERS_TO_METERS: number = 1000;

    constructor(
        protected driverFinanceService: DriverFinanceService,
        protected financeStorage: FinanceStorage,
        protected notificationStorage: NotificationStorage
    ) { }

    public ngOnInit(): void {
        this.financeStorage.economyPeriods$.subscribe((economyPeriods) => {
            this.economyPeriods = economyPeriods;
        });
        this.updateSummary();
    }

    public updateSummary(): void {
        const notification = this.notificationStorage.loading();
        this.driverFinanceService.getSummary(this.start + ' 00:00:00', this.end + ' 23:59:59')
            .subscribe((summaries) => {
                this.summaries = [];
                for (const summary of summaries) {
                    this.summaries.push(
                        new Summary(summary.deliveryCompany.companyName, summary.entries)
                    );
                }
                this.updateFilter();
                notification.dismiss().success('Success');
            });
    }

    public updateFilter(): void {
        for (const summary of this.summaries) {
            const filter: DistanceFilter = {};
            if (this.distanceFilter.min !== undefined) {
                filter.min = this.distanceFilter.min * this.KILOMETERS_TO_METERS;
            }
            if (this.distanceFilter.max !== undefined) {
                filter.max = this.distanceFilter.max * this.KILOMETERS_TO_METERS;
            }
            summary.filter(filter);
        }
    }

    public updatePeriod(): void {
        this.start = this.economyPeriod.start;
        this.end = this.economyPeriod.end;
    }

    get sortedSummaries(): Summary[] {
        return _.orderBy(
            this.summaries,
            `sum.${this.sort.propertyName}`,
            this.sort.reverse ? 'desc' : 'asc'
        );
    }
}
