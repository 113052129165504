import * as angular from 'angular';

import "app/alert/alert/alert.service";

var template = require('./alert.component.tpl.html');

angular.module('cmsApp.alert.profile.alert.component', [
        'cmsApp.alert.profile.alert.service'
    ])
    .component('roAlertProfileAlert', {
        bindings: {
            'roAlert': '<',
            'alerterTypes': '<'
        },
        controller: AlertProfileAlertComponent,
        controllerAs: 'component',
        template: template
    });

function AlertProfileAlertComponent($scope, $filter, AlertService, translateService) {
    var vm = this;

    vm.alert = vm.roAlert;

    ////////////////////

    vm.$onInit = function() {
        
    };

    vm.alerterTypeFromId = function(alerterTypeID) {
        return $filter('filter')(vm.alerterTypes, {alerterTypeID: alerterTypeID}, true)[0];
    };

    vm.save = function(alert) {
        $scope.$emit('showNotification', { id: 'alertListCreateSubscription', loading: true, alertType: 'alert-info', title: translateService.instant('general.saving')});

        AlertService.update(alert, alert.alertID).then(function(response) {
            vm.alert = response.plain();

            $scope.$emit('showNotification', { id: 'alertListCreateSubscription', loading: false, alertType: 'alert-success', title: translateService.instant('general.saved')});
        });
    };

    vm.delete = function(alert) {
        $scope.$emit('showNotification', { id: 'alertListCreateSubscription', loading: true, alertType: 'alert-info', title: translateService.instant('general.saving')});

        AlertService.remove(alert.alertID).then(function() {
            vm.alert = null;

            $scope.$emit('showNotification', { id: 'alertListCreateSubscription', loading: false, alertType: 'alert-success', title: translateService.instant('general.saved')});
        });
    };
}