angular.module( 'cmsApp.admin', [
    'ui.router',
    'ui.bootstrap'
])

.config(function config( $stateProvider ) {

})

.controller( 'AdminCtrl', function AdminCtrl( $state, UserRepository, tokens, target ) {
    /* jshint validthis: true */
    var vm = this;

    console.log('tokens', tokens);
    console.log('target', target);

    UserRepository.setTokens(tokens.aToken, tokens.rToken);

    $state.go(target);


    return vm;
})

;
