import * as angular from 'angular';

import 'app/delivery/driver/deliverydriver.factory';
import 'app/delivery/driver/createdeliverydriver.modal';

var modalCreateTemplate = require('./modal.create.tpl.html');

angular.module('cmsApp.delivery.driver', [
        'cmsApp.delivery.driver.factory',
        'cmsApp.delivery.driver.modal.create',
    ])
    .controller('DeliveryDriverCtrl', DeliveryDriverCtrl);

function DeliveryDriverCtrl(
    $scope, $filter, $q, $modal, modalService, DeliveryCompanyRepository, DeliveryDriverRepository, deliveryCompanyID,
    translateService,
) {
    /* jshint validthis: true */
    var cvm = this;

    cvm.company = {};
    cvm.drivers = [];
    cvm.deliveryCompanies = [];

    cvm.newDriver = '';
    cvm.showInactive = false;

    cvm.driverTransportModeOptions = {
        0: translateService.instant('delivery.driver.mode_car'),
        1: translateService.instant('delivery.driver.mode_bike'),
    };

    cvm.driverAccountTypeOptions = {
        0: translateService.instant('delivery.driver.type_driver'),
        1: translateService.instant('delivery.driver.type_terminal'),
    };

    ////////////////////

    cvm.activate = function() {
        DeliveryCompanyRepository.get('', deliveryCompanyID, { 'with': 'financesettings' }).then(function(data) {
            cvm.company = data;
            $scope.$emit('selectItem', { company: cvm.company, item: 'driver' });
            $scope.$emit('setLoading', false);

            DeliveryCompanyRepository.getList('/' + deliveryCompanyID + '/driver', { 'includes[]': 'deliveryCities' })
                .then(function(data) {
                    cvm.drivers = data;

                    angular.forEach(cvm.drivers, function(driver) {
                        driver.active = Boolean(driver.active);
                        driver.deliveryCompanyID = parseInt(driver.deliveryCompanyID);
                    });
                });
        });

        DeliveryCompanyRepository.getList().then(function(data) {
            cvm.deliveryCompanies = $filter('orderBy')(data.plain(), 'companyName');

            angular.forEach(cvm.deliveryCompanies, function(company) {
                company.deliveryCompanyID *= 1;
            });
        });
    };

    cvm.toggleActive = function(driver) {

        $scope.$emit('showNotification', {
            id: 'dSaveOrder',
            loading: true,
            alertType: 'alert-info',
            title: translateService.instant('general.loading'),
        });

        var newdata = {
            deliveryDriverID: driver.deliveryDriverID,
            active: !driver.active,
        };

        DeliveryDriverRepository.update(newdata, driver.deliveryDriverID).then(function(data) {
            driver.active = !driver.active;

            $scope.$emit('showNotification', {
                id: 'dSaveOrder',
                loading: false,
                alertType: 'alert-success',
                title: translateService.instant('general.saved'),
            });
        }, function(error) {
            $scope.$emit('showNotification', {
                id: 'dSaveOrder',
                loading: false,
                alertType: 'alert-danger',
                title: error.data.error_message,
            });
        });
    };

    cvm.createDriver = function() {

        var modalInstance = $modal.open({
            template: modalCreateTemplate,
            controller: 'CreatedeliverydriverModalCtrl as vm',
            windowClass: 'ro-modal createdeliverydrivermodal modalService',
            resolve: {
                deliveryCompany: function() {
                    return cvm.company;
                },
            },
        });

        modalInstance.result.then(function(driver) {
            cvm.drivers.push(driver);
        });
    };

    cvm.removeDriver = function(driver) {
        var modalOptions = {
            closeButtonText: 'Avbryt',
            actionButtonText: 'Ta bort',
            actionButtonClass: 'btn-danger',
            headerText: translateService.instant('delivery.driver.confirm_delete_short'),
            bodyText: translateService.instant('delivery.driver.confirm_delete'),
        };

        modalService.showModal({}, modalOptions).then(function(result) {
            if (result) {
                $scope.$emit('showNotification', {
                    id: 'dRemoveDriver',
                    loading: true,
                    alertType: 'alert-info',
                    title: translateService.instant('general.loading'),
                });

                DeliveryDriverRepository.remove(driver.deliveryDriverID).then(function(data) {
                    cvm.drivers.splice(cvm.drivers.indexOf(driver), 1);
                    $scope.$emit('showNotification', {
                        id: 'dRemoveDriver',
                        loading: false,
                        alertType: 'alert-success',
                        title: translateService.instant('general.saved'),
                    });
                });
            }
        });
    };

    cvm.driverUpdated = (updatedDriver) => {
        // Don't use index: the showInactive checkbox will mess up indices
        const isEditedDriver = driver => driver.deliveryDriverID === updatedDriver.deliveryDriverID;
        const editedDriver = cvm.drivers.find(isEditedDriver);

        if (editedDriver.deliveryCompanyID !== updatedDriver.deliveryCompanyID) {
            cvm.drivers = cvm.drivers.filter(isEditedDriver);
        } else {
            cvm.drivers = cvm.drivers.map(driver => {
                return driver.deliveryDriverID === updatedDriver.deliveryDriverID
                    ? { ...updatedDriver, showEdit: true }
                    : driver;
            });
        }
    };

    cvm.filterFn = (driver) => {
        return cvm.showInactive ? true : driver.active;
    };

    cvm.activate();
    return cvm;
}
