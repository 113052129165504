angular.module( 'cmsApp.financeanalyzer.factory', [])

.factory('FinanceAnalyzerRepository', function (Restangular, AbstractRepository) {

    function FinanceAnalyzerRepository() {
        AbstractRepository.call(this, Restangular, 'finance/analyzer');
    }

    AbstractRepository.extend(FinanceAnalyzerRepository);

    return new FinanceAnalyzerRepository();
})

;