var template = require('./modal.tpl.html');

angular.module( 'common.modalService', [
    'ui.bootstrap'
])
.service('modalService', modalService);

function modalService($modal) {

    var modalDefaults = {
        backdrop: true,
        keyboard: true,
        modalFade: true,
        windowClass: 'modalService',
        template: template
    };

    var modalOptions = {
        closeButtonShow: true,
        textInputShow: false,
        textInputLabel: 'Text input here',
        closeButtonText: 'Close',
        actionButtonText: 'OK',
        actionButtonClass: 'btn-success',
        headerText: 'Proceed?',
        bodyText: 'Perform this action?',
        textInputValue: ''
    };

    this.showModal = function (customModalDefaults, customModalOptions) {
        if (!customModalDefaults) {
        	customModalDefaults = {};
        }
        customModalDefaults.backdrop = 'static';
        return this.show(customModalDefaults, customModalOptions);
    };

    this.show = function (customModalDefaults, customModalOptions) {
        //Create temp objects to work with since we're in a singleton service
        var tempModalDefaults: any = {};
        var tempModalOptions: any = {};

        //Map angular-ui modal custom defaults to modal defaults defined in this service
        angular.extend(tempModalDefaults, modalDefaults, customModalDefaults);

        //Map modal.html $scope custom properties to defaults defined in this service
        angular.extend(tempModalOptions, modalOptions, customModalOptions);

        if (!tempModalDefaults.controller) {
            tempModalDefaults.controller = function ($scope, $modalInstance) {
                $scope.modalOptions = tempModalOptions;
                $scope.modalOptions.ok = function (result, formValid) {
                    if($scope.modalOptions.textInputShow === false || formValid) {
                        if(result) {
                            $modalInstance.close(result);
                        }
                        else
                        {
                            $modalInstance.close(true);
                        }
                    }
                    else if(formValid === false)
                    {
                        $scope.modalOptions.submitted = true;
                    }
                };
                $scope.modalOptions.close = function (result) {
                    $modalInstance.close(false);
                };
                $scope.modalOptions.keypress = function (keyEvent, result, formValid) {
                    if(keyEvent.which === 13)
                    {
                        $scope.modalOptions.ok(result, formValid);
                    }
                };
            };

            tempModalDefaults.controller.$inject = ['$scope', '$modalInstance'];
        }

        return $modal.open(tempModalDefaults).result;
    };
}