angular.module( 'cmsApp.alert.profile.factory', [
    'ui.router',
    'ui.bootstrap'
])

.factory('AlertProfileRepository', function (Restangular, AbstractRepository) {

    function AlertProfileRepository() {
        AbstractRepository.call(this, Restangular, 'alertprofiles');
    }

    // getList: function (path, params, headers) {
    AbstractRepository.prototype.search = function(data) {
        return this.getList('/search', data, {});
    };

    AbstractRepository.extend(AlertProfileRepository);

    return new AlertProfileRepository();
})

;
