angular.module( 'common.roFormsubmit', [
    'ui.bootstrap'
])
.directive('roFormsubmit', roFormsubmit);

function roFormsubmit() {
    return {
        require: "form",
        link: function($scope, $el, $attr, $form) {
            $form.rosubmit = function() {
                $el[0].submit();
            };
        }
    };
}