
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HungrigClient } from '@ro-ngx/core';
import { BaseService } from 'app/http/base/base.service';
import { Observable } from 'rxjs';
import { EconomyPeriod } from './economy-period';

@Injectable()
export class FinanceService extends BaseService {

    public resourceUrl: string = 'finance';

    constructor(public hungrigClient: HungrigClient) {
        super(hungrigClient);
    }

    public getEconomyPeriods(): Observable<EconomyPeriod[]> {
        return this.hungrigClient.get(this.url('/economy-periods')).pipe(
            map(this.toResponse),
            map((response) => response.data),);
    }

}
