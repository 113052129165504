angular.module( 'cmsApp.delivery.driver.factory', [
])

.factory('DeliveryDriverRepository', function (Restangular, AbstractRepository) {

    function DeliveryDriverRepository() {
        AbstractRepository.call(this, Restangular, 'delivery/driver');
    }

    AbstractRepository.extend(DeliveryDriverRepository);

    return new DeliveryDriverRepository();
})

;
