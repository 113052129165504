import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Article, ArticleStorage } from "@ro-ngx/articles";
import { Observable ,  BehaviorSubject } from "rxjs";
import { finalize, flatMap, map, share, startWith, switchMap, tap } from "rxjs/operators";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Paginate, PaginateBody } from "@ro-ngx/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { sortBy } from 'lodash';

export interface FormValues {
    page: number;
    name: string;
}

@Component({
    selector: 'sidebar',
    template: require('./sidebar.component.html'),
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SideBarComponent implements OnInit {

    public isLoadingArticles$: BehaviorSubject<boolean>;
    public paginate$: Observable<Paginate<Article[]>>;
    public paginateBody$: Observable<PaginateBody>;
    public articles$: Observable<Article[]>;
    public form: FormGroup;

    constructor(
        protected translateService: TranslateService,
        protected articleStorage: ArticleStorage,
        protected activatedRoute: ActivatedRoute,
        protected formBuilder: FormBuilder,
        protected router: Router) {
        this.form = this.createForm();
        this.isLoadingArticles$ = new BehaviorSubject(false);
    }

    public ngOnInit(): void {
        this.paginate$ = this.getPaginate()
            .pipe(share());

        this.paginateBody$ = this.paginate$
            .pipe(map((paginate) => paginate.meta));

        this.articles$ = this.paginate$
            .pipe(
                map((paginate) => paginate.data),
                switchMap((articles) => {
                    const ids = articles.map((article) => article.articleID);
                    return this.articleStorage.articles$
                        .pipe(
                            map((unfilted) => unfilted.filter((article) => ids.indexOf(article.articleID) > -1)),
                            map((filtered) => sortBy(filtered, ['name', 'slug']))
                        );
                })
            );
    }

    public clearSearch(): void {
        this.form.patchValue({
            name: ''
        });
    }

    public createArticle(): void {
        this.isLoadingArticles$.next(true);
        this.articleStorage.createArticle({
                name: this.translateService.instant('article-list.create.name'),
                slug: this.translateService.instant('article-list.create.slug')
            })
            .pipe(finalize(() => this.isLoadingArticles$.next(false)))
            .subscribe((article) => {
                this.form.patchValue({
                    name: article.name
                });

                this.router.navigate([article.articleID], { relativeTo: this.activatedRoute })
                    .then();
            });
    }

    protected getPaginate(): Observable<Paginate<Article[]>> {
        return this.getFormValues()
            .pipe(
                tap(() => this.isLoadingArticles$.next(true)),
                flatMap((options) => this.articleStorage.getPaginatedArticles(options)
                    .pipe(finalize(() => this.isLoadingArticles$.next(false)))
                )
            );
    }

    protected getFormValues(): Observable<FormValues> {
        return this.form.valueChanges
            .pipe(
                startWith({
                    page: 1, name: ''
                }),
                map((formValues) => ({
                    page: formValues.page,
                    name: formValues.name.length ? formValues.name : undefined
                }))
            );
    }

    protected createForm(): FormGroup {
        return this.formBuilder.group({
            page: [1],
            name: ['']
        });
    }
}
