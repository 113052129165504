import * as _ from "lodash";

/**
 * @deprecated
 */
export class AppController {

    public isSafari = (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1);
    public isPhone;
    public menuCollapsed = true;
    public currentState = '';
    public notifications = [];

    public $scope;
    public $state;
    public ENV;
    public $timeout;
    public matchmedia;
    public AuthRepository;
    public UserRepository;

    public ustatus = {
        user: null, isLoggedIn: false
    };

    constructor($scope, $state, ENV, $timeout, matchmedia, AuthRepository, UserRepository) {
        this.isPhone = matchmedia.isPhone();

        this.$scope = $scope;
        this.$state = $state;
        this.ENV = ENV;
        this.$timeout = $timeout;
        this.matchmedia = matchmedia;
        this.AuthRepository = AuthRepository;
        this.UserRepository = UserRepository;

        if (localStorage.getItem('access_token') !== null) {
            AuthRepository.setAccessToken(localStorage.getItem('access_token'));
        }

        $scope.$on('$stateChangeSuccess', (event, toState, toParams, fromState, fromParams) => {

            if (toState && toState.data && toState.data.pageTitle) {
                $scope.pageTitle = toState.data.pageTitle;
                $scope.pageDescription = toState.data.pageDescription;
                $scope.pageKeywords = toState.data.pageKeywords;
            }

            this.currentState = toState.name;
        });

        $scope.$on('showNotification', (event, data) => {
            return this.showNotification(data);
        });
    }

    showNotification(data) {
        var timing = 0;

        if ( ! data.removeItem) {
            data.removeItem = () => {
                if (this.notifications.indexOf(data) >= 0) {
                    this.notifications.splice(this.notifications.indexOf(data), 1);
                }
            };
        }

        data.alertType = data.alertType || 'alert-info';
        data.title = data.title || '';
        data.message = data.message || '';
        data.id = data.id || false;
        data.loading = data.loading || false;

        if (data.id) {
            var existingNotification: any = false;

            _.forEach(this.notifications, notification => {
                if (notification.id == data.id) {
                    existingNotification = notification;
                }
            });

            if (existingNotification !== false) {
                existingNotification.alertType = data.alertType;
                existingNotification.title = data.title;
                existingNotification.message = data.message;
                existingNotification.loading = data.loading;

                if (!data.loading) {
                    timing = data.timeout || 5000 + (this.notifications.indexOf(existingNotification) * 2500);
                    return this.$timeout(existingNotification.removeItem, timing, true);
                }
            } else {
                this.notifications.splice(0, 0, data);

                if (!data.loading) {
                    timing = data.timeout || 5000 + (this.notifications.length * 2500);
                    return this.$timeout(data.removeItem, timing, true);
                }
            }
        } else {
            this.notifications.splice(0, 0, data);
            timing = data.timeout || 5000 + (this.notifications.length * 2500);
            return this.$timeout(data.removeItem, timing, true);
        }
    }

    setLoggedIn(user) {
        this.UserRepository.setUser(user);
        this.ustatus.user = user;
        this.ustatus.isLoggedIn = true;
        this.$scope.$broadcast('loginSuccess', user);
    }

    gotoPrevious() {
        if (this.currentState == 'restaurantlist') {
            this.$state.go('home');
        } else {
            this.$state.go('home');
        }
    }
}
