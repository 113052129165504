import { find } from 'lodash';
import { differenceInMinutes } from "date-fns";
import { DeliveryOrderActionType } from '@ro-ngx/delivery';

export class OrderRowHeaderColumnPickupDiffUnitsComponent {
    public static readonly controller = OrderRowHeaderColumnPickupDiffUnitsComponent;
    public static readonly controllerAs = 'vm';
    public static readonly template = require('./order-row-header-column-pickup-diff-units.component.html');
    public static readonly bindings = {
        order: '<'
    };

    public order: any;

    public getDifferenceWithUnits() {
        const differenceInMinutes = this.getDifferenceInMinutes();
        return typeof differenceInMinutes !== 'undefined' ? `${differenceInMinutes.toString()}m` : null;
    }

    public getClass() {
        const differenceInMinutes = this.getDifferenceInMinutes();

        if (typeof differenceInMinutes === 'undefined') {
            return '';
        }

        if (differenceInMinutes < -10) {
            return 'ro-text-danger';
        }

        if (differenceInMinutes < -5) {
            return '';
        }

        if (differenceInMinutes < 5) {
            return 'ro-text-success font-weight-bold';
        }

        return 'ro-text-danger font-weight-bold';
    }

    public getDifferenceInMinutes() {
        if (typeof this.order.driverShouldPickupAt !== 'undefined'
            && typeof this.order.driverCompletedPickupAt !== 'undefined') {

            if (this.order.driverCompletedPickupAt) {
                return differenceInMinutes(this.order.driverCompletedPickupAt, this.order.driverShouldPickupAt);
            }

            return undefined;
        }

        const finishAction = this.getFinishAction();

        if (finishAction) {
            return differenceInMinutes(finishAction.timestamp, this.order.pickupTimestamp);
        }

        if (this.order.finishTimestamp) {
            return differenceInMinutes(this.order.finishTimestamp, this.order.pickupTimestamp);
        }

        return undefined;
    }

    public getFinishAction(): any {
        return find(this.order.deliveryorderactions, {
            type: DeliveryOrderActionType.Finished
        });
    }
}
