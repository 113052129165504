import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ImageLibraryState } from './image-library-state';
import { ImageLibraryStateMode } from './image-library-state-mode';
import { ImageType } from './http/image-type';

@Injectable()
export class ImageLibraryStateStorage {

    public static defaultState: ImageLibraryState = {
        mode: ImageLibraryStateMode.Stored,
        modeOptions: {},
        imageType: ImageType.ProductCoverImage
    };

    public state$: Observable<ImageLibraryState>;

    protected state: BehaviorSubject<ImageLibraryState>;

    constructor() {
        this.state = new BehaviorSubject<ImageLibraryState>(ImageLibraryStateStorage.defaultState);
        this.state$ = this.state.asObservable();
    }

    public changeMode(mode: ImageLibraryStateMode, options: {[key: string]: any} = {}): void {
        this.changeValue((state) => {
            state.mode = mode;
            state.modeOptions = options;

            return state;
        });
    }

    public setImageType(imageType: ImageType): void {
        this.setValue('imageType', imageType);
    }

    public setClientKey(clientKey: string): this {
        this.setValue('clientKey', clientKey);

        return this;
    }

    public getClientKey(): string {
        const state = this.state.getValue();

        return state['clientKey'] ? state['clientKey'] : undefined;
    }

    public getImageType(): ImageType {
        const state = this.state.getValue();

        return state['imageType'] ? state['imageType'] : undefined;
    }

    protected changeValue(callback: (mode: ImageLibraryState) => ImageLibraryState): void {
        const state = this.state.getValue();
        this.state.next(callback(state));
    }

    protected setValue(key: keyof ImageLibraryState, value: any): void {
        this.changeValue((state) => {
            // @ts-ignore
            state[key] = value;

            return state;
        });
    }
}
