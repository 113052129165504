import * as _ from "lodash";
import { IService, IPromise } from "restangular";

export class AbstractRepository {

    public restangular: IService;

    public primaryKey: string;

    public route: string;

    constructor(Restangular: IService, route: string, primaryKey?: string) {
        this.restangular = Restangular;
        this.primaryKey = primaryKey;
        this.route = route;
    }

    public getList(path: string, params: Object = {}, headers: Array<string> = []) {
        return this.restangular.all(this.route + (!!path ? path : "")).getList(params, headers);
    }

    public get(path, id, params?, headers?): IPromise<any> {
        return this.restangular.one(this.route + (!!path ? path : ""), id).get(params, headers);
    }

    public getView(id) {
        return this.restangular.one(this.route, id).one(this.route + 'view').get();
    }

    public update(data, itemID, originalData?) {
        if (originalData) {
            data = this.getChanges(data, originalData);
        }

        return this.restangular.one(this.route).customPUT(data, itemID);
    }

    public customUpdate(data, route) {
        return this.restangular.one(route).customPUT(data, '');
    }

    public customPOST(data, route) {
        return this.restangular.one(route).customPOST(data, '');
    }

    public create(path, newResource) {
        if (!newResource) {
            newResource = path;
            path = '';
        }

        return this.restangular.all(this.route + path).post(newResource);
    }

    public duplicate(id, newResource) {
        return this.restangular.all(this.route + '/' + id).post(newResource);
    }

    public remove(id, headers, data) {
        return this.restangular.one(this.route, id).remove(data, headers);
    }

    public getChanges(data, originalData) {
        var changes = {};

        _.forEach(data, (value, key) => {
            if (key == this.primaryKey || originalData[key] != value) {
                changes[key] = data[key];
            }
        });

        return changes;
    }

    public static extend(repository) {
        repository.prototype = Object.create(AbstractRepository.prototype);
        repository.prototype.constructor = repository;
    }
}
