import { ModuleWithProviders, NgModule } from '@angular/core';
import { ImageLibraryComponent } from './image-library.component';
import { CommonModule } from '@angular/common';
import { ImageLibraryStateStorage } from './image-library-state.storage';
import { ImageStoredComponent } from './image-stored/image-stored.component';
import { FileUploadModule } from 'ng2-file-upload';
import { ImageCropComponent } from './image-crop/image-crop.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ImageUploadComponent } from './image-upload/image-upload.component';
import { ImageLibraryConfig } from './image-library.config';
import { UploadService } from './http/upload/upload.service';
import { UploadStorage } from './http/upload/upload.storage';
import { ImageService } from './http/image.service';
import { ImageStorage } from './http/image.storage';
import { TranslateModule } from '@ngx-translate/core';
import { RoNg2Module } from '@ro-ngx/core';
import {BsDropdownModule} from "ngx-bootstrap/dropdown";

export * from './http';
export * from './image-crop';
export * from './image-stored';
export * from './image-upload';

export * from './image-library.component';
export * from './image-library.config';
export * from './image-library-state.storage';
export * from './image-library-state';
export * from './image-library-state-mode';

@NgModule({
    imports: [
        CommonModule,
        FileUploadModule,
        TranslateModule,
        ModalModule,
        RoNg2Module,
        BsDropdownModule.forRoot(),
    ],
    exports: [
        ImageLibraryComponent
    ],
    declarations: [
        ImageLibraryComponent,
        ImageStoredComponent,
        ImageCropComponent,
        ImageUploadComponent
    ],
    providers: [
        ImageLibraryStateStorage,
        UploadService,
        UploadStorage,
        ImageService,
        ImageStorage
    ],
})
export class ImageLibraryModule {
    public static foorRoot(config: ImageLibraryConfig): ModuleWithProviders {
        return {
            ngModule: ImageLibraryModule,
            providers: [
                {
                    provide: ImageLibraryConfig,
                    useValue: config
                },
                ImageLibraryStateStorage
            ]
        };
    }
}
