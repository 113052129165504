angular.module( 'common.roFormfocusme', [
    'ui.bootstrap'
])
.directive('roFormfocusme', roFormfocusme);

function roFormfocusme($timeout, $parse) {
    return {
      restrict: 'A',
      link: function(scope, element, attrs) {
          scope.$watch(attrs.roFormfocusme, function(newValue, oldValue) {
            //console.log('sw', newValue);
              if (newValue) { element[0].focus(); }
          });
          element.bind("blur", function(e) {
              $timeout(function() {
                  scope.$apply(attrs.roFormfocusme + "=false");
              }, 0);
          });
          element.bind("focus", function(e) {
              $timeout(function() {
                  scope.$apply(attrs.roFormfocusme + "=true");
              }, 0);
          });
      }
    };
}