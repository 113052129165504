import * as angular from 'angular';

import "app/alertviewer/viewer/viewer.repository";
import "app/alert/alert-list/alert-list.component";

var template = require('./removeprofilemodal/removeprofile.modal.tpl.html');

angular.module('cmsApp.alert.profile', [
        'cmsApp.alert.profile.alert-list.component',
        'cmsApp.alertviewer.viewer.repository',
        'ui.select',
        'ngSanitize'
    ])
    .controller('AlertProfileCtrl', AlertProfileCtrl);

function AlertProfileCtrl($scope, $filter, $modal, AlertProfileRepository, AlertViewerRepository, alertGroupID, alertProfileID) {
    var vm = this;

    vm.profile = {};

    vm.alertViewers = [];

    ////////////////////

    function activate() {

        AlertProfileRepository.get('', alertProfileID, {with: 'alerts'}).then(function(data) {
            vm.profile = data.plain();

            vm.profile.active = vm.profile.active == 1;
            vm.profile.viewer *= 1;

            vm.profile.alerts = vm.filterInvalidAlerterTypes(vm.profile.alerts);

            $scope.$emit('selectItem', { groupID: alertGroupID, profileID: alertProfileID } );

            $scope.$emit('setLoading', false);
        });

        AlertViewerRepository.getList().then(function(data) {
            data = data.plain();

            angular.forEach(data, function(viewer) {
                viewer.alertViewerID *= 1;
            });

            data.unshift({
                alertViewerID: 0,
                alertViewerName: '',
            });

            angular.extend(vm.alertViewers, vm.alertViewers, data);
        });
    }

    vm.save = function() {
        $scope.$emit('showNotification', { id: 'aProfileSave', loading: true, alertType: 'alert-info', title: 'Sparar...', message: 'Dina ändringar sparas' });

        AlertProfileRepository.update(vm.profile, vm.profile.alertProfileID).then(function(data) {
            $scope.$emit('showNotification', { id: 'aProfileSave', loading: false, alertType: 'alert-success', title: 'Sparat!', message: 'Dina ändringar har blivit sparade' });
        });

        $scope.$emit('updateProfile', vm.profile);
    };

    vm.removeProfile = function() {
        var modalInstance = $modal.open({
            template: template,
            controller: 'RemoveprofileModalCtrl as vm',
            windowClass: 'ro-modal removeprofilemodal modalService',
            resolve: { }
        });

        modalInstance.result.then(function (data) {
            if(data == '0') // remove alertmember
            {
                $scope.$emit('removeMember', vm.profile.alertProfileID);
            }
            else if(data == '1') // remove all alertmembers + alertprofile
            {
                $scope.$emit('removeProfile', vm.profile.alertProfileID);
            }
        }, function() {
            // dismissed
        });
    };

    vm.filterInvalidAlerterTypes = function(objects) {
        var invalidAlerterTypes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];

        return $filter('filter')(objects, function(value, index, array) {
            if (invalidAlerterTypes.indexOf(value.alerterTypeID) === -1) {
                return true;
            }

            return false;
        });
    };

    activate();
    return vm;
}