
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Upload } from './upload';
import { ImageType } from '../image-type';
import { ImageLibraryConfig } from '../../image-library.config';
import { ErrorFactory, HungrigClient, ResponseFactory } from '@ro-ngx/core';

@Injectable()
export class UploadService {

    constructor(public imageLibraryConfig: ImageLibraryConfig,
                public hungrigClient: HungrigClient) {
    }

    public getUploads(imageType: ImageType, clientKey?: string): Observable<Upload[]> {
        const search = {
            'imageType': imageType
        };

        if (clientKey) {
            search['clientKey'] = clientKey;
        }

        return this.hungrigClient.get(this.imageLibraryConfig.apiUrl + '/image/upload', { search: search }).pipe(
            map(ResponseFactory.toData),
            catchError(ErrorFactory.throwObservable));
    }

    public deleteUpload(itemID: number): Observable<number> {
        return this.hungrigClient.delete(this.imageLibraryConfig.apiUrl + '/image/upload/' + itemID).pipe(
            map(ResponseFactory.toData),
            catchError(ErrorFactory.throwObservable));
    }
}
