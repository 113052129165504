
var template = require('./columnhead.tpl.html');

angular.module('cmsApp.financeanalyzer.columnHead', [])
    .directive('columnHead', columnHead);

function columnHead() {
    return {
        restrict: 'EA', scope: {
            columnHead: '=',
            columnTitle: '=',
            toggle: '=',
            searchOptions: '=',
            search: '=',
            city: '='
        },
        controller: function( $scope, $state ) {
            /* jshint validthis: true */
            var vm = this;

            ////////////////////

            vm.doSort = function(sort) {
                if (vm.searchOptions.sortOrder == vm.toggle) {
                    if (vm.searchOptions.sortType == 'desc') {
                        vm.searchOptions.sortType = 'asc';
                    } else {
                        vm.searchOptions.sortType = 'desc';
                    }
                } else {
                    vm.searchOptions.sortOrder = vm.toggle;
                }

                vm.search();
            };
        }, controllerAs: 'vm', bindToController: true, template: template
    };
}

