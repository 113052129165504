import { Injectable } from '@angular/core';
import { CollectionStorage } from '@ro-ngx/core';
import { Observable } from 'rxjs';
import { ArticleBody } from "../models";
import { ArticleBodyService } from "../services";
import { GetArticleBodyOptions } from "../services/article-body.service";
import { share } from "rxjs/operators";

@Injectable()
export class ArticleBodyStorage extends CollectionStorage<ArticleBody> {

    public articleBody$: Observable<ArticleBody[]>;

    constructor(protected articleBodyService: ArticleBodyService) {
        super();
        this.articleBody$ = this.subject.asObservable();
    }

    public getArticleBody(articleBodyID: number, options: GetArticleBodyOptions): Observable<ArticleBody> {
        const obs = this.articleBodyService.getArticleBody(articleBodyID, options).pipe(share());
        return this.singleUnionObservable(obs, 'articleBodyID');
    }

    public createArticleBody(data: Partial<ArticleBody>): Observable<ArticleBody> {
        const obs = this.articleBodyService.createArticleBody(data).pipe(share());
        return this.pushObservable(obs);
    }

    public updateArticleBody(articleBodyID: number, data: Partial<ArticleBody>): Observable<ArticleBody> {
        const obs = this.articleBodyService.updateArticleBody(articleBodyID, data).pipe(share());
        return this.singleUnionObservable(obs, 'articleBodyID');
    }

    public deleteArticleBody(articleBodyID: number): Observable<string | number> {
        const obs = this.articleBodyService.deleteArticleBody(articleBodyID).pipe(share());
        return this.spliceObservable(obs, 'articleBodyID');
    }
}
