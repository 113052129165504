export class SummaryComponent {
    public static readonly controller = SummaryComponent;
    public static readonly controllerAs = 'vm';
    public static readonly template = require('./summary.component.html');
    public static readonly bindings = {
        sidedata: '=',
        meta: '=',
        data: '=',
    };

    public sidedata: any;
    public userMeta: any;
    public meta: any;

    public data: any;

    public $onChanges() {
        this.userMeta = this.data.userMeta;
    }
}
