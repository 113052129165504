import * as angular from 'angular';
import { difference } from 'lodash';
import 'common/editAlerts/editAlerts.directive';

angular.module( 'cmsApp.delivery.global', [
    'ui.select',
    'cmsApp.editAlerts'
])
.config(DeliveryGlobalCtrlConfig)
.controller('DeliveryGlobalCtrl', DeliveryGlobalCtrl);

function DeliveryGlobalCtrlConfig( $stateProvider ) {

}

function DeliveryGlobalCtrl( $scope, $state, $sce, $q, DeliveryMessageRepository, DeliveryCompanyRepository, DeliveryConverterRepository, translateService ) {
    /* jshint validthis: true */
    var gvm = this;

    gvm.curMessage = '';
    gvm.messageSent = false;

    gvm.restaurants = [];
    gvm.selectedRestaurant = 'zyJee3wiJMNxz136SF';
    gvm.selectedDay = 1;

    gvm.days = [
        { dayNo: 1, dayName: translateService.instant('date.days.monday') },
        { dayNo: 2, dayName: translateService.instant('date.days.tuesday') },
        { dayNo: 3, dayName: translateService.instant('date.days.wednesday') },
        { dayNo: 4, dayName: translateService.instant('date.days.thursday') },
        { dayNo: 5, dayName: translateService.instant('date.days.friday') },
        { dayNo: 6, dayName: translateService.instant('date.days.saturday') },
        { dayNo: 7, dayName: translateService.instant('date.days.sunday') }
    ];

    gvm.dCompanies = [];
    gvm.selectedCompany = null;

    gvm.selectedTemplate = null;
    gvm.selectedPriceplan = null;

    ////////////////////

    gvm.activate = function() {
        $scope.$emit('selectItem', { company: null, item: 'global' } );

        DeliveryConverterRepository.get('/restaurants', '').then(function (data) {
            gvm.restaurants = data;
        });

        DeliveryCompanyRepository.getList('?with=deliverytemplates,deliverypriceplans').then(function(data) {
            gvm.dCompanies = data;

            $scope.$emit('setLoading', false);
        });
    };

    gvm.sendDeliveryMessage = function(message) {
        DeliveryMessageRepository.create('/all', { message: message }).then(function(obj) {
            gvm.curMessage = '';
            gvm.messageSent = true;
        });
    };

    gvm.doConvert = function () {
        var options = angular.copy({
            clientKey:              gvm.selectedRestaurant,
            dayNo:                  gvm.selectedDay,
            deliveryTemplateID:     gvm.selectedTemplate,
            deliveryPriceplanID:    gvm.selectedPriceplan
        });

        DeliveryConverterRepository.create(options).then(function (data) {
            $scope.$emit('showNotification', {
                alertType: 'alert-success',
                title: translateService.instant('general.saved')
            });
        });
    };

    gvm.diffZips = function(zips1, zips2) {
        var z1 = zips1.split(',');
        var z2 = zips2.split(',');
        gvm.diffzipsresult = difference(z1, z2);

        if(gvm.diffzipsresult.length < 1) {
            gvm.diffzipsresult = difference(z2, z1);
        }
    };

    gvm.activate();
    return gvm;
}