export class OrderRowHeaderColumnPickupDiffWarningComponent {
    public static readonly controller = OrderRowHeaderColumnPickupDiffWarningComponent;
    public static readonly controllerAs = 'vm';
    public static readonly template = require('./order-row-header-column-pickup-diff-warning.component.html');
    public static readonly bindings = {
        order: '<'
    };

    public order: any;

    public shouldShowWarning(): boolean {
        if (typeof this.order.hasRestaurantClaimedMissingDeliveryDriver !== 'undefined') {
            return this.order.hasRestaurantClaimedMissingDeliveryDriver;
        }

        return this.order.deliveryorderproblem
            && this.order.deliveryorderproblem.status === 2;
    }
}
