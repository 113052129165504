
import {share, tap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from './user';
import { UserService } from './user.service';
import { ItemStorage } from '@ro-ngx/core';

@Injectable()
export class UserStorage extends ItemStorage<User> {

    public user$: Observable<User>;

    constructor(protected userService: UserService) {
        super();

        this.user$ = this.subject.asObservable();
    }

    public me(): Observable<User> {
        return this.userService.me().pipe(share(),
            tap((user: User) => this.nextSubject(user)));
    }
}
