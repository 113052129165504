import { AlertRepository } from '../alert/alert-repository';
import { SeoService } from "../global/seo/seo.service";

const template = require('./printer.component.html');

export class PrinterComponent {
    static controller: any = PrinterComponent;
    static controllerAs: string = 'vm';
    static template = template;

    public title: string = 'SKRIVAREN';

    protected alertRepository: AlertRepository;

    constructor(protected seoService: SeoService, AlertRepository: AlertRepository) {
        this.seoService.setTitle('Hjälpsida - Skrivaren');

        this.alertRepository = AlertRepository;
    }
}