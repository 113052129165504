
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HungrigClient } from '@ro-ngx/core';
import { BaseService } from 'app/http/base/base.service';
import { Observable } from 'rxjs';

@Injectable()
export class FinanceReportService extends BaseService {


    constructor(public hungrigClient: HungrigClient) {
        super(hungrigClient);
    }
     public getFinanceReport(SetYear, SetMonth): Observable<any>  {

      return this.hungrigClient.get(this.url(`/finance/kpi-report?year=${SetYear}&month=${SetMonth}`)).pipe(
            map((response) => response.json()))
   }
}
