import { Injectable } from '@angular/core';
import { CollectionStorage, Paginate } from '@ro-ngx/core';
import { Observable } from 'rxjs';
import { Article } from "../models";
import { ArticleService } from "../services";
import { GetArticleOptions, GetPaginatedArticlesOptions } from "../services/article.service";
import { share, tap } from "rxjs/operators";

@Injectable()
export class ArticleStorage extends CollectionStorage<Article> {

    public articles$: Observable<Article[]>;

    constructor(protected articleService: ArticleService) {
        super();
        this.articles$ = this.subject.asObservable();
    }

    public getPaginatedArticles(options?: GetPaginatedArticlesOptions): Observable<Paginate<Article[]>> {
        const obs = this.articleService.getPaginatedArticles(options).pipe(share());

        return obs.pipe(
            tap((paginate) => this.unionSubject(paginate.data, 'articleID'))
        );
    }

    public getArticle(articleID: number, options?: GetArticleOptions): Observable<Article> {
        const obs = this.articleService.getArticle(articleID, options).pipe(share());
        return this.singleUnionObservable(obs, 'articleID');
    }

    public createArticle(data: Partial<Article>): Observable<Article> {
        const obs = this.articleService.createArticle(data).pipe(share());

        return this.pushObservable(obs);
    }

    public updateArticle(articleID: number, data: Partial<Article>): Observable<Article> {
        const obs = this.articleService.updateArticle(articleID, data).pipe(share());
        return this.singleUnionObservable(obs, 'articleID');
    }

    public deleteArticle(articleID: number): Observable<string | number> {
        const obs = this.articleService.deleteArticle(articleID).pipe(share());
        return this.spliceObservable(obs, 'articleID');
    }
}
