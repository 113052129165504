import * as angular from 'angular';

import { find } from 'lodash';

const template = require('./deliverypriceplanspan.tpl.html');

angular.module( 'cmsApp.delivery.priceplan.span', [
    'ui.bootstrap',
    'common.modalService',
    'ui.select',
    'ngSanitize'
])
.directive('roDeliveryPriceplanSpan', roDeliveryPriceplanSpan);

function roDeliveryPriceplanSpan() {
    return {
        restrict: 'EA',    
        scope: {
            repo: '=',
            roDeliveryPriceplanSpan: '=',
            priceplanEdit: '='
        },
        controller: function( $scope, modalService, translateService, ENV, DeliveryDistancePriceStorage ) {
            /* jshint validthis: true */
            var vm = this;

            vm.brand = ENV.APP_BRAND;
            vm.currencyCode = translateService.instant('currency.' + ENV.APP_CURRENCY + '.local');

            vm.isDeliveryDistancePrice = false;
            vm.deliveryDistancePrice = undefined;

            vm.isPremiumDistancePrice = false;
            vm.premiumDistancePrice = undefined;

            vm.distancePrices = [];
            vm.distancePriceSpans = [];
            vm.distancePricesSubscription = undefined;

            $scope.$on('$destroy', function() {
                vm.distancePricesSubscription.unsubscribe();
            });

            vm.deliveryDistancePriceToggle = function() {
                if ( ! vm.deliveryDistancePrice && vm.distancePrices.length > 0) {
                    vm.selectDeliveryDistancePrice(vm.distancePrices[0]);
                }
                else {
                    vm.selectDeliveryDistancePrice(undefined);
                }
            };

            vm.selectDeliveryDistancePrice = function(deliveryDistancePrice) {
                vm.deliveryDistancePrice = deliveryDistancePrice;
                vm.roDeliveryPriceplanSpan.deliveryDistancePriceID = deliveryDistancePrice ? deliveryDistancePrice.dDistancePriceID : null;
            };

            vm.premiumDistancePriceToggle = function() {
                if ( ! vm.premiumDistancePrice && vm.distancePrices.length > 0) {
                    vm.premiumDistancePrice = vm.distancePrices[0];
                }
            };

            vm.selectPremiumDistancePrice = function(deliveryDistancePrice) {
                vm.premiumDistancePrice = deliveryDistancePrice;
                vm.roDeliveryPriceplanSpan.premiumDistancePriceID = deliveryDistancePrice ? deliveryDistancePrice.dDistancePriceID : null;
                console.log(deliveryDistancePrice);
            };

            vm.refresh = function() {
                // id is for legacy reasons, don't know what
                // it would break if it were to be changed.
                vm.weekDays = [
                    { id: 'mon', name: translateService.instant('date.days.monday_abbr') },
                    { id: 'tue', name: translateService.instant('date.days.tuesday_abbr') },
                    { id: 'wed', name: translateService.instant('date.days.wednesday_abbr') },
                    { id: 'thu', name: translateService.instant('date.days.thursday_abbr') },
                    { id: 'fri', name: translateService.instant('date.days.friday_abbr') },
                    { id: 'sat', name: translateService.instant('date.days.saturday_abbr') },
                    { id: 'sun', name: translateService.instant('date.days.sunday_abbr') }
                ];

                vm.distancePricesSubscription = DeliveryDistancePriceStorage.distancePrices$
                    .subscribe((distancePrices) => {
                        vm.distancePrices = distancePrices;
                    });

                vm.isDeliveryDistancePrice = !!vm.roDeliveryPriceplanSpan.deliveryDistancePriceID;
                vm.isPremiumDistancePrice = !!vm.roDeliveryPriceplanSpan.premiumDistancePriceID;

                if (vm.isDeliveryDistancePrice) {
                    vm.deliveryDistancePrice = find(vm.distancePrices, {
                        dDistancePriceID: vm.roDeliveryPriceplanSpan.deliveryDistancePriceID
                    });
                }

                if (vm.isPremiumDistancePrice) {
                    vm.premiumDistancePrice = find(vm.distancePrices, {
                        dDistancePriceID: vm.roDeliveryPriceplanSpan.premiumDistancePriceID
                    });
                }

                vm.roDeliveryPriceplanSpan.selected = false;

                vm.roDeliveryPriceplanSpan.selectedWeekDays = [];

                if(vm.roDeliveryPriceplanSpan.mon) {
                    vm.roDeliveryPriceplanSpan.selectedWeekDays.push('mon');
                }
                if(vm.roDeliveryPriceplanSpan.tue) {
                    vm.roDeliveryPriceplanSpan.selectedWeekDays.push('tue');
                }
                if(vm.roDeliveryPriceplanSpan.wed) {
                    vm.roDeliveryPriceplanSpan.selectedWeekDays.push('wed');
                }
                if(vm.roDeliveryPriceplanSpan.thu) {
                    vm.roDeliveryPriceplanSpan.selectedWeekDays.push('thu');
                }
                if(vm.roDeliveryPriceplanSpan.fri) {
                    vm.roDeliveryPriceplanSpan.selectedWeekDays.push('fri');
                }
                if(vm.roDeliveryPriceplanSpan.sat) {
                    vm.roDeliveryPriceplanSpan.selectedWeekDays.push('sat');
                }
                if(vm.roDeliveryPriceplanSpan.sun) {
                    vm.roDeliveryPriceplanSpan.selectedWeekDays.push('sun');
                }

                if(vm.roDeliveryPriceplanSpan.minTime && vm.roDeliveryPriceplanSpan.minTime != '00:00:00')
                {
                    vm.roDeliveryPriceplanSpan.minTime = new Date(1970, 0, 1, vm.roDeliveryPriceplanSpan.minTime.substr(0,2), vm.roDeliveryPriceplanSpan.minTime.substr(3,2));
                }
                else
                {
                    vm.roDeliveryPriceplanSpan.minTime = null;
                }

                if(vm.roDeliveryPriceplanSpan.maxTime && vm.roDeliveryPriceplanSpan.maxTime != '00:00:00')
                {
                    vm.roDeliveryPriceplanSpan.maxTime = new Date(1970, 0, 1, vm.roDeliveryPriceplanSpan.maxTime.substr(0,2), vm.roDeliveryPriceplanSpan.maxTime.substr(3,2));
                }
                else
                {
                    vm.roDeliveryPriceplanSpan.maxTime = null;
                }
            };

            vm.deleteItem = function() {
                var modalOptions = {
                    closeButtonText: translateService.instant('general.cancel'),
                    actionButtonText: translateService.instant('general.delete'),
                    actionButtonClass: 'btn-danger',
                    headerText: translateService.instant('delivery.priceplan_span.confirm_delete_short'),
                    bodyText: translateService.instant('delivery.priceplan_span.confirm_delete')
                };

                modalService.showModal({}, modalOptions).then(function (result) {
                    if(result) {
                        vm.roDeliveryPriceplanSpan.selected = false; 
                        $scope.$emit('deletePriceplanSpan', vm.roDeliveryPriceplanSpan);
                    }
                });
            };

            /*vm.repo.get("", 1).then(function(data) {
                vm.geet = data;
            });                        */

            vm.selectItem = function() {
                vm.roDeliveryPriceplanSpan.selected = !vm.roDeliveryPriceplanSpan.selected;

                var anySelected = false;

                vm.priceplanEdit.deliverypriceplanspans.forEach(function (element) {
                    if(element.selected) {
                        anySelected = true;
                    }
                });

                vm.priceplanEdit.hideSaveButtons = anySelected;
            };

            vm.refresh();
        },
        controllerAs: 'vm',
        bindToController: true,
        template: template
    };
}

