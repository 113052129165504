angular.module( 'cmsApp.alert.group', [
    'ui.select',
    'ngSanitize'
    ])
.config(AlertGroupCtrlConfig)
.controller('AlertGroupCtrl', AlertGroupCtrl);

function AlertGroupCtrlConfig( $stateProvider ) {

}

function AlertGroupCtrl( $scope, $state, $sce, AlertGroupRepository, modalService, alertGroupID, translateService) {
    /* jshint validthis: true */
    var gvm = this;

    gvm.group = null;

    ////////////////////

    function activate() {
        AlertGroupRepository.get("", alertGroupID).then(function(data) {
            gvm.group = data;
            $scope.$emit('selectItem', { groupID: gvm.group.alertGroupID, profileID: null } );

            $scope.$emit('setLoading', false);
        });
    }

    gvm.save = function() {
        $scope.$emit('showNotification', {
            id: 'aGroupSave',
            loading: true,
            alertType: 'alert-info',
            title: translateService.instant('general.loading')
        });

        AlertGroupRepository.update(gvm.group, gvm.group.alertGroupID).then(function(data) {
            $scope.$emit('showNotification', {
                id: 'aGroupSave',
                loading: false,
                alertType: 'alert-success',
                title: translateService.instant('general.saved')
            });
        });

        $scope.$emit('updateGroup', gvm.group);
    };

    gvm.removeGroup = function() {
        var modalOptions = {
            closeButtonText: translateService.instant('general.cancel'),
            actionButtonText: translateService.instant('general.delete'),
            actionButtonClass: 'btn-danger',
            headerText: translateService.instant('alert.alert_group.confirm_deletion_short'),
            bodyText: translateService.instant('alert.alert_group.confirm_deletion')
        };

        modalService.showModal({}, modalOptions).then(function (result) {
            if(result) {
                $scope.$emit('removeGroup', gvm.group);
            }
        });
    };
    
    activate();
    return gvm;
}