import { Injectable } from '@angular/core';
import { BaseService, HungrigClient } from "@ro-ngx/core";
import { JsonConvert } from "json2typescript";
import { Observable } from "rxjs";
import { ArticleBody } from "../models//article-body";
import { map } from "rxjs/operators";

export interface GetArticleBodyOptions {
    includes: string[];
}

@Injectable()
export class ArticleBodyService extends BaseService {
    constructor(
        public hungrigClient: HungrigClient,
        protected jsonConvert: JsonConvert
    ) {
        super(hungrigClient);
        this.apiUrl = hungrigClient.baseUrl;
    }

    public getArticleBody(articleBodyID: number, options: GetArticleBodyOptions): Observable<ArticleBody> {
        return this.get(`/article-body/${articleBodyID}`, { search: options })
            .pipe(map((articles: ArticleBody) => this.jsonConvert.deserialize(articles, ArticleBody) as any));
    }

    public createArticleBody(data: Partial<ArticleBody>): Observable<ArticleBody> {
        return this.post(`/article-body`, data)
            .pipe(map((articles: ArticleBody) => this.jsonConvert.deserialize(articles, ArticleBody) as any));
    }

    public updateArticleBody(articleBodyID: number, data: Partial<ArticleBody>): Observable<ArticleBody> {
        return this.put(`/article-body/${articleBodyID}`, data)
            .pipe(map((articles: ArticleBody) => this.jsonConvert.deserialize(articles, ArticleBody) as any));
    }

    public deleteArticleBody(articleBodyID: number): Observable<string | number> {
        return this.delete(`/article-body/${articleBodyID}`).pipe(map(() => articleBodyID));
    }
}
