angular.module( 'cmsApp.delivery.company.factory', [
    'ui.router',
    'ui.bootstrap'
])

.factory('DeliveryCompanyRepository', function ($q, $timeout, Restangular, AbstractRepository) {

    function DeliveryCompanyRepository() {
        
        var repo = this;
        
        repo.cache = {};
        repo.promise = {};
        
        /**
         * Path cached getList Version.
         * Same as the original getList call BUT each individual call is cached for 5 seconds. 
         * That means that all calls with identical paths (but no specific parameters or headers)
         * will be debounced from the first call, the following 5 seconds and resolved at the same time.
         * 
         * 
         * @param  string path    
         * @param  object params
         * @param  object headers
         * @return promise         
         */
        repo.getList = function (path, params, headers) {
            if(params || headers) {
                return this.restangular.all(this.route + (!!path ? path : "")).getList(params, headers);
            }
            
            if(!repo.cache[path]) {
                if(!repo.promise[path]) {
                    repo.promise[path] = $q(function(resolve, reject) {
                        repo.restangular.all(repo.route + (!!path ? path : "")).getList(params, headers).then(function (data) {
                            repo.cache[path] = data;
                            resolve(data);
                        }, function (error) {
                            reject(error);
                        });
                    });
                    
                    $timeout(function(){
                        repo.cache[path] = null;
                        repo.promise[path] = null;
                    }, 5000);
                }
                return repo.promise[path];
            } else {
                if(!repo.promise[path]) {
                    repo.promise[path] = $q(function(resolve, reject) {
                        resolve(repo.cache[path]);
                    });
                }
                
                return repo.promise[path];
            }
        };

        repo.drivers = function(deliveryCompanyID) {
            return this.getList('/' + deliveryCompanyID + '/drivers');
        };
        
        AbstractRepository.call(this, Restangular, 'delivery/companies', 'deliveryCompanyID');
    }

    AbstractRepository.extend(DeliveryCompanyRepository);

    return new DeliveryCompanyRepository();
})

;
