import { last } from 'lodash';
import { format, isAfter, parse, subMinutes } from "date-fns";

export enum RescheduleRequestStatus {
    REQUESTED = 'requested',
    CONFIRMED = 'confirmed',
    DENIED = 'denied',
    SETTLED = 'settled'
}

export enum RescheduleRequestState {
    REQUESTED = 'requested',
    WAITING = 'waiting',
    CONFIRMED = 'confirmed',
    DENIED = 'denied'
}

export class OrderRowHeaderColumnPickupAtTimeComponent {
    public static readonly controller = OrderRowHeaderColumnPickupAtTimeComponent;
    public static readonly controllerAs = 'vm';
    public static readonly template = require('./order-row-header-column-pickup-at-time.component.html');
    public static readonly bindings = {
        order: '<'
    };

    public order: any;

    public getDriverShouldPickupAtTime(): string {
        return format(this.getDriverShouldPickupAt(), 'HH:mm');
    }

    public getDriverShouldPickupAt() {
        if (typeof this.order.driverShouldPickupAt !== 'undefined') {
            return this.order.driverShouldPickupAt;
        }

        return this.order.pickupTimestamp;
    }

    public getRescheduleRequestState(): RescheduleRequestState {
        if (typeof this.order.rescheduleRequestState !== null) {
            return this.order.rescheduleRequestState;
        }

        const rescheduleRequest = this.getLastRescheduleRequest();
        const dateNow = new Date();

        if (rescheduleRequest) {
            if (rescheduleRequest.status === RescheduleRequestStatus.REQUESTED
                && isAfter(subMinutes(dateNow, 1), parse(rescheduleRequest.createdAt))) {
                return RescheduleRequestState.WAITING;
            }

            if (rescheduleRequest.status === RescheduleRequestStatus.REQUESTED) {
                return RescheduleRequestState.REQUESTED;
            }

            if (rescheduleRequest.status === RescheduleRequestStatus.CONFIRMED) {
                return RescheduleRequestState.CONFIRMED;
            }

            if (rescheduleRequest.status === RescheduleRequestStatus.DENIED) {
                return RescheduleRequestState.DENIED;
            }
        }

        return null;
    }

    public getLastRescheduleRequest(): any {
        return last(this.order.rescheduleRequests);
    }
}
