angular.module( 'cmsApp.delivery.priceplan.factory', [
    'ui.router',
    'ui.bootstrap'
])

.factory('DeliveryPriceplanRepository', function (Restangular, AbstractRepository) {

    function DeliveryPriceplanRepository() {

        AbstractRepository.prototype.savePriceplan = function(data) {
            return this.restangular.one('delivery/priceplans').customPUT(data, data.deliveryPriceplanID);
        };

        AbstractRepository.prototype.createPriceplan = function(item) {
            return this.restangular.all('delivery/priceplans').post(item);
        };

        AbstractRepository.prototype.removePriceplan = function(item) {
            return this.restangular.one('delivery/priceplans', item.deliveryPriceplanID).remove(null, null);
        };

        AbstractRepository.call(this, Restangular, 'delivery/priceplans');
    }

    AbstractRepository.extend(DeliveryPriceplanRepository);

    return new DeliveryPriceplanRepository();
})

;
