import * as angular from 'angular';

import 'app/alert/alert/alert.component';
import 'app/alert/alert/alert.service';
import 'app/alert/alerter-type/alerter-type.service';

var template = require('./alert-list.component.tpl.html');

angular.module( 'cmsApp.alert.profile.alert-list.component', [
    'cmsApp.alert.profile.alert.component',
    'cmsApp.alert.profile.alert.service',
    'cmsApp.alert.alerter-type.service'
])
.component('roAlertProfileAlertList', {
    bindings: {
        'alerts': '<',
        'alertProfile': '<'
    },
    controller: AlertProfileAlertListComponent,
    controllerAs: 'component',
    template: template
});

function AlertProfileAlertListComponent($scope, $filter, AlerterTypeService, AlertService, translateService) {
    var vm = this;

    vm.alerterTypes = [];

    ////////////////////

    vm.$onInit = function() {
        AlerterTypeService.getList().then(function(response) {
            vm.alerterTypes = vm.filterInvalidAlerterTypes(response.plain());
        });
    };

    vm.addSubscription = function(alerterType) {
        $scope.$emit('showNotification', {
            id: 'alertListCreateSubscription',
            loading: true,
            alertType: 'alert-info',
            title: translateService.instant('general.loading'),
            message: translateService.instant('alert.list.adding_prenumeration')
        });

        AlertService.create('', {
            alertProfileID: vm.alertProfile.alertProfileID,
            alerterTypeID: alerterType
        }).then(function(response) {
            $scope.$emit('showNotification', {
                id: 'alertListCreateSubscription',
                loading: false,
                alertType: 'alert-success',
                title: translateService.instant('general.success'),
                message: translateService.instant('alert.list.prenumeration_added')
            });

            vm.alerts.push(response.plain());
        });
    };

    vm.filterInvalidAlerterTypes = function(objects) {
        var invalidAlerterTypes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];

        return $filter('filter')(objects, function(value, index, array) {
            if (invalidAlerterTypes.indexOf(value.alerterTypeID) === -1) {
                return true;
            }

            return false;
        });
    };
}