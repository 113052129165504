angular.module( 'cmsApp.appconfig.factory', [])

.factory('AppconfigRepository', function (Restangular, AbstractRepository) {

    function AppconfigRepository() {
        AbstractRepository.call(this, Restangular, 'appconfigs');
    }

    AbstractRepository.extend(AppconfigRepository);

    return new AppconfigRepository();
})

;
