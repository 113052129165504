var template = require('./alertview-delivery.component.tpl.html');

angular.module( 'cmsApp.alertview.types.alertviewdelivery.component', [])
    .component('roAlertviewDelivery', {
        bindings: {
            'alertviewlog': '=',
            'todaystimesettings': '='
        },
        controller: roAlertviewDeliveryController,
        controllerAs: 'vm',
        template: template
    });

function roAlertviewDeliveryController() {
    var _this = this;

    _this.data = {};

    _this.todaysTimesettings = {};

    //////////////

    _this.$onInit = function() {
        _this.data = _this.alertviewlog;
        _this.todaysTimesettings = _this.todaystimesettings;
    };
}