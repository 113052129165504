import * as angular from 'angular';
import 'app/deliveryfinance/delivery-order/change-delivery-company/change-delivery-company.component';
import 'app/deliveryfinance/delivery-order/accept-delivery-order/accept-delivery-order.component';
import 'app/deliveryfinance/reschedule-activites/reschedule-activities.modal';
import 'app/deliveryfinance/deliveryorder.factory';
import 'app/delivery/driver/deliverydriver.factory';
import 'common/SearchSupportService/SearchSupportService';
import 'common/clientkeySelector/clientkeySelector.directive';
import { NotificationStorage, DialogService } from '@ro-ngx/core';
import { find, get, merge } from 'lodash';
import { AuthorizedService } from '@ro-ngx/authentication';
import { User, PermissionName, UserConnectionType, ActivityType } from '@ro-ngx/users';
import { Subscription } from 'rxjs';
import { first ,  filter ,  debounceTime, flatMap, tap } from 'rxjs/operators';
import {
    addMinutes,
    endOfDay,
    format,
    isAfter,
    isBefore,
    startOfDay,
    subDays,
    parse,
    subMinutes,
    differenceInMinutes
} from 'date-fns';
import {
    DeliveryOrderScheduleService,
    DeliveryOrderRescheduleRequestStatus,
    DeliveryOrderStatus,
    DeliveryOrderService,
    DeliveryDriverService,
    DeliveryDirectionOptions
} from '@ro-ngx/delivery';
import { TransportMode } from '../http/delivery/driver';
import { OrderRowHeaderColumnCarrierAdminCommentComponent } from './order-row/order-row-header/order-row-header-column-carrier/order-row-header-column-carrier-admin-comment/order-row-header-column-carrier-admin-comment.component';
import { OrderRowHeaderColumnCarrierDriverCommentComponent } from './order-row/order-row-header/order-row-header-column-carrier/order-row-header-column-carrier-driver-comment/order-row-header-column-carrier-driver-comment.component';
import { OrderRowHeaderColumnCarrierAssignedByTimeoutComponent } from './order-row/order-row-header/order-row-header-column-carrier/order-row-header-column-carrier-assigned-by-timeout/order-row-header-column-carrier-assigned-by-timeout.component';
import { OrderRowHeaderColumnCarrierDeliveryCompanyNameComponent } from './order-row/order-row-header/order-row-header-column-carrier/order-row-header-column-carrier-delivery-company-name/order-row-header-column-carrier-delivery-company-name.component';
import { OrderRowHeaderColumnCarrierDeliveryDriverNameComponent } from './order-row/order-row-header/order-row-header-column-carrier/order-row-header-column-carrier-delivery-driver-name/order-row-header-column-carrier-delivery-driver-name.component';
import { OrderRowHeaderColumnCarrierLockedOnCarrierComponent } from './order-row/order-row-header/order-row-header-column-carrier/order-row-header-column-carrier-locked-on-carrier/order-row-header-column-carrier-locked-on-carrier.component';
import { OrderRowHeaderColumnCarrierTransportModeComponent } from './order-row/order-row-header/order-row-header-column-carrier/order-row-header-column-carrier-transport-mode/order-row-header-column-carrier-transport-mode.component';
import { OrderRowHeaderColumnCarrierCustomerCommentComponent } from './order-row/order-row-header/order-row-header-column-carrier/order-row-header-column-carrier-customer-comment/order-row-header-column-carrier-customer-comment.component';
import { OrderRowHeaderColumnCarrierComponent } from './order-row/order-row-header/order-row-header-column-carrier/order-row-header-column-carrier.component';
import { OrderRowHeaderColumnRestaurantComponent } from './order-row/order-row-header/order-row-header-column-restaurant/order-row-header-column-restaurant.component';
import { OrderRowHeaderColumnRestaurantNameComponent } from './order-row/order-row-header/order-row-header-column-restaurant/order-row-header-column-restaurant/order-row-header-column-restaurant-name.component';
import { OrderRowHeaderColumnDateComponent } from './order-row/order-row-header/order-row-header-column-date/order-row-header-column-date.component';
import { OrderRowHeaderColumnDateFormatComponent } from './order-row/order-row-header/order-row-header-column-date/order-row-header-column-date-format/order-row-header-column-date-format.component';
import { OrderRowHeaderColumnIdComponent } from './order-row/order-row-header/order-row-header-column-id/order-row-header-column-id.component';
import { OrderRowHeaderColumnIdNumericComponent } from './order-row/order-row-header/order-row-header-column-id/order-row-header-column-id-numeric/order-row-header-column-id-numeric.component';
import { OrderRowHeaderColumnDistanceComponent } from './order-row/order-row-header/order-row-header-column-distance/order-row-header-column-distance.component';
import { OrderRowHeaderColumnDistanceKilometersComponent } from './order-row/order-row-header/order-row-header-column-distance/order-row-header-column-distance-kilometers/order-row-header-column-distance-kilometers.component';
import { OrderRowHeaderColumnOrderedTimeComponent } from './order-row/order-row-header/order-row-header-column-ordered-time/order-row-header-column-ordered-time.component';
import { OrderRowHeaderColumnOrderedTimeFormatComponent } from './order-row/order-row-header/order-row-header-column-ordered-time/order-row-header-column-ordered-time-format/order-row-header-column-ordered-time-format.component';
import { OrderRowHeaderColumnPickupAtComponent } from './order-row/order-row-header/order-row-header-column-pickup-at/order-row-header-column-pickup-at.component';
import { OrderRowHeaderColumnPickupAtTimeComponent } from './order-row/order-row-header/order-row-header-column-pickup-at/order-row-header-column-pickup-at-time/order-row-header-column-pickup-at-time.component';
import { OrderRowHeaderColumnPickupInComponent } from './order-row/order-row-header/order-row-header-column-pickup-in/order-row-header-column-pickup-in.component';
import { OrderRowHeaderColumnPickupInUnitsComponent } from './order-row/order-row-header/order-row-header-column-pickup-in/order-row-header-column-pickup-in-units/order-row-header-column-pickup-in-units.component';
import { OrderRowHeaderColumnCheckinDiffComponent } from './order-row/order-row-header/order-row-header-column-checkin-diff/order-row-header-column-checkin-diff.component';
import { OrderRowHeaderColumnCheckinDiffUnitsComponent } from './order-row/order-row-header/order-row-header-column-checkin-diff/order-row-header-column-checkin-diff-units/order-row-header-column-checkin-diff-units.component';
import { OrderRowHeaderColumnPickupDiffUnitsComponent } from './order-row/order-row-header/order-row-header-column-pickup-diff/order-row-header-column-pickup-diff-units/order-row-header-column-pickup-diff-units.component';
import { OrderRowHeaderColumnPickupDiffComponent } from './order-row/order-row-header/order-row-header-column-pickup-diff/order-row-header-column-pickup-diff.component';
import { OrderRowHeaderColumnPickupDiffWarningComponent } from './order-row/order-row-header/order-row-header-column-pickup-diff/order-row-header-column-pickup-diff-warning/order-row-header-column-pickup-diff-warning.component';
import { OrderRowHeaderColumnDeliveryAtComponent } from './order-row/order-row-header/order-row-header-column-delivery-at/order-row-header-column-delivery-at.component';
import { OrderRowHeaderColumnDeliveryAtUnitsComponent } from './order-row/order-row-header/order-row-header-column-delivery-at/order-row-header-column-delivery-at-units/order-row-header-column-delivery-at-units.component';
import { MinimalDeliveryOrderRepository } from './minimal-delivery-order.repository';
import { BeepingService } from './beeping.service';
import { SummaryComponent } from './summary/summary.component';
import { DeliveryOrder } from '@ro-ngx/compensation-history';
import { DeliveryFleetComponent } from './delivery-order/delivery-fleet/delivery-fleet.component';
import { DeliveryOrderRepository } from 'app/deliveryfinance/deliveryorder.factory';

const rescheduleActivitiesTpl = require('./reschedule-activites/reschedule-activities.modal.html');

angular.module('cmsApp.deliveryfinance', [
        'cmsApp.deliveryfinance.deliveryorder.changedeliverycompany.component',
        'cmsApp.deliveryfinance.deliveryorder.acceptdeliveryorder.component',
        'cmsApp.deliveryorder.factory',
        'ui.bootstrap',
        'cmsApp.delivery.driver.factory',
        'common.SearchSupportService',
        'cmsApp.clientkeySelector',
        'cmsApp.deliveryfinance.rescheduleActivities'
    ])

    // = = = FIX FOR ANGULAR UI / BOOTSTRAP INITIAL FORMATTING BUG
    // See https://github.com/angular-ui/bootstrap/issues/2659
    .directive('datepickerPopup', function() {
        return {
            restrict: 'EAC',
            require: 'ngModel',
            link: function(scope, element, attr, controller: any) {
                //remove the default formatter from the input directive to prevent conflict
                controller.$formatters.shift();
            }
        };
    })
    // // = = = END OF FIX FOR ANGULAR UI / BOOTSTRAP INITIAL FORMATTING BUG

    .service('MinimalDeliveryOrderRepository', MinimalDeliveryOrderRepository)
    .service('BeepingService', BeepingService)

    .component('deliveryFinanceSummary', SummaryComponent)

    .component('orderRowHeaderColumnCarrier', OrderRowHeaderColumnCarrierComponent)
    .component('orderRowHeaderColumnCarrierDeliveryCompanyName', OrderRowHeaderColumnCarrierDeliveryCompanyNameComponent)
    .component('orderRowHeaderColumnCarrierTransportMode', OrderRowHeaderColumnCarrierTransportModeComponent)
    .component('orderRowHeaderColumnCarrierDeliveryDriverName', OrderRowHeaderColumnCarrierDeliveryDriverNameComponent)
    .component('orderRowHeaderColumnCarrierLockedOnCarrier', OrderRowHeaderColumnCarrierLockedOnCarrierComponent)
    .component('orderRowHeaderColumnCarrierAssignedByTimeout', OrderRowHeaderColumnCarrierAssignedByTimeoutComponent)
    .component('orderRowHeaderColumnCarrierAdminComment', OrderRowHeaderColumnCarrierAdminCommentComponent)
    .component('orderRowHeaderColumnCarrierCustomerComment', OrderRowHeaderColumnCarrierCustomerCommentComponent)
    .component('orderRowHeaderColumnCarrierDriverComment', OrderRowHeaderColumnCarrierDriverCommentComponent)

    .component('orderRowHeaderColumnRestaurant', OrderRowHeaderColumnRestaurantComponent)
    .component('orderRowHeaderColumnRestaurantName', OrderRowHeaderColumnRestaurantNameComponent)

    .component('orderRowHeaderColumnDate', OrderRowHeaderColumnDateComponent)
    .component('orderRowHeaderColumnDateFormat', OrderRowHeaderColumnDateFormatComponent)

    .component('orderRowHeaderColumnId', OrderRowHeaderColumnIdComponent)
    .component('orderRowHeaderColumnIdNumeric', OrderRowHeaderColumnIdNumericComponent)

    .component('orderRowHeaderColumnDistance', OrderRowHeaderColumnDistanceComponent)
    .component('orderRowHeaderColumnDistanceKilometers', OrderRowHeaderColumnDistanceKilometersComponent)

    .component('orderRowHeaderColumnOrderedTime', OrderRowHeaderColumnOrderedTimeComponent)
    .component('orderRowHeaderColumnOrderedTimeFormat', OrderRowHeaderColumnOrderedTimeFormatComponent)

    .component('orderRowHeaderColumnPickupAt', OrderRowHeaderColumnPickupAtComponent)
    .component('orderRowHeaderColumnPickupAtTime', OrderRowHeaderColumnPickupAtTimeComponent)

    .component('orderRowHeaderColumnPickupIn', OrderRowHeaderColumnPickupInComponent)
    .component('orderRowHeaderColumnPickupInUnits', OrderRowHeaderColumnPickupInUnitsComponent)

    .component('orderRowHeaderColumnCheckinDiff', OrderRowHeaderColumnCheckinDiffComponent)
    .component('orderRowHeaderColumnCheckinDiffUnits', OrderRowHeaderColumnCheckinDiffUnitsComponent)

    .component('orderRowHeaderColumnPickupDiff', OrderRowHeaderColumnPickupDiffComponent)
    .component('orderRowHeaderColumnPickupDiffUnits', OrderRowHeaderColumnPickupDiffUnitsComponent)
    .component('orderRowHeaderColumnPickupDiffWarning', OrderRowHeaderColumnPickupDiffWarningComponent)

    .component('orderRowHeaderColumnDeliveryAt', OrderRowHeaderColumnDeliveryAtComponent)
    .component('orderRowHeaderColumnDeliveryAtUnits', OrderRowHeaderColumnDeliveryAtUnitsComponent)

    .component('deliveryFleet', DeliveryFleetComponent)

    .controller('DeliveryFinanceCtrl', DeliveryFinanceCtrl);

function DeliveryFinanceCtrl(
    $scope,
    $filter,
    $state,
    $interval,
    DeliveryOrderRepository: DeliveryOrderRepository,
    ENV,
    DeliveryCompanyRepository,
    DeliveryRestaurantRepository,
    DeliveryDriverRepository,
    moment,
    DeliveryTimeoutSchedule,
    SearchSupportService,
    BeepingService: BeepingService,
    authorizedService: AuthorizedService,
    notificationStorage: NotificationStorage,
    financeService,
    translateService,
    dialogService: DialogService,
    MinimalDeliveryOrderRepository: MinimalDeliveryOrderRepository,
    deliveryOrderScheduleService: DeliveryOrderScheduleService,
    deliveryDriverService: DeliveryDriverService,
    deliveryOrderService: DeliveryOrderService,
    $modal,
    DeliveryDistrictRepository,
) {
    /* jshint validthis: true */
    var vm: any | {
        subscriptions: Subscription[];
        user: User
    } = this;

    vm.DeliveryOrderRescheduleRequestStatus = DeliveryOrderRescheduleRequestStatus;

    vm.ENV = ENV;

    vm.orders = {};

    vm.fromOptions = { open: false };
    vm.toOptions = { open: false };

    vm.isEconomyView = false;

    vm.searchtext = '';
    vm.searchCompanies = [];
    vm.searchExcludeCompanies = false;
    vm.searchCompaniesThroughCompanyTimeout = [];
    vm.searchRestaurants = [];
    vm.searchDrivers = [];
    vm.searchStatus = [];
    vm.searchOnlineDrivers = true;
    vm.searchTags = [];
    vm.expandedOrdersIds = {};
    vm.searchDistricts = [];

    // When last search occured (milliseconds since Unix Epoch)
    vm.searchedOn = null;

    vm.searchFrom = null;
    vm.searchTo = null;

    vm.searchConfirmReq = true;
    vm.searchHungrig = true;
    vm.searchRo = true;
    vm.searchDelivery = true;
    vm.searchPremium = true;
    vm.searchOnlyAdminComment = false;
    vm.searchHasActiveRescheduleRequest = false;
    vm.overrideCriteria = 'show_all';

    vm.autoUpdate = false;
    vm.soundUpdate = false;

    vm.dCompanies = [];
    vm.dCompaniesBeenFetched = false;
    vm.restaurants = [];
    vm.drivers = [];
    vm.priorityTags = [];
    vm.districts = [];

    vm.perpage = 50;

    vm.meta = {
        current_page: 1,
        last_page: null,
        per_page: null,
        from: null,
        to: null,
        total: null
    };

    vm.dCompaniesForSearch = [];

    vm.dCompaniesSearch = function(search) {
        const results = $filter('filter')(vm.dCompanies, { companyName: search });

        if (results.length > 10) {
            results.length = 10;
        }

        vm.dCompaniesForSearch = results;
    };

    vm.restaurantsForSearch = [];

    vm.restaurantsSearch = function(search) {
        const results = $filter('filter')(vm.restaurants, { restaurantName: search });

        if (results.length > 10) {
            results.length = 10;
        }

        vm.restaurantsForSearch = results;
    };

    vm.districtsForSearch = [];

    vm.districtsSearch = function(search){
        const results = $filter('filter')(vm.districts, { name: search });

        vm.districtsForSearch = results;
    };

    vm.driversForSearch = [];

    vm.statusOptions = [
        {
            status: DeliveryOrderStatus.UNCONFIRMED,
            title: translateService.instant('delivery_order.status.unconfirmed')
        },
        {
            status: DeliveryOrderStatus.CONFIRMED,
            title: translateService.instant('delivery_order.status.confirmed')
        },
    ];

    vm.DeliveryOrderActionType = {
        CHECK_IN: 0,
        FINISHED: 1,
        DELIVERED: 2,
        DRIVER_COMMENT: 10
    };

    vm.DeliveryOrderActionTypeOrder = [
        vm.DeliveryOrderActionType.CHECK_IN,
        vm.DeliveryOrderActionType.FINISHED,
        vm.DeliveryOrderActionType.DELIVERED,
        vm.DeliveryOrderActionType.DRIVER_COMMENT
    ];

    vm.DeliveryOrderActionTypeTranslations = {
        [vm.DeliveryOrderActionType.CHECK_IN]: 'deliveryfinance.order_action.check_in',
        [vm.DeliveryOrderActionType.FINISHED]: 'deliveryfinance.order_action.finished',
        [vm.DeliveryOrderActionType.DELIVERED]: 'deliveryfinance.order_action.delivered',
        [vm.DeliveryOrderActionType.DRIVER_COMMENT]: 'deliveryfinance.order_action.driver_comment'
    };

    vm.driversSearch = (driverName) => {
        const options = {
            limit: 10
        };

        if (driverName !== '') {
            options['driverName'] = driverName + '%';
        }

        if (vm.searchOnlineDrivers) {
            options['hasRequestAfter'] = format(subMinutes(new Date(), 5));
        }

        const debounceTimeMs = 250;

        deliveryDriverService.getDrivers(options)
            .pipe(debounceTime(debounceTimeMs))
            .subscribe((drivers) => vm.driversForSearch = drivers);
    };

    vm.timeoutSchedules = [
        {
            deliveryTimeoutScheduleID: 0,
            scheduleName: 'Ingen timeout'
        }
    ];

    vm.sidedata = {};

    vm.selectedPeriod = translateService.instant('deliveryfinance.period_quick_choice');
    vm.periodNames = [translateService.instant('deliveryfinance.period_quick_choice')];
    vm.economyPeriods = {};

    vm.dateRegex = '\\d\\d\\d\\d-\\d\\d-\\d\\d';

    vm.dateOptions = {
        formatYear: 'yy',
        startingDay: 1
    };

    vm.updatePromise = null;

    vm.pickupLineBeforeAcceptLine = false;
    vm.user = undefined;
    vm.userMeta = {
        isAllowedToReadAll: false,
        deliveryCompanyIDs: []
    };

    vm.subscriptions = [];
    vm.isAllowedToReadAll = false;

    ////////////////////

    vm.isAllowedReadCreatedBefore7Days = function(user: User, deliveryCompanyIds: number[]) {
        if (user.hasPermissions([
            PermissionName.DELIVERY_ORDERS_READ_ALL,
            PermissionName.DELIVERY_ORDERS_READ_CREATED_BEFORE_7_DAYS
        ])) {
            return true;
        }

        for (let i = 0; i < deliveryCompanyIds.length; i++) {
            let deliveryCompanyId = deliveryCompanyIds[i];
            let userConnection = user.findUserConnection(UserConnectionType.DELIVERY_COMPANY, deliveryCompanyId);
            if (!userConnection || !userConnection.hasPermissions([
                PermissionName.DELIVERY_ORDERS_READ,
                PermissionName.DELIVERY_ORDERS_READ_CREATED_BEFORE_7_DAYS
            ])) {
                return false;
            }
        }

        return true;
    };

    vm.isAllowedReadCreatedBeyond40Mintures = function(user: User, deliveryCompanyIds: number[]) {
        if (user.hasPermissions([
            PermissionName.DELIVERY_ORDERS_READ_ALL,
            PermissionName.DELIVERY_ORDERS_READ_CREATED_BEYOND_40_MINUTES
        ])) {
            return true;
        }

        for (let i = 0; i < deliveryCompanyIds.length; i++) {
            let deliveryCompanyId = deliveryCompanyIds[i];
            let userConnection = user.findUserConnection(UserConnectionType.DELIVERY_COMPANY, deliveryCompanyId);
            if (!userConnection || !userConnection.hasPermissions([
                PermissionName.DELIVERY_ORDERS_READ,
                PermissionName.DELIVERY_ORDERS_READ_CREATED_BEYOND_40_MINUTES
            ])) {
                return false;
            }
        }

        return true;
    };

    vm.activate = function() {
        vm.subscriptions.push(
            authorizedService.user$
                .subscribe((user) => vm.user = user)
        );

        vm.subscriptions.push(
            authorizedService.user$
                .pipe(filter((user) => !!user), first())
                .subscribe((user: User) => {
                    vm.user = user;
                    vm.userMeta.deliveryCompanyIDs = user.getUserConnectionType(UserConnectionType.DELIVERY_COMPANY)
                        .map((userConnection) => userConnection.deliveryCompanyID);

                    vm.userMeta.isAllowedToReadAll = user.hasPermission(PermissionName.DELIVERY_COMPANIES_READ_ALL);

                    const query = {};
                    if (!vm.userMeta.isAllowedToReadAll) {
                        query['deliveryCompanyID[]'] = vm.userMeta.deliveryCompanyIDs;
                    }

                    DeliveryDriverRepository.getList(null, query).then(function(data) {
                        vm.drivers = data.plain();
                    });

                    DeliveryDistrictRepository.getList().then(function(data) {
                        vm.districts = data.plain();
                    });

                    DeliveryCompanyRepository.getList(null, query).then(function(data) {
                        vm.dCompanies = data.plain();
                        vm.dCompaniesBeenFetched = true;
                    });

                    DeliveryRestaurantRepository.getList('/all/').then(function(data) {
                        vm.restaurants = data;
                        vm.periodNames = vm.periodNames.concat(Object.keys(vm.economyPeriods));
                    });

                    financeService.getEconomyPeriods()
                        .subscribe((periods) => {
                            periods.forEach((period) => vm.economyPeriods[period.title] = [
                                period.start,
                                period.end
                            ]);
                        });

                    DeliveryOrderRepository.getList('/prioritytags/').then(function(data) {
                        vm.priorityTags = data;
                    });

                    DeliveryTimeoutSchedule.getList().then(function(data) {
                        vm.timeoutSchedules = vm.timeoutSchedules.concat(data);
                    });

                    if ($state.params.orderID) {
                        vm.searchtext = $state.params.orderID;
                    }

                    vm.searchFrom = new Date();
                    vm.searchTo = new Date();

                    // Use ng-change instead??
                    $scope.$watch('[vm.searchtext,vm.distfrom,vm.distto,vm.searchFrom,vm.searchTo,vm.searchConfirmReq,vm.searchHungrig,vm.searchRo,vm.searchPremium,vm.searchDelivery,vm.searchExcludeCompanies]', function(newValue, oldValue) {
                        vm.search();
                    });
                })
        );

    };

    $scope.$on("$destroy", () => {
        vm.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
    });

    vm.truncateText = function(text) {
        return SearchSupportService.truncateText(text);
    };

    vm.selectDefaultPeriod = function() {
        vm.selectedPeriod = translateService.instant('deliveryfinance.period_quick_choice');
    };

    vm.selectPeriod = function() {
        if (!vm.economyPeriods[vm.selectedPeriod]) {
            return;
        }

        var selectedPeriod = vm.economyPeriods[vm.selectedPeriod];

        vm.searchFrom = new Date(selectedPeriod[0]);
        vm.searchTo = new Date(selectedPeriod[1]);
    };

    vm.getSearchData = function() {
        var searchCompanies: any = [];
        angular.forEach(vm.searchCompanies, function(company) {
            searchCompanies.push(company.deliveryCompanyID);
        });

        var excludeDeliveryCompanyIDs: any = [];
        if (vm.searchExcludeCompanies) {
            excludeDeliveryCompanyIDs = searchCompanies;
            searchCompanies = [];
        }

        if (!vm.userMeta.isAllowedToReadAll && searchCompanies.length === 0) {
            angular.forEach(vm.userMeta.deliveryCompanyIDs, (deliveryCompanyID) => {
                if (excludeDeliveryCompanyIDs.indexOf(deliveryCompanyID) === -1) {
                    searchCompanies.push(deliveryCompanyID);
                }
            });
        }

        var searchCompaniesThroughCompanyTimeout: any = [];
        angular.forEach(vm.searchCompaniesThroughCompanyTimeout, function(company) {
            searchCompaniesThroughCompanyTimeout.push(company.deliveryCompanyID);
        });
        searchCompaniesThroughCompanyTimeout = searchCompaniesThroughCompanyTimeout.join(',');

        let fromDate = startOfDay(vm.searchFrom);
        if (!vm.isAllowedReadCreatedBefore7Days(vm.user, searchCompanies)) {
            const sevenDaysAgo = subDays(startOfDay(new Date()), 6);

            if (isBefore(fromDate, sevenDaysAgo)) {
                fromDate = sevenDaysAgo;
            }
        }

        let to = endOfDay(vm.searchTo);
        if (!vm.isAllowedReadCreatedBeyond40Mintures(vm.user, searchCompanies)) {
            const beyond40Minutes = addMinutes(new Date(), 40);

            if (isAfter(to, beyond40Minutes)) {
                to = beyond40Minutes;
            }
        }

        return {
            'search':                         vm.searchtext,
            'distfrom':                       vm.distfrom,
            'distto':                         vm.distto,
            'deliveryCompanyID[]':            searchCompanies,
            'excludeDeliveryCompanyID[]':     excludeDeliveryCompanyIDs,
            'deliveryDistrictID[]':           vm.searchDistricts.map((district) => district.deliveryDistrictID),
            'restaurants':                    vm.searchRestaurants.join(','),
            'status[]':                       vm.searchStatus.map((statusOption) => statusOption.status),
            'companiesThroughCompanyTimeout': searchCompaniesThroughCompanyTimeout,
            'hasActiveRescheduleRequest':     vm.searchHasActiveRescheduleRequest ? 1 : 0,
            'overrideCriteria':               vm.overrideCriteria,
            'drivers':                        vm.searchDrivers.map((driver) => driver.deliveryDriverID).join(','),
            'tags':                           vm.searchTags.map((driver) => driver.priorityTag).join(','),
            'from':                           format(fromDate, 'YYYY-MM-DD HH:mm:ss'),
            'to':                             format(to, 'YYYY-MM-DD HH:mm:ss'),
            'confirmReq':                     (vm.searchConfirmReq ? 1 : 0),
            'hungrig':                        (vm.searchHungrig ? 1 : 0),
            'ro':                             (vm.searchRo ? 1 : 0),
            'onlyAdminComment':               (vm.searchOnlyAdminComment ? 1 : 0),
            'delivery':                       (vm.searchDelivery ? 1 : 0),
            'premium':                        (vm.searchPremium ? 1 : 0),
            'perpage':                        vm.perpage,
            'page':                           vm.meta.current_page
        };
    };

    vm.toggleOrderExpansion = (order) => {
        if (vm.expandedOrdersIds[order.orderID]) {
            return vm.shrinkOrder(order);
        }

        return vm.expandOrder(order);
    };

    vm.expandOrder = (order) => {
        vm.refreshOrder(order)
            .then(() => vm.expandedOrdersIds[order.orderID] = true);
    };

    vm.shrinkOrder = (order) => {
        delete vm.expandedOrdersIds[order.orderID];
    };

    vm.toggleEconomyView = () => {
        vm.isEconomyView = ! vm.isEconomyView;
        vm.search();
    };

    vm.searchDataKeyValue = [];

    vm.toKeyValue = function(data) {
        const keyValue = [];
        Object.keys(data)
            .forEach((key) => {
                if (data.hasOwnProperty(key)) {
                    const value = data[key];

                    if (Array.isArray(value)) {
                        value.forEach((nestedValue) => {
                            keyValue.push({
                                key: key,
                                value: nestedValue
                            });
                        });
                    }
                    else {
                        keyValue.push({
                            key: key,
                            value: value
                        });
                    }
                }
            });

        return keyValue;
    };

    vm.hasPermission = function(deliveryCompanyID, globally, locally) {
        const canGlobally = vm.user.userConnections.find((userConnection) => userConnection.hasPermissions(globally));
        if (canGlobally) {
            return true;
        }

        const userConnection = vm.user.findUserConnection(UserConnectionType.DELIVERY_COMPANY, deliveryCompanyID);
        return userConnection ? userConnection.hasPermissions(locally) : false;
    };

    vm.permissions = {
        deliveryOrderReadPrice: {
            globally: [
                PermissionName.DELIVERY_ORDERS_READ_ALL,
                PermissionName.DELIVERY_ORDERS_READ_PRICE
            ],
            locally: [
                PermissionName.DELIVERY_ORDERS_READ,
                PermissionName.DELIVERY_ORDERS_READ_PRICE
            ]
        },
        deliveryOrderUpdateTimes: {
            globally: [
                PermissionName.DELIVERY_ORDERS_UPDATE_ALL,
                PermissionName.DELIVERY_ORDERS_UPDATE_TIMES
            ],
            locally: [
                PermissionName.DELIVERY_ORDERS_UPDATE,
                PermissionName.DELIVERY_ORDERS_UPDATE_TIMES
            ]
        },
        deliveryOrderUpdateDistance: {
            globally: [
                PermissionName.DELIVERY_ORDERS_UPDATE_ALL,
                PermissionName.DELIVERY_ORDERS_UPDATE_DISTANCE
            ],
            locally: [
                PermissionName.DELIVERY_ORDERS_UPDATE,
                PermissionName.DELIVERY_ORDERS_UPDATE_DISTANCE
            ]
        }
    };

    vm.filterCompaniesForOrders = () => {
        angular.forEach(vm.orders, (order: DeliveryOrder) => {
            (order as any).dCompaniesForOrder = vm.filterCompaniesForOrder(order);
        });
    };

    vm.filterCompaniesForOrder = (order: DeliveryOrder) => {
        if (vm.dCompaniesBeenFetched) {
            // AVBOKAD - A company for a delivery cancellation.
            const AVBOKAD_ID = ENV.APP_COUNTRY === 'SE' ? 98 : 3;

            return vm.dCompanies
                // List only companies belonging to order district, and AVBOKAD
                .filter((dc) => {
                    const isAvbokad = dc.deliveryCompanyID === AVBOKAD_ID;
                    return dc.deliveryDistrictID === order.deliverycompany['deliveryDistrictID'] || isAvbokad;
                })
                // List AVBOKAD at the end
                .sort((a, b) => {
                    if (a.deliveryCompanyID === AVBOKAD_ID) {
                        return 1;
                    } else if (b.deliveryCompanyID === AVBOKAD_ID) {
                        return -1;
                    } else {
                        return a < b;
                    }
                });

        } else {
            return [];
        }
    };

    vm.search = function() {
        let loader = notificationStorage.loading();
        let searchedOn = (new Date).getTime();
        vm.searchedOn = searchedOn;

        if (vm.updatePromise) {
            $interval.cancel(vm.updatePromise);
            vm.updatePromise = null;
        }

        const query = vm.getSearchData();
        vm.searchDataKeyValue = vm.toKeyValue(query);

        vm.getDeliveryOrders(query)
            .then((data) => {

            if (searchedOn !== vm.searchedOn) {
                notificationStorage.dismiss(loader);
                return;
            }

            vm.orders = data.orders;
            vm.meta = data.meta;
            vm.sidedata = data.sideData;

            angular.forEach(vm.orders, function(order) {
                if ( ! vm.isEconomyView) {
                    if (vm.orderInformation[order.orderID]) {
                        merge(order, vm.orderInformation[order.orderID]);
                    }

                    if (vm.expandedOrdersIds[order.orderID]) {
                        vm.refreshOrder(order);
                    }
                }

                if (vm.soundUpdate && order.status == 1 && get(order, 'deliverydriver.driverName') == null) {
                    BeepingService.beep();
                }

                vm.calculateOrderAttributes(order);
            });

            if (!vm.updatePromise && vm.autoUpdate) {
                vm.updatePromise = $interval(vm.search, 30000);
            }

            notificationStorage.dismiss(loader);
        }, function(response) {
            if (response.data.status === 403) {
                vm.orders = [];
                vm.meta = {};
                vm.sidedata = {};
            }
            notificationStorage.dismiss(loader);
        });
    };

    vm.getDeliveryOrders = (query) => {
        if (vm.isEconomyView) {
            return DeliveryOrderRepository.getDeliveryOrders(query);
        }

        return MinimalDeliveryOrderRepository.getMinimalOrders(query)
            .then((minimalDeliveryOrders: any) => ({
                orders: minimalDeliveryOrders,
                metadata: {},
                meta: { current_page: minimalDeliveryOrders.metadata.currentPage },
                sideData: {}
            }));
    };

    vm.createDirectionOptions = (order): DeliveryDirectionOptions => {
        let origin: string | number[] = [
            order.order.restaurantcontact.address,
            order.order.restaurantcontact.zip,
            order.order.restaurantcontact.city
        ].join(',');

        if (order.originLat && order.originLon) {
            origin = [
                order.originLon,
                order.originLat
            ];
        }

        let destination: string | number[] = [
            order.order.ordercustomer.address,
            order.order.ordercustomer.zip,
            order.order.ordercustomer.city
        ].join(',');

        if (order.destinationLat && order.destinationLon) {
            destination = [
                order.destinationLon,
                order.destinationLat
            ];
        }

        const transportMode = order.deliverydriver ? order.deliverydriver.transportMode : TransportMode.Bicycle;

        const markers = [];

        if (order.deliverydriver.deliveryDriverPosition) {
            const currentPosition = order.deliverydriver.deliveryDriverPosition;

            markers.push({
                coordinates: [
                    currentPosition.longitude,
                    currentPosition.latitude
                ],
                icon: order.deliverydriver.transportMode === TransportMode.Bicycle ? 'bicycle' : 'car'
            });
        }

        const mapCenter = [
            order.order.restaurantcontact.longitude,
            order.order.restaurantcontact.latitude
        ];

        return {
            origin,
            destination,
            transportMode,
            markers,
            mapCenter
        };
    };

    vm.transportModeIcon = function(order) {
        const transportMode = get(order, 'deliverydriver.transportMode');

        switch (transportMode) {
            case 0:
                return `<i class="fa fa-car" aria-hidden="true"></i>`;

            case 1:
                return `<i class="fa fa-motorcycle" aria-hidden="true"></i>`;

            default:
                return '';
        }
    };

    vm.showDriverPosition = function(deliveryDriverID: number): void {
        const blank = window.open('', '_blank');
        blank.location.replace(window.location.origin + `/driver-location?deliveryDriverID=${deliveryDriverID}`);
    };

    vm.timeoutOrder = (order) => {
        const loader = notificationStorage.loading();

        deliveryOrderService.timeoutDeliveryOrder(order.deliveryOrderID)
            .subscribe(() => {
                loader.success(translateService.instant('general.success'));
                vm.search();
            }, (error) => loader.danger(error.error_message));
    };

    vm.removeOrderTimeout = (order) => {
        const loader = notificationStorage.loading();

        deliveryOrderService.removeDeliveryOrderTimeout(order.deliveryOrderID)
            .subscribe(() => {
                order.show = false;
                loader.success(translateService.instant('general.success'));
                vm.search();
            }, (error) => loader.danger(error.error_message));
    };

    vm.hasActiveTimeout = (order) => {
        return order.timeoutLine !== null && order.status === 1;
    };

    vm.changeDeliveryTimeoutScheduleOnOrder = function(order) {
        var timeoutLine = vm.getTimeoutLineFromOrderUsingCreateLine(order);

        order.changeTime = {
            timeoutLine: timeoutLine ? {
                date: timeoutLine.format('YYYY-MM-DD'),
                hours: timeoutLine.format('HH'),
                minutes: timeoutLine.format('mm'),
                seconds: timeoutLine.format('ss')
            } : null
        };
    };

    vm.getTimeoutLineFromOrderUsingCreateLine = function(order) {
        if (order.deliveryTimeoutScheduleID === 0) {
            return false;
        }

        var timeoutScheme = $filter('filter')(vm.timeoutSchedules, { deliveryTimeoutScheduleID: order.deliveryTimeoutScheduleID }, true)[0];

        var firstTimeoutStep = timeoutScheme.timeoutsteps[0];
        return moment(order.createLine).add(firstTimeoutStep.timeout, 'seconds');
    };

    vm.addChangeTimeToOrder = function(order) {
        const createLine = moment(order.createLine);
        const pickupLine = order.$activeRescheduleRequest ?
            moment(order.$activeRescheduleRequest.toPickupLine) : moment(order.pickupLine);
        const timeoutLine = order.timeoutLine ? moment(order.timeoutLine) : false;

        order.changeTime = {
            createLine: {
                moment: moment.unix(createLine),
                date: createLine.format('YYYY-MM-DD'),
                hours: createLine.format('HH'),
                minutes: createLine.format('mm'),
                seconds: '00'
            },
            pickupLine: {
                moment: moment.unix(pickupLine),
                date: pickupLine.format('YYYY-MM-DD'),
                hours: pickupLine.format('HH'),
                minutes: pickupLine.format('mm'),
                seconds: '00'
            },
            timeoutLine: timeoutLine ? {
                moment: moment.unix(timeoutLine),
                date: timeoutLine.format('YYYY-MM-DD'),
                hours: timeoutLine.format('HH'),
                minutes: timeoutLine.format('mm'),
                seconds: timeoutLine.format('ss')
            } : null
        };

        return order;
    };

    vm.changeDeliveryPickupLineMinutes = function(order, modifier) {
        const basePickupLine = order.$activeRescheduleRequest ? order.$activeRescheduleRequest.toPickupLine : order.pickupLine;
        const mPickupLine = moment(basePickupLine).add(modifier, 'minutes');
        const pickupLine = mPickupLine.format('YYYY-MM-DD HH:mm:ss');

        vm.changeDeliveryPickupLine(order, pickupLine);
    };

    vm.changeDeliveryPickupLineCustom = function(order) {
        const pickupLine = order.changeTime.pickupLine.date + ' ' +
            order.changeTime.pickupLine.hours + ':' +
            order.changeTime.pickupLine.minutes + ':' +
            order.changeTime.pickupLine.seconds;

        vm.changeDeliveryPickupLine(order, pickupLine);
    };

    vm.changeDeliveryPickupLine = function(order, pickupLine) {
        const diff = moment(pickupLine).diff(moment(order.order.acceptTimestamp));

        if (diff < 0) {
            vm.pickupLineBeforeAcceptLine = true;
            return;
        }

        vm.pickupLineBeforeAcceptLine = false;

        const bodyName = order.$supportsRescheduleRequests ? 'body_reschedule_support' : 'body_no_reschedule_support';
        const body = translateService.instant('delivery_order_reschedule_request.confirm_create_dialog.' + bodyName, {
            restaurantName: order.restaurant.restaurantName
        });

        dialogService.confirm({
                body,
                title: translateService.instant('delivery_order_reschedule_request.confirm_create_dialog.title'),
                accent: 'warning'
            })
            .pipe(
                filter((ok) => ok)
            )
            .subscribe(() => {
                const loader = notificationStorage.loading(translateService.instant('general.loading'));
                order.pickupLine = pickupLine;

                DeliveryOrderRepository.reschedule(order.deliveryOrderID, pickupLine)
                    .then(() => {
                        return vm.refreshOrder(order);
                    }, (error) => {
                        notificationStorage.dismiss(loader)
                            .danger(error.data.error_message);
                    })
                    .then(() => {
                        vm.addChangeTimeToOrder(order);
                        notificationStorage.dismiss(loader)
                            .success(translateService.instant('general.success'));
                    });
            });
    };

    vm.changeDeliveryCreateLine = function(order) {
        order.createLine = order.changeTime.createLine.date + ' ' + order.changeTime.createLine.hours + ':' + order.changeTime.createLine.minutes + ':' + order.changeTime.createLine.seconds;

        $scope.$emit('showNotification', {
            id: 'changeDeliveryCreateLine',
            loading: true,
            alertType: 'alert-info',
            title: translateService.instant('general.loading')
        });

        DeliveryOrderRepository.rescheduleCreateLine(order.deliveryOrderID, order.createLine)
            .then(function(data) {
                order.showChangeCreateLine = false;

                vm.refreshOrder(order)
                    .then(function() {
                        vm.addChangeTimeToOrder(order);
                        $scope.$emit('showNotification', {
                            id: 'changeDeliveryCreateLine',
                            loading: false,
                            alertType: 'alert-success',
                            title: translateService.instant('general.success')
                        });
                    });
            });
    };

    vm.denyRescheduleRequest = (order, rescheduleRequest) => {
        let loader;

        dialogService.confirm(translateService.instant('delivery_order_reschedule_request.deny_dialog'))
            .pipe(
                filter((ok) => ok),
                tap(() => loader = notificationStorage.loading(translateService.instant('general.loading'))),
                flatMap(() => deliveryOrderScheduleService.denyRescheduleRequest(rescheduleRequest.doRescheduleRequestID))
            )
            .subscribe(() => {
                vm.refreshOrder(order);
                loader.success(translateService.instant('general.success'));
            }, (error) => {
                loader.danger(error.errorMessage);
            });
    };

    vm.confirmRescheduleRequest = (order, rescheduleRequest) => {
        const dialog = {
            title: translateService.instant('delivery_order_reschedule_request.confirm_dialog.title'),
            body: translateService.instant('delivery_order_reschedule_request.confirm_dialog.body', {
                from: format(rescheduleRequest.fromPickupLine, 'YYYY-MM-DD HH:mm'),
                to: format(rescheduleRequest.toPickupLine, 'YYYY-MM-DD HH:mm')
            })
        };

        let loader;

        dialogService.confirm(dialog)
            .pipe(
                filter((ok) => ok),
                tap(() => loader = notificationStorage.loading(translateService.instant('general.loading'))),
                flatMap(() => deliveryOrderScheduleService.confirmRescheduleRequest(rescheduleRequest.doRescheduleRequestID))
            )
            .subscribe(() => {
                vm.refreshOrder(order);
                loader.success(translateService.instant('general.success'));
            }, (error) => {
                loader.danger(error.errorMessage);
            });
    };

    vm.settleRescheduleRequest = (order, rescheduleRequest) => {
        let loader;

        dialogService.confirm(translateService.instant('delivery_order_reschedule_request.settle_dialog'))
            .pipe(
                filter((ok) => ok),
                tap(() => loader = notificationStorage.loading(translateService.instant('general.loading'))),
                flatMap(() => deliveryOrderScheduleService.settleRescheduleRequest(rescheduleRequest.doRescheduleRequestID))
            )
            .subscribe(() => {
                vm.refreshOrder(order);
                loader.success(translateService.instant('general.success'));
            }, (error) => {
                loader.danger(error.errorMessage);
            });
    };

    vm.updateTimeoutSchedule = function(order) {
        if (order.changeTime.timeoutLine) {
            order.timeoutLine = order.changeTime.timeoutLine.date + ' ' + order.changeTime.timeoutLine.hours + ':' + order.changeTime.timeoutLine.minutes + ':' + order.changeTime.timeoutLine.seconds;
        }
        else {
            order.timeoutLine = null;
        }

        $scope.$emit('showNotification', {
            id: 'changeDeliveryTimeout',
            loading: true,
            alertType: 'alert-info',
            title: translateService.instant('general.loading')
        });

        DeliveryOrderRepository.update({
            deliveryTimeoutScheduleID: order.deliveryTimeoutScheduleID,
            timeoutLine: order.timeoutLine,
            timeoutStep: 0
        }, order.deliveryOrderID).then(function(data) {
            vm.refreshOrder(order)
                .then(function() {
                    vm.addChangeTimeToOrder(order);
                    order.changeTimeoutScheme = false;
                    $scope.$emit('showNotification', {
                        id: 'changeDeliveryTimeout',
                        loading: false,
                        alertType: 'alert-success',
                        title: translateService.instant('general.success')
                    });
                });
        });
    };

    vm.parseConfirmed = function(confirmTimestamp, createTimestamp) {
        confirmTimestamp = (Date.parse(confirmTimestamp) / 1000);
        createTimestamp = (Date.parse(createTimestamp) / 1000);

        var difference: any = confirmTimestamp - createTimestamp;
        difference = parseInt(difference);

        var output: any = false;

        if (!isNaN(confirmTimestamp) && !isNaN(createTimestamp) && !isNaN(difference)) {
            if (difference < 60) {
                output = difference + 's';
            }
            else {
                difference = difference / 60;
                difference = parseInt(difference);
                output = difference + 'm';
            }
        }

        return output;
    };

    vm.toggleDatepicker = function($event, value) {
        $event.preventDefault();
        $event.stopPropagation();

        value.open = !value.open;
    };

    vm.paginationRange = function(meta) {
        var difference = 2;
        var from = Math.max(meta.current_page - difference, 1);
        var to = Math.min(meta.current_page + difference, meta.last_page);

        var range = [];
        for (var i = from; i <= to; i++) {
            range.push(i);
        }
        return range;
    };

    vm.setPage = function(page) {
        if (page > 0) {
            vm.meta.current_page = page;
            vm.search();
        }
    };

    vm.updateAdminComment = function(order) {
        $scope.$emit('showNotification', {
            id: 'updateAdminComment',
            loading: true,
            alertType: 'alert-info',
            title: translateService.instant('general.loading')
        });

        DeliveryOrderRepository.update({
                deliveryOrderID: order.deliveryOrderID,
                adminComment: order.changeAdminComment
            }, order.deliveryOrderID)
            .then(function() {
                vm.refreshOrder(order)
                    .then(function() {
                        order.showAdminComment = false;
                        $scope.$emit('showNotification', {
                            id: 'updateAdminComment',
                            loading: false,
                            alertType: 'alert-success',
                            title: translateService.instant('general.success')
                        });
                    });
            });
    };

    vm.updateDistance = function(order) {
        $scope.$emit('showNotification', {
            id: 'updateDistance',
            loading: true,
            alertType: 'alert-info',
            title: translateService.instant('general.loading')
        });

        DeliveryOrderRepository.update({
                deliveryOrderID: order.deliveryOrderID,
                gmDistance: order.distance,
                gmDistanceBike: order.gmDistanceBike
            }, order.deliveryOrderID)
            .then(function() {
                vm.refreshOrder(order)
                    .then(function() {
                        order.changeDistance = false;
                        $scope.$emit('showNotification', {
                            id: 'updateDistance',
                            loading: false,
                            alertType: 'alert-success',
                            title: translateService.instant('general.success')
                        });
                    });
            });
    };

    vm.orderInformation = {};
    vm.refreshOrder = function(order) {
        return Promise.all([
            DeliveryOrderRepository.getDeliveryOrder(order.deliveryOrderID)
                .then(function(deliveryOrder) {
                    angular.merge(order, deliveryOrder);
                    angular.extend(order, deliveryOrder);
                    vm.calculateOrderAttributes(order);
                    vm.orderInformation[deliveryOrder.orderID] = { ...deliveryOrder };
                }),

            MinimalDeliveryOrderRepository.getMinimalOrder(order.deliveryOrderID)
                .then(function(minimalDeliveryOrder) {
                    angular.merge(order, minimalDeliveryOrder);
                    angular.extend(order, minimalDeliveryOrder);
                })
        ]);
    };

    vm.calculateOrderAttributes = function(order) {
        const dateNow = new Date();

        if (typeof order.status === 'undefined') {
            order.status = order.isConfirmed ? 2 : 1;
        }

        if (typeof order.rescheduleRequests !== 'undefined') {
            vm.refreshOrderRescheduleRequests(order);
        }

        if (typeof order.deliverycompany !== 'undefined') {
            order.dCompaniesForOrder = vm.filterCompaniesForOrder(order);
        }

        if (typeof order.driverShouldPickupAt !== 'undefined') {
            order.$pickupSoon = differenceInMinutes(order.driverShouldPickupAt, dateNow) <= 10;
        }
        else if (typeof order.pickupLine !== 'undefined') {
            order.$pickupFromNow = differenceInMinutes(parse(order.pickupLine), dateNow);
            order.$pickupSoon = order.$pickupFromNow <= 10;
        }

        if (typeof order.restaurant !== 'undefined') {
            order.$recentlyPinged = isAfter(parse(order.restaurant.restaurantStatus.requestedAt), subMinutes(dateNow, 2));

            const lowestIosVersion = 4200;
            const lowestAndroidVersion = 4300;
            const managerVersion = parseInt(order.restaurant.restaurantStatus.managerVersion);
            order.$supportsRescheduleRequests = order.restaurant.restaurantStatus.managerOS === 'ios'
                ? managerVersion > lowestIosVersion
                : managerVersion > lowestAndroidVersion;
        }

        if (typeof order.deliverydriver !== 'undefined') {
            order.$transportModeIcon = vm.transportModeIcon(order);
        }

        if (typeof order.deliveryorderactions !== 'undefined') {
            const driverCommentAction = find(order.deliveryorderactions, { type: vm.DeliveryOrderActionType.DRIVER_COMMENT }) as any;
            order.$driverCommentActionDone = !!driverCommentAction;
            order.$driverCommentTime = driverCommentAction ? moment(driverCommentAction.timestamp).format('HH:mm') : '';
        }

        if (typeof order.isDriverLateForCheckin !== 'undefined'
            && typeof order.isDriverLateForDelivery !== 'undefined') {
            order.$lateForCheckin = order.isDriverLateForCheckin;
            order.$lateForDelivered = order.isDriverLateForDelivery;
        }
        else if (typeof order.deliveryorderactions !== 'undefined') {
            const checkinAction = find(order.deliveryorderactions, { type: vm.DeliveryOrderActionType.CHECK_IN }) as any;
            order.$lateForCheckin = order.status == DeliveryOrderStatus.CONFIRMED && !checkinAction && order.$pickupFromNow < 0;

            const deliveredAction = find(order.deliveryorderactions, { type: vm.DeliveryOrderActionType.DELIVERED }) as any;
            const deliverFromNow = differenceInMinutes(order.order.deliveryTimestamp, dateNow);
            order.$lateForDelivered = checkinAction && !deliveredAction && deliverFromNow < 0;
        }

        if (typeof order.createLine !== 'undefined') {
            order.$showDetailedCustomer = true;
            if (moment(order.createLine).isBefore(moment().subtract(1, 'days'))) {
                order.$showDetailedCustomer = vm.user.hasPermission(PermissionName.ORDER_CUSTOMERS_READ_DETAILS_BEFORE_24_HOURS);
            }
        }


        order.$rescheduleWarning = null;
        if (!order.$supportsRescheduleRequests) {
            order.$rescheduleWarning = 'no_reschedule_support';
        }
        else if (!order.$recentlyPinged) {
            order.$rescheduleWarning = 'no_recent_ping';
        }
        else if (order.$pickupSoon) {
            order.$rescheduleWarning = 'pickup_soon';
        }

        if (!order.hasOwnProperty('$permissions')) {
            order.$permissions = {
                deliveryOrderReadPrice: vm.hasPermission(order.deliveryCompanyID, vm.permissions.deliveryOrderReadPrice.globally, vm.permissions.deliveryOrderReadPrice.locally),
                deliveryOrderUpdateTimes: vm.hasPermission(order.deliveryCompanyID, vm.permissions.deliveryOrderUpdateTimes.globally, vm.permissions.deliveryOrderUpdateTimes.locally),
                deliveryOrderUpdateDistance: vm.hasPermission(order.deliveryCompanyID, vm.permissions.deliveryOrderUpdateDistance.globally, vm.permissions.deliveryOrderUpdateDistance.locally),
                orderCustomerReadEmail: vm.user.hasPermission(PermissionName.ORDER_CUSTOMERS_READ_EMAIL),
                orderContactCreate: vm.user.hasPermission(PermissionName.ORDER_CONTACTS_CREATE)
            };
        }

        order.changeAdminComment = order.adminComment;

        if (typeof order.createLine !== 'undefined') {
            vm.addChangeTimeToOrder(order);
        }

        if (typeof order.order !== 'undefined') {
            order.$directionOptions = vm.createDirectionOptions(order);
        }
    };

    vm.refreshOrderRescheduleRequests = (order) => {
        order.$activeRescheduleRequest = null;
        order.$activeRescheduleRequestDiff = null;
        order.$hasUnconfirmedRescheduleRequest = false;

        let latestCompletedReschedule = null;
        const dateNow = new Date();

        angular.forEach(order.rescheduleRequests, (request) => {
            if (request.status === DeliveryOrderRescheduleRequestStatus.Requested) {
                order.$activeRescheduleRequest = request;
                order.$activeRescheduleRequestDiff = differenceInMinutes(request.toPickupLine, request.fromPickupLine);
                order.$hasUnconfirmedRescheduleRequest = isAfter(subMinutes(dateNow, 1), parse(request.createdAt));
            }

            if (
                (request.status === DeliveryOrderRescheduleRequestStatus.Confirmed ||
                    request.status === DeliveryOrderRescheduleRequestStatus.Denied) &&
                (latestCompletedReschedule === null ||
                    isAfter(request.createdAt, latestCompletedReschedule.createdAt))
            ) {
                latestCompletedReschedule = request;
            }
        });

        order.$latestCompletedReschedule = latestCompletedReschedule;
    };

    vm.openRescheduleActivities = (reschedule) => {
        $modal.open({
            template: rescheduleActivitiesTpl,
            controller: 'RescheduleActivitiesModalCtrl as vm',
            windowClass: 'ro-modal modalService',
            resolve: {
                activities: () => {
                    if (
                        reschedule.status === DeliveryOrderRescheduleRequestStatus.Confirmed &&
                        !reschedule.userActivities.some((activity) => activity.type === 'delivery_order_reschedule_request_confirm')
                    ) {
                        reschedule.userActivities.push({
                            type: 'delivery_order_reschedule_request_confirm',
                            user: {
                                firstName: 'Restaurangen',
                                lastName: ''
                            },
                            createdAt: reschedule.confirmedAt
                        });
                    }

                    return reschedule.userActivities;
                }
            }
        });
    };

    vm.isLatestRescheduleRequestDenied = (order) => {
        return order.$latestCompletedReschedule &&
            order.$latestCompletedReschedule.status === DeliveryOrderRescheduleRequestStatus.Denied;
    };

    vm.activate();

    return vm;
}
