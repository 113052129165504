export class OrderRowHeaderColumnCarrierAdminCommentComponent {
    public static readonly controller = OrderRowHeaderColumnCarrierAdminCommentComponent;
    public static readonly controllerAs = 'vm';
    public static readonly template = require('./order-row-header-column-carrier-admin-comment.component.html');
    public static readonly bindings = {
        order: '<'
    };

    public order: any;

    public hasAdminComment() {
        if (typeof this.order.hasAdminComment !== 'undefined') {
            return this.order.hasAdminComment;
        }

        return !! this.order.adminComment;
    }
}
