import { JsonConverter, JsonCustomConvert } from "json2typescript";

@JsonConverter
export class DateConverter implements JsonCustomConvert<Date|null> {
    public serialize(date: Date | null): any {
        if (date === null) {
            return null;
        }

        return date.toUTCString();
    }

    public deserialize(date: string | null): Date | null {
        if (date === null) {
            return null;
        }

        return new Date(date);
    }
}
