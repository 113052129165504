import { Injectable } from '@angular/core';
import { FinanceService } from './finance.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { BaseStorage } from 'app/http/base/base.storage';
import { EconomyPeriod } from './economy-period';

@Injectable()
export class FinanceStorage extends BaseStorage {

    public economyPeriods$: Observable<EconomyPeriod[]>;
    protected economyPeriods: BehaviorSubject<EconomyPeriod[]>;


    constructor(private financeService: FinanceService) {
        super();

        this.economyPeriods = new BehaviorSubject([]);
        this.economyPeriods$ = this.economyPeriods.asObservable();


        this.getEconomyPeriods();
    }

    public getEconomyPeriods(): Observable<EconomyPeriod[]> {
        const observable = this.financeService.getEconomyPeriods();
        observable.subscribe((economyPeriods) => this.economyPeriods.next(economyPeriods));
        return observable;
    }
}
