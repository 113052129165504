angular.module( 'cmsApp.delivery.polygon.factory', [
])

.factory('DeliveryPolygonRepository', function (Restangular, AbstractRepository) {

    function DeliveryPolygonRepository() {
        AbstractRepository.call(this, Restangular, 'delivery/polygon');
    }

    AbstractRepository.extend(DeliveryPolygonRepository);

    return new DeliveryPolygonRepository();
})

;
