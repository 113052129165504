angular.module( 'cmsApp.app.factory', [
    'ui.router',
    'ui.bootstrap'
])

.factory('AppRepository', function (Restangular, AbstractRepository, $q) {

    function AppRepository() {
        this.access_token = null;

        AbstractRepository.call(this, Restangular, 'app');
    }

    AbstractRepository.extend(AppRepository);

    return new AppRepository();
})

;
