import * as angular from "angular";

angular.module('cmsApp.pm', [])

    .controller('PmCtrl', function PmCtrl($scope, $state, $timeout, modalService, pmid, BookingPmRepository) {
    /* jshint validthis: true */
    var vm = this;
    
    vm.loaded = false;
    vm.errormsg = '';
    
    vm.booking = {};
    vm.pmdata = {};

    vm.activate = function() {
        BookingPmRepository.get("", pmid).then(function(data) {
            if(data) {
                vm.loaded = true;
                vm.booking = data;
                
                try {
                    vm.pmdata = JSON.parse(data.pm);
                } catch(e) {
                    vm.loaded = false;
                }
                
                if(vm.pmdata && vm.loaded) {
                    /*$timeout(function() {
                        vm.importPMdata(vm.pmdata);
                    }, 100);*/
                    vm.importPMdata(vm.pmdata);
                } else {
                    if(!data.pm.trim()) {
                        vm.importPMdata(vm.getPMdata());
                        vm.loaded = true;
                    } else {
                        vm.errormsg = 'JSON-fel, kunde inte ladda JSON-data från PM. Kontakta supporten.';
                    }
                }
            } else {
                vm.errormsg = 'Ingen data funnen. Bokningen kanske är borttagen eller tillhör en annan restaurang.';
            }
        }, function(error) {
            vm.errormsg = 'Fel: ' + JSON.stringify(error);
        });
    };
    
    vm.save = function() {
        var data = vm.getPMdata();
        
        $scope.$emit('showNotification', { id: 'PMSave', loading: true, alertType: 'alert-info', title: 'Sparar...', message: 'Dina ändringar sparas' });
        
        BookingPmRepository.customUpdate(data, 'romanager/bookingpm/' + vm.booking.id).then(function(data) {
            $scope.$emit('showNotification', { id: 'PMSave', loading: false, alertType: 'alert-success', title: 'Sparat!', message: 'Dina ändringar har blivit sparade.' });
        });
    };
    
    vm.reset = function() {
        var modalOptions = {
            closeButtonText: 'Avbryt',
            actionButtonText: 'Återställ',
            actionButtonClass: 'btn-warning',
            headerText: 'Är du säker?',
            bodyText: 'Är du säker på att du vill återställa alla fält?'
        };

        modalService.showModal({}, modalOptions).then(function (result) {
            if(result) {
                vm.importPMdata(vm.pmdata);
            }
        });
    };
    
    vm.getPMdata = function() {
        var data = {};
        
        var fields = document.querySelectorAll('[data-field]') as any;
        
        angular.forEach(fields, function(item) {
            data[item.getAttribute('data-field')] = item.value;
        });
        
        return data;
    };
    
    vm.importPMdata = function(data) {
        var fields = document.querySelectorAll('[data-field]') as any;
        
        angular.forEach(fields, function(item) {
            item.value = data[item.getAttribute('data-field')] || '';
        });
    };
    
    vm.activate();

    return vm;
})

;
