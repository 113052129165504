angular.module( 'cmsApp.eatheretable.factory', [
])

.factory('EatHereTableRepository', function (Restangular, AbstractRepository) {

    function EatHereTableRepository() {
        AbstractRepository.call(this, Restangular, 'eatheretables', 'eatHereTableID');
    }

    AbstractRepository.extend(EatHereTableRepository);

    return new EatHereTableRepository();
})

;
