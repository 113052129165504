angular.module( 'cmsApp.delivery.converter.factory', [
    'ui.router',
    'ui.bootstrap'
])

.factory('DeliveryConverterRepository', function (Restangular, AbstractRepository) {

    function DeliveryConverterRepository() {
        AbstractRepository.call(this, Restangular, 'delivery/converter');
    }

    AbstractRepository.extend(DeliveryConverterRepository);

    return new DeliveryConverterRepository();
})

;
