
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BaseService } from "../../base/base.service";
import { HungrigClient, ErrorFactory, ResponseFactory } from "@ro-ngx/core";
import { Observable } from 'rxjs';
import { DeliveryRestaurant } from './delivery-restaurant';

@Injectable()
export class DeliveryRestaurantService extends BaseService {

    public resourceUrl: string = 'delivery/restaurants';

    constructor(public hungrigClient: HungrigClient) {
        super(hungrigClient);
    }

    public getDeliveryRestaurant(clientKey: string): Observable<DeliveryRestaurant> {
        return this.hungrigClient.get(this.url(`/clientkey/${clientKey}`)).pipe(
            map(ResponseFactory.toData),
            catchError(ErrorFactory.throwObservable));
    }

    public updateDeliveryRestaurant(
        id: number, deliveryRestaurant: Partial<DeliveryRestaurant>
    ): Observable<DeliveryRestaurant> {
        return this.hungrigClient.put(this.url(`/${id}`), deliveryRestaurant).pipe(
            map(ResponseFactory.toData),
            catchError(ErrorFactory.throwObservable));
    }
}
