const template = require('./adwords.tpl.html');

import { NotificationStorage } from '@ro-ngx/core';

export class AdwordsComponent {
    public static readonly controller = AdwordsComponent;
    public static readonly controllerAs: string = 'vm';
    public static readonly template: string = template;

    public settings = {};
    public generated: boolean = false;

    constructor(
        protected $stateParams,
        protected AdwordsRepository,
        protected translateService,
        protected notificationStorage: NotificationStorage
    ) {
    }

    public $onInit() {
        this.fetchSettings();
    }

    public save() {
        const notification = this.notificationStorage.loading(this.translateService.instant('general.loading'));

        if (this.generated) {
            this.AdwordsRepository.createSettings(this.$stateParams.clientKey, this.settings)
                .then(
                    () => notification.success(this.translateService.instant('general.success')),
                    () => notification.danger(this.translateService.instant('general.success'))
                );
            this.generated = false;
        } else {
            this.AdwordsRepository.updateSettings(this.$stateParams.clientKey, this.settings)
                .then(
                    () => notification.success(this.translateService.instant('general.success')),
                    () => notification.danger(this.translateService.instant('general.success'))
                );
        }
    }

    protected fetchSettings() {
        this.AdwordsRepository.getSettings(this.$stateParams.clientKey)
            .then((settings) => {
                this.settings = settings.plain();
            }, (error) => {
                if (error.status === 404) {
                    this.generateSettings();
                    return;
                }
                this.notificationStorage.danger(this.translateService.instant('general.error'));
            });
    }

    protected generateSettings() {
        this.AdwordsRepository.generateSettings(this.$stateParams.clientKey)
            .then((settings) => {
                this.settings = settings.plain();
                this.generated = true;
            }, (error) => {
                this.notificationStorage.danger(this.translateService.instant('general.error'));
            });
    }
}
