
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BaseService } from '../base/base.service';
import { ErrorFactory, ResponseFactory, HungrigClient } from '@ro-ngx/core';
import { Observable } from 'rxjs';
import { AppMultiClient } from './app-multi-client';

@Injectable()
export class AppMultiClientService extends BaseService {
    public resourceUrl: string = '';

    constructor(hungrigClient: HungrigClient) {
        super(hungrigClient);
    }

    public getRestaurantClients(clientKey: string): Observable<AppMultiClient[]> {
        return this.hungrigClient.get(this.url(`cms/restaurants/${clientKey}/multiclients`, false)).pipe(
            map(ResponseFactory.toData),
            catchError(ErrorFactory.throwObservable));
    }

    public createClient(clientKey: string, client: Partial<AppMultiClient>): Observable<AppMultiClient> {
        return this.hungrigClient.post(this.url(`cms/restaurants/${clientKey}/multiclients`), client).pipe(
            map(ResponseFactory.toData),
            catchError(ErrorFactory.throwObservable));
    }

    public updateClient(clientKey: string, id: number, client: Partial<AppMultiClient>): Observable<AppMultiClient> {
        return this.hungrigClient.put(this.url(`cms/restaurants/${clientKey}/multiclients/${id}`), client).pipe(
            map(ResponseFactory.toData),
            catchError(ErrorFactory.throwObservable));
    }

    public deleteClient(clientKey: string, id: number): Observable<boolean> {
        return this.hungrigClient.delete(this.url(`cms/restaurants/${clientKey}/multiclients/${id}`)).pipe(
            map(ResponseFactory.toData),
            catchError(ErrorFactory.throwObservable));
    }

    public updateMultipleClients(clientKey: string, clients: AppMultiClient[]): Observable<AppMultiClient[]> {
        return this.hungrigClient.put(this.url(`cms/restaurants/${clientKey}/multiclients`), clients).pipe(
            map(ResponseFactory.toData),
            catchError(ErrorFactory.throwObservable));
    }
}
