angular.module( 'cmsApp.appthemes.appconfig', [

])
.config(AppconfigCtrlConfig)
.controller('AppconfigCtrl', AppconfigCtrl);

function AppconfigCtrlConfig( $stateProvider ) {

}

function AppconfigCtrl( $scope, $state, $sce, modalService, AppthemeRepository, AppconfigRepository, appThemeID ) {
    /* jshint validthis: true */
    var acvm = this;

    acvm.appconfigs = [];
    acvm.addRestaurant = null;
    
    acvm.searchRestaurants = [];

    ////////////////////

    function activate() {
        AppthemeRepository.get('/' + appThemeID + '/restaurants', '').then(function(data) {
            acvm.appconfigs = data;
            
            angular.forEach(acvm.appconfigs, function(item, index) {
                item.optionsExtrasType = item.optionsExtrasType.toString();
            });
            
            $scope.$emit('selectItem', { appThemeID: appThemeID, subItem: 'restaurants' });
            $scope.$emit('setLoading', false);
        });
    }
    
    acvm.selectRestaurant = function(item, model) {
        var appconfig = item.appconfig;
        
        if(!acvm.getById(acvm.appconfigs, appconfig.appConfigID, 'appConfigID'))
        {
            appconfig.appThemeID = appThemeID;
            
            $scope.$emit('showNotification', { id: 'appConfigSave', loading: true, alertType: 'alert-info', title: 'Sparar...', message: 'Dina ändringar sparas' });

            AppconfigRepository.update(appconfig, appconfig.appConfigID).then(function(data) {
                $scope.$emit('showNotification', { id: 'appConfigSave', loading: false, alertType: 'alert-success', title: 'Sparat!', message: 'Dina ändringar har blivit sparade' });
            });
            
            appconfig.restaurant = angular.copy(item);
            
            acvm.appconfigs.push(appconfig);
        } else {
            $scope.$emit('showNotification', { id: 'appConfigExists', loading: false, alertType: 'alert-warning', title: 'Ojdå', message: 'Denna existerar redan' });
        }
        
        acvm.addRestaurant = null;
    };
    
    acvm.refreshRestaurants = function(search) {
        AppconfigRepository.getList('/search/'+search).then(function(data) {
            acvm.searchRestaurants = data;
        });
    };
    
    acvm.getById = function(collection, id, primaryKey) {
        if(!primaryKey) {
            primaryKey = 'id';
        }
        
        var found = null;
        
        angular.forEach(collection, function(item, index) {
            if(item[primaryKey] == id) {
                found = item;
            }
        });
        
        return found;
    };
    
    acvm.save = function(appconfig) {
        $scope.$emit('showNotification', { id: 'acUpdate', loading: true, alertType: 'alert-info', title: 'Sparar...', message: 'Dina ändringar sparas' });
        
        AppconfigRepository.update(appconfig, appconfig.appConfigID).then(function(data) {
            $scope.$emit('showNotification', { id: 'acUpdate', loading: false, alertType: 'alert-success', title: 'Sparat!', message: 'Dina ändringar har blivit sparade' });
        });
    };
    
    acvm.removeAppConfig = function(removeItemID) {
        var modalOptions = {
            closeButtonText: 'Avbryt',
            actionButtonText: 'Ta bort',
            actionButtonClass: 'btn-danger',
            headerText: 'Är du säker?',
            bodyText: 'Är du säker på att du vill ta bort denna kopplingen? Om du gör detta så kommer denna restaurang inte att ha något tema.'
        };

        modalService.showModal({}, modalOptions).then(function (result) {
            if(result) {
                acvm.appconfigs.forEach(function (appconfig, index) {
                    if(appconfig.appConfigID == removeItemID) {   
                        appconfig.appThemeID = 0;
                        AppconfigRepository.update(appconfig, appconfig.appConfigID).then(function(data) {
                            acvm.appconfigs.splice(index, 1);
                        });
                     }
                });
            }
        });
    };
    
    activate();
    return acvm;
}