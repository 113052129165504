
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ErrorFactory, HungrigClient, ResponseFactory } from "@ro-ngx/core";
import { BaseService } from "../../base/base.service";
import { Observable } from 'rxjs';
import { Driver } from './driver';

@Injectable()
export class DriverService extends BaseService {

    public resourceUrl: string = 'delivery/driver';

    constructor(public hungrigClient: HungrigClient) {
        super(hungrigClient);
    }

    public getDriverPositions(deliveryDriverIDs: number[], deliveryCompanyIDs: number[], deliveryDistrictIDs: number[]): Observable<Driver[]> {
        const params = { deliveryDriverIDs, deliveryCompanyIDs, deliveryDistrictIDs };
        return this.hungrigClient.post(this.url(`/positions`), params).pipe(
        map(ResponseFactory.toData),
            catchError(ErrorFactory.throwObservable));
    }

    public getDrivers(perPage: number, page: number, search: string): Observable<Driver[]> {
        return this.hungrigClient.get(this.url(`/paginate`), {
            params: { perPage, page, search },
        }).pipe(
            map(ResponseFactory.toData),
            catchError(ErrorFactory.throwObservable));
    }
}
