import * as angular from 'angular';

import 'app/shared/cache/cache.repository';

var template = require('./cache.component.tpl.html');

angular.module('cmsApp.tech.cache.component', [
    'cmsApp.shared.cache.repository'
])
    .component('roTechCacheComponent', {
        bindings: {},
        controller: roTechCacheComponent,
        controllerAs: 'vm',
        template: template
    });

function roTechCacheComponent($scope, modalService, CacheRepository, translateService) {
    var vm = this;

    vm.tag = '';

    vm.key = '';

    vm.$onInit = function () {

    };

    vm.displayKey = function (tag, key) {
        if (tag.length < 1 && key.length < 1) {
            return false;
        }

        $scope.$emit('showNotification', {id: 'flushKey', loading: true, alertType: 'alert-info', title: translateService.instant('general.loading') });

        CacheRepository.get('', key, {tag: tag})
            .then(function (response) {
                var data = response.plain();

                $scope.$emit('showNotification', {id: 'flushKey', loading: false, alertType: 'alert-success', title: translateService.instant('general.success') });

                var modalOptions = {
                    closeButtonText: translateService.instant('general.cancel'),
                    // actionButtonText: 'Ta bort',
                    // actionButtonClass: 'btn-danger',
                    headerText: key,
                    bodyText: JSON.stringify(data)
                };

                modalService.showModal({}, modalOptions).then(function (result) {
                });
            }, function () {
                $scope.$emit('showNotification', {id: 'flushKey', loading: false, alertType: 'alert-danger', title: translateService.instant('general.error') });
            });
    };

    vm.flushKey = function (tag, key) {
        if (tag.length < 1 && key.length < 1) {
            return false;
        }

        $scope.$emit('showNotification', {id: 'flushKey', loading: true, alertType: 'alert-info', title: translateService.instant('general.loading')});

        CacheRepository.remove(key, [], {tag: tag})
            .then(function () {
                $scope.$emit('showNotification', {id: 'flushKey', loading: false, alertType: 'alert-success', title: translateService.instant('general.success')});
            }, function () {
                $scope.$emit('showNotification', {id: 'flushKey', loading: false, alertType: 'alert-danger', title: translateService.instant('general.error')});
            });
    };
}
