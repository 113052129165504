angular.module( 'cmsApp.admincontact.factory', [
    'ui.router',
    'ui.bootstrap'
])

.factory('AdmincontactRepository', function (Restangular, AbstractRepository) {

    function AdmincontactRepository() {
        AbstractRepository.call(this, Restangular, 'admincontacts');
    }

    AbstractRepository.extend(AdmincontactRepository);

    return new AdmincontactRepository();
})

;
