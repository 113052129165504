angular.module( 'cmsApp.delivery.company.modal.create', [
])
.controller('CreatedeliverycompanyModalCtrl', CreatedeliverycompanyModalCtrl);

function CreatedeliverycompanyModalCtrl( $modalInstance, DeliveryDriverRepository ) {
    /* jshint validthis: true */
    var vm = this;

    vm.required = false;
    vm.submitted = false;

    vm.company = {
		companyName: '',
		username: '',
		password: ''
    };

    activate();

    ////////////////////
    
    function activate() {
    	// body...
    }

    vm.ok = function() {
		$modalInstance.close(vm.company);
    };

    vm.cancel = function() {
        $modalInstance.dismiss('cancel');
    };

    return vm;
}