import * as angular from 'angular';

import '../auth/auth.factory';

angular.module( 'cmsApp.login', [
    'cmsApp.auth.factory'
])

.config(function config( $stateProvider ) {

})

.controller( 'LoginCtrl', function LoginCtrl( $scope, $stateParams, $state, $location, AuthRepository ) {
    /* jshint validthis: true */
    var vm = this;
    
    vm.username = '';
    vm.password = '';

    vm.loginRun = false;
    
    vm.loginError = false;
    
    vm.activate = function() {
        if ($stateParams.accessToken) {
            vm.loginUsingAccessToken($stateParams.accessToken);
        }
    };

    vm.login = function() {
        vm.loginRun = true;
        AuthRepository.authDriver(vm.username, vm.password).then(function(data) {
            AuthRepository.setAccessToken(data.access_token);
            AuthRepository.grant_type = 'driver';
            $location.path('driverfinance');
        }, function(error) {
            vm.loginError = true;
            vm.loginRun = false;
        });
    };

    vm.loginUsingAccessToken = function(accessToken) {
        AuthRepository.setAccessToken(accessToken);
        AuthRepository.grant_type = 'driver';
        $location.path('driverfinance');
    };
    
    vm.activate();
    
    return vm;
})

;
