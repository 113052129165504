import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseComponent } from "./base.component";

@Component({
    selector: 'article-list',
    template: require('./article-list.component.html'),
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ArticleListComponent extends BaseComponent {
}
