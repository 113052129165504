import { format } from 'date-fns';

export class OrderRowHeaderColumnOrderedTimeFormatComponent {
    public static readonly controller = OrderRowHeaderColumnOrderedTimeFormatComponent;
    public static readonly controllerAs = 'vm';
    public static readonly template = require('./order-row-header-column-ordered-time-format.component.html');
    public static readonly bindings = {
        order: '<'
    };

    public order: any;

    public getCustomerOrderdAtTime() {
        return format(this.getCustomerOrderedAt(), 'HH:mm');
    }

    public getCustomerOrderedAt(): string {
        if (typeof this.order.customerOrderedAt !== 'undefined') {
            return this.order.customerOrderedAt;
        }

        return this.order.order.timestamp;
    }
}
