import "core-js/es6";
import "core-js/es7/reflect";
import { ENV } from './enviroment';
require('zone.js/dist/zone');
require('text-encoding');

if (ENV.DEBUG !== 'true') {
    console.debug = function() {};
}
else {
    console.debug = console.log;
}

if (process.env.ENV === 'production') {

} else {
    Error['stackTraceLimit'] = Infinity;
    require('zone.js/dist/long-stack-trace-zone');
}
