import { Injectable } from '@angular/core';
import { BaseService } from "../../base/base.service";
import { HungrigClient } from "@ro-ngx/core";

@Injectable()
export class OrderService extends BaseService {

    public resourceUrl: string = 'delivery/orders';

    constructor(public hungrigClient: HungrigClient) {
        super(hungrigClient);
    }

}
