angular.module('cmsApp.alert.alerter-type.service', [])

    .factory('AlerterTypeService', function(Restangular, AbstractRepository) {

        function AlerterTypeService() {
            AbstractRepository.call(this, Restangular, 'alerterTypes');
        }

        AbstractRepository.extend(AlerterTypeService);

        return new AlerterTypeService();
    })

;