export function restangularConfig(RestangularProvider, ENV) {
    RestangularProvider.setBaseUrl(ENV.BASE_URL);

    RestangularProvider.addResponseInterceptor(function(response, operation) {
        var ret;
        if(operation === 'getList') {
            ret = response.data || [];
            ret.sideData = response.sideData;
            ret.metadata = response.meta;
        } else {
            ret = response.data;
        }
        return ret;
    });
};
