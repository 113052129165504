
import {map, catchError} from 'rxjs/operators';
import { ErrorFactory, HungrigClient, ResponseFactory } from '@ro-ngx/core';
import { Observable } from 'rxjs';
import { BaseService } from "app/http";
import { Injectable } from '@angular/core';

@Injectable()
export class RestaurantMetaTagService extends BaseService {
    public resourceUrl: string = 'cms/restaurants';

    constructor(hungrigClient: HungrigClient) {
        super(hungrigClient);
    }

    public getMetaTags(clientKey: string): Observable<any[]> {
        return this.hungrigClient.get(this.url(`/${clientKey}/metatags`)).pipe(
            map(ResponseFactory.toData))
            .pipe(catchError(ErrorFactory.throwObservable));
    }

    public updateMetaTags(clientKey: string, tags: any[]): Observable<any[]> {
        return this.hungrigClient.put(this.url(`/${clientKey}/metatags`), tags).pipe(
            map(ResponseFactory.toData))
            .pipe(catchError(ErrorFactory.throwObservable));
    }
}
