import { find } from 'lodash';
import { differenceInMinutes } from "date-fns";
import { DeliveryOrderActionType } from '@ro-ngx/delivery';

export class OrderRowHeaderColumnCheckinDiffUnitsComponent {
    public static readonly controller = OrderRowHeaderColumnCheckinDiffUnitsComponent;
    public static readonly controllerAs = 'vm';
    public static readonly template = require('./order-row-header-column-checkin-diff-units.component.html');
    public static readonly bindings = {
        order: '<'
    };

    public order: any;

    public getDifferenceWithUnits() {
        const differenceInMinutes = this.getDifferenceInMinutes();

        if (typeof differenceInMinutes !== 'undefined') {
            return `${differenceInMinutes}m`
        }

        return null;
    }

    public getClass() {
        const differenceInMinutes = this.getDifferenceInMinutes();

        if (typeof differenceInMinutes === 'undefined') {
            return '';
        }

        if (differenceInMinutes < -10) {
            return 'ro-text-danger';
        }

        if (differenceInMinutes < -5) {
            return '';
        }

        if (differenceInMinutes < 5) {
            return 'ro-text-success font-weight-bold';
        }

        return 'ro-text-danger font-weight-bold';
    }

    public getDifferenceInMinutes() {
        if (typeof this.order.driverShouldPickupAt !== 'undefined'
            && typeof this.order.driverCheckedInAt !== 'undefined') {
            return this.order.driverCheckedInAt
                ? differenceInMinutes(this.order.driverCheckedInAt, this.order.driverShouldPickupAt)
                : undefined;
        }

        if (this.hasDriverCheckedIn()) {
            return differenceInMinutes(this.getCheckinAction().timestamp, this.order.pickupLine);
        }

        return undefined;
    }

    public getCheckinAction(): any {
        return find(this.order.deliveryorderactions, {
            type: DeliveryOrderActionType.CheckIn
        });
    }

    public hasDriverCheckedIn(): boolean {
        return !! this.getCheckinAction();
    }
}
