import * as angular from 'angular';

import 'app/alertviewer/viewlog/viewlog.repository';

angular.module( 'cmsApp.alertviewer.service', [
    'cmsApp.alertviewer.view.log.repository',
    'angularMoment'
])
.factory('roAlertviewService', roAlertviewService);

function roAlertviewService(AlertViewLogRepository, $filter, moment) {
    const viewlogs = [];
    const searchInput = {
        alertViewerID: 1,
        createLine: moment().startOf('day').unix(),
        clientKey: undefined,
        orderID: undefined
    };

    const roAlertviewService = {
        viewlogs:       viewlogs,
        searchInput:    searchInput,
        lastSearchPerformed: moment(),
        getAlertviews:  getAlertviews,
        completeAlert:  completeAlert,
        updateAlert:    updateAlert,
        refreshAlerts:  refreshAlerts,
        deleteAlert:    deleteAlert,
        findAlertview:  findAlertview
    };

    return roAlertviewService;

    ////////////
    
    function getAlertviews() {
        AlertViewLogRepository.getList('', getSearchInput()).then(function(response) {
            roAlertviewService.viewlogs = response.plain()
                .slice(0, 80)
                .map(mapAlert);
        });
    }

    function findAlertview(id) {
        return $filter('filter')(roAlertviewService.viewlogs, {alertViewLogID: id}, true)[0];
    }

    function completeAlert(alert) {
        var updatedAlert = unmapAlert(angular.extend({}, alert, {status: 1}));

        return AlertViewLogRepository.update(updatedAlert, alert.alertViewLogID).then(function(response) {
            response = mapAlert(response.plain());

            angular.extend(alert, response);
        });
    }

    function updateAlert(alert) {
        const updatedAlert = unmapAlert(angular.extend({}, alert));

        return AlertViewLogRepository.update(updatedAlert, alert.alertViewLogID).then(function(response) {
            response = mapAlert(response.plain());

            const oldAlert = findAlertview(alert.alertViewLogID);
            angular.merge(oldAlert, response);
        });
    }

    function refreshAlerts() {
        const now = moment();

        AlertViewLogRepository.getList('/changes/' + roAlertviewService.lastSearchPerformed.unix(), getSearchInput()).then(function(response) {
            response = response.plain()
                            .map(mapAlert);

            angular.forEach(response, amendAlert);

            roAlertviewService.lastSearchPerformed = now;
        });
    }

    function amendAlert(alert) {
        var existingAlert = findAlertview(alert.alertViewLogID);

        if (existingAlert) {
            angular.merge(existingAlert, alert);
        } else {
            alert.createdByRefresh = moment();
            roAlertviewService.viewlogs.unshift(alert);
        }
    }

    function deleteAlert(alert) {
        var index = roAlertviewService.viewlogs.indexOf(alert);
        roAlertviewService.viewlogs.splice(index, 1);
    }

    function mapAlert(alert) {
        alert.createLineMoment  = moment(alert.createLine);
        alert.finishLineMoment  = moment(alert.finishLine);
        
        alert.history = alert.status == 1;
        alert.open    = alert.history !== true;

        return alert;
    }

    function unmapAlert(alert) {
        alert.createLine = alert.createLineMoment.unix();
        alert.finishLine = moment().unix();

        return alert;
    }

    function getSearchInput() {
        var searchInput = {};

        for (var input in roAlertviewService.searchInput) {
            if (roAlertviewService.searchInput[input] !== undefined && roAlertviewService.searchInput[input].length !== '') {
                searchInput[input] = roAlertviewService.searchInput[input];
            }
        }

        return searchInput;
    }
}
