export class Summary {
    private static readonly TYPE_SUM: string = 'sum';
    private static readonly TYPE_AVG: string = 'avg';

    private sum;

    public static readonly PROPERTY_HEADERS: Object = {
        'deliveryCompany.companyName': 'Namn',
        'deliveryCount': 'Ant. lev.',
        'totalCost': 'Tot. kos.',
        'averageCost': 'Med. kos.',
        'averageCustomerPrice': 'Med. pris kund',
        'result': 'Resultat',
        'averageConfirmTime': 'Med. bek. tid',
        'averagePickupTime': 'Med. hämt. tid',
        'problemCount': 'Ant. problem',
        'deliveryAssignTimeoutCount': 'Ant. missade order',
        'averageGmDistance': 'Med. distans'
    };

    public static readonly PROPERTY_TYPES: Object = {
        'deliveryCount': Summary.TYPE_SUM,
        'totalCost': Summary.TYPE_SUM,
        'averageCost': Summary.TYPE_AVG,
        'averageCustomerPrice': Summary.TYPE_AVG,
        'result': Summary.TYPE_SUM,
        'averageConfirmTime': Summary.TYPE_AVG,
        'averagePickupTime': Summary.TYPE_AVG,
        'problemCount': Summary.TYPE_SUM,
        'deliveryAssignTimeoutCount': Summary.TYPE_SUM,
        'averageGmDistance': Summary.TYPE_AVG
    };

    public constructor (
        private name: string,
        private entries: any[]
    ) {
        this.calculateSum();
    }

    public getEntries (): Object {
        return this.entries;
    }

    public getSum (): Object {
        return this.sum;
    }

    public getName (): string {
        return this.name;
    }

    public getPropertyHeaders (): Object {
        return Summary.PROPERTY_HEADERS;
    }

    public getPropertyTypes (): Object {
        return Summary.PROPERTY_TYPES;
    }

    private calculateSum (): void {
        this.sum = [];
        for (let property in Summary.PROPERTY_TYPES) {
            this.sum[property] = 0;
            let count = 0;

            for (let entry of this.entries) {
                if (typeof Summary.PROPERTY_TYPES[property] === 'undefined') continue;
                if (entry[property] === 0) continue;
                this.sum[property] += entry[property];
                count++;
            }

            if (Summary.PROPERTY_TYPES[property] === Summary.TYPE_AVG && count > 0) {
                this.sum[property] = this.sum[property] / count;
            }
        }
    }
}
