angular.module( 'cmsApp.delivery.summaries', [
])
.config(DeliverySummariesCtrlConfig)
.controller('DeliverySummariesCtrl', DeliverySummariesCtrl);

function DeliverySummariesCtrlConfig( $stateProvider ) {

}

function DeliverySummariesCtrl( $scope, $state, $q, ENV, modalService, DeliveryCompanyRepository, deliveryCompanyID ) {
    /* jshint validthis: true */
    var cvm = this;
    
    cvm.ENV = ENV;
    
    cvm.summaries = null;
    
    cvm.allowRegen = false;

    ////////////////////

    cvm.activate = function() {
        DeliveryCompanyRepository.get('/' + deliveryCompanyID, '').then(function (data) {
            cvm.company = data;

            DeliveryCompanyRepository.get('/' + deliveryCompanyID + '/summaries', '').then(function (data) {
                cvm.summaries = data;
            });
            
            $scope.$emit('setLoading', false);
            $scope.$emit('selectItem', { company: cvm.company, item: 'summaries' } );
        });
    };

    cvm.activate();
    return cvm;
}